import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { VIEWING_CLEAR_ANNOTATIONS } from './constants';
import { useCallback } from 'react';

export function clearAnnotations() {
  return {
    type: VIEWING_CLEAR_ANNOTATIONS,
  };
}

export function useClearAnnotations() {
  const dispatch = useDispatch();

  const { annotations } = useSelector(
    (state) => ({
      annotations: state.viewing.annotations,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(clearAnnotations(...args));
    },
    [dispatch],
  );

  return {
    annotations,
    clearAnnotations: boundAction,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case VIEWING_CLEAR_ANNOTATIONS:
      return {
        ...state,
        annotations: [],
        filteredAnnotations: [],
      };

    default:
      return state;
  }
}
