import {
  CASE_FETCH_MODERATORS_BEGIN,
  CASE_FETCH_MODERATORS_SUCCESS,
  CASE_FETCH_MODERATORS_FAILURE,
  CASE_FETCH_MODERATORS_DISMISS_FEEDBACK,
} from './constants';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';

export const fetchCaseModerators = createPromiseAction(CASE_FETCH_MODERATORS_BEGIN, (payload) => ({
  inputData: payload,
}));

export function dismissFetchCaseModeratorsFeedback() {
  return {
    type: CASE_FETCH_MODERATORS_DISMISS_FEEDBACK,
  };
}

export function* doFetchCaseModerators(action) {
  const { caseId } = action.payload;

  let res = yield call(api.get, `/cases/${caseId}/roles/moderator`);

  if (res && res.error) {
    yield put({
      type: CASE_FETCH_MODERATORS_FAILURE,
      feedback: {
        message: 'feedback.fetchModeratorsFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_FETCH_MODERATORS_SUCCESS,
    data: { caseModerators: res },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchFetchCaseModerators() {
  yield takeEvery(fetchCaseModerators, withCurrentCaseId(doFetchCaseModerators));
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_FETCH_MODERATORS_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        fetchModeratorsPending: true,
        fetchModeratorsFeedback: null,
      };

    case CASE_FETCH_MODERATORS_SUCCESS:
      // The request is success
      return {
        ...state,
        fetchModeratorsPending: false,
        fetchModeratorsFeedback: action.feedback,
        moderators: action.data.caseModerators,
      };

    case CASE_FETCH_MODERATORS_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchModeratorsPending: false,
        fetchModeratorsFeedback: action.feedback,
      };

    case CASE_FETCH_MODERATORS_DISMISS_FEEDBACK:
      // Dismiss the request failure Feedback
      return {
        ...state,
        fetchModeratorsFeedback: null,
      };

    default:
      return state;
  }
}
