import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import T from 'i18n';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import * as actions from './redux/actions';
import Table from '../common/Table';
import { selectCurrentCaseId } from 'common/selectors';
import { Button } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#F7F7F8',
    height: '100%',
    padding: '0 0.7rem',
    overflow: 'overlay',
  },
  fullsize: {
    height: '100%',
  },
}));

const columnSelector = (currentCaseId, dispatch, dialInNumbers, setDialInNumbers) => {
  return [
    {
      Header: 'case.phoneNumber',
      id: 'phoneNumber',
      Cell: (item) => (
        <div className="customGrid">
          <span>{item.number}</span>
        </div>
      ),
      minWidth: '160px',
      width: '20rem',
      sort: false,
    },
    {
      id: 'case',
      Header: 'case.case',
      Cell: (item) => (
        <div className="customGrid">
          <span>{item.caseName}</span>
        </div>
      ),
      minWidth: '260px',
      width: 'calc(100% - 39rem)',
      sort: false,
    },
    {
      id: 'action',
      Header: 'case.action',
      Cell: (item) => {
        return item && item.caseId === currentCaseId ? (
          <div className="customGrid">
            <Button
              variant="text"
              sx={{ paddingLeft: 0 }}
              onClick={() => {
                dispatch(actions.deleteDialInNumber({ number: item.number })).then((res) => {
                  const updatedRes = dialInNumbers.map((itm) =>
                    itm.number === item.number ? { ...itm, caseId: '', caseName: '' } : itm,
                  );
                  setDialInNumbers(updatedRes);
                });
              }}
            >
              {T.translate('generic.unAssign')}
            </Button>
          </div>
        ) : !item.caseId ? (
          <div className="customGrid">
            <Button
              variant="text"
              sx={{ paddingLeft: 0 }}
              onClick={() => {
                dispatch(actions.updateDialInNumbers({ number: item.number })).then((res) => {
                  const updatedRes = dialInNumbers.map((itm) =>
                    itm.number === item.number ? res : itm,
                  );
                  setDialInNumbers(updatedRes);
                });
              }}
            >
              {T.translate('generic.assign')}
            </Button>
          </div>
        ) : null;
      },
      minWidth: '160px',
      width: '20rem',
      sort: false,
    },
  ];
};

const DialInNumbersPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const currentCaseId = useSelector(selectCurrentCaseId);
  const [dialInNumbers, setDialInNumbers] = useState(null);

  const columns = columnSelector(currentCaseId, dispatch, dialInNumbers, setDialInNumbers);

  useEffect(() => {
    if (!dialInNumbers) dispatch(actions.fetchDialInNumbers()).then((res) => setDialInNumbers(res));
  }, [dialInNumbers, dispatch]);

  return (
    <div className={classes.root}>
      <Grid container spacing={2} className={classes.fullsize}>
        <Grid container item xs={12}>
          <div className="case-table-wrapper admin-table-wrapeer" key="table-wrapper">
            <Container fluid className="h-100">
              <Row className="justify-content-between align-items-center p-2 px-3">
                <Breadcrumb>
                  <Breadcrumb.Item active={true}>
                    {T.translate('case.dialInNumbers')}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Row>
              <Row className="secondRow">
                <Col md={12} className="h-100">
                  <Card>
                    {dialInNumbers && dialInNumbers.length > 0 && (
                      <Table
                        key={Math.random()}
                        data={dialInNumbers}
                        columns={columns}
                        isItemLoaded={({ index }) => !!dialInNumbers[index]}
                        loadMoreItems={() => {}}
                        totalDocumentsCount={0}
                        rowHeight={40}
                        readOnly
                      />
                    )}
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default DialInNumbersPage;
