import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import LngDetector from 'i18next-browser-languagedetector';
import parse from 'html-react-parser';
import MarkdownIt from 'markdown-it';

let md = MarkdownIt({
  breaks: true,
  html: true,
});

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: en,
  },
  // fr: {
  //   translation: {
  //      translation: fr,
  //   }
  // }
};

i18n
  .use({
    type: 'postProcessor',
    name: 'markdown-processor',
    process: (value, key, options, translator) => {
      return parse(md.renderInline(value));
    },
  })
  .use(initReactI18next)
  .use(LngDetector)
  .init({
    resources,
    fallbackLng: 'en',
    // debug: true,
    react: {
      wait: true,
    },
    postProcess: 'markdown-processor',
  });

// i18n
//   .use(initReactI18next) // passes i18n down to react-i18next
//   .init({
//     resources,
//     lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
//     // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
//     // if you're using a language detector, do not define the lng option

//     interpolation: {
//       escapeValue: false, // react already safes from xss
//     },
//   });

export default {
  translate: i18n.t,
  i18n,
};
