import { takeEvery, put, call, take } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  // rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import {
  VIEWING_DOWNLOAD_DOCUMENT_BEGIN,
  VIEWING_DOWNLOAD_DOCUMENT_SUCCESS,
  VIEWING_DOWNLOAD_DOCUMENT_FAILURE,
  VIEWING_DOWNLOAD_DOCUMENT_PROGRESS,
} from './constants';
import api from 'common/api';
import { withCurrentCaseId } from 'common/selectors';
import { mergeItemImmutable, addItemImmutable } from 'utils/arrays';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

export const downloadDocument = createPromiseAction(VIEWING_DOWNLOAD_DOCUMENT_BEGIN);

// worker Saga: will be fired on VIEWING_DOWNLOAD_DOCUMENT_BEGIN actions
export function* doDownloadDocument(action) {
  const {
    payload: {
      caseId,
      fileId,
      original,
      print,
      version,
      versionId,
      burnId,
      burnExternalId,
      variant,
      burnCrossRef = false,
      hyperlink = false,
      publichyperlink = false,
      filenameSelector,
      folderId,
    },
  } = action;

  const channel = yield call(
    api.createDownloadFileChannel,
    original
      ? original
      : `/cases/${caseId}/files/${fileId}${
          version
            ? `?version=${version}&versionId=${versionId}&burnId=${burnId}&burnExternalId=${burnExternalId}&burnCrossRef=${burnCrossRef}&hyperlink=${hyperlink}&publichyperlink=${publichyperlink}&filenameSelector=${filenameSelector}&folderId=${folderId}`
            : variant
              ? `?variant=${variant}`
              : ''
        }`,
    original ? true : false,
  );

  while (true) {
    const { progress = 0, error, success, response: document } = yield take(channel);

    if (error) {
      yield put({
        type: VIEWING_DOWNLOAD_DOCUMENT_FAILURE,
        data: {
          file: {
            fileId,
            error,
            original,
            print,
            ...(original
              ? { originalProgress: null }
              : print
                ? { printProgress: null }
                : { progress: null }),
          },
        },
      });
      // return yield call(rejectPromiseAction, action, res.error);
      yield call(resolvePromiseAction, action);

      return;
    }

    if (success) {
      yield put({
        type: VIEWING_DOWNLOAD_DOCUMENT_SUCCESS,
        data: {
          file: {
            fileId,
            success,
            fileName: document.fileName,
            original,
            print,
            ...(original
              ? { originalProgress: null }
              : print
                ? { printProgress: null }
                : { progress: null }),
          },
        },
      });

      yield call(resolvePromiseAction, action, document);
      return;
    }

    yield put({
      type: VIEWING_DOWNLOAD_DOCUMENT_PROGRESS,
      data: {
        file: {
          fileId,
          original,
          print,
          ...(original
            ? { originalProgress: progress }
            : print
              ? { printProgress: progress }
              : { progress }),
        },
      },
    });
  }
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchDownloadDocument() {
  yield takeEvery(downloadDocument, withCurrentCaseId(doDownloadDocument));
}

export function useDownloadDocument() {
  const dispatch = useDispatch();

  const { downloadDocumentsFeedback, downloadedDocuments } = useSelector(
    (state) => ({
      downloadDocumentsFeedback: state.viewing.downloadDocumentsFeedback,
      downloadedDocuments: state.viewing.downloadedDocuments,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(downloadDocument(...args));
    },
    [dispatch],
  );

  return {
    downloadedDocuments,
    downloadDocument: boundAction,
    downloadDocumentsFeedback,
  };
}

// Redux reducer
export function reducer(state, action) {
  const getKey = ({ original, print, fileId }) =>
    `${fileId}-${original ? 'downloadOriginal' : print ? 'print' : 'download'}-Feedback`;
  const getErrorMessage = ({ original, print }) =>
    original
      ? 'feedback.downloadOriginalFailure'
      : print
        ? 'feedback.printFailure'
        : 'feedback.downloadFailure';
  const getProgressMessage = ({ original, print }) =>
    original
      ? 'feedback.downloadOriginalProgress'
      : print
        ? 'feedback.printProgress'
        : 'feedback.downloadProgress';
  const getProgress = ({ progress, originalProgress, printProgress, original, print }) =>
    Math.round(original ? originalProgress : print ? printProgress : progress);
  switch (action.type) {
    case VIEWING_DOWNLOAD_DOCUMENT_BEGIN + '.TRIGGER':
      return {
        ...state,
        [getKey(action.payload)]: null,
      };

    case VIEWING_DOWNLOAD_DOCUMENT_SUCCESS:
      const indexS = state.downloadedDocuments.findIndex(
        (x) => x.fileId === action.data.file.fileId,
      );
      return {
        ...state,
        downloadedDocuments:
          indexS < 0
            ? addItemImmutable(state.downloadedDocuments, action.data.file)
            : mergeItemImmutable(state.downloadedDocuments, action.data.file, indexS),
        [getKey(action.data.file)]: null,
      };

    case VIEWING_DOWNLOAD_DOCUMENT_PROGRESS:
      const indexP = state.downloadedDocuments.findIndex(
        (x) => x.fileId === action.data.file.fileId,
      );
      return {
        ...state,
        downloadedDocuments:
          indexP < 0
            ? addItemImmutable(state.downloadedDocuments, action.data.file)
            : mergeItemImmutable(state.downloadedDocuments, action.data.file, indexP),
        [getKey(action.data.file)]: {
          message: getProgressMessage(action.data.file),
          params: {
            file: action.data.file.fileId,
            progress: getProgress(action.data.file),
          },
          infoSticky: true,
          error: false,
        },
      };

    case VIEWING_DOWNLOAD_DOCUMENT_FAILURE:
      const indexF = state.downloadedDocuments.findIndex(
        (x) => x.fileId === action.data.file.fileId,
      );
      return {
        ...state,
        downloadDocumentsFeedback: { error: true },
        downloadedDocuments:
          indexF < 0
            ? addItemImmutable(state.downloadedDocuments, action.data.file)
            : mergeItemImmutable(state.downloadedDocuments, action.data.file, indexF),
        [getKey(action.data.file)]: {
          message: getErrorMessage(action.data.file),
          params: { file: action.data.file.fileId },
          error: action.data.file.error,
          infoSticky: false,
        },
      };

    default:
      return state;
  }
}
