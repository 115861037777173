import React from 'react';
import T from 'i18n';
import moment from 'moment';
import { addAlpha } from 'utils/color';
import { SELECTION_COLOR } from '../constants';

type AnnotationItemProps = {
  highlight: any;
  selectedHighlight: any;
  scrollToHighlightHandler: (highlight: any) => void;
  sortMode: any;
};

export default ({
  highlight,
  selectedHighlight,
  scrollToHighlightHandler,
  sortMode,
}: AnnotationItemProps) => {
  return (
    <li
      key={highlight.annotation.uid}
      className="sidebar-view__highlight"
      style={{ ...(sortMode && { cursor: 'move' }) }}
      onClick={() => {
        if (!sortMode) scrollToHighlightHandler && scrollToHighlightHandler(highlight);
      }}
      {...(selectedHighlight &&
        selectedHighlight.annotation.uid === highlight.annotation.uid && {
          style: { backgroundColor: addAlpha(SELECTION_COLOR, 70) },
        })}
    >
      <div>
        {highlight.annotation.data.comment && highlight.annotation.type !== 'TextSignature' && (
          <div>
            <strong>{highlight.annotation.data.comment || ''}</strong>
          </div>
        )}
        {highlight.annotation.data.linkId && highlight.annotation.type !== 'TextAnnotation' && (
          <div>
            <a
              onClick={(e) => e.stopPropagation()}
              href={highlight.annotation.href}
              target="_blank"
              rel="noopener noreferrer"
            >
              {highlight.annotation.data.linkId}
            </a>
          </div>
        )}
        {highlight.annotation.type === 'TextAnnotation' && highlight.annotation.text && (
          <div>{highlight.annotation.text}</div>
        )}
        {highlight.annotation.selectedText ? (
          <blockquote style={{ marginTop: '0.5rem' }}>
            {`${highlight.annotation.selectedText.slice(0, 90).trim()}…`}
          </blockquote>
        ) : null}
        {highlight.annotation.data.image ? (
          <div className="highlight__image" style={{ marginTop: '0.5rem' }}>
            <img src={highlight.annotation.data.image} alt={'Screenshot'} />
          </div>
        ) : null}
        {(highlight.annotation.type === 'TextSignature' ||
          highlight.annotation.type === 'TextAnnotation') && (
          <span>
            <span>
              <strong>
                {highlight.annotation.type === 'TextSignature'
                  ? T.translate('common.signedBy')
                  : T.translate('common.referenceBy')}
                :{' '}
              </strong>
              {highlight.createdBy.name}
              <span style={{ float: 'right' }}>
                <strong>{T.translate('case.team')}: </strong>
                {highlight.createdBy.teamName}
              </span>
            </span>
            <br />
            <strong>{T.translate('common.when')}: </strong>
            {moment(highlight.createdBy.date).format('DD/MM/YYYY HH:mm')}
          </span>
        )}
      </div>
      <div className="highlight__location">Page {highlight.annotation.pageNumber}</div>
    </li>
  );
};
