import React from 'react';
import { useAppStateContext } from '../../../AppStateProvider/AppStateProvider';
import { Button, Container, Grid, Typography, Paper } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { ErrorIcon } from '../../../../icons/ErrorIcon';

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      padding: '1.5em',
      borderRadius: '8px',
    },
    paperContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    errorIcon: {
      right: 'calc(100% + 18px)',
    },
    downloadButton: {
      marginTop: '2em',
      '& svg': {
        position: 'relative',
        left: '-5px',
      },
    },
    viewButton: {
      marginTop: '2em',
      '& svg': {
        position: 'relative',
        left: '-5px',
      },
    },
  }),
);

interface ConnectionFailedProps {
  openModal: () => void;
}

export function ConnectionFailed({ openModal }: ConnectionFailedProps) {
  const classes = useStyles();
  const { dispatch } = useAppStateContext();

  return (
    <>
      <Container>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={5}>
            <Typography variant="h5" gutterBottom style={{ position: 'relative' }}>
              <div className={classes.errorIcon}>
                <ErrorIcon />
              </div>
              Connection failed
            </Typography>

            <Typography variant="body1" gutterBottom>
              It's possible that you're behind a firewall that will need to be updated by your
              network administrator.
            </Typography>

            <Button
              variant="contained"
              color="primary"
              onClick={() => dispatch({ type: 'next-pane' })}
              className={classes.downloadButton}
            >
              Ok
            </Button>
          </Grid>

          <Grid item xs={5} className={classes.paperContainer}>
            <Paper className={classes.paper}>
              <Typography variant="body1" gutterBottom>
                To conduct a video call, your internet needs to be able to communicate with our
                cloud service provider.
              </Typography>

              <Typography variant="body1">
                Learn{' '}
                <a
                  href="https://www.trialviewlive.com/public/TrialView%20Firewall%20Requirements%20Doc.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  how to configure your firewall correctly
                </a>
                .
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
