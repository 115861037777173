import React, { useState } from 'react';
import T from 'i18n';
import { Modal } from 'features/common/MaterialBasedComponents';
import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import { SelectForm, Spinner, TextForm } from 'features/common';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { caseRolesModalAction } from './enums';
import { emailValidation } from 'utils/regex';

const AddNewMember = ({ show, action, handleClose, handleSave, roles }) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm();
  const [invitationcount, setInvitationCount] = useState(1);

  const inputs = [];

  for (let i = 1; i <= invitationcount; i++) {
    inputs.push(
      <Grid item container key={i} xs={12} columnSpacing={4} sx={{ mt: '1rem' }}>
        <Grid item xs={5}>
          <TextForm
            {...register(`users[${i - 1}].email`, {
              validate: {
                required: (val) => !!val,
                isInvalidEmail: (val) => emailValidation.test(val),
              },
            })}
            label={T.translate('case.emailAddress')}
            placeholder={T.translate('case.emailAddressPlaceholder')}
            defaultValue={''}
            errors={errors}
            autofocus
          />
        </Grid>
        <Grid item xs={6}>
          <SelectForm
            label={T.translate('case.caseRole')}
            name={`users[${i - 1}].roleId`}
            defaultValue={''}
            valueKey="roleId"
            labelKey="name"
            options={roles}
            getOptionLabel={(option) => `${option['name']} - ${option['description'] || ''}`}
            control={control}
            errors={errors}
            rules={{ required: true }}
            menuPosition="fixed"
          />
        </Grid>
        {i !== 1 && (
          <Grid item xs={1}>
            <IconButton
              style={{ marginTop: '-10px' }}
              onClick={() => setInvitationCount(invitationcount - 1)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Grid>
        )}
      </Grid>,
    );
  }

  return (
    <Modal
      show={show}
      title={T.translate('case.addNewMember')}
      handleClose={() => {
        setInvitationCount(1);
        handleClose();
      }}
      handleSave={handleSubmit((data) => {
        data.users.map((user) => handleSave(action, user));
        setInvitationCount(1);
        handleClose();
      })}
      saveTitle={T.translate('generic.add')}
    >
      <Form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Form.Group>
          <Grid container>{inputs}</Grid>
          <Grid container sx={{ mt: '0.5rem' }}>
            <Grid item xs={6}>
              <Button
                color="primary"
                startIcon={<AddCircleIcon />}
                onClick={() => setInvitationCount(invitationcount + 1)}
              >
                {T.translate('case.addAnotherPerson')}
              </Button>
            </Grid>
          </Grid>
        </Form.Group>
      </Form>
    </Modal>
  );
};

const RemoveMember = ({ show, action, handleClose, handleSave, selectedRows }) => {
  const [loading, setLoading] = useState(false);
  return (
    <Modal
      show={show}
      title={T.translate('case.removeMember')}
      handleClose={handleClose}
      handleSave={() => {
        setLoading(true);
        selectedRows.map((row) => handleSave(action, row));
        setLoading(false);
        handleClose();
      }}
      saveTitle={T.translate('generic.remove')}
    >
      {loading ? (
        <Spinner />
      ) : (
        <div className="removeUser">
          <span>{T.translate('case.removeUserMsg1')}</span>
          <ul>
            {selectedRows.map((row, index) => (
              <li key={index}>{row.email}</li>
            ))}
          </ul>
          <span>{T.translate('case.removeUserMsg2')}</span>
        </div>
      )}
    </Modal>
  );
};

const CaseRolesModal = ({ show, action, handleClose, handleSave, selectedRows, roles }) => {
  return (
    <>
      {action === caseRolesModalAction.addNewMember && (
        <AddNewMember
          show={show}
          action={action}
          handleClose={handleClose}
          handleSave={handleSave}
          roles={roles}
        />
      )}
      {action === caseRolesModalAction.removeMember && (
        <RemoveMember
          show={show}
          action={action}
          handleClose={handleClose}
          handleSave={handleSave}
          selectedRows={selectedRows}
        />
      )}
    </>
  );
};

export default CaseRolesModal;
