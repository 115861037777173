import { takeLatest, call } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import { VIEWING_FETCH_RECIPIENTS_LOOKUP_BEGIN } from './constants';
import api from 'common/api';
import { withCurrentCaseId } from 'common/selectors';

export const fetchRecipientsLookup = createPromiseAction(
  VIEWING_FETCH_RECIPIENTS_LOOKUP_BEGIN,
  (payload) => ({
    lookup: payload,
  }),
);

// worker Saga: will be fired on VIEWING_FETCH_RECIPIENTS_LOOKUP_BEGIN actions
export function* doFetchRecipientsLookup(action) {
  const {
    payload: { caseId, lookup },
  } = action;

  const res = yield call(
    api.get,
    `/cases/${caseId}/filters?type=recipients&search=${encodeURI(lookup)}`,
  );

  if (res && res.error) {
    yield call(rejectPromiseAction, action, res.error);
  }

  yield call(resolvePromiseAction, action, res);
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchFetchRecipientsLookup() {
  yield takeLatest(fetchRecipientsLookup, withCurrentCaseId(doFetchRecipientsLookup));
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case VIEWING_FETCH_RECIPIENTS_LOOKUP_BEGIN + '.TRIGGER':
      return {
        ...state,
      };

    default:
      return state;
  }
}
