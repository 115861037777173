import React, { useState, useEffect, Component } from 'react';
import { makeStyles } from '@mui/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import withAuth from 'features/auth/withAuth';
import NavBar from 'features/common/NavBar';
import Footer from 'features/common/Footer';
import classnames from 'classnames';
import T from 'i18n';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  selectPermissions,
  selectParams,
  selectTwilioSettings,
  selectBBCDemo,
} from 'common/selectors';
import * as actions from 'features/viewing/redux/actions';
import * as actionsCase from 'features/case/redux/actions';
import { useFetchRecentlyPresentedToday } from 'features/conference/redux/hooks';
import { openNewBackgroundTab } from 'utils/browser';
import Tooltip from '@mui/material/Tooltip';
import FullScreenButton from './FullScreenButton';
import Splitter, { SplitDirection } from '@devbookhq/splitter';
import FilterPresentedDocuments, { defaultFilter } from './FilterPresentedDocuments';
import { Avatar, Badge, ClickAwayListener, Popper } from '@mui/material';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import { isAfter, isBefore, format, addMinutes, subSeconds } from 'date-fns';
import AiToolsResponseWrapper from './AiToolsResponseWrapper';

const useStyles = makeStyles({
  page: {
    height: '200px',
    width: 'calc(100% - 1rem)',
    cursor: 'pointer',
    padding: '0.5rem',
    margin: '0.5rem',
    border: '1px solid darkgray',
    display: 'flex',
    flexDirection: 'column',
    '&:hover': {
      backgroundColor: 'rgba(216, 216, 216, 0.5)',
    },
  },
});

const Header = React.memo(({ documentsFilter, setDocumentsFilter }) => {
  const [showFilters, setShowFilters] = useState(false);
  const [filterPosition, setFilterPosition] = useState(null);

  const filterIconClicked = (event) => {
    setShowFilters(!showFilters);
    setFilterPosition(event.currentTarget);
  };

  return (
    <div
      style={{
        display: 'flex',
        background: '#ccc',
        fontSize: '1.2rem',
        height: '5rem',
      }}
    >
      <div
        style={{
          flexGrow: 1,
          textAlign: 'center',
          marginRight: '0.5rem',
          alignSelf: 'center',
        }}
      >
        Last presented
      </div>
      <ClickAwayListener onClickAway={() => setShowFilters(false)}>
        <div>
          <div onClick={filterIconClicked} className="filter-icon-section">
            <Badge
              badgeContent={documentsFilter === defaultFilter ? 0 : 1}
              overlap="circular"
              color="primary"
              showZero={false}
            >
              <Avatar className="filterRolesButton">
                <FilterListRoundedIcon fontSize="small" style={{ color: '#9a9a9a' }} />
              </Avatar>
            </Badge>
          </div>
          <Popper
            id={'filter-popover'}
            className="role-popper-shadow"
            open={Boolean(showFilters)}
            anchorEl={filterPosition}
            disablePortal={true}
            placement="bottom-end"
          >
            <FilterPresentedDocuments
              documentsFilter={documentsFilter}
              setDocumentsFilter={setDocumentsFilter}
            />
          </Popper>
        </div>
      </ClickAwayListener>
    </div>
  );
});

const PresentedDocuments = ({ caseId }) => {
  const classes = useStyles();
  const { fetchRecentlyPresentedToday } = useFetchRecentlyPresentedToday();
  const [recentlyPresented, setRecentlyPresented] = useState([]);

  const [documentsFilter, setDocumentsFilter] = useState(defaultFilter);

  useEffect(() => {
    let timer;
    timer = setInterval(() => {
      fetchRecentlyPresentedToday().then((res) => setRecentlyPresented(res.reverse()));
    }, 10000);

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [fetchRecentlyPresentedToday]); // empty bracket calls the useEffect callback on initial render

  return (
    <div>
      <Header documentsFilter={documentsFilter} setDocumentsFilter={setDocumentsFilter} />
      <PerfectScrollbar
        options={{ suppressScrollX: true }}
        style={{ width: '100%', height: 'calc(100% - 5rem)' }}
      >
        {recentlyPresented
          .filter((document) => {
            const date = new Date(document.viewed);

            return (
              isAfter(date, subSeconds(documentsFilter.from, 1)) &&
              isBefore(date, addMinutes(documentsFilter.to, 1))
            );
          })
          .map(({ viewed, name, id, type, presentedBy }) => {
            return (
              <Tooltip placement="top" title={`Presented by: ${presentedBy}`}>
                <div
                  className={classes.page}
                  onClick={() => openNewBackgroundTab(`/case/${caseId}/files/${id}`)}
                >
                  <div style={{ fontWeight: 'bold' }}>{id}</div>
                  <div style={{ whiteSpace: 'pre-wrap' }}>{name}</div>
                  <div style={{ flexGrow: 1 }}>File type: {type}</div>
                  <div style={{}}>{format(new Date(viewed), 'HH:mm')}</div>
                  <div style={{ fontSize: '0.7rem' }}>{format(new Date(viewed), 'dd/MM/yyyy')}</div>
                </div>
              </Tooltip>
            );
          })}
      </PerfectScrollbar>
    </div>
  );
};

class Transcript extends Component {
  static propTypes = {};

  render() {
    if (this.props.fetchCasesPending || this.props.fetchCaseDetailsPending) return null;

    const isPublic = this.props.hearingRoomMode === 'public';
    const isPrivate = this.props.hearingRoomMode !== 'public';
    const modeText = T.translate(isPublic ? 'case.publicHearingRoom' : 'case.privateHearingRoom');
    const brandText = [
      this.props.case.name +
        `${this.props.case.claimNumber ? ` ${this.props.case.claimNumber}` : ''}`,
      modeText,
    ]
      .filter(Boolean)
      .join(' - ');

    return (
      <div className={classnames({ dark: isPublic, blue: isPrivate }, 'case-theatre')}>
        <NavBar
          showLogo
          theme={classnames({ dark: isPublic, blue: isPrivate })}
          brandText={brandText}
          additionalButton={[<FullScreenButton />].filter(Boolean)}
        />
        <div style={{ height: 'calc(100% - 2rem - 61px)' }}>
          <Splitter
            direction={SplitDirection.Horizontal}
            initialSizes={this.props.isBBCDemo ? [30, 70] : [80, 20]}
            minWidths={this.props.isBBCDemo ? [180, 470] : [500, 150]}
          >
            <iframe
              style={{ width: '100%', height: '101%' }}
              title="transcript"
              src={isPublic ? this.props.transcriptLink : this.props.transcriptLinkPrivate}
            ></iframe>
            {this.props.isBBCDemo ? (
              <div style={{ width: '100%', height: '100%' }}>
                {this.props.children}
                {!this.props.file && <AiToolsResponseWrapper />}
              </div>
            ) : (
              <PresentedDocuments caseId={this.props.case.id} />
            )}
          </Splitter>
        </div>
        <Footer theme={classnames({ dark: isPublic, blue: isPrivate })} />
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state, { hearingRoomMode }) {
  const {
    home: { cases, fetchCasesPending },
    case: { caseDetails, fetchCaseDetailsPending },
  } = state;

  const { case: currentCase, file } = selectParams(state);
  const { hearingRoom } = selectPermissions(state);

  const { transcriptLink, transcriptLinkPrivate } = selectTwilioSettings(state);
  const isBBCDemo = selectBBCDemo(state);

  return {
    case: cases.find((c) => c.id === currentCase) || {},
    fetchCasesPending,
    //if we loaded case details once no need to check for pending
    fetchCaseDetailsPending: !!caseDetails ? false : fetchCaseDetailsPending,
    hearingRoom,
    transcriptLink,
    transcriptLinkPrivate,
    isBBCDemo,
    file,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
    actionsCase: bindActionCreators({ ...actionsCase }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withAuth(Transcript, { forceLogin: true }));
