import {
  CASE_REMOVE_GROUP_BEGIN,
  CASE_REMOVE_GROUP_SUCCESS,
  CASE_REMOVE_GROUP_FAILURE,
  CASE_REMOVE_GROUP_DISMISS_FEEDBACK,
} from './constants';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';
import { deleteItemImmutable } from 'utils/arrays';

export const removeGroup = createPromiseAction(CASE_REMOVE_GROUP_BEGIN);

export function dismissRemoveGroupFeedback() {
  return {
    type: CASE_REMOVE_GROUP_DISMISS_FEEDBACK,
  };
}

export function* doRemoveGroup(action) {
  const { caseId, groupId } = action.payload;
  let res = yield call(api.del, `/cases/${caseId}/groups/${groupId}`);

  if (res && res.error) {
    yield put({
      type: CASE_REMOVE_GROUP_FAILURE,
      feedback: { message: 'feedback.removeGroupFailure', error: res.error, retryAction: action },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_REMOVE_GROUP_SUCCESS,
    data: { groupId },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchRemoveGroup() {
  yield takeLatest(removeGroup, withCurrentCaseId(doRemoveGroup));
}

export function useRemoveGroup() {
  const dispatch = useDispatch();

  const { removeGroupPending, removeGroupFeedback } = useSelector(
    (state) => ({
      removeGroupPending: state.case.removeGroupPending,
      removeGroupFeedback: state.case.removeGroupFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(removeGroup(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissRemoveGroupFeedback());
  }, [dispatch]);

  return {
    removeGroup: boundAction,
    removeGroupPending,
    removeGroupFeedback,
    dismissRemoveGroupFeedback: boundDismissFeedback,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_REMOVE_GROUP_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        removeGroupPending: true,
        removeGroupFeedback: null,
      };

    case CASE_REMOVE_GROUP_SUCCESS:
      // The request is success
      const index = state.groups.findIndex((x) => x.id === action.data.groupId);
      return {
        ...state,
        removeGroupPending: false,
        removeGroupFeedback: action.feedback,
        groups: deleteItemImmutable(state.groups, index),
      };

    case CASE_REMOVE_GROUP_FAILURE:
      // The request is failed
      return {
        ...state,
        removeGroupPending: false,
        removeGroupFeedback: action.feedback,
      };

    case CASE_REMOVE_GROUP_DISMISS_FEEDBACK:
      // Dismiss the request failure error
      return {
        ...state,
        removeGroupFeedback: null,
      };

    default:
      return state;
  }
}
