import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Divider, Link, MenuItem, Select, TextField } from '@mui/material';
import { useUpdateTranscriptItem } from '../../redux/updateTranscriptItem';
import debounce from 'utils/debounce';

const useStyles = makeStyles(() =>
  createStyles({
    transcriptItem: {
      display: 'flex',
      flexDirection: 'row',
      padding: '0.5rem 1rem',
      fontSize: '1.2rem',
    },
    transcriptItemSpeaker: {
      width: '20%',
      marginRight: '1rem',
    },
    transcriptItemSpeakerHover: {
      '&.MuiOutlinedInput-root': {
        width: '100%',
        '& fieldset': {
          borderColor: 'transparent',
        },
        '&:hover fieldset': {
          borderColor: 'transparent',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'transparent',
        },
      },
      '& .MuiInputBase-input': {
        padding: '0 !important',
        fontSize: '1.2rem',
      },
    },
    transcriptItemText: {
      width: '80%',
      marginLeft: '1rem',
    },
    transcriptItemLink: {
      '&:hover': { cursor: 'pointer' },
    },
    transcriptItemTextArea: {
      '& .MuiOutlinedInput-root': {
        padding: 0,
        fontSize: '1.2rem',
        '&.Mui-focused textarea': {
          backgroundColor: '#d6d6d6',
          padding: '0.5rem',
        },
        '& fieldset': {
          borderColor: 'transparent',
        },
        '&:hover fieldset': {
          borderColor: 'transparent',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'transparent',
        },
      },
    },
    transcriptItemOffset: {
      fontSize: '1rem',
    },
  }),
);

interface TranscriptEditableItemsProps {
  index: number;
  videoDetails: any;
  transcriptItem: {
    id: string;
    recordedSpeaker: string;
    speaker: string;
    offset: string;
    text: string;
    seconds: string;
  };
  isCtrlPressed: boolean;
  handlePlayerSliderChange: Function;
}

export default function TranscriptEditableItems({
  index,
  videoDetails,
  transcriptItem,
  isCtrlPressed,
  handlePlayerSliderChange,
}: TranscriptEditableItemsProps) {
  const classes = useStyles();
  const { updateTranscriptItem } = useUpdateTranscriptItem();

  const handleSpeakerChange = (val: { name: string; value: string }) => {
    if (transcriptItem.speaker !== val.value) {
      updateTranscriptItem({
        fileId: videoDetails.id,
        transcriptId: val.name,
        transcriptItem: {
          ...transcriptItem,
          speaker: val.value,
        },
      });
    }
  };

  const handleTextChange = debounce((val: string) => {
    if (val !== transcriptItem.text)
      updateTranscriptItem({
        fileId: videoDetails.id,
        transcriptId: transcriptItem.id,
        transcriptItem: {
          ...transcriptItem,
          text: val,
        },
      });
  }, 500);

  return (
    <li key={index} className={classes.transcriptItem}>
      <div className={classes.transcriptItemSpeaker}>
        <div>
          <Select
            className={classes.transcriptItemSpeakerHover}
            variant="outlined"
            name={transcriptItem.id}
            value={transcriptItem.recordedSpeaker}
            onChange={(e) => handleSpeakerChange(e.target)}
            IconComponent={() => null}
          >
            {videoDetails.recordedSpeakers.map((rSpk: { name: string }, idx: number) => (
              <MenuItem key={idx} value={rSpk.name}>
                {rSpk.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <span className={classes.transcriptItemOffset}>{transcriptItem.offset}</span>
      </div>
      <div>
        <Divider orientation="vertical" sx={{ borderColor: 'rgb(0 0 0 / 52%)' }} />
      </div>
      <div className={classes.transcriptItemText}>
        {!isCtrlPressed ? (
          <TextField
            className={classes.transcriptItemTextArea}
            variant="outlined"
            defaultValue={transcriptItem.text}
            onChange={(e) => handleTextChange(e.target.value)}
            fullWidth
            multiline
          />
        ) : (
          <Link
            underline="hover"
            className={classes.transcriptItemLink}
            color={'#777'}
            onClick={() => {
              handlePlayerSliderChange(parseFloat(transcriptItem.seconds));
            }}
          >
            {transcriptItem.text}
          </Link>
        )}
      </div>
    </li>
  );
}
