import React, {
  useImperativeHandle,
  RefObject,
  useEffect,
  useRef,
  forwardRef,
  useCallback,
} from 'react';
import T from 'i18n';
import {
  Autocomplete,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { ClearIcon, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import LookupMui from 'features/common/MaterialBasedComponents/Lookup';
import { useDispatch } from 'react-redux';
import { fetchAuthorsLookup } from 'features/viewing/redux/fetchAuthorsLookup';
import { fetchRecipientsLookup } from 'features/viewing/redux/fetchRecipientsLookup';
import { fetchCreatedByLookup } from 'features/viewing/redux/fetchCreatedByLookup';
import debounce from 'utils/debounce';
import { fetchDocumentsDocFinder } from '../redux/fetchDocumentsDocFinder';
import { useSelector } from 'react-redux';
import { selectCurrentFile } from 'common/selectors';
import { makeStyles } from '@mui/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSetShowDocFinderFilter } from '../redux/setShowDocFinderFilter';
import TrialBundlesMenu from '../SearchCenter/TrialBundlesMenu';
import { Card } from 'react-bootstrap';
import {
  AccountCircle,
  AccountCircleOutlined,
  FilterAlt,
  FilterAltOff,
  FolderCopy,
  ManageAccounts,
  Search,
  Style,
} from '@mui/icons-material';
import DatePicker from 'features/common/Filters/DatePicker';
import { useFetchTags } from '../redux/fetchTags';

const useStyles = makeStyles({
  root: {
    margin: '0 0 0 -1rem',
    padding: '1rem 1rem 1rem 0',
    width: '100%',
    '& .MuiGrid-item': {
      paddingTop: '0 !important',
    },

    '& .MuiInput-input ': {
      padding: '4px 4px 4px 0px !important',
    },
  },
});

// const DateInputCustom = (props: any) => (
//   <DateInput
//     {...props}
//     mui
//     placeholder={props.placeholder}
//     style={{ marginTop: '0', ...props.style }}
//     variant="standard"
//     sx={{
//       '& .Mui-focused .MuiIconButton-root': {
//         visibility: props.value ? 'visible !important' : 'hidden',
//       },
//     }}
//     InputProps={{
//       startAdornment: <CalendarMonth style={{ marginRight: '0.5rem' }} />,
//       endAdornment: (
//         <IconButton
//           size="small"
//           style={{ padding: '0.3rem', visibility: 'hidden' }}
//           onMouseDown={() => props.onClear && props.onClear()}
//         >
//           <ClearIcon style={{ fontSize: '1.2rem' }} />
//         </IconButton>
//       ),
//     }}
//   />
// );

const TrialBookFilter = React.forwardRef(
  ({ trialBundlesMenu, setTrialBundlesMenu, onChange, value, ...rest }: any, ref: any) => {
    return (
      <>
        <Typography
          style={{
            marginLeft: 0,
            alignSelf: 'center',
            marginTop: '0.5rem',
            fontWeight: 'bold',
          }}
        >
          {T.translate('filters.trialBundles')}
        </Typography>
        <TextField
          variant="standard"
          sx={{
            '& .MuiInput-root:hover .MuiIconButton-root': {
              visibility: (value || []).length > 0 ? 'visible !important' : 'hidden',
            },
          }}
          style={{ width: '100%' }}
          placeholder="Select trial books"
          value={
            (value || []).length > 0
              ? value[0].name + (value.length > 1 ? ` & ${value.length - 1} more ...` : '')
              : ''
          }
          InputProps={{
            readOnly: true,
            startAdornment: <FolderCopy style={{ marginRight: '0.5rem' }} />,
            endAdornment: (
              <IconButton
                size="small"
                style={{ padding: '0.3rem', visibility: 'hidden' }}
                onClick={(e: any) => {
                  onChange([]);
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                <ClearIcon style={{ fontSize: '1.2rem' }} />
              </IconButton>
            ),
          }}
          onClick={(e) => setTrialBundlesMenu(e.currentTarget)}
        />
        {trialBundlesMenu && (
          <TrialBundlesMenu
            ref={ref}
            value={value || []}
            onChange={onChange}
            anchorEl={trialBundlesMenu}
            onClose={() => setTrialBundlesMenu(null)}
            isOpen
            noClear
            width="30rem"
          />
        )}
      </>
    );
  },
);

const LookupWithLabel = React.forwardRef(({ label, fetch, ...rest }: any, ref: any) => {
  return (
    <>
      <Typography
        style={{
          marginLeft: 0,
          alignSelf: 'center',
          marginTop: '0.5rem',
          fontWeight: 'bold',
        }}
      >
        {label}
      </Typography>
      <LookupMui {...rest} fetchFunction={fetch} minInputLength={2} variant="standard" multiple />
    </>
  );
});

const SelectWithLabel = React.forwardRef(
  ({ label, items, startAdornment, ...rest }: any, ref: any) => {
    return (
      <>
        <Typography
          style={{
            marginLeft: 0,
            alignSelf: 'center',
            marginTop: '0.5rem',
            fontWeight: 'bold',
          }}
        >
          {label}
        </Typography>
        <Autocomplete
          {...rest}
          size="small"
          onChange={(e: any, value: any) => {
            rest.onChange(value);
          }}
          value={rest.multiple ? rest.value : rest.value || 'null'}
          options={items.map((option: any) => option.id)}
          getOptionLabel={(option: any) => items.find((item: any) => item.id === option)?.name}
          variant="standard"
          sx={{ width: '100%' }}
          id="clear-on-escape"
          clearOnEscape
          componentsProps={{
            clearIndicator: {
              onClick: () => {
                rest.onChange(rest.multiple ? [] : 'null');
              },
            },
          }}
          renderInput={(params: any) => (
            <TextField
              {...params}
              placeholder={rest.placeholder}
              variant="standard"
              InputProps={{
                ...params.InputProps,
                ...(startAdornment && {
                  startAdornment: (
                    <>
                      <InputAdornment position="start">{startAdornment}</InputAdornment>
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }),
              }}
            />
          )}
          filterOptions={(x) => x}
        />
      </>
    );
  },
);

export const useClickOutside = (
  ref: RefObject<HTMLElement | undefined>,
  refFilter: RefObject<HTMLElement | undefined>,
  callback: (e: MouseEvent) => void,
  addEventListener = true,
) => {
  const filterButtons = document.getElementsByClassName('filterButton');
  const filterButton = filterButtons.length > 0 && filterButtons[0];

  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (
        filterButton &&
        !filterButton.contains(event.target as HTMLElement) &&
        ref.current &&
        !ref.current.contains(event.target as HTMLElement) &&
        ((refFilter.current && !refFilter.current.contains(event.target as HTMLElement)) ||
          !refFilter.current)
      ) {
        callback(event);
      }
    },
    [callback, filterButton, ref, refFilter],
  );

  useEffect(() => {
    if (addEventListener) {
      document.addEventListener('click', handleClick);
    }

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [addEventListener, handleClick]);
};

export default forwardRef(({ search }: any, ref: any) => {
  const {
    // watch,
    handleSubmit,
    control,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      fileName: '',
      dateFrom: null,
      dateTo: null,
      author: undefined,
      recipient: undefined,
      trialBook: undefined,
      createdBy: undefined,
      tags: [],
      hasAnnotations: 'null',
      privateOnly: 'null',
      hasHyperlinks: 'null',
    },
  });

  const dispatch = useDispatch();
  const classes = useStyles();

  const { fetchTags } = useFetchTags();

  const [tags, setTags] = React.useState<any>([]);

  const onSubmit = debounce(
    (data: any) => dispatch(fetchDocumentsDocFinder({ search, ...data })),
    250,
  );

  // make search react on change of form values
  // useEffect(() => {
  //   const subscription = watch(() => handleSubmit(onSubmit)());
  //   return () => subscription.unsubscribe();
  // }, [handleSubmit, onSubmit, watch]);

  const hasFileOpenned = !!useSelector(selectCurrentFile);
  const { showDocFinderFilter, setShowDocFinderFilter } = useSetShowDocFinderFilter();
  const [trialBundlesMenu, setTrialBundlesMenu] = React.useState<null | HTMLElement>(null);

  const onClickOutside = (e: MouseEvent) => {
    showDocFinderFilter && setShowDocFinderFilter(false);
  };

  const refCard = useRef<HTMLDivElement>(null);
  const refFilter = useRef<HTMLDivElement>(null);

  useClickOutside(refCard, refFilter, onClickOutside, hasFileOpenned);

  useImperativeHandle(ref, () => ({
    clearFilters: () => {
      reset();
    },
  }));

  useEffect(() => {
    fetchTags().then((tagsFetched: any) => {
      setTags(tagsFetched);
    });
  }, []);

  useEffect(() => {
    setShowDocFinderFilter(!hasFileOpenned);
  }, [hasFileOpenned, setShowDocFinderFilter]);

  return (
    <Card
      ref={refCard}
      onKeyDown={(e: any) => e.key === 'Enter' && handleSubmit(onSubmit)()}
      style={{
        width: '24rem',
        marginLeft: '1rem',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        paddingLeft: '1rem',
        paddingTop: '0',
        paddingBottom: '1rem',
        paddingRight: '1rem',
        transition: '0.3s all',
        ...(!hasFileOpenned && {
          marginRight: showDocFinderFilter ? 0 : '-24rem',
        }),
        ...(hasFileOpenned && {
          position: 'fixed',
          right: showDocFinderFilter ? 0 : '-23rem',
          border: '1px solid #e0e0e0',
          backgroundColor: 'white',
          height: 'calc(100% - 146px)',
          paddingLeft: '1rem',
          paddingRight: '-0.5rem',
          zIndex: 4,
        }),
      }}
      // onMouseOver={() => !showDocFinderFilter && setShowDocFinderFilter(true)}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
          position: 'absolute',
          top: '0.5rem',
          right: '1rem',
          zIndex: 4,
        }}
      >
        {!showDocFinderFilter && hasFileOpenned && (
          <FilterAlt fontSize="small" style={{ position: 'absolute', left: '12px' }} />
        )}
        <Tooltip title={T.translate('filters.search')}>
          <div //needs to have div to be able to show tooltip on hover
            style={{
              cursor: 'pointer',
              color: '#4567e5',
            }}
          >
            <Search fontSize="large" onClick={handleSubmit(onSubmit)} />
          </div>
        </Tooltip>
        <Tooltip title={T.translate('filters.clearFilter')}>
          <div //needs to have div to be able to show tooltip on hover
            style={{
              cursor: 'pointer',
              fontSize: '1.3rem',
              marginLeft: '0.5rem',
            }}
          >
            <FilterAltOff fontSize="medium" onClick={() => reset()} />
          </div>
        </Tooltip>
      </div>
      <PerfectScrollbar style={{ height: '100%', width: '24rem' }}>
        <Grid className={classes.root} container spacing={2}>
          <Grid item xs={12}>
            <Typography
              style={{
                marginLeft: 0,
                alignSelf: 'center',
                marginTop: '0.5rem',
                fontWeight: 'bold',
              }}
            >
              {T.translate('filters.fileName')}
            </Typography>
            <Controller
              name="fileName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="standard"
                  sx={{
                    '& .Mui-focused .MuiIconButton-root': {
                      visibility: field.value ? 'visible !important' : 'hidden',
                    },
                  }}
                  style={{ width: '100%' }}
                  placeholder="Search by file name"
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        id="clear-search"
                        size="small"
                        style={{ padding: '0.3rem', visibility: 'hidden' }}
                        onMouseDown={() => {
                          setValue('fileName', '');
                        }}
                      >
                        <ClearIcon style={{ fontSize: '1.2rem' }} />
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Typography
                style={{
                  marginLeft: 0,
                  alignSelf: 'center',
                  marginTop: '0.5rem',
                  fontWeight: 'bold',
                }}
              >
                {T.translate('filters.date')}
              </Typography>
              <div style={{ display: 'flex' }}>
                <Controller
                  name="dateFrom"
                  control={control}
                  render={({ field }) => (
                    // <DateInputCustom {...field} onCLear={()=> setValue('dateFrom', '')} />
                    <DatePicker {...field} placeholder={T.translate('generic.from')} />
                  )}
                />
                <Controller
                  name="dateTo"
                  control={control}
                  render={({ field }) => (
                    // <DateInputCustom {...field} onCLear={() => setValue('dateTo', '')} />
                    <DatePicker
                      {...field}
                      style={{ marginLeft: '1rem' }}
                      placeholder={T.translate('generic.to')}
                    />
                  )}
                />
              </div>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="author"
              control={control}
              render={({ field }) => {
                return (
                  <LookupWithLabel
                    {...field}
                    fetch={fetchAuthorsLookup}
                    label={T.translate('filters.authors')}
                    placeholder="Search by author's name"
                    startAdornment={<AccountCircle />}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="recipient"
              control={control}
              render={({ field }) => {
                return (
                  <LookupWithLabel
                    {...field}
                    fetch={fetchRecipientsLookup}
                    label={T.translate('filters.recipients')}
                    placeholder="Search by recipient's name"
                    startAdornment={<AccountCircleOutlined />}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="trialBook"
              control={control}
              render={({ field }) => {
                return (
                  <TrialBookFilter
                    {...field}
                    ref={refFilter}
                    trialBundlesMenu={trialBundlesMenu}
                    setTrialBundlesMenu={setTrialBundlesMenu}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="createdBy"
              control={control}
              render={({ field }) => {
                return (
                  <LookupWithLabel
                    {...field}
                    fetch={fetchCreatedByLookup}
                    label={T.translate('filters.uploadedBy')}
                    placeholder="Search by uploaded by's name"
                    startAdornment={<ManageAccounts />}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="tags"
              control={control}
              render={({ field }) => {
                return (
                  <SelectWithLabel
                    {...field}
                    multiple
                    variant="standard"
                    label={T.translate('filters.tags')}
                    items={tags}
                    startAdornment={<Style />}
                    placeholder="Search by tags"
                  />
                );
              }}
            />
          </Grid>
          {/* <Divider style={{ width: '100%' }} /> */}
          <Grid item xs={12}>
            <Controller
              name="hasAnnotations"
              control={control}
              render={({ field }) => {
                return (
                  <SelectWithLabel
                    {...field}
                    variant="standard"
                    label={T.translate('filters.hasAnnotations')}
                    items={[
                      { id: 'false', name: T.translate('filters.withoutAnnotations') },
                      { id: 'null', name: T.translate('filters.allDocuments') },
                      { id: 'true', name: T.translate('filters.withAnnotations') },
                    ]}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="hasHyperlinks"
              control={control}
              render={({ field }) => {
                return (
                  <SelectWithLabel
                    {...field}
                    variant="standard"
                    label={T.translate('filters.privatePublicHyperlinks')}
                    items={[
                      { id: 'false', name: T.translate('filters.withoutPublicHyperlinks') },
                      { id: 'null', name: T.translate('filters.allDocuments') },
                      { id: 'true', name: T.translate('filters.withPublicHyperlinks') },
                    ]}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="privateOnly"
              control={control}
              render={({ field }) => {
                return (
                  <SelectWithLabel
                    {...field}
                    variant="standard"
                    label={T.translate('filters.privatePublicAnnotations')}
                    items={[
                      { id: 'false', name: T.translate('filters.publicOnly') },
                      { id: 'null', name: T.translate('filters.allDocuments') },
                      { id: 'true', name: T.translate('filters.privateOnly') },
                    ]}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      </PerfectScrollbar>
    </Card>
  );
});
