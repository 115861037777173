import React from 'react';
import SearchAnswerContainer from './SearchAnswerContainer';

import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { formatCamelCase } from 'utils/strings';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const RecursiveRenderer = ({
  data,
  collapsable = false,
}: {
  data: any;
  collapsable?: boolean;
}) => {
  const removeKeys = ['id', 'startPage', 'endPage', 'status', 'testimonyType', 'witness'];
  // Check if the current data is an object
  if (typeof data === 'object' && data !== null) {
    if (Array.isArray(data)) {
      // If data is an array, map through the array and render items
      return (
        <Box component="ul" sx={{ pl: 3 }}>
          {data.map((item, index) => (
            <Box component="li" key={index}>
              <RecursiveRenderer data={item} collapsable={collapsable} />
            </Box>
          ))}
        </Box>
      );
    } else {
      // If data is an object, map through its keys and render key-value pairs
      return (
        <Box sx={{ pl: 0 }}>
          {Object.entries(data).map(([key, value], index) => {
            if (!value || removeKeys.includes(key)) return null;
            if (key === 'evidenceSummaries') {
              if (data.status === 'queued') {
                return (
                  <Box key={index} sx={{ mb: 1 }}>
                    <Typography component="span" sx={{ fontWeight: 600 }}>
                      {formatCamelCase(key)}:&nbsp;
                    </Typography>
                    <Typography
                      component="span"
                      style={{
                        lineBreak: 'auto',
                        animation: `blinker 1s linear infinite`,
                        fontWeight: 600,
                        color: '#0000007a',
                      }}
                    >
                      Generating summary...
                    </Typography>
                  </Box>
                );
              } else if (data.status === 'failed') {
                return (
                  <Box key={index} sx={{ mb: 1 }}>
                    <Typography component="span" sx={{ fontWeight: 600 }}>
                      {formatCamelCase(key)}:&nbsp;
                    </Typography>
                    <Typography component="span">
                      An issue occurred while generating Summary
                    </Typography>
                  </Box>
                );
              } else {
                return (
                  <Box key={index} sx={{ mb: 1 }}>
                    <Typography component="span" sx={{ fontWeight: 600 }}>
                      {formatCamelCase(key)}:&nbsp;
                    </Typography>
                    <RecursiveRenderer data={value} collapsable={collapsable} />
                  </Box>
                );
              }
            } else if (key === 'testimonies' && Array.isArray(value) && collapsable) {
              return (
                <Box key={index} sx={{ mb: 1 }}>
                  {value.length > 0 ? (
                    value.map((testimony: any, tIndex: number) => (
                      <Accordion
                        key={tIndex}
                        sx={{
                          boxShadow: 'none',
                          border: 'none',
                          padding: 0,
                          ...(tIndex !== value.length - 1 && {
                            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                            '&:before': {
                              display: 'none',
                            },
                          }),
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`testimony-panel${tIndex}-content`}
                          id={`testimony-panel${tIndex}-header`}
                          sx={{ padding: 0 }}
                        >
                          <Typography sx={{ fontWeight: 'bold' }}>
                            {`${testimony.barrister?.toUpperCase() || 'Unknown Barrister'} -`}
                            &nbsp;
                          </Typography>
                          <Typography>{`${testimony.testimonyType || 'Testimony'}`}</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ padding: 0 }}>
                          <RecursiveRenderer data={testimony} collapsable={collapsable} />
                        </AccordionDetails>
                      </Accordion>
                    ))
                  ) : (
                    <Typography>No testimonies available.</Typography>
                  )}
                </Box>
              );
            } else if (key === 'created') {
              return (
                <Box key={index} sx={{ mb: 1, color: '#0000007a' }}>
                  <Typography component="span" sx={{ fontWeight: 600 }}>
                    {formatCamelCase(key)}:&nbsp;
                  </Typography>
                  <Typography component="span">
                    {typeof value === 'string' && !isNaN(Date.parse(value))
                      ? new Date(value).toLocaleString()
                      : 'Invalid Date'}
                  </Typography>
                </Box>
              );
            } else {
              // Default rendering case
              return (
                <Box key={index} sx={{ mb: 1 }}>
                  <Typography component="span" sx={{ fontWeight: 600 }}>
                    {formatCamelCase(key)}:&nbsp;
                  </Typography>
                  <RecursiveRenderer data={value} collapsable={collapsable} />
                </Box>
              );
            }
          })}
        </Box>
      );
    }
  } else {
    // If data is neither an object nor an array, just render the value
    return <Typography component="span">{String(data)}</Typography>;
  }
};

const DocSummary = ({ summary = [], submissions = [], rulings = [], scrollBarRef }: any) => {
  return typeof summary === 'string' ? (
    <SearchAnswerContainer
      response={summary}
      typingEffect={true}
      scrollBarRef={scrollBarRef}
      handleMatchesLinkClicked={() => null}
      allMatchesCount={0}
    />
  ) : summary?.length > 0 || submissions?.length > 0 || rulings?.length > 0 ? (
    <>
      {summary?.length > 0 && (
        <Box
          component={typeof summary[0] === 'string' ? 'ul' : 'div'}
          sx={{ pl: typeof summary[0] === 'string' ? 3 : 0 }}
        >
          {summary.map((item: any, index: number) =>
            typeof item === 'string' ? (
              <Box component="li" key={index}>
                <Typography component="span">{String(item)}</Typography>
              </Box>
            ) : (
              <Accordion key={index} defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                >
                  <Typography sx={{ fontWeight: 'bold' }}>Witness - &nbsp;</Typography>
                  <Typography>{item.witness || `Witness ${index + 1}`}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <RecursiveRenderer data={item} collapsable={true} />
                </AccordionDetails>
              </Accordion>
            ),
          )}
        </Box>
      )}
      {submissions?.length > 0 && (
        <Accordion
          sx={{
            overflow: 'hidden',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              display: 'flex',
              alignItems: 'center',
              pr: 2,
            }}
          >
            <Typography sx={{ fontWeight: 'bold', flexGrow: 1 }}>Submissions</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ pt: 0 }}>
            {submissions.map((sub: any, tIndex: number) => (
              <Accordion
                key={tIndex}
                sx={{
                  boxShadow: 'none',
                  border: 'none',
                  padding: 0,
                  ...(tIndex !== submissions.length - 1 && {
                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                    '&:before': {
                      display: 'none',
                    },
                  }),
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`sub-panel${tIndex}-content`}
                  id={`sub-panel${tIndex}-header`}
                  sx={{ padding: 0 }}
                >
                  <Typography sx={{ fontWeight: 'bold' }}>
                    {`${sub.barrister?.toUpperCase() || 'Unknown Barrister'}`}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  <RecursiveRenderer data={sub} collapsable={true} />
                </AccordionDetails>
              </Accordion>
            ))}
          </AccordionDetails>
        </Accordion>
      )}
      {rulings?.length > 0 && (
        <Accordion
          sx={{
            overflow: 'hidden',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              display: 'flex',
              alignItems: 'center',
              pr: 2,
            }}
          >
            <Typography sx={{ fontWeight: 'bold', flexGrow: 1 }}>Rulings</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {rulings.map((item: any, index: number) => (
              <Accordion
                key={index}
                sx={{
                  boxShadow: 'none',
                  border: 'none',
                  padding: 0,
                  ...(index !== submissions.length - 1 && {
                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                    '&:before': {
                      display: 'none',
                    },
                  }),
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`ruling-panel${index}-content`}
                  id={`ruling-panel${index}-header`}
                  sx={{ padding: 0 }}
                >
                  <Typography sx={{ fontWeight: 'bold' }}>
                    {item.barrister?.toUpperCase() || `Ruling ${index + 1}`}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  <RecursiveRenderer data={item} collapsable={true} />
                </AccordionDetails>
              </Accordion>
            ))}
          </AccordionDetails>
        </Accordion>
      )}
    </>
  ) : (
    <Typography
      sx={{
        padding: 10,
        textAlign: 'center',
      }}
    >
      No summaries available.
    </Typography>
  );
};

export default DocSummary;
