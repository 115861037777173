import { configureStore as createStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import history from './history';
import createRootReducer from './rootReducer';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './rootSaga';
import { persistStore } from 'redux-persist';
import signalRMiddleware from './signalRMiddleware';
import updateUserSettingsMiddleware from './updateUserSettingsMiddleware';
import { promiseMiddleware } from '@adobe/redux-saga-promise';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';

const sagaMiddleware = createSagaMiddleware();

const router = routerMiddleware(history);

const config = {
  channel: 'sync_tabs_channel',
  whitelist: [
    'COMMON_UPDATE_USER_SETTINGS_SUCCESS',
    'AUTH_UPDATE_USER_SESSION',
    'AUTH_LOGIN',
    'AUTH_LOGOUT',
    'CONFERENCE_GET_TOKEN_SUCCESS',
    'CONFERENCE_GET_TOKEN_DISMISS_TOKEN',
  ],
};
const stateSyncMiddleware = createStateSyncMiddleware(config);

// NOTE: Do not change middleares delaration pattern since rekit plugins may register middlewares to it.
const middlewares = [
  router,
  promiseMiddleware,
  sagaMiddleware,
  signalRMiddleware,
  updateUserSettingsMiddleware,
  stateSyncMiddleware,
];

let persistor;

function configureStore(initialState) {
  const store = createStore({
    reducer: createRootReducer(history),
    middleware: middlewares,
    // eslint-disable-next-line no-undef
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState: initialState,
  });

  initMessageListener(store);
  sagaMiddleware.run(rootSaga);
  persistor = persistStore(store);
  return { store, persistor };
}

export const getPersistor = () => persistor;

export default configureStore();
