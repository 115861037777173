import { Tooltip } from '@mui/material';
import {
  selectBundleTabPage,
  selectIncludeBracesAroundPageRef,
  selectParams,
} from 'common/selectors';
import { createPageRef } from 'features/case/redux/createPageRef';
import React, { useRef, useState, useEffect } from 'react';
import AutosizeInput from 'react-input-autosize';
import onClickOutside from 'react-onclickoutside';
import { useDispatch, useSelector } from 'react-redux';
import { getGlobalPageText, getStartPageForGlobalPaging } from './globalPage';

const CurrentGlobalPage = ({
  currentFileMetaData,
  currentPageNumber,
  setPageNumber,
  fileId,
  startPageForGlobalPaging,
}: {
  currentFileMetaData: any;
  currentPageNumber: number;
  setPageNumber: (val: any) => void;
  fileId: string;
  startPageForGlobalPaging: string;
}) => {
  const dispatch = useDispatch();
  const { case: aCase, file } = useSelector(selectParams);
  const isBundleTabPage = useSelector(selectBundleTabPage);
  const isIncludeBracesAroundPageRef = useSelector(selectIncludeBracesAroundPageRef);
  const [gPNumberValue, setGPNumberValue] = useState<number>(0);
  const [openTooltip, setOpenTooltip] = useState(false);
  const gPNumberElement = useRef<any>(null);
  const startPage = startPageForGlobalPaging || currentFileMetaData.startPage;

  const startPageVal = (startPage || 0).toString().split('.');
  const isStartPageFirstDigitAfterDecimalZero =
    startPageVal && startPageVal.length > 1 && startPageVal[1].startsWith('0');
  const currentFileStartPage = getStartPageForGlobalPaging(startPage);
  const endGPNumber = parseInt(currentFileMetaData.pageCount, 10) + currentFileStartPage - 1;

  const gpText = getGlobalPageText(startPageForGlobalPaging, currentFileMetaData, gPNumberValue);

  //@ts-ignore
  CurrentGlobalPage.handleClickOutside = () =>
    gPNumberElement.current && gPNumberElement.current.blur();
  const changePageNumber = () => {
    if (isBundleTabPage) {
      setPageNumber(gPNumberValue);
      setGPNumberValue(gPNumberValue);
    } else {
      const newNum = Math.min(Math.max(gPNumberValue, currentFileStartPage), endGPNumber);
      const localPageValue = newNum - currentFileStartPage + 1;
      setPageNumber(localPageValue);
      setGPNumberValue(newNum);
    }
  };
  const copyToClipboard = () => {
    let text = isBundleTabPage
      ? `${currentFileMetaData.globalPagePrefix}/${currentFileMetaData.tab}/${currentPageNumber}`
      : gpText.toString();
    if (isIncludeBracesAroundPageRef) {
      text = `{${text}}`;
    }
    const url =
      `${window.location.origin}/case/${aCase}/files/${fileId || file}?goToPage=${currentPageNumber}` +
      `${currentFileMetaData.tab ? `&tab=${currentFileMetaData.tab}` : ''}` +
      `${startPage ? `&startPage=${startPage}` : ''}` +
      `${currentFileMetaData.pageCount ? `&pageCount=${currentFileMetaData.pageCount}` : ''}` +
      `${currentFileMetaData.globalPagePrefix ? `&globalPagePrefix=${currentFileMetaData.globalPagePrefix}` : ''}`;
    const htmlToCopy = `<a href="${url}">${text}</a>`;
    // Using the Clipboard API to write HTML
    navigator.clipboard
      .write([
        new ClipboardItem({
          'text/html': new Blob([htmlToCopy], { type: 'text/html' }),
          'text/plain': new Blob([text], { type: 'text/plain' }),
        }),
      ])
      .then(() => {
        handleTooltipOpen();
        dispatch(createPageRef({ globalPage: text }));
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  const handleTooltipClose = () => setOpenTooltip(false);
  const handleTooltipOpen = () => setOpenTooltip(true);

  useEffect(() => {
    if (openTooltip) {
      setTimeout(() => {
        setOpenTooltip(false);
      }, 1500);
    }
  }, [openTooltip]);

  useEffect(() => {
    if (isBundleTabPage) {
      setGPNumberValue(currentPageNumber);
    } else {
      const currentGPNumber = currentFileStartPage + currentPageNumber - 1;
      setGPNumberValue(currentGPNumber);
    }
  }, [currentPageNumber, currentFileStartPage, isBundleTabPage]);

  return (
    <Tooltip
      placement="top"
      onClose={handleTooltipClose}
      open={openTooltip}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={`${gpText} - Copied to clipboard`}
      arrow
    >
      <div
        className="pageNumber"
        onClick={() => gPNumberElement.current && gPNumberElement.current.focus()}
        onDoubleClick={(e: any) => {
          e.stopPropagation();
          copyToClipboard();
        }}
      >
        <div style={{ marginLeft: '0.3rem' }}>
          {isBundleTabPage ? (
            <>
              <span style={{ marginRight: '0.1rem' }}>
                {currentFileMetaData.globalPagePrefix}
                {' / '}
                {currentFileMetaData.tab}
                {' / '}
              </span>
              <AutosizeInput
                ref={gPNumberElement}
                value={gPNumberValue || ''}
                inputStyle={{ textAlign: 'left', border: 'none', background: 'transparent' }}
                onChange={(event: any) => {
                  const re = /^[0-9\b]+$/;
                  if (event.target.value === '' || re.test(event.target.value)) {
                    setGPNumberValue(event.target.value);
                  }
                }}
                onBlur={changePageNumber}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    gPNumberElement.current.blur();
                  }
                  if ((e.ctrlKey || e.metaKey) && e.keyCode === 67) {
                    copyToClipboard();
                  }
                }}
                autoComplete="off"
              />
            </>
          ) : (
            <>
              <span style={{ marginRight: '-0.1rem' }}>
                {currentFileMetaData.globalPagePrefix}
                {startPageVal.length > 1 &&
                  startPageVal[0] + `.${isStartPageFirstDigitAfterDecimalZero ? '0' : ''}`}
              </span>
              <AutosizeInput
                ref={gPNumberElement}
                value={gPNumberValue || ''}
                onChange={(event: any) => {
                  const re = /^[0-9\b]+$/;
                  if (event.target.value === '' || re.test(event.target.value))
                    setGPNumberValue(event.target.value);
                }}
                onBlur={changePageNumber}
                onKeyDown={(e: any) => {
                  e.key === 'Enter' && gPNumberElement.current.blur();
                  (e.ctrlKey || e.metaKey) && e.keyCode === 67 && copyToClipboard();
                }}
                autoComplete="off"
              />
            </>
          )}
        </div>
      </div>
    </Tooltip>
  );
};

export default onClickOutside(CurrentGlobalPage, {
  //@ts-ignore
  handleClickOutside: () => CurrentGlobalPage.handleClickOutside,
});
