import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  CASE_UPDATE_CASE_BEGIN,
  CASE_UPDATE_CASE_SUCCESS,
  CASE_UPDATE_CASE_FAILURE,
  CASE_UPDATE_CASE_DISMISS_FEEDBACK,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from 'common/selectors';
import { updateCaseName } from 'features/home/redux/updateCaseName';

export const updateCase = createPromiseAction(CASE_UPDATE_CASE_BEGIN, (payload) => ({
  caseData: payload,
}));

export function dismissUpdateCaseFeedback() {
  return {
    type: CASE_UPDATE_CASE_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_FETCH_CASE_BEGIN actions
export function* doUpdateCase(action) {
  const {
    payload: { caseId, caseData },
  } = action;

  let res = yield call(api.put, `/cases/${caseId}`, caseData);
  if (res && res.error) {
    yield put({
      type: CASE_UPDATE_CASE_FAILURE,
      feedback: { message: 'feedback.updateCaseFailure', error: res.error, retryAction: action },
    });
    if (res.error.status === 412)
      yield put(updateCaseName({ id: res.error.body.id, name: res.error.body.name }));

    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_UPDATE_CASE_SUCCESS,
    data: { case: res },
  });

  yield put(updateCaseName({ id: res.id, name: res.name }));

  yield call(resolvePromiseAction, action, res);
}

export function* watchUpdateCase() {
  yield takeLatest(updateCase, withCurrentCaseId(doUpdateCase));
}

export function useUpdateCase() {
  const dispatch = useDispatch();

  const { updateCasePending, updateCaseFeedback } = useSelector(
    (state) => ({
      updateCasePending: state.case.updateCasePending,
      updateCaseFeedback: state.case.updateCaseFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(updateCase(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissUpdateCaseFeedback());
  }, [dispatch]);

  return {
    updateCase: boundAction,
    updateCasePending,
    updateCaseFeedback,
    dismissUpdateCaseFeedback: boundDismissFeedback,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_UPDATE_CASE_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        updateCasePending: true,
        updateCaseFeedback: null,
      };

    case CASE_UPDATE_CASE_SUCCESS:
      // The request is success
      return {
        ...state,
        updateCasePending: false,
        updateCaseFeedback: action.feedback,
        caseDetails: action.data.case,
      };

    case CASE_UPDATE_CASE_FAILURE:
      // The request is failed
      const concurencyError = action.feedback.error.status === 412;
      return {
        ...state,
        updateCasePending: false,
        updateCaseFeedback: action.feedback,
        ...(concurencyError && {
          caseDetails: action.feedback.error.body,
        }),
      };

    case CASE_UPDATE_CASE_DISMISS_FEEDBACK:
      // Dismiss the request failure error
      return {
        ...state,
        updateCaseFeedback: null,
      };

    default:
      return state;
  }
}
