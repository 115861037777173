import { call, put, takeEvery, select } from 'redux-saga/effects';
import {
  CASE_UPDATE_DOCUMENT_META_BEGIN,
  CASE_UPDATE_DOCUMENT_META_SUCCESS,
  CASE_UPDATE_DOCUMENT_META_FAILURE,
  CASE_UPDATE_DOCUMENT_META_DISMISS_FEEDBACK,
} from './constants';
import api from 'common/api';
import { withCurrentCaseId, selectCurrentFolder } from '../../../common/selectors';
import { replaceItemImmutable } from 'utils/arrays';
import addSortNumberToDocuments from './addSortNumberToDocuments';

export function updateDocumentMeta(meta, fileId) {
  // If need to pass args to saga, pass it with the begin action.
  return {
    type: CASE_UPDATE_DOCUMENT_META_BEGIN,
    payload: { meta, fileId },
  };
}

export function dismissUpdateDocumentMetaFeedback() {
  return {
    type: CASE_UPDATE_DOCUMENT_META_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_UPDATE_META_BEGIN actions
export function* doUpdateDocumentMeta(action) {
  const {
    payload: { meta, fileId, caseId },
  } = action;
  const currentFolder = yield select(selectCurrentFolder);

  const res = yield call(api.put, `/cases/${caseId}/files/${fileId}`, meta);

  if (res && res.error) {
    return yield put({
      type: CASE_UPDATE_DOCUMENT_META_FAILURE,
      feedback: {
        message: 'feedback.updateDocumentMetaFailure',
        error: res.error,
        retryAction: action,
      },
      fileId,
    });
  }

  // Dispatch success action out of try/catch so that render errors are not catched.
  yield put({
    type: CASE_UPDATE_DOCUMENT_META_SUCCESS,
    data: {
      meta: res.id ? res : { ...res, id: fileId },
      fileId,
      zeroBasedIndex: currentFolder.zeroBasedIndex,
    },
  });
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchUpdateDocumentMeta() {
  yield takeEvery(CASE_UPDATE_DOCUMENT_META_BEGIN, withCurrentCaseId(doUpdateDocumentMeta));
}

// Redux reducer
export function reducer(state, action) {
  const getIndex = (documentId) => state.documents.findIndex(({ id }) => id === documentId);
  switch (action.type) {
    case CASE_UPDATE_DOCUMENT_META_BEGIN:
      return {
        ...state,
        updateDocumentMetaPending: true,
        updateDocumentMetaFeedback: null,
      };

    case CASE_UPDATE_DOCUMENT_META_SUCCESS:
      return {
        ...state,
        updateDocumentMetaPending: false,
        updateDocumentMetaFeedback: action.feedback,
        documents: addSortNumberToDocuments(
          replaceItemImmutable(
            state.documents,
            { ...state.documents[getIndex(action.data.fileId)], ...action.data.meta },
            getIndex(action.data.fileId),
          ),
          action.data.zeroBasedIndex,
        ),
      };

    case CASE_UPDATE_DOCUMENT_META_FAILURE:
      // The request is failed
      const concurencyError = action.feedback.error.status === 412;
      return {
        ...state,
        updateDocumentMetaPending: false,
        updateDocumentMetaFeedback: action.feedback,
        ...(concurencyError && {
          documents: replaceItemImmutable(
            state.documents,
            {
              ...state.documents[getIndex(action.fileId)],
              ...action.feedback.error.body,
            },
            getIndex(action.fileId),
          ),
        }),
      };

    case CASE_UPDATE_DOCUMENT_META_DISMISS_FEEDBACK:
      return {
        ...state,
        updateDocumentMetaFeedback: null,
      };

    default:
      return state;
  }
}
