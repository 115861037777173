import { put, call, takeLatest } from 'redux-saga/effects';
import {
  CASE_FETCH_SEARCH_HISTORY_BEGIN,
  CASE_FETCH_SEARCH_HISTORY_SUCCESS,
  CASE_FETCH_SEARCH_HISTORY_FAILURE,
  CASE_FETCH_SEARCH_HISTORY_DISMISS_FEEDBACK,
} from './constants';
import api from 'common/api';
import { withCurrentCaseId } from 'common/selectors';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';

export const fetchSearchHistory = createPromiseAction(CASE_FETCH_SEARCH_HISTORY_BEGIN);

export function dismissFetchSearchHistoryFeedback() {
  return {
    type: CASE_FETCH_SEARCH_HISTORY_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_FETCH_DOCUMENTS_BEGIN actions
export function* doFetchSearchHistory(action) {
  const {
    payload: { caseId },
  } = action;

  const res = yield call(api.get, `/cases/${caseId}/search-history`);

  if (res && res.error) {
    yield put({
      type: CASE_FETCH_SEARCH_HISTORY_FAILURE,
      feedback: {
        message: 'feedback.fetchSearchHistoryFailure',
        error: res.error,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }
  // Dispatch success action out of try/catch so that render errors are not catched.
  yield put({
    type: CASE_FETCH_SEARCH_HISTORY_SUCCESS,
    data: {
      results: res,
    },
  });
  yield call(resolvePromiseAction, action, res);
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchFetchSearchHistory() {
  yield takeLatest(fetchSearchHistory, withCurrentCaseId(doFetchSearchHistory));
}

export function useFetchSearchHistory() {
  const dispatch = useDispatch();

  const { searchHistory, fetchSearchHistoryPending, fetchSearchHistoryFeedback } = useSelector(
    (state) => ({
      searchHistory: state.case.searchHistory,
      fetchSearchHistoryPending: state.case.fetchSearchHistoryPending,
      fetchSearchHistoryFeedback: state.case.fetchSearchHistoryFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(fetchSearchHistory(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissFetchSearchHistoryFeedback());
  }, [dispatch]);

  return {
    searchHistory,
    fetchSearchHistory: boundAction,
    fetchSearchHistoryPending,
    fetchSearchHistoryFeedback,
    dismissFetchSearchHistoryFeedback: boundDismissFeedback,
  };
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_FETCH_SEARCH_HISTORY_BEGIN + '.TRIGGER':
      return {
        ...state,
        fetchSearchHistoryPending: true,
        fetchSearchHistoryFeedback: action.feedback,
        searchHistory: [],
      };

    case CASE_FETCH_SEARCH_HISTORY_SUCCESS:
      return {
        ...state,
        fetchSearchHistoryPending: false,
        fetchSearchHistoryFeedback: action.feedback,
        searchHistory: action.data.results,
      };

    case CASE_FETCH_SEARCH_HISTORY_FAILURE:
      return {
        ...state,
        fetchSearchHistoryPending: false,
        searchHistory: [],
        fetchSearchHistoryFeedback: action.feedback,
      };

    case CASE_FETCH_SEARCH_HISTORY_DISMISS_FEEDBACK:
      return {
        ...state,
        fetchSearchHistoryFeedback: null,
      };

    default:
      return state;
  }
}
