import {
  COMMON_FETCH_USER_DETAILS_BEGIN,
  COMMON_FETCH_USER_DETAILS_SUCCESS,
  COMMON_FETCH_USER_DETAILS_FAILURE,
  COMMON_FETCH_USER_DETAILS_DISMISS_FEEDBACK,
} from './constants';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';

export const fetchUserDetails = createPromiseAction(COMMON_FETCH_USER_DETAILS_BEGIN);

export function dismissFetchUserDetailsFeedback() {
  return {
    type: COMMON_FETCH_USER_DETAILS_DISMISS_FEEDBACK,
  };
}

export function* doFetchUserDetails(action) {
  const {
    payload: { userId },
  } = action;

  let res = yield call(api.get, `/users/${userId}`);

  if (res && res.error) {
    yield put({
      type: COMMON_FETCH_USER_DETAILS_FAILURE,
      feedback: {
        message: 'feedback.fetchUserDetailsFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: COMMON_FETCH_USER_DETAILS_SUCCESS,
    data: { userDetails: res },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchFetchUserDetails() {
  yield takeLatest(fetchUserDetails, doFetchUserDetails);
}

export function useFetchUserDetails() {
  const dispatch = useDispatch();

  const { userDetails, fetchUserDetailsPending, fetchUserDetailsFeedback } = useSelector(
    (state) => ({
      userDetails: state.common.userDetails,
      fetchUserDetailsPending: state.common.fetchUserDetailsPending,
      fetchUserDetailsFeedback: state.common.fetchUserDetailsFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(fetchUserDetails(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissFetchUserDetailsFeedback());
  }, [dispatch]);

  return {
    userDetails,
    fetchUserDetails: boundAction,
    fetchUserDetailsPending,
    fetchUserDetailsFeedback,
    dismissFetchUserDetailsFeedback: boundDismissFeedback,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case COMMON_FETCH_USER_DETAILS_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        fetchUserDetailsPending: true,
        fetchUserDetailsFeedback: null,
      };

    case COMMON_FETCH_USER_DETAILS_SUCCESS:
      // The request is success
      return {
        ...state,
        fetchUserDetailsPending: false,
        fetchUserDetailsFeedback: action.feedback,
        userDetails: action.data.userDetails,
      };

    case COMMON_FETCH_USER_DETAILS_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchUserDetailsPending: false,
        fetchUserDetailsFeedback: action.feedback,
      };

    case COMMON_FETCH_USER_DETAILS_DISMISS_FEEDBACK:
      // Dismiss the request failure error
      return {
        ...state,
        fetchUserDetailsFeedback: null,
      };

    default:
      return state;
  }
}
