import { call, put, takeLatest } from 'redux-saga/effects';
import {
  VIEWING_FETCH_ANNOTATIONS_BEGIN,
  VIEWING_FETCH_ANNOTATIONS_SUCCESS,
  VIEWING_FETCH_ANNOTATIONS_FAILURE,
  VIEWING_FETCH_ANNOTATIONS_DISMISS_FEEDBACK,
  VIEWING_FILTER_ANNOTATIONS,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { withCurrentCaseId, withCurrentUserId } from 'common/selectors';
import {
  DEFAULT_ANNOTATION_FILTER,
  createFilter,
  filterAnnotations as filter,
} from './filterAnnotations';

export const fetchAnnotations = createPromiseAction(VIEWING_FETCH_ANNOTATIONS_BEGIN);

export function dismissFetchAnnotationsFeedback() {
  return {
    type: VIEWING_FETCH_ANNOTATIONS_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on VIEWING_FETCH_ANNOTATIONS_BEGIN actions
export function* doFetchAnnotations(action) {
  const {
    payload: { caseId, file, hearingRoomMode, currentUserId: userId },
  } = action;

  const res = yield api.get(
    `/cases/${caseId}/files/${file}/annotations${
      hearingRoomMode ? `?hearingRoom=${hearingRoomMode}` : ''
    }`,
  );

  if (res && res.error) {
    yield put({
      type: VIEWING_FETCH_ANNOTATIONS_FAILURE,
      feedback: {
        message: 'feedback.fetchAnnotationsFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  // Dispatch success action out of try/catch so that render errors are not catched.
  yield put({
    type: VIEWING_FETCH_ANNOTATIONS_SUCCESS,
    data: res,
    userId,
  });

  yield call(resolvePromiseAction, action, res);
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchFetchAnnotations() {
  yield takeLatest(fetchAnnotations, withCurrentUserId(withCurrentCaseId(doFetchAnnotations)));
}

export function useFetchAnnotations() {
  const dispatch = useDispatch();

  const { fetchAnnotationsPending, fetchAnnotationsFeedback, annotations } = useSelector(
    (state) => ({
      fetchAnnotationsPending: state.viewing.fetchAnnotationsPending,
      fetchAnnotationsFeedback: state.viewing.fetchAnnotationsFeedback,
      annotations: state.viewing.annotations,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(fetchAnnotations(...args));
    },
    [dispatch],
  );

  return {
    annotations,
    fetchAnnotations: boundAction,
    fetchAnnotationsPending,
    fetchAnnotationsFeedback,
  };
}

export function filterAnnotations(updatedFilters, userId) {
  return {
    type: VIEWING_FILTER_ANNOTATIONS,
    updatedFilters,
    userId,
  };
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case VIEWING_FETCH_ANNOTATIONS_BEGIN + '.TRIGGER':
      return {
        ...state,
        fetchAnnotationsPending: true,
        fetchAnnotationsFeedback: null,
      };

    case VIEWING_FETCH_ANNOTATIONS_SUCCESS:
      return {
        ...state,
        fetchAnnotationsPending: false,
        fetchAnnotationsFeedback: action.feedback,
        annotations: action.data,
        filteredAnnotations: filter(action.data, DEFAULT_ANNOTATION_FILTER, action.userId),
        updatedFilters: createFilter(action.data, DEFAULT_ANNOTATION_FILTER),
      };

    case VIEWING_FILTER_ANNOTATIONS:
      return {
        ...state,
        filteredAnnotations: filter(state.annotations, action.updatedFilters, action.userId),
        updatedFilters: action.updatedFilters,
      };

    case VIEWING_FETCH_ANNOTATIONS_FAILURE:
      return {
        ...state,
        fetchAnnotationsPending: false,
        fetchAnnotationsFeedback: action.feedback,
      };

    case VIEWING_FETCH_ANNOTATIONS_DISMISS_FEEDBACK:
      return {
        ...state,
        fetchAnnotationsFeedback: null,
      };

    default:
      return state;
  }
}
