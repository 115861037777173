import React, { useState, useEffect, useRef } from 'react';
import T from 'i18n';
import * as actions from 'features/case/redux/actions';
import { teamAdminModal } from './enums';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {
  faTrashAlt,
  faEdit,
  faLink,
  faInfoCircle,
  faPlusCircle,
  faUserEdit,
  faLaptopCode,
  faRetweet,
  faExchangeAlt,
  faHistory,
  faUserPlus,
} from '@fortawesome/free-solid-svg-icons';
import TeamAdminModal from './TeamAdminModal';
import TeamAdminTableCase from './TeamAdminTableCase';
import ActionsButton from '../common/ActionsButton';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentCaseTeams,
  selectCurrentTeam,
  selectParams,
  selectPermissions,
} from 'common/selectors';
import { FileDropZone } from 'features/common';
import { useFetchCaseDetails } from './redux/fetchCaseDetails';

const TeamAdminTableWrapper = ({ teamName, isAllUsers }) => {
  const dispatch = useDispatch();
  const currentTeam = useSelector(selectCurrentTeam);
  const teams = useSelector(selectCurrentCaseTeams);
  const params = useSelector(selectParams);
  const {
    hearingRoom: { conferenceType },
  } = useSelector(selectPermissions);
  const [team, setTeam] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [inviteStatus, setInviteStatus] = useState([]);
  const [fixedActiveRolesList, setFixedActiveRolesList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalAction, setModalAction] = useState('');
  const [hoveredUser, setHoveredUser] = useState(null);
  const [importedUsersList, setImportedUsersList] = useState([]);
  const selectedConference = conferenceType && conferenceType('public');
  const showChangePosition = !fixedActiveRolesList.every((item) => item === 'fixedActive');
  const userFileDropRef = useRef(null);
  const { fetchCaseDetails } = useFetchCaseDetails();
  const openDialog = () => {
    if (userFileDropRef.current) {
      userFileDropRef.current.open();
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setModalAction('');
    if (importedUsersList.length > 0) setImportedUsersList([]);
  };

  const handleShow = (modalAction) => {
    setModalAction(modalAction);

    setHoveredUser(null);
    setShowModal(true);
  };

  const handleSystemTestShow = useCallback(
    (newHoveredUser, action) => {
      setShowModal(true);
      setModalAction(action);
      setHoveredUser(newHoveredUser);
    },
    [setShowModal, setModalAction],
  );

  const handleSave = (action, data, teamId, sendInvite) => {
    switch (action) {
      case teamAdminModal.removeMember:
        dispatch(actions.removeTeamMember({ teamId, user: data }));
        break;
      case teamAdminModal.updateMember:
        break;
      case teamAdminModal.removeSystemTestResult:
        dispatch(actions.updateTeamMember({ teamId, user: data })).then(() => {
          fetchCaseDetails();
        });
        break;
      case teamAdminModal.inviteMember:
        dispatch(actions.sendInvite({ teamId, email: data, sendInvite }));
        break;
      case teamAdminModal.changeHearingRoomPosition:
        dispatch(actions.updateTeamMember({ teamId, user: data })).then(() => {
          fetchCaseDetails();
        });
        break;
      case teamAdminModal.reSendInvite:
        dispatch(actions.reSendInvite({ teamId, user: data }));
        break;
      case teamAdminModal.changeTeam:
        dispatch(actions.removeTeamMember({ teamId, user: data }))
          .then(() =>
            dispatch(
              actions.sendInvite({
                teamId: data.newTeamId,
                email: data.data,
                sendInvite: false,
                changeTeam: true,
              }),
            )
              .then(() => {
                fetchCaseDetails();
              })
              .catch((err) => {
                console.error(err);
                return false;
              }),
          )
          .catch((err) => {
            console.error(err);
            return false;
          });
        break;
      default:
    }
  };

  const buttonActions = () => {
    return [
      !isAllUsers && {
        title: T.translate('case.addNewMember'),
        handler: () => handleShow(teamAdminModal.inviteMember),
        icon: faPlusCircle,
        disabled: isAllUsers,
      },
      !isAllUsers && {
        title: T.translate('case.bulkUpload'),
        handler: openDialog,
        icon: faUserPlus,
        disabled: isAllUsers,
      },
      !isAllUsers && {
        title: T.translate('case.reSendInvite'),
        handler: () => handleShow(teamAdminModal.reSendInvite),
        icon: faRetweet,
        disabled: selectedRows.length < 1,
      },
      {
        title: T.translate('generic.getInviteLink'),
        handler: () => handleShow(teamAdminModal.getInviteLink),
        icon: faLink,
        disabled: selectedRows.length !== 1 || !inviteStatus.includes('pending'),
      },
      !isAllUsers && { divider: true },
      {
        title: T.translate('generic.update'),
        handler: () => handleShow(teamAdminModal.updateMember),
        icon: faEdit,
        disabled: selectedRows.length < 1 || inviteStatus.includes('pending'),
      },
      selectedConference &&
        isAllUsers && {
          title: T.translate('generic.changePosition'),
          handler: () => handleShow(teamAdminModal.changeHearingRoomPosition),
          icon: faUserEdit,
          disabled: selectedRows.length < 1 || showChangePosition,
        },
      {
        title: T.translate('generic.remove'),
        handler: () => handleShow(teamAdminModal.removeMember),
        icon: faTrashAlt,
        disabled: selectedRows.length < 1,
      },
      !isAllUsers && {
        title: T.translate('generic.changeTeam'),
        handler: () => handleShow(teamAdminModal.changeTeam),
        icon: faExchangeAlt,
        disabled: selectedRows.length !== 1,
      },
      { divider: true },
      {
        title: T.translate('generic.systemTestResult'),
        handler: () => handleShow(teamAdminModal.systemTestResult),
        icon: faLaptopCode,
        disabled: selectedRows.length !== 1,
      },
      {
        title: T.translate('generic.loginHistory'),
        handler: () => handleShow(teamAdminModal.loginHistory),
        icon: faHistory,
        disabled: selectedRows.length !== 1,
      },
      !isAllUsers && {
        title: T.translate('generic.activityLogs'),
        handler: () => handleShow(teamAdminModal.getActivityLogs),
        icon: faInfoCircle,
      },
    ].filter(Boolean);
  };

  const onRowSelected = useCallback(
    (newlySelectedRows) => {
      setSelectedRows(newlySelectedRows);
      setInviteStatus(newlySelectedRows.map((row) => row.teamInviteStatus));
      if (isAllUsers && selectedConference) {
        const fixedActives = newlySelectedRows.map((row) => row.hearingRoomRole);
        setFixedActiveRolesList(fixedActives);
      }
    },
    [setSelectedRows, isAllUsers, selectedConference],
  );

  const onUserUpload = (acceptedFiles) => {
    let usersList = [];
    let filesProcessed = 0;

    const checkAndSetUsersList = () => {
      filesProcessed++;
      if (filesProcessed === acceptedFiles.length) {
        setImportedUsersList(usersList);
        handleShow(teamAdminModal.inviteMember);
      }
    };

    acceptedFiles.forEach((file) => {
      if (file.type === 'text/csv') {
        const reader = new FileReader();
        reader.onload = function (e) {
          const emails = e.target.result.split(',');
          emails.forEach((email) => {
            usersList.push(email.trim());
          });
          checkAndSetUsersList();
        };
        reader.readAsText(file);
      } else {
        checkAndSetUsersList();
      }
    });
  };

  const handleUserKeyPress = useCallback(
    (e) => {
      if (selectedRows.length === 1) {
        if ((e.ctrlKey || e.metaKey) && e.code === 'KeyM') {
          handleShow(teamAdminModal.updateMember);
        }
      }
    },
    [selectedRows],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  useEffect(() => {}, []);

  useEffect(() => {
    if (isAllUsers) {
      if (teams.length > 0) {
        const allUsers = teams.reduce((acc, t) => {
          return [...acc, ...t.users.map((user) => ({ ...user, teamName: t.name }))];
        }, []);
        setTeam({ users: allUsers });
      }
    } else {
      setTeam({ teamId: currentTeam.id, users: currentTeam.users });
    }
  }, [isAllUsers, teams, currentTeam]);

  return (
    <>
      {team ? (
        <div className="case-table-wrapper admin-table-wrapeer" key="table-wrapper">
          <Container fluid className="h-100">
            <Row
              className={`${
                params.teamId || isAllUsers ? 'justify-content-between' : 'justify-content-end'
              } align-items-center p-2 px-3`}
            >
              {(params.teamId || isAllUsers) && (
                <Breadcrumb>
                  <Breadcrumb.Item active={true}>{teamName}</Breadcrumb.Item>
                </Breadcrumb>
              )}
              <ActionsButton buttonActions={buttonActions()} />
            </Row>
            <Row className="secondRow">
              <Col md={12} className="h-100">
                <Card>
                  <FileDropZone
                    refLink={userFileDropRef}
                    onDropCustom={(acceptedFiles) => onUserUpload(acceptedFiles)}
                  >
                    <TeamAdminTableCase
                      onRowSelected={onRowSelected}
                      users={team.users}
                      isAllUsers={isAllUsers}
                      selectedConference={selectedConference}
                      handleSystemTestShow={handleSystemTestShow}
                    />
                  </FileDropZone>
                </Card>
                {isAllUsers && (
                  <div className="admin-footer">
                    <div className="user-count">
                      {T.translate('case.userCount', { count: team.users.length })}
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
          <TeamAdminModal
            show={showModal}
            action={modalAction}
            handleClose={handleClose}
            handleSave={handleSave}
            selectedRows={selectedRows}
            teamId={team.teamId}
            users={team.users}
            teams={isAllUsers && teams.length > 0 ? teams : null}
            selectedConference={selectedConference}
            hoveredUser={hoveredUser}
            importedUsersList={importedUsersList}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default TeamAdminTableWrapper;
