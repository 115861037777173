import React from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

interface CurrentFileMetaData {
  startPage: string;
  globalPagePrefix: string;
  pageCount: number | string;
  tab?: string;
  [key: string]: any;
}

interface GlobalPagingBreadcrumbProps {
  isGlobalPaging: boolean;
  currentFileMetaData: CurrentFileMetaData | null;
  fileStartPage: string[];
  isStartPageFirstDigitAfterDecimalZero: boolean;
}

const GlobalPagingBreadcrumbItem: React.FC<GlobalPagingBreadcrumbProps> = ({
  isGlobalPaging,
  currentFileMetaData,
  fileStartPage,
  isStartPageFirstDigitAfterDecimalZero,
}) => {
  if (!isGlobalPaging || !currentFileMetaData || !currentFileMetaData.startPage) return null;

  let computedEndPage: number | string;
  if (currentFileMetaData.startPage.includes('.')) {
    const prefix = fileStartPage[0];
    const decimalPart = fileStartPage[1];
    const parsedDecimal = isStartPageFirstDigitAfterDecimalZero
      ? parseInt(decimalPart.slice(1), 10)
      : parseInt(decimalPart, 10);
    computedEndPage =
      `${prefix}.${isStartPageFirstDigitAfterDecimalZero ? '0' : ''}` +
      (parsedDecimal + parseInt(`${currentFileMetaData.pageCount}`, 10) - 1);
  } else {
    computedEndPage =
      parseInt(currentFileMetaData.startPage, 10) +
      parseInt(`${currentFileMetaData.pageCount}`, 10) -
      1;
  }

  const breadcrumbText = `${currentFileMetaData.globalPagePrefix}${currentFileMetaData.startPage}-${computedEndPage}${
    currentFileMetaData.tab ? ` [Tab ${currentFileMetaData.tab}]` : ''
  }`;

  return <Breadcrumb.Item active={true}>{breadcrumbText}</Breadcrumb.Item>;
};

export default GlobalPagingBreadcrumbItem;
