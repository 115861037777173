/* eslint-disable no-extra-boolean-cast */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import T from 'i18n';
import FilterAnnotations from 'features/viewing/AcusoftViewer/AcusoftViewerControl/AnnotationSidebarList/FilterAnnotations';
import { Mark } from '@prizmdoc/viewer-core';
import AnnotationItem from './AnnotationItem';
import { updateAnnotationSeq } from 'features/viewing/redux/updateAnnotationSeq';
import Avatar from '@mui/material/Avatar';
import reorder from './reorder';
import { useSelector } from 'react-redux';
import { selectDocumentAnnotationsFilter } from 'common/selectors';
import { filterAnnotations } from 'features/viewing/redux/fetchAnnotations';
import { Box, Tabs } from '@mui/material';
import SideSummaryDisplay from '../SideSummaryDisplay';
import Tab from '@mui/material/Tab';
import { Lock, LockOpen } from '@mui/icons-material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  style?: any;
}

type SidebarViewerProps = {
  highlights: Array<any>;
  selectedHighlight: any;
  scrollToHighlight: (highlight: any) => void;
  userId: string;
  fileId: string;
  fileName: string;
  fileHasSummary: boolean;
};

export default ({
  highlights,
  selectedHighlight,
  scrollToHighlight,
  userId,
  fileId,
  fileName,
  fileHasSummary,
}: SidebarViewerProps) => {
  const dispatch = useDispatch();
  const [sortMode, setSortMode] = useState(false);
  const [filterPosition, setFilterPosition] = useState<HTMLElement | null>(null);
  const [sidebarViewValue, setSidebarViewValue] = useState(0);

  const handleSwitchView = (event: React.SyntheticEvent, newValue: number) => {
    setSidebarViewValue(newValue);
  };

  const onlyAnnotations = highlights.filter((highlight) =>
    [Mark.Type.HighlightAnnotation, Mark.Type.RectangleAnnotation].includes(
      highlight.annotation.type,
    ),
  );

  const filter = useSelector(selectDocumentAnnotationsFilter);

  const filterCount =
    Object.keys(filter).filter((key) => !filter[key]).length +
    (filter.createdBy
      ? Object.keys(filter.createdBy).filter((key) => !filter.createdBy[key]).length
      : 0);

  const filterHandler = (updatedFilters: any) => {
    dispatch(filterAnnotations(updatedFilters, userId));
  };

  const handleLockClick = (canSort: boolean) => {
    const hasMyAnnotations = onlyAnnotations.find((ann: any) => ann.createdBy.id === userId);

    setSortMode(canSort);
    if (canSort) {
      if (!!filterPosition) handleClickAway();
      filterHandler({
        ...(filter.createdBy && {
          createdBy: {
            ...Object.keys(filter.createdBy).reduce((a, key) => ({ ...a, [key]: false }), {}),
            ...(hasMyAnnotations && { [hasMyAnnotations.createdBy.name]: true }),
          },
        }),
        hyperlinks: false,
        crossReference: false,
      });
    } else {
      filterHandler({
        ...(filter.createdBy && {
          createdBy: {
            ...Object.keys(filter.createdBy).reduce((a, key) => ({ ...a, [key]: true }), {}),
          },
        }),
        hyperlinks: true,
        crossReference: true,
      });
    }
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;
    if (result.source.index === result.destination.index) return;

    const updatedAnnotations = reorder(
      onlyAnnotations,
      result.source.index,
      result.destination.index,
    );
    dispatch(updateAnnotationSeq({ updatedAnnotations }));
  };

  const filterClicked = (event: any) => {
    setFilterPosition(event.currentTarget);
  };

  const handleClickAway = () => {
    setFilterPosition(null);
  };

  const renderAnnotations = () => (
    <>
      <div className="annotationsContainer" style={{ display: 'flex' }}>
        <div className="annotationsScrollbarContainer">
          {onlyAnnotations.length > 0 ? (
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="annotations-list">
                {(provided) => {
                  return (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      <ul className="sidebar-view__highlights" style={{ listStyleType: 'none' }}>
                        {onlyAnnotations.map((highlight, index) => {
                          return highlight.annotation.data ? (
                            sortMode ? (
                              <Draggable
                                key={highlight.id}
                                draggableId={highlight.id}
                                index={index}
                              >
                                {(providedDrag, snapshot) => (
                                  <div
                                    ref={providedDrag.innerRef}
                                    {...providedDrag.draggableProps}
                                    {...providedDrag.dragHandleProps}
                                    style={{
                                      ...(snapshot.isDragging && {
                                        backgroundColor: 'rgba(255, 255, 255, 0.23)',
                                      }),
                                      ...providedDrag.draggableProps.style,
                                    }}
                                  >
                                    <AnnotationItem
                                      highlight={highlight}
                                      selectedHighlight={selectedHighlight}
                                      scrollToHighlightHandler={scrollToHighlight}
                                      sortMode={sortMode}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ) : (
                              <AnnotationItem
                                key={highlight.id}
                                highlight={highlight}
                                selectedHighlight={selectedHighlight}
                                scrollToHighlightHandler={scrollToHighlight}
                                sortMode={sortMode}
                              />
                            )
                          ) : null;
                        })}
                        {provided.placeholder}
                      </ul>
                    </div>
                  );
                }}
              </Droppable>
            </DragDropContext>
          ) : (
            <div className="clearAll">{T.translate('viewing.nothingSelected')}</div>
          )}
        </div>
      </div>
    </>
  );

  const renderSummaries = () => {
    return (
      <SideSummaryDisplay
        open={true}
        fileId={fileId}
        fileName={fileName}
        setLocalSidebarOpen={null}
        fileHasSummary={fileHasSummary}
      />
    );
  };

  const CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, style, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        style={style}
        {...other}
      >
        {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
      </div>
    );
  };

  const a11yProps = (index: number) => {
    return {
      id: `sidebar-tab-${index}`,
      'aria-controls': `sidebar-tabpanel-${index}`,
    };
  };

  return (
    <div
      style={{
        height: '100%',
        flexDirection: 'column',
        position: 'relative',
        display: 'flex',
        flex: 1,
        overflowY: 'auto',
      }}
    >
      <div
        className="description-class"
        style={{
          justifyContent: 'space-between',
          flexGrow: 0,
          alignItems: 'center',
          paddingTop: '0.5rem',
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={sidebarViewValue} onChange={handleSwitchView} sx={{ minHeight: '36px' }}>
            <Tab
              sx={{ pb: 0, pt: 0, pl: 0, minHeight: '36px' }}
              label={T.translate('viewing.annotations')}
              icon={
                <IconButton size="small" onClick={() => handleLockClick(!sortMode)}>
                  {sortMode ? <LockOpen fontSize="small" /> : <Lock fontSize="small" />}
                </IconButton>
              }
              iconPosition="start"
              {...a11yProps(0)}
            />
            <Tab
              sx={{ pb: 0, pt: 0, minHeight: '36px' }}
              label={T.translate('viewing.summaries')}
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>

        {!sortMode && sidebarViewValue === 0 && (
          <ClickAwayListener onClickAway={handleClickAway}>
            <div>
              <div onClick={filterClicked}>
                <Badge badgeContent={filterCount} overlap="circular" color="primary">
                  <Avatar className="filterAnnotationsButton">
                    <FilterListRoundedIcon style={{ color: 'gray' }} />
                  </Avatar>
                </Badge>
              </div>
              <Popper
                id={'simple-popover'}
                className="popper-shadow"
                open={!!filterPosition}
                disablePortal={true}
                placement="bottom-end"
                style={{ pointerEvents: 'all', width: '18rem' }}
                {...(filterPosition && { anchorEl: filterPosition })}
              >
                <FilterAnnotations filterHandler={filterHandler} />
              </Popper>
            </div>
          </ClickAwayListener>
        )}
      </div>
      <CustomTabPanel value={sidebarViewValue} index={0}>
        {renderAnnotations()}
      </CustomTabPanel>
      <CustomTabPanel
        value={sidebarViewValue}
        index={1}
        style={{ display: 'flex', overflowY: 'auto', justifyContent: 'center' }}
      >
        {renderSummaries()}
      </CustomTabPanel>
    </div>
  );
};
