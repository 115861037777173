import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { COMMON_NON_SUPPORTED_BROWSER } from './constants';

export function setNonSupportedBrowser() {
  return {
    type: COMMON_NON_SUPPORTED_BROWSER,
  };
}

export function useNonSupportedBrowser() {
  const dispatch = useDispatch();
  const nonSupportedBrowser = useSelector((state) => state.common.nonSupportedBrowser);
  const boundAction = useCallback(
    (...params) => dispatch(setNonSupportedBrowser(...params)),
    [dispatch],
  );
  return { nonSupportedBrowser, setNonSupportedBrowser: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case COMMON_NON_SUPPORTED_BROWSER:
      return {
        ...state,
        nonSupportedBrowser: true,
      };

    default:
      return state;
  }
}
