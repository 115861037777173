import React, { useState, useEffect, forwardRef, useMemo } from 'react';
import AcusoftViewerControl from './AcusoftViewerControl';
import Spinner from 'features/common/Spinner';
import Error from './AcusoftViewerControl/ViewerError';
import Logger from 'utils/logger';
import {
  selectAccessToken,
  selectCurrentCaseId,
  selectMatchedUrl,
  selectMaxPagePrefetch,
  selectPresentQuery,
  selectQuery,
} from 'common/selectors';
import { useSelector } from 'react-redux';
import { useFetchViewingSessionId } from '../redux/fetchViewingSessionId';
import { Ajax } from '@prizmdoc/viewer-core';
import AcusoftViewerControlWithDocumentLookup from './AcusoftViewerControl/AcusoftViewerControlWithDocumentLookup';

interface Props {
  fileId: string;
  isPreviewMode?: boolean;
  isDocFinder: boolean;
  isQueryParamMatchingAnyFolder: boolean;
  startPageForGlobalPaging: string;
  hearingRoomMode?: 'private' | 'public';
  fileData?: {
    viewingSessionId?: string;
    prizmApiUrl?: string;
    viewingFilePrivate?: boolean;
    documentRotation?: number;
    passViewingSessionIdToReceivers?: boolean;
  };
  bundleSpecificFileDetails?: object;
  removeInput?: boolean;
  enableScroll?: boolean;
  searchHandler?: (searchDetails: any) => void;
  searchFunctionsHandler?: (searchFunctions: any) => void;
  isFullScreen?: boolean;
  sideSummaryOpen?: boolean;
  setSideSummaryOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  fileHasSummary?: boolean;
}

const AcusoftViewer = forwardRef(
  (
    {
      fileId,
      isPreviewMode,
      isDocFinder,
      hearingRoomMode,
      fileData,
      bundleSpecificFileDetails,
      isQueryParamMatchingAnyFolder,
      startPageForGlobalPaging,
      removeInput,
      enableScroll,
      searchHandler,
      searchFunctionsHandler,
      isFullScreen,
      sideSummaryOpen,
      setSideSummaryOpen,
      fileHasSummary,
    }: Props,
    ref,
  ) => {
    const [viewerOptions, setViewerOptions] = useState(fileData);
    const [viewingSessionIdError, setViewingSessionIdError] = useState(false);
    const [presentView, setPresentView] = useState('1');
    const present = useSelector(selectPresentQuery) as 'private' | 'public' | undefined;
    const maxPagePrefetch = useSelector(selectMaxPagePrefetch);
    const currentUrl = useSelector(selectMatchedUrl);
    const caseId = useSelector(selectCurrentCaseId);
    const isPresentModePresent = present && currentUrl.includes(`${caseId}/present-mode`);
    const accessToken = useSelector(selectAccessToken);
    const query = useSelector(selectQuery);

    const { fetchViewingSessionId } = useFetchViewingSessionId();

    const fetchViewingSessionIdHandler = useMemo(
      () => () =>
        fetchViewingSessionId(fileId)
          .then((res: any) => {
            setViewerOptions({
              viewingSessionId: res.viewingSessionId,
              prizmApiUrl: res.prizmApiUrl,
              viewingFilePrivate: res.file?.private,
              passViewingSessionIdToReceivers: res.passViewingSessionIdToReceivers,
            });
          })
          .catch((res: any) => {
            Logger.ERROR('Fetching viewing session ID', res.error);
            setViewingSessionIdError(true);
          }),
      [fetchViewingSessionId, fileId],
    );

    useEffect(() => {
      if (
        !viewerOptions?.viewingSessionId ||
        !viewerOptions?.prizmApiUrl ||
        viewerOptions?.viewingFilePrivate === undefined
      ) {
        fetchViewingSessionIdHandler();
      }
      // MOUNT
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      Ajax.setHeaders({
        Authorization: `Bearer ${accessToken}`,
      });
    }, [accessToken]);

    useEffect(() => {
      if (query && query.view === 'share') {
        setPresentView('share');
      } else {
        setPresentView('normal');
      }
    }, [query]);

    return (
      <div className="viewing-acusoft-viewer">
        {viewingSessionIdError ? (
          <Error />
        ) : viewerOptions?.viewingSessionId && viewerOptions?.prizmApiUrl && accessToken ? (
          presentView === 'share' || (query.view === 'share' && isFullScreen) ? (
            <AcusoftViewerControlWithDocumentLookup
              // key={viewerOptions.viewingSessionId}
              ref={ref}
              className="pcc-page-list"
              options={{
                documentID: viewerOptions.viewingSessionId,
                imageHandlerUrl: viewerOptions.prizmApiUrl,
                // maxPrefetch not a part of documentation, but was instructed by acusoft team in an email 08.07.22
                maxPrefetch: isPresentModePresent ? 0 : parseInt(maxPagePrefetch),
                pageRotation: viewerOptions.documentRotation || 0,
                // viewerAssetsPath: process.env.REACT_APP_VIEWER_ASSETS,
              }}
              fileId={fileId}
              viewingFilePrivate={viewerOptions.viewingFilePrivate}
              passViewingSessionIdToReceivers={viewerOptions.passViewingSessionIdToReceivers}
              firstParentTriaBundleFileDetails={bundleSpecificFileDetails}
              present={present}
              removeInput={removeInput}
              fileData={fileData}
              enableScroll={enableScroll}
              isFullScreen={isFullScreen}
              isPreviewMode={isPreviewMode}
              hearingRoomMode={hearingRoomMode}
              searchHandler={searchHandler}
              searchFunctionsHandler={searchFunctionsHandler}
              sideSummaryOpen={sideSummaryOpen}
              setSideSummaryOpen={setSideSummaryOpen}
              fileHasSummary={fileHasSummary}
              fetchViewingSessionIdHandler={fetchViewingSessionIdHandler}
              isQueryParamMatchingAnyFolder={isQueryParamMatchingAnyFolder}
              startPageForGlobalPaging={startPageForGlobalPaging}
              isDocFinder={isDocFinder}
            />
          ) : (
            <AcusoftViewerControl
              key={viewerOptions.viewingSessionId}
              ref={ref}
              className="pcc-page-list"
              options={{
                documentID: viewerOptions.viewingSessionId,
                imageHandlerUrl: viewerOptions.prizmApiUrl,
                // maxPrefetch not a part of documentation, but was instructed by acusoft team in an email 08.07.22
                maxPrefetch: isPresentModePresent ? 0 : parseInt(maxPagePrefetch),
                pageRotation: viewerOptions.documentRotation || 0,
                // viewerAssetsPath: process.env.REACT_APP_VIEWER_ASSETS,
              }}
              fileId={fileId}
              viewingFilePrivate={viewerOptions.viewingFilePrivate}
              passViewingSessionIdToReceivers={viewerOptions.passViewingSessionIdToReceivers}
              firstParentTriaBundleFileDetails={bundleSpecificFileDetails}
              present={present}
              removeInput={removeInput}
              fileData={fileData}
              enableScroll={enableScroll}
              isFullScreen={isFullScreen}
              isQueryParamMatchingAnyFolder={isQueryParamMatchingAnyFolder}
              isDocFinder={isDocFinder}
              startPageForGlobalPaging={startPageForGlobalPaging}
              isPreviewMode={isPreviewMode}
              hearingRoomMode={hearingRoomMode}
              searchHandler={searchHandler}
              searchFunctionsHandler={searchFunctionsHandler}
              sideSummaryOpen={sideSummaryOpen}
              setSideSummaryOpen={setSideSummaryOpen}
              fileHasSummary={fileHasSummary}
              fetchViewingSessionIdHandler={fetchViewingSessionIdHandler}
            />
          )
        ) : (
          <Spinner />
        )}
      </div>
    );
  },
);

export default AcusoftViewer;
