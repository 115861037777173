import {
  CASE_UPDATE_NOTIFICATIONS_BEGIN,
  CASE_UPDATE_NOTIFICATIONS_SUCCESS,
  CASE_UPDATE_NOTIFICATIONS_FAILURE,
  CASE_UPDATE_NOTIFICATIONS_DISMISS_FEEDBACK,
} from './constants';
import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';

export const updateNotifications = createPromiseAction(CASE_UPDATE_NOTIFICATIONS_BEGIN);

export function dismissUpdateNotificationsFeedback() {
  return {
    type: CASE_UPDATE_NOTIFICATIONS_DISMISS_FEEDBACK,
  };
}

export function* doUpdateNotifications(action) {
  const {
    payload: { caseId, data },
  } = action;

  let res = yield call(api.put, `/cases/${caseId}/notifications`, data);

  if (res && res.error) {
    yield put({
      type: CASE_UPDATE_NOTIFICATIONS_FAILURE,
      feedback: {
        message: 'feedback.updateNotificationsFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_UPDATE_NOTIFICATIONS_SUCCESS,
    data: { notifications: res },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchUpdateNotifications() {
  yield takeLatest(updateNotifications, withCurrentCaseId(doUpdateNotifications));
}

export function useUpdateNotifications() {
  const dispatch = useDispatch();

  const { notifications, updateNotificationsPending, updateNotificationsFeedback } = useSelector(
    (state) => ({
      notifications: state.case.notifications,
      updateNotificationsPending: state.case.updateNotificationsPending,
      updateNotificationsFeedback: state.case.updateNotificationsFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(updateNotifications(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissUpdateNotificationsFeedback());
  }, [dispatch]);

  return {
    notifications,
    updateNotifications: boundAction,
    updateNotificationsPending,
    updateNotificationsFeedback,
    dismissUpdateNotificationsFeedback: boundDismissFeedback,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_UPDATE_NOTIFICATIONS_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        updateNotificationsPending: true,
        updateNotificationsFeedback: null,
      };

    case CASE_UPDATE_NOTIFICATIONS_SUCCESS:
      // The request is success
      return {
        ...state,
        updateNotificationsPending: false,
        updateNotificationsFeedback: action.feedback,
        notifications: action.data.notifications,
      };

    case CASE_UPDATE_NOTIFICATIONS_FAILURE:
      // The request is failed
      return {
        ...state,
        updateNotificationsPending: false,
        updateNotificationsFeedback: action.feedback,
      };

    case CASE_UPDATE_NOTIFICATIONS_DISMISS_FEEDBACK:
      // Dismiss the request failure Feedback
      return {
        ...state,
        updateNotificationsFeedback: null,
      };

    default:
      return state;
  }
}
