import React, { useState, useEffect, useCallback } from 'react';
import * as actions from 'features/common/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import PerfectScrollbar from 'react-perfect-scrollbar';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import Tooltip from '@mui/material/Tooltip';
import T from 'i18n';
import FilterRoles from './FilterRoles';
import { Spinner } from 'features/common';
import { selectRolesSettings, selectCurrentCase } from 'common/selectors';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import history from 'common/history';
import { useFetchCaseDetails } from './redux/fetchCaseDetails';

const useCustomTableHeadingStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    fontSize: '1.5rem',
    fontWeight: 100,
    color: '#9a9a9a',
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  caseHeading: {
    '&:hover': {
      cursor: 'pointer',
      color: '#0056b3',
      textDecoration: 'underline',
    },
  },
  filterIcon: {
    color: '#9a9a9a',
  },
}));

const CustomTableHeading = ({ updateTable }) => {
  const defaultFilters = {
    documents: false,
    folders: false,
    hearingRoom: false,
    admin: false,
  };
  const classes = useCustomTableHeadingStyles();
  const { id: currentCaseId, name: caseName } = useSelector(selectCurrentCase);
  const [showFilters, setShowFilters] = useState(false);
  const [filterPosition, setFilterPosition] = useState(null);
  const [rolesFilters, setRolesFilters] = useState(defaultFilters);
  const [filtersCount, setFiltersCount] = useState(0);

  const filterIconClicked = (event) => {
    setShowFilters(!showFilters);
    setFilterPosition(event.currentTarget);
  };

  const filterHandler = (updatedFilters) => {
    let count = 0;
    for (const key in updatedFilters) {
      if (updatedFilters[key]) count += 1;
    }
    setFiltersCount(count);
    setRolesFilters(updatedFilters);
    updateTable(updatedFilters);
  };

  return (
    <div style={{ display: 'flex' }}>
      <Typography
        className={classes.root}
        color="inherit"
        variant="h6"
        id="tableTitle"
        component="div"
      >
        <span
          className={classes.caseHeading}
          onClick={() =>
            history.push(
              history.location.pathname.replace(/\/case.*/, ``) + `/case/${currentCaseId}`,
            )
          }
        >
          {caseName}
        </span>
        <ChevronRightIcon fontSize="large" />
        {T.translate('case.roleSettings')}
      </Typography>
      <ClickAwayListener onClickAway={() => setShowFilters(false)}>
        <div>
          <div onClick={filterIconClicked} className="filter-icon-section">
            <Badge badgeContent={filtersCount} overlap="circular" color="primary">
              <Avatar className="filterRolesButton">
                <FilterListRoundedIcon fontSize="medium" className={classes.filterIcon} />
              </Avatar>
            </Badge>
          </div>
          <Popper
            id={'filter-popover'}
            className="role-popper-shadow"
            open={Boolean(showFilters)}
            anchorEl={filterPosition}
            disablePortal={true}
            placement="bottom-end"
          >
            <FilterRoles defaultFilters={rolesFilters} filterHandler={filterHandler} />
          </Popper>
        </div>
      </ClickAwayListener>
    </div>
  );
};

const RolesTable = () => {
  const dispatch = useDispatch();
  const { caseDetails } = useFetchCaseDetails();
  const { setRoles, customRoles } = useSelector(selectRolesSettings);
  const [roles, setRolesP] = useState([]);
  const [permissions, setPermissions] = useState({});
  const [filteredPermissions, setFilteredPermissions] = useState(null);
  const [errorMsg, setErrorMsg] = useState(false);
  const [loading, setLoading] = useState(false);

  const getFormatedLabel = (label) => {
    return label.replace(/([A-Z])/g, ' $1').trim();
  };

  const updateTable = (permissionFilters) => {
    const selectPermissions = [];
    for (const key in permissionFilters) {
      if (permissionFilters[key]) selectPermissions.push(key);
    }
    setFilteredPermissions(selectPermissions);
  };

  const filterObject = (obj) => {
    if (filteredPermissions && filteredPermissions.length > 0) {
      const newObj = {};
      for (const [key, val] of Object.entries(obj)) {
        if (filteredPermissions.includes(key)) newObj[key] = val;
      }
      return newObj;
    } else {
      return obj;
    }
  };

  const getCaseSpecificRoles = useCallback(
    (res) => {
      const roles = res.map((roleCat) => {
        const newOptions = roleCat.options.filter((option) => customRoles.includes(option.id));
        return {
          label: roleCat.label,
          options: newOptions,
        };
      });
      setRolesP(roles);
    },
    [customRoles],
  );

  useEffect(() => {
    setLoading(true);
    if (caseDetails)
      dispatch(actions.fetchAllPermissions())
        .then((res) => {
          setPermissions(res.permissions);
          dispatch(actions.fetchRoles({ groupRoleByClass: true, includePermissions: true }))
            .then((res) => {
              setLoading(false);
              if (setRoles) getCaseSpecificRoles(res);
              else setRolesP(res);
            })
            .catch((err) => {
              console.error(err);
              setLoading(false);
              setErrorMsg(true);
            });
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          setErrorMsg(true);
        });
  }, [dispatch, caseDetails, setRoles, getCaseSpecificRoles]);

  return (
    <>
      {!loading ? (
        <div className="roles-guide">
          {!errorMsg && (
            <Paper elevation={3} className="roles-table">
              <CustomTableHeading updateTable={updateTable} />
              <TableContainer className="roles-table-container">
                <PerfectScrollbar options={{ maxScrollbarLength: 350 }}>
                  <Table size="small" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}
                        ></TableCell>
                        {(filteredPermissions && filteredPermissions.length > 0
                          ? filteredPermissions
                          : Object.keys(permissions)
                        ).map((permission, index) => (
                          <TableCell
                            key={index}
                            align="center"
                            style={{
                              color: '#9a9a9a',
                              fontWeight: '600',
                              whiteSpace: 'nowrap',
                              borderTop: '1px solid rgba(224, 224, 224, 1)',
                            }}
                            colSpan={Object.keys(permissions[permission]).length}
                          >
                            {getFormatedLabel(permission).toUpperCase()}
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align="left"
                          style={{ top: 32, color: '#9a9a9a', fontWeight: '600' }}
                        >
                          {'ROLES'}
                        </TableCell>
                        {(filteredPermissions && filteredPermissions.length > 0
                          ? filteredPermissions
                          : Object.keys(permissions)
                        ).map((permission) =>
                          Object.keys(permissions[permission]).map((item, index) => (
                            <TableCell
                              key={index}
                              align="center"
                              style={{
                                top: 32,
                                color: '#9a9a9a',
                                fontWeight: '600',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {getFormatedLabel(item).toUpperCase()}
                            </TableCell>
                          )),
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {roles.map((role) =>
                        role.options.map((item, index) => (
                          <TableRow>
                            <TableCell
                              key={index}
                              align="left"
                              style={{
                                color: '#9a9a9a',
                                fontWeight: '600',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              <Tooltip title={item.description} placement="top" arrow>
                                <span style={{ cursor: 'default' }}>{item.name.toUpperCase()}</span>
                              </Tooltip>
                            </TableCell>
                            {Object.values(filterObject(item.permissions)).map((permission) =>
                              Object.values(permission).map((item, index) => (
                                <TableCell key={index} align="center">
                                  {typeof item === 'boolean' ? (
                                    item ? (
                                      <DoneRoundedIcon style={{ color: 'green' }} />
                                    ) : (
                                      <ClearRoundedIcon style={{ color: 'red' }} />
                                    )
                                  ) : item !== null && Array.isArray(item) && item.length > 0 ? (
                                    item.map((val, idx) => (
                                      <div key={idx} style={{ whiteSpace: 'nowrap' }}>
                                        {val.charAt(0).toUpperCase() + val.slice(1)}
                                      </div>
                                    ))
                                  ) : (
                                    // : item !== null && typeof item === 'object' ? (
                                    //   Object.entries(item).map(([key, val], idx) => {
                                    //     return (
                                    //       val && (
                                    //         <div key={idx} style={{ whiteSpace: 'nowrap' }}>
                                    //           {key.charAt(0).toUpperCase() + key.slice(1)}
                                    //         </div>
                                    //       )
                                    //     );
                                    //   })
                                    // )
                                    ``
                                  )}
                                </TableCell>
                              )),
                            )}
                          </TableRow>
                        )),
                      )}
                    </TableBody>
                  </Table>
                </PerfectScrollbar>
              </TableContainer>
            </Paper>
          )}
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default RolesTable;
