import { Button } from '@mui/material';
import TypingEffect from 'features/common/TypingEffect';
import React, { createRef, useRef } from 'react';

interface SearchAnswerContainerProps {
  response: any;
  typingEffect: boolean;
  scrollBarRef: any;
  handleMatchesLinkClicked: Function;
  allMatchesCount: number;
  allMatches?: any;
  isTranscriptPage?: boolean;
}

const SearchAnswerContainer = ({
  response,
  typingEffect,
  scrollBarRef,
  handleMatchesLinkClicked,
  allMatchesCount,
  allMatches,
  isTranscriptPage,
}: SearchAnswerContainerProps) => {
  const textRefs = useRef<React.RefObject<HTMLSpanElement>[]>(response.map(() => createRef()));

  const selectText = (index: number) => {
    const textElement = textRefs.current[index].current;
    if (textElement) {
      if (window.getSelection) {
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(textElement);
        selection?.removeAllRanges();
        selection?.addRange(range);
      }
    }
  };

  const getSourcesBtns = (refrences: any, lineIndex: number) => {
    return refrences.map(
      (src: any, srcIdx: number) =>
        (src.source || src) <= allMatchesCount && (
          <React.Fragment key={srcIdx}>
            (
            <Button
              size="small"
              sx={{
                p: 0,
                minWidth: '0.5rem',
                fontSize: isTranscriptPage ? '1.3rem' : '1rem',
                fontWeight: 600,
              }}
              variant="text"
              onClick={(e) => {
                selectText(lineIndex);
                handleMatchesLinkClicked(e, src.source || src, allMatches, src.highlight || '');
              }}
            >
              {src.source || src}
            </Button>
            )
          </React.Fragment>
        ),
    );
  };

  return response.map((item: any, ind: number) => (
    <React.Fragment key={ind}>
      {typingEffect ? (
        <TypingEffect
          responseRef={textRefs.current[ind]}
          response={item.text}
          typingSpeed={20}
          scrollBarRef={scrollBarRef}
          elementsAfterText={getSourcesBtns(item.references || item.sources, ind)}
        />
      ) : (
        <>
          <span ref={textRefs.current[ind]}>{item.text}</span>
          {getSourcesBtns(item.references || item.sources, ind)}
        </>
      )}
      <br />
    </React.Fragment>
  ));
};

export default SearchAnswerContainer;
