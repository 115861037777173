import React from 'react';
import moment from 'moment';
import Card from 'react-bootstrap/Card';
import T from 'i18n';
import Collapsible from 'features/common/Collapsible';
import Table from 'features/common/Table';
import SortHeader from 'features/case/TableCase/SortHeader';

const columnSelector = () => {
  return [
    {
      id: 'action',
      Header: () => <SortHeader text="common.action" />,
      Cell: (log) => (
        <div className="customGrid">
          <span style={{ overflowWrap: 'anywhere' }}>{log.action}</span>
        </div>
      ),
      minWidth: '150px',
      width: '15rem',
    },
    {
      id: 'name',
      Header: () => <SortHeader text="common.who" />,
      Cell: (log) => (
        <div className="customGrid">
          <span style={{ overflowWrap: 'anywhere' }}>{log.name}</span>
        </div>
      ),
      minWidth: '90px',
      width: '10rem',
    },
    {
      id: 'date',
      Header: () => <SortHeader text="common.when" />,
      Cell: (log) => (
        <div className="customGrid">
          <span>{moment(log.date).utc().format('DD/MM/YYYY HH:mm')}</span>
        </div>
      ),
      minWidth: '100px',
      width: '10rem',
    },
    {
      id: 'additionalData',
      Header: () => <SortHeader text="common.additionalData" />,
      Cell: (log) => (
        <div className="customGrid">
          <span>
            {log.previousData
              ? Object.entries(log.previousData).map(
                  (data) => `${data[0].charAt(0).toUpperCase() + data[0].slice(1)}: "${data[1]}", `,
                )
              : ''}
          </span>
        </div>
      ),
      minWidth: '200px',
      width: 'calc(100% - 35rem)',
      sort: false,
    },
  ];
};

export const CollapsiblePipelineLogs = ({
  data = [],
  tableHeight,
  rowHeight,
  outsideTableMargin,
}) => {
  return (
    <div style={{ margin: '1rem' }}>
      <Collapsible title={T.translate('common.pipelineLog')}>
        {data.length > 0 ? (
          <PipelineLogs
            data={data}
            tableHeight={tableHeight}
            rowHeight={rowHeight}
            outsideTableMargin={outsideTableMargin}
          />
        ) : (
          T.translate('common.noPipelineRecords')
        )}
      </Collapsible>
    </div>
  );
};

const PipelineLogs = ({ data, tableHeight, rowHeight, outsideTableMargin }) => {
  const columns = columnSelector();

  return (
    data && (
      <Card
        style={{
          height: tableHeight,
          margin: outsideTableMargin,
        }}
      >
        <Table key={Math.random()} data={data} columns={columns} rowHeight={rowHeight} />
      </Card>
    )
  );
};

export default PipelineLogs;
