export const getGlobalPageText = (
  startPageForGlobalPaging: string,
  currentFileMetaData: {
    pageCount: any;
    startPage: any;
    globalPagePrefix: any;
  },
  gPNumberValue: number | string,
) => {
  const startPage = startPageForGlobalPaging || currentFileMetaData.startPage;

  const currentFileStartPage = getStartPageForGlobalPaging(startPage);

  const endGPNumber = parseInt(currentFileMetaData.pageCount, 10) + currentFileStartPage - 1;

  const gPNumberValueInternal = Math.min(
    Math.max(Number(gPNumberValue), currentFileStartPage),
    endGPNumber,
  );

  const startPageVal = (startPage || 0).toString().split('.');
  const isStartPageFirstDigitAfterDecimalZero =
    startPageVal && startPageVal.length > 1 && startPageVal[1].startsWith('0');
  const gpText = `${currentFileMetaData.globalPagePrefix}${
    startPageVal.length > 1
      ? startPageVal[0] + `.${isStartPageFirstDigitAfterDecimalZero ? '0' : ''}`
      : ''
  }${gPNumberValueInternal}`;

  return gpText;
};

export const getStartPageForGlobalPaging = (startPage: string) => {
  const startPageVal = (startPage || 0).toString().split('.');
  return startPageVal.length > 1 ? parseInt(startPageVal[1], 10) : parseInt(startPage, 10);
};
