import { MATCHER_ROUTE_CHANGED } from 'features/matcher/redux/constants';
import { CASE_DELETE_FOLDER_SUCCESS } from 'features/case/redux/constants';
import * as actions from 'features/common/redux/actions';
import {
  selectCurrentCaseId,
  selectUserSettings,
  selectCurrentLastFolder,
  selectUpdateUserSettingsPending,
} from 'common/selectors';

const createPath = ({
  url,
  params: {
    folder,
    folder1,
    folder2,
    folder3,
    folder4,
    courtBundle,
    trialbook,
    teamBundle,
    privateBundle,
    bundle,
  },
}) => {
  if (url.includes('all-documents')) {
    return 'all-documents';
  }
  if (courtBundle || trialbook || teamBundle || privateBundle || bundle) {
    let path = courtBundle
      ? `court-bundles/${courtBundle}`
      : trialbook
        ? `trialbundles/${trialbook}`
        : teamBundle
          ? `teamBundles/${teamBundle}`
          : privateBundle
            ? `privateBundles/${privateBundle}`
            : `bundles/${bundle}`;
    if (folder) {
      path += `/${folder}`;
    }
    if (folder1) {
      path += `/${folder1}`;
    }
    if (folder2) {
      path += `/${folder2}`;
    }
    if (folder3) {
      path += `/${folder3}`;
    }
    if (folder4) {
      path += `/${folder4}`;
    }
    return path;
  }
};

const updateUserSettingsMiddleware =
  ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    if (action.type === MATCHER_ROUTE_CHANGED || action.type === CASE_DELETE_FOLDER_SUCCESS) {
      const state = getState();
      if (state.matcher.url) {
        const currentCaseId = selectCurrentCaseId(state);
        const userSettings = selectUserSettings(state);
        const updateUserSettingsPending = selectUpdateUserSettingsPending(state);
        const currentLastFolder = selectCurrentLastFolder(state);
        const lastFolderUrl =
          action.type === CASE_DELETE_FOLDER_SUCCESS ? '' : createPath(state.matcher);
        if (
          !updateUserSettingsPending &&
          ((lastFolderUrl && currentLastFolder !== lastFolderUrl) ||
            action.type === CASE_DELETE_FOLDER_SUCCESS)
        ) {
          dispatch(
            actions.updateUserSettings({
              userSettings: {
                ...userSettings,
                lastFolder: {
                  ...userSettings.lastFolder,
                  [currentCaseId]: lastFolderUrl,
                },
              },
            }),
          );
        }
      }
    }
    return next(action);
  };

export default updateUserSettingsMiddleware;
