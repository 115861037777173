import { call, put, takeLatest } from 'redux-saga/effects';
import {
  VIEWING_FETCH_RECORDING_BEGIN,
  VIEWING_FETCH_RECORDING_SUCCESS,
  VIEWING_FETCH_RECORDING_FAILURE,
  VIEWING_FETCH_RECORDING_DISMISS_FEEDBACK,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from 'common/selectors';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

export const fetchRecording = createPromiseAction(VIEWING_FETCH_RECORDING_BEGIN);

export function dismissFetchRecordingFeedback() {
  return {
    type: VIEWING_FETCH_RECORDING_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_FETCH_RECORDING_BEGIN actions
export function* doFetchRecording(action) {
  const {
    payload: { caseId, fileId },
  } = action;
  const res = yield call(api.get, `/cases/${caseId}/recordings/${fileId}`);

  if (res && res.error) {
    yield put({
      type: VIEWING_FETCH_RECORDING_FAILURE,
      feedback: {
        message: 'feedback.fetchRecordingFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }
  // Dispatch success action out of try/catch so that render errors are not catched.
  yield put({
    type: VIEWING_FETCH_RECORDING_SUCCESS,
    data: res,
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchFetchRecording() {
  yield takeLatest(fetchRecording, withCurrentCaseId(doFetchRecording));
}

export function useFetchRecording() {
  const dispatch = useDispatch();

  const { recording, fetchRecordingPending, fetchRecordingFeedback } = useSelector(
    (state) => ({
      recording: state.viewing.recording,
      fetchRecordingPending: state.viewing.fetchRecordingPending,
      fetchRecordingFeedback: state.viewing.fetchRecordingFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(fetchRecording(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissFetchRecordingFeedback());
  }, [dispatch]);

  return {
    recording,
    fetchRecording: boundAction,
    fetchRecordingPending,
    fetchRecordingFeedback,
    dismissFetchRecordingFeedback: boundDismissFeedback,
  };
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case VIEWING_FETCH_RECORDING_BEGIN + '.TRIGGER':
      return {
        ...state,
        fetchRecordingPending: true,
        recording: null,
        fetchRecordingFeedback: null,
      };

    case VIEWING_FETCH_RECORDING_SUCCESS:
      return {
        ...state,
        recording: action.data,
        fetchRecordingPending: false,
        fetchRecordingFeedback: action.feedback,
      };

    case VIEWING_FETCH_RECORDING_FAILURE:
      return {
        ...state,
        fetchRecordingPending: false,
        recording: null,
        fetchRecordingFeedback: action.feedback,
      };

    default:
      return state;
  }
}
