import React, { useEffect, useRef } from 'react';
import { TextField } from '@mui/material';
import { getErrorFeedback } from 'features/common/ErrorFeedback';

const TextForm = React.forwardRef(
  (
    {
      placeholder,
      onChange,
      defaultValue,
      name,
      label,
      readOnly,
      value,
      type,
      autofocus,
      autocomplete,
      variant = 'outlined',
      rows = '2',
      margin = 'dense',
      shrink,
      fullWidth = true,
      multiline,
      errors,
      ...rest
    },
    ref,
  ) => {
    const firstInputRef = useRef();

    useEffect(
      () =>
        autofocus &&
        firstInputRef.current &&
        firstInputRef.current.focus &&
        firstInputRef.current.focus(),
      [autofocus, firstInputRef],
    );

    return (
      <>
        <TextField
          {...rest}
          label={label}
          inputRef={(el) => {
            ref && ref(el);
            firstInputRef.current = el;
          }}
          InputLabelProps={{ shrink }}
          rows={rows}
          margin={margin}
          fullWidth={fullWidth}
          name={name}
          placeholder={placeholder}
          defaultValue={defaultValue}
          multiline={multiline}
          onChange={onChange}
          disabled={readOnly}
          value={value}
          type={type}
          autoComplete={autocomplete ? 'on' : 'off'}
          variant={variant}
          error={!!(errors && errors[name])}
          helperText={getErrorFeedback(name, errors)}
        />
      </>
    );
  },
);

export default TextForm;
