import React, { PureComponent, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import {
  selectUserId,
  selectUserName,
  selectUserEmail,
  selectCurrentCaseId,
  selectCurrentCaseTeam,
  selectPermissions,
} from 'common/selectors';
import T from 'i18n';
import ConferenceRoomType from './enums/ConferenceRoomType';

const Twilio = lazy(() => import('features/conference/Twilio'));
const StreamViewer = lazy(() => import('features/conference/StreamViewer'));

const renderLoader = () => <p></p>;

export class Conference extends PureComponent {
  render() {
    const { hearingRoom: { canJoinConference, conferenceType, canStream } = {} } =
      this.props.permissions;
    const conferenceAlias =
      this.props.currentCaseId +
      (this.props.hearingRoomMode === 'public' ? '' : `-${this.props.currentCaseTeam.id}`);

    const confProps = {
      userId: this.props.userId,
      userName: this.props.userName,
      userEmail: this.props.userEmail,
      teamName: this.props.currentCaseTeam.name,
      hearingRoomMode: this.props.hearingRoomMode,
      permissions: this.props.permissions,
      conferenceAlias: conferenceAlias,
      bigScreen: this.props.bigScreen,
    };

    return this.props.userId ? (
      this.props.currentCaseId &&
      (this.props.userName || this.props.userEmail) &&
      canJoinConference &&
      canJoinConference(this.props.hearingRoomMode) ? (
        conferenceType &&
        conferenceType(this.props.hearingRoomMode) === ConferenceRoomType.twilio ? (
          <Suspense fallback={renderLoader()}>
            <Twilio {...confProps} />
          </Suspense>
        ) : (
          <div>{T.translate('conference.pleaseConfigureMessage')}</div>
        )
      ) : canStream(this.props.hearingRoomMode) ? (
        <Suspense fallback={renderLoader()}>
          <StreamViewer />
        </Suspense>
      ) : null
    ) : null;
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    userId: selectUserId(state),
    userName: selectUserName(state),
    userEmail: selectUserEmail(state),
    currentCaseId: selectCurrentCaseId(state),
    currentCaseTeam: selectCurrentCaseTeam(state),
    permissions: selectPermissions(state),
  };
}

export default connect(mapStateToProps)(Conference);
