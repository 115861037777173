export function findParentArrayAndIndex(
  folders: any[],
  targetFolderId: string,
): { arr: any[]; index: number } | null {
  for (let i = 0; i < folders.length; i++) {
    const current = folders[i];

    if (current.id === targetFolderId) {
      return {
        arr: folders,
        index: i,
      };
    }

    if (Array.isArray(current.folders) && current.folders.length > 0) {
      const deeper = findParentArrayAndIndex(current.folders, targetFolderId);
      if (deeper) {
        return deeper;
      }
    }
  }
  return null;
}

export function findContainerAndIndex(
  containers: any[],
  targetFolderId: string,
): { container: any; arr: any[]; index: number } | null {
  for (const container of containers) {
    if (!Array.isArray(container.folders)) continue; // skip if no folders

    const result = findParentArrayAndIndex(container.folders, targetFolderId);
    if (result) {
      return {
        container,
        ...result,
      };
    }
  }
  return null;
}
