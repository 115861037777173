/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import './UploadFileContent.scss';
import {
  AccordionDetails,
  AccordionSummary,
  Accordion as MuiAccordion,
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import pLimit from 'p-limit';

import { selectCurrentCaseId, selectFolderContainers } from 'common/selectors';
import { useSelector } from 'react-redux';
import { BlobServiceClient } from '@azure/storage-blob';
import MuiButton from '@mui/material/Button';
import { Spinner } from 'features/common';
import { BatchUploadReport, FileMetaData, ParsedTemplate } from './types';
import { v4 as uuidv4 } from 'uuid';
import { faRedo, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { validFileExts, validFileExtsShareOnly } from './constants';
import T from 'i18n';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { fetchFolder } from '../redux/fetchFolder';
import { fetchDocuments } from '../redux/fetchDocuments';
import { useFetchNotifications } from '../redux/fetchNotifications';
import {
  createBatch,
  fetchBatchReport,
  getContainerClient,
  getSasToken,
  isPDFEncrypted,
  removeTrailingSpacesFromFilesWithAndWithoutExtensions,
  triggerBatchProcessing,
} from './utils';
import AdvancedSettings from './AdvancedSettings';
import RenderFilesAndFolders from './components/RenderFilesAndFolders';

type UploadFileContentProps = {
  setUploadComplete: React.Dispatch<React.SetStateAction<boolean>>;
  setBatchUploading: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentBatchId: React.Dispatch<React.SetStateAction<number | null>>;
  setUploadInProgress: React.Dispatch<React.SetStateAction<boolean>>;
  setBatchUploadReport: React.Dispatch<React.SetStateAction<BatchUploadReport>>;
  setFileMetadata: (files: any) => void;
  setShowSideBar: any;
  setBatchSize: React.Dispatch<React.SetStateAction<number>>;
  fileMetadata: FileMetaData[];
  currentFolderId: string;
  showSidebar: boolean;
  uploadComplete: boolean;
  uploadInProgress: boolean;
  currentBatchId: number | null;
  folder?: any;
  containStatusWindow?: boolean;
  batchUploadReport?: BatchUploadReport;
  batchSize?: number;
};

const UploadFileContent = ({
  setUploadComplete,
  setBatchUploading,
  setCurrentBatchId,
  setUploadInProgress,
  setBatchUploadReport,
  setFileMetadata,
  setShowSideBar,
  setBatchSize,

  fileMetadata,
  currentFolderId,
  showSidebar,
  uploadComplete,
  uploadInProgress,
  folder,
}: UploadFileContentProps) => {
  const dragCounter = useRef(0);
  const caseId = useSelector(selectCurrentCaseId);
  const containers = useSelector(selectFolderContainers);

  const getFolderCodePathById = (folderId: string) => {
    if (!containers || !folderId) {
      return [];
    }

    function findFolderCodePath(folderList: any[], targetId: string, currentPath: string[] = []) {
      for (const f of folderList) {
        if (f.id === targetId || f.code === targetId) {
          return [...currentPath, f.code];
        }
        if (f.folders && f.folders.length > 0) {
          const subPath: any = findFolderCodePath(f.folders, targetId, [...currentPath, f.code]);
          if (subPath) {
            return subPath;
          }
        }
      }
      return null;
    }

    for (const container of containers) {
      if (container.folders && container.folders.length > 0) {
        const codePath = findFolderCodePath(container.folders, folderId, [container.code]);
        if (codePath) {
          if (codePath.includes('trialbooks')) {
            const index = codePath.indexOf('trialbooks');
            codePath[index] = 'trialbundles';
            return codePath;
          }
          return codePath;
        }
      }
    }

    return [];
  };
  const [showOverlay, setShowOverlay] = useState(false);
  const [containsValidFiles, setContainsValidFiles] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [folderToDelete, setFolderToDelete] = useState<string | null>(null);
  const [processingFiles, setProcessingFiles] = useState(false);
  const [apiError, setApiError] = useState<string | null>(null);
  const [folderUploadWarning, setFolderUploadWarning] = useState<string | null>(null);
  const [mappingData, setMappingData] = useState<any[]>([]);
  const [isMappingAttached, setIsMappingAttached] = useState<boolean>(false);
  const [allFieldsMapped, setAllFieldsMapped] = useState<boolean>(false);
  const [mapFailSignal, setMapFailSignal] = useState<boolean>(false);

  const progressStateRef = useRef<{ [key: string]: number }>({});
  const [selectedTemplate, setSelectedTemplate] = useState<ParsedTemplate>();
  const { notifications } = useFetchNotifications();
  const [resolveNotificationsPromise, setResolveNotificationsPromise] = useState<
    (() => void) | null
  >(null);

  const initialNotificationsLengthRef = useRef(notifications?.length);

  const dispatch = useDispatch();

  const processFiles = async (files: File[], folderPath: string) => {
    for (const file of files) {
      const cleanedFileName = file.name.replace(/\n/g, ' ');
      let lineBreakRemoved = false;
      if (file.name !== cleanedFileName) {
        lineBreakRemoved = true;
      }
      const extension = cleanedFileName.split('.').pop()?.toLowerCase();
      const isValidExtension =
        extension &&
        (validFileExts.includes(extension) || validFileExtsShareOnly.includes(extension));

      let status = isValidExtension ? '' : 'unsupported';

      if (isValidExtension) {
        // TODO: Correctly implement: Check for password protection
        let isProtected = false;

        if (extension === 'pdf') {
          isProtected = await isPDFEncrypted(file);
          if (isProtected) {
            status = 'protected';
          }
        }
      }
      const duplicateFile = fileMetadata.find(
        (existingFile) => existingFile.name === file.name && existingFile.folderPath === folderPath,
      );
      if (duplicateFile) {
        continue;
      }

      const newId = uuidv4();
      const fileNameWithExtension = `${newId}.${extension}`;

      setFileMetadata((prevFiles: FileMetaData[]) => [
        ...prevFiles,
        {
          file,
          name: file.name,
          uniqueId: newId,
          fileNameWithExtension,
          size: file.size,
          type: file.type,
          folderPath,
          progress: 0,
          status,
          lineBreakRemoved,
        },
      ]);
    }
  };

  const onDrop = async (acceptedFiles: File[]) => {
    if (uploadComplete) {
      handleClearAll();
    }
    if (folderUploadWarning) {
      setFolderUploadWarning(null);
    }

    setProcessingFiles(true);
    let hasDirectories = false;

    const folderMap: { [folderPath: string]: File[] } = {};
    acceptedFiles.forEach((file) => {
      const filePath = (file as any).path || file.name;
      const pathParts = filePath.split('/');
      const modifiedPathParts = pathParts.slice(2); // Remove first folder

      if (modifiedPathParts.length > 1) {
        hasDirectories = true;
      }

      if (modifiedPathParts.length > 1) {
        const folderPath = modifiedPathParts.slice(0, -1).join('/');
        if (!folderMap[folderPath]) {
          folderMap[folderPath] = [];
        }
        folderMap[folderPath].push(file);
      } else {
        if (!folderMap['root']) {
          folderMap['root'] = [];
        }
        folderMap['root'].push(file);
      }
    });

    if (hasDirectories && folder && folder.fileCount > 0) {
      setFolderUploadWarning(T.translate('case.batchUpload.convertToSubBundle'));
      setProcessingFiles(false);
      return;
    }

    for (const [folderPath, files] of Object.entries(folderMap)) {
      await processFiles(files, folderPath === 'root' ? '' : folderPath);
    }
    setProcessingFiles(false);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      dragCounter.current += 1;
      if (dragCounter.current === 1) {
        setShowOverlay(true);
      }
    }
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    dragCounter.current -= 1;
    if (dragCounter.current === 0) {
      setShowOverlay(false);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  // const handleToggleAccordion = (folderPath: string, isExpanding: boolean) => {
  //   setExpandedFolders((prevState) => {
  //     const newState = { ...prevState };
  //     newState[folderPath] = isExpanding;

  //     if (!isExpanding) {
  //       const node = findNodeByPath(folderTree, folderPath);
  //       if (node) {
  //         const allSubfolderPaths = getAllSubfolderPaths(node);
  //         allSubfolderPaths.forEach((path) => {
  //           newState[path] = false;
  //         });
  //       }
  //     }

  //     return newState;
  //   });
  // };

  // const handleOpenSnackbar = (_folder: string) => {
  //   setFolderToDelete(_folder);
  //   setOpenSnackbar(true);
  // };

  const handleCloseSnackbar = useCallback(() => {
    setOpenSnackbar(false);
    setFolderToDelete(null);
  }, []);

  const handleProceed = useCallback(() => {
    if (folderToDelete !== null) {
      setFileMetadata((prevFiles: FileMetaData[]) =>
        prevFiles.filter((file) => !file?.folderPath?.startsWith(folderToDelete ?? '')),
      );
    }
    handleCloseSnackbar();
  }, [folderToDelete, setFileMetadata, handleCloseSnackbar]);

  const handleConfirm = async () => {
    try {
      if (isMappingAttached) {
        if (!allFieldsMapped) {
          setUploadInProgress(false);
          setBatchUploading(false);
          setMapFailSignal(true);
          return;
        }
      }
      setApiError(null);
      setUploadInProgress(true);
      setBatchUploading(true);

      const { sasUrl } = await getSasToken(caseId);

      const blobServiceClient = new BlobServiceClient(sasUrl);
      const containerClient = blobServiceClient.getContainerClient('');

      const filesToUpload = fileMetadata.filter((file) => file.status !== 'unsupported');
      await uploadFilesToAzure(filesToUpload, containerClient);

      const incompleteFiles = filesToUpload.filter((file) => file.status === 'failed');
      const successfulFiles = filesToUpload.filter((file) => file.status === 'uploaded');

      if (incompleteFiles.length > 0) {
        setShowRetryDialog(true);
      } else {
        await proceedToCreateBatch(successfulFiles);
      }
    } catch (error) {
      console.error('Error in handleConfirm:', error);
      const detectedError: any = error;
      setUploadInProgress(false);
      setBatchUploading(false);
      setApiError((detectedError?.message as any) || T.translate('case.batchUpload.uploadError'));

      setFileMetadata((prevFiles: FileMetaData[]) =>
        prevFiles.map((file: FileMetaData) => ({ ...file, status: 'failed' })),
      );
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, forceUpdate] = useState(0); // Dummy state to trigger re-renders
  const throttleUpdate = useRef(false);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const uploadFilesToAzure = async (files: FileMetaData[], containerClient: any) => {
    try {
      const limit = pLimit(10);

      // Wrap each upload in the limiter
      const uploadPromises = files.map((file) =>
        limit(async () => {
          const abortController = new AbortController();
          file.abortController = abortController;
          file.status = 'uploading';

          const blockBlobClient = containerClient.getBlockBlobClient(file.fileNameWithExtension);

          try {
            await blockBlobClient.uploadData(file.file, {
              blobHTTPHeaders: {
                blobContentType: file.type,
              },
              onProgress: (progress: { loadedBytes: number }) => {
                const percentCompleted = Math.round((progress.loadedBytes / file.size) * 100);
                progressStateRef.current[file.uniqueId] = percentCompleted;
                if (!throttleUpdate.current) {
                  throttleUpdate.current = true;

                  if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                  }

                  // Set new timeout and store its ID
                  timeoutRef.current = setTimeout(() => {
                    throttleUpdate.current = false;
                    forceUpdate((n) => n + 1);
                    timeoutRef.current = null;
                  }, 500);
                }
              },
              abortSignal: abortController.signal,
              maxSingleShotSize: 4 * 1024 * 1024,
              concurrency: 4, // controls INTERNAL SDK concurrency for a single file not p limiter stuff.
              blockSize: 4 * 1024 * 1024,
            });

            file.status = 'uploaded';
          } catch (error) {
            if (error instanceof Error) {
              file.status = error.name === 'AbortError' ? 'cancelled' : 'failed';
              file.errorMessage = error.message;
            } else {
              file.status = 'failed';
              file.errorMessage = 'Unknown error';
            }
          }
        }),
      );

      await Promise.all(uploadPromises);
      setFileMetadata([...files]);
    } catch {
      throw new Error(T.translate('case.batchUpload.uploadError'));
    }
  };

  const prepareJsonDataPayload = (files: FileMetaData[]) => {
    const codePath = getFolderCodePathById(currentFolderId);
    const route = `/case/${caseId}/${codePath.join('/')}`;
    const basePayload = files
      .filter((file) => file.status === 'uploaded')
      .map((file) => ({
        uniqueId: file.uniqueId,
        fileName: removeTrailingSpacesFromFilesWithAndWithoutExtensions(file.name),
        folderPath: file.folderPath,
        originalFileName: file.name,
        rootFolderLink: route,
      }));

    // return basePayload if the mappingData is empty, else return the mappingData
    if (mappingData.length === 0) {
      return basePayload;
    }
    return mappingData;
  };

  const waitForNotifications = async () => {
    initialNotificationsLengthRef.current = notifications.length;
    const notificationsPromise = new Promise<void>((resolve) => {
      setResolveNotificationsPromise(() => resolve);
    });
    await notificationsPromise;
  };

  const refreshData = async () => {
    try {
      await dispatch(fetchFolder({ folderId: currentFolderId, noPromise: false }));
      await dispatch(fetchDocuments({} as any));
    } catch {
      console.warn(T.translate('case.batchUpload.refreshDataWarning'));
    }
  };

  const handleClearAll = useCallback(() => {
    if (uploadComplete) {
      setShowSideBar(false);
    }
    setApiError(null);
    setOpenSnackbar(false);
    setUploadComplete(false);
    setFileMetadata([]);
    setBatchUploading(false);
    setFolderUploadWarning(null);
  }, [
    setOpenSnackbar,
    setUploadComplete,
    setFileMetadata,
    setBatchUploading,
    setShowSideBar,
    uploadComplete,
  ]);

  const [showRetryDialog, setShowRetryDialog] = useState(false);

  const handleDialogClose = () => {
    setShowRetryDialog(false);
  };

  const handleRetryFailures = async () => {
    setShowRetryDialog(false);
    await retryAllFailedFiles();
  };

  const handleProcessUploadedFiles = async () => {
    setShowRetryDialog(false);
    const successfulFiles = fileMetadata.filter((file) => file.status === 'uploaded');
    if (successfulFiles.length > 0) {
      await proceedToCreateBatch(successfulFiles);
    } else {
      setApiError(T.translate('case.batchUpload.noValidFiles'));
      setUploadInProgress(false);
      setBatchUploading(false);
    }
  };

  const handleCancelUpload = () => {
    setShowRetryDialog(false);
    setUploadInProgress(false);
    setBatchUploading(false);
  };

  const proceedToCreateBatch = async (successfulFiles: FileMetaData[]) => {
    try {
      const jsonDataPayload = prepareJsonDataPayload(successfulFiles);
      setBatchSize(jsonDataPayload.length);

      if (jsonDataPayload.length === 0) {
        throw new Error(T.translate('case.batchUpload.noValidFiles'));
      }

      const codePath = getFolderCodePathById(currentFolderId);
      const route = `/case/${caseId}/${codePath.join('/')}`;
      const batch: any = await createBatch(
        caseId,
        currentFolderId,
        jsonDataPayload,
        selectedTemplate?.id as string,
        route,
      );
      setCurrentBatchId(batch.id);

      await triggerBatchProcessing(batch.id, caseId);
      await waitForNotifications();
      await refreshData();

      setUploadComplete(true);
      setUploadInProgress(false);
      setBatchUploading(false);

      await fetchBatchReport(caseId, batch.id, setBatchUploadReport);
      handleClearAll();
    } catch (error) {
      console.error('Error in proceedToCreateBatch:', error);
      setUploadInProgress(false);
      setBatchUploading(false);
      setApiError(T.translate('case.batchUpload.apiError'));
    }
  };

  const retryAllFailedFiles = async () => {
    try {
      const containerClient = await getContainerClient(caseId);

      const incompleteFiles = fileMetadata.filter(
        (file) => file.status === 'failed' || file.status === 'cancelled',
      );

      incompleteFiles.forEach((file) => {
        file.status = 'uploading';
      });

      setFileMetadata((prevFiles: FileMetaData[]) => [...prevFiles]);

      await Promise.all(
        incompleteFiles.map(async (file) => {
          await retryFileUpload(file, containerClient);
        }),
      );

      const incompleteFilesAfterRetry = incompleteFiles.filter(
        (file) => file.status === 'failed' || file.status === 'cancelled',
      );

      const successfulFiles = fileMetadata.filter((file) => file.status === 'uploaded');

      if (incompleteFilesAfterRetry.length > 0) {
        setShowRetryDialog(true);
      } else if (successfulFiles.length > 0) {
        await proceedToCreateBatch(successfulFiles);
      } else {
        setApiError(T.translate('case.batchUpload.noValidFiles'));
        setUploadInProgress(false);
        setBatchUploading(false);
      }
    } catch (error) {
      console.error('Error in retryAllFailedFiles:', error);
      const detectedError: any = error;
      setApiError(detectedError?.message || T.translate('case.batchUpload.uploadError'));
      setUploadInProgress(false);
      setBatchUploading(false);
    }
  };

  const retryFileUpload = async (file: FileMetaData, containerClient: any) => {
    try {
      file.status = 'uploading';
      setFileMetadata((prevFiles: FileMetaData[]) => [...prevFiles]);

      const abortController = new AbortController();
      file.abortController = abortController;

      const blockBlobClient = containerClient.getBlockBlobClient(file.fileNameWithExtension);
      progressStateRef.current[file.uniqueId] = 0;

      try {
        await blockBlobClient.uploadData(file.file, {
          blobHTTPHeaders: {
            blobContentType: file.type,
          },
          onProgress: (progress: { loadedBytes: number }) => {
            const percentCompleted = Math.round((progress.loadedBytes / file.size) * 100);
            progressStateRef.current[file.uniqueId] = percentCompleted;
          },
          abortSignal: abortController.signal,
          maxSingleShotSize: 4 * 1024 * 1024,
          concurrency: 10,
        });

        file.status = 'uploaded';
        file.errorMessage = undefined;
      } catch (error) {
        if (error instanceof Error) {
          file.status = error.name === 'AbortError' ? 'cancelled' : 'failed';
          file.errorMessage = error.message;
        } else {
          file.status = 'failed';
          file.errorMessage = 'Unknown error';
        }
      }

      setFileMetadata((prevFiles: FileMetaData[]) => [...prevFiles]);
    } catch (error) {
      console.error('Error in retryFileUpload:', error);
    }
  };

  const removeFile = (fileName: string) => {
    setFileMetadata((prevFiles: FileMetaData[]) =>
      prevFiles.filter((file) => file.name !== fileName),
    );
  };

  useEffect(() => {
    const validFiles = fileMetadata.filter(
      (fileMeta) => fileMeta.status !== 'unsupported' && fileMeta.status !== 'failed',
    );

    setContainsValidFiles(validFiles.length > 0);
  }, [fileMetadata]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleProceed();
      }
    };

    if (openSnackbar) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [openSnackbar, handleProceed]);

  useEffect(() => {
    if (uploadComplete && !showSidebar) {
      handleClearAll();
    }
  }, [showSidebar, uploadComplete]);

  useEffect(() => {
    if (
      notifications?.length > initialNotificationsLengthRef?.current &&
      resolveNotificationsPromise
    ) {
      resolveNotificationsPromise();
      setResolveNotificationsPromise(null);
    }
  }, [notifications, resolveNotificationsPromise]);

  useEffect(() => {
    setFolderUploadWarning(null);
  }, [folder]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      handleClearAll();
    };
  }, []);

  return (
    <>
      {showOverlay && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 9999,
            backgroundColor: 'transparent',
          }}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          // onDrop={handleDrop}
        />
      )}
      {!uploadComplete ? (
        <>
          <div className="upload-blob-container">
            <div
              {...getRootProps({
                className: `drag-drop-box ${isDragActive ? 'drag-active' : ''}`,
              })}
            >
              <input {...getInputProps()} />
              <p>{T.translate('case.batchUpload.dropzone.title')}</p>
              <MuiButton
                variant="text"
                sx={{ paddingLeft: 0, justifyContent: 'start' }}
                size="small"
              >
                <p>{T.translate('case.batchUpload.dropzone.subtitle')}</p>
              </MuiButton>
            </div>
          </div>
          {folderUploadWarning && (
            <Typography
              color="error"
              variant="body2"
              style={{ marginTop: '10px', textAlign: 'center' }}
            >
              {folderUploadWarning}
            </Typography>
          )}
        </>
      ) : (
        <div className="upload-blob-container">
          <div className="drag-drop-box">
            <p
              style={{
                color: '#7b8184',
              }}
            >
              {T.translate('case.batchUpload.dropzone.title')}
            </p>
            <MuiButton
              variant="text"
              sx={{ paddingLeft: 0, justifyContent: 'start' }}
              size="small"
              disabled
            >
              <p
                style={{
                  color: '#7b8184',
                }}
              >
                {T.translate('case.batchUpload.dropzone.subtitle')}
              </p>
            </MuiButton>
          </div>
        </div>
      )}
      {!uploadComplete && (
        <MuiAccordion
          sx={{
            overflow: 'hidden',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: 'none',
            borderBottom: '1px solid #e0e0e0',
            paddingVertical: '3px',
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              display: 'flex',
              alignItems: 'center',
              pr: 2,
            }}
          >
            <Typography variant="h6" gutterBottom>
              {T.translate('case.batchUpload.advancedSettings.title')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AdvancedSettings
              onMappingFileAttached={() => setIsMappingAttached(true)}
              setAllFieldsMapped={setAllFieldsMapped}
              allFieldsMapped={allFieldsMapped}
              failSignal={mapFailSignal}
              fileMetaData={fileMetadata}
              setMappingData={setMappingData}
              mappingData={mappingData}
              caseId={caseId}
              selectedTemplate={selectedTemplate}
              setSelectedTemplate={setSelectedTemplate}
              base={true}
            />
          </AccordionDetails>
        </MuiAccordion>
      )}
      <Dialog
        open={showRetryDialog}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            return;
          }
          handleDialogClose();
        }}
        disableEscapeKeyDown
        aria-labelledby="retry-dialog-title"
        aria-describedby="retry-dialog-description"
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}
        PaperProps={{
          style: {
            margin: '20px',
            width: '400px',
          },
        }}
        maxWidth="md"
      >
        <DialogTitle>
          <h4>{T.translate('case.batchUpload.dialog.title')}</h4>
        </DialogTitle>
        <DialogContent>
          <p style={{ paddingRight: 20, fontSize: 13 }}>
            {T.translate('case.batchUpload.dialog.message')}
          </p>
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'flex-end',
          }}
        >
          <Button onClick={handleCancelUpload} color="inherit">
            {T.translate('case.batchUpload.dialog.actions.cancel')}
          </Button>
          <Button onClick={handleRetryFailures} color="primary">
            {T.translate('case.batchUpload.dialog.actions.retry')}
          </Button>
          <Button onClick={handleProcessUploadedFiles} color="secondary">
            {T.translate('case.batchUpload.dialog.actions.skip')}
          </Button>
        </DialogActions>
      </Dialog>
      <div className="upload-file-list" style={{ overflowY: 'hidden', paddingBottom: '30px' }}>
        <p
          style={{
            color: 'red',
            textAlign: 'center',
            margin: '10px 0',
            display: 'block',
          }}
        >
          {apiError}
        </p>
        {fileMetadata?.length > 0 && !processingFiles && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginLeft: 4,
              alignItems: 'center',
              alignContent: 'center',
              justifyItems: 'center',
            }}
          >
            {uploadComplete ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 4,
                  justifyContent: 'flex-start',
                  flexDirection: 'row',
                }}
              >
                <h5>{T.translate('case.batchUpload.uploadComplete')}</h5>
              </div>
            ) : (
              <h5>
                {fileMetadata?.length > 0 && uploadInProgress && !uploadComplete
                  ? T.translate('case.batchUpload.dropzone.uploading', {
                      totalUploading: fileMetadata?.filter((file) => file.status === 'uploaded')
                        .length,
                      totalFiles: fileMetadata?.length,
                    })
                  : T.translate('case.batchUpload.dropzone.documents', {
                      count: fileMetadata?.length,
                    })}
              </h5>
            )}
            <div>
              {containsValidFiles && !uploadComplete ? (
                <>
                  {!uploadInProgress && (
                    <>
                      <IconButton
                        onClick={handleClearAll}
                        sx={{
                          color: 'red',
                          '&:hover': {
                            backgroundColor: 'rgba(250, 78, 75, 0.1)',
                          },
                        }}
                      >
                        <FontAwesomeIcon size="sm" icon={faTrash} />
                      </IconButton>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={async () => await handleConfirm()}
                        disabled={uploadInProgress}
                        style={{ marginLeft: 5 }}
                      >
                        {uploadInProgress ? (
                          <Spinner style={{ fontSize: '0.5rem' }} />
                        ) : (
                          T.translate('case.batchUpload.dropzone.upload')
                        )}
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <>
                  {!uploadComplete && (
                    <IconButton
                      onClick={() => setShowRetryDialog(true)}
                      sx={{
                        color: 'grey',
                        '&:hover': {
                          backgroundColor: 'rgba(256, 256, 256, 0.1)',
                        },
                      }}
                    >
                      <FontAwesomeIcon icon={faRedo} size="1x" />
                    </IconButton>
                  )}

                  <IconButton
                    onClick={handleClearAll}
                    sx={{
                      color: 'red',
                      '&:hover': {
                        backgroundColor: 'rgba(250, 78, 75, 0.1)',
                      },
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} size="1x" />
                  </IconButton>
                </>
              )}
            </div>
          </div>
        )}
        {processingFiles ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 10,
              flexDirection: 'column',
              gap: 10,
            }}
          >
            <Spinner />
            <p
              style={{
                textAlign: 'center',
              }}
            >
              {T.translate('case.batchUpload.processingFiles', {
                fileCount: fileMetadata?.length,
              })}
            </p>
          </div>
        ) : !uploadComplete ? (
          <RenderFilesAndFolders
            fileMetadata={fileMetadata}
            progressStateRef={progressStateRef}
            setFileMetadata={setFileMetadata}
            removeFile={removeFile}
          />
        ) : null}
      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={20000}
        onClose={(event, reason) => {
          if (reason === 'clickaway') {
            return;
          }
          handleCloseSnackbar();
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="warning"
          variant="filled"
          sx={{ backgroundColor: '#7CA0F4', color: 'white' }}
          action={
            <>
              <Button color="inherit" size="small" onClick={handleProceed}>
                {T.translate('case.batchUpload.snackbar.proceed')}
              </Button>
              <Button color="inherit" size="small" onClick={handleCloseSnackbar}>
                {T.translate('case.batchUpload.snackbar.cancel')}
              </Button>
            </>
          }
        >
          {T.translate('case.batchUpload.snackbar.warning', { folderToDelete })}
        </Alert>
      </Snackbar>
    </>
  );
};

export default UploadFileContent;
