import {
  COMMON_FETCH_SYSTEM_STATUS_BEGIN,
  COMMON_FETCH_SYSTEM_STATUS_SUCCESS,
  COMMON_FETCH_SYSTEM_STATUS_FAILURE,
  COMMON_FETCH_SYSTEM_STATUS_DISMISS_FEEDBACK,
} from './constants';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';

export const fetchSystemStatus = createPromiseAction(COMMON_FETCH_SYSTEM_STATUS_BEGIN);

export function dismissFetchSystemStatusFeedback() {
  return {
    type: COMMON_FETCH_SYSTEM_STATUS_DISMISS_FEEDBACK,
  };
}

export function* doFetchSystemStatus(action) {
  let res = yield call(api.get, `/system-status`, {}, false);

  if (res && res.error) {
    yield put({
      type: COMMON_FETCH_SYSTEM_STATUS_FAILURE,
      // feedback: {
      //   message: 'feedback.fetchSystemStatusFailure',
      //   error: res.error,
      //   retryAction: action,
      // },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: COMMON_FETCH_SYSTEM_STATUS_SUCCESS,
    data: res,
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchFetchSystemStatus() {
  yield takeLatest(fetchSystemStatus, doFetchSystemStatus);
}

export function useFetchSystemStatus() {
  const dispatch = useDispatch();

  const { systemStatus, fetchSystemStatusPending, fetchSystemStatusFeedback } = useSelector(
    (state) => ({
      systemStatus: state.common.systemStatus,
      fetchSystemStatusPending: state.common.fetchSystemStatusPending,
      fetchSystemStatusFeedback: state.common.fetchSystemStatusFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(fetchSystemStatus(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissFetchSystemStatusFeedback());
  }, [dispatch]);

  return {
    systemStatus,
    fetchSystemStatus: boundAction,
    fetchSystemStatusPending,
    fetchSystemStatusFeedback,
    dismissFetchSystemStatusFeedback: boundDismissFeedback,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case COMMON_FETCH_SYSTEM_STATUS_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        fetchSystemStatusPending: true,
        fetchSystemStatusFeedback: null,
      };

    case COMMON_FETCH_SYSTEM_STATUS_SUCCESS:
      // The request is success
      return {
        ...state,
        fetchSystemStatusPending: false,
        fetchSystemStatusFeedback: action.feedback,
        systemStatus: action.data && action.data.status,
      };

    case COMMON_FETCH_SYSTEM_STATUS_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchSystemStatusPending: false,
        fetchSystemStatusFeedback: action.feedback,
      };

    case COMMON_FETCH_SYSTEM_STATUS_DISMISS_FEEDBACK:
      // Dismiss the request failure error
      return {
        ...state,
        fetchSystemStatusFeedback: null,
      };

    default:
      return state;
  }
}
