import React, { useState } from 'react';
import T from 'i18n';
import { IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import { FiltersStyles } from './FiltersStyles.js';
import classnames from 'classnames';
import { BookmarkAdd } from '@mui/icons-material';
import { useBookmarks } from '../redux/bookmarks';

export default ({
  isAnnotation,
  filterCategory,
}: {
  isAnnotation: boolean;
  filterCategory: any;
}) => {
  const [bookmarkText, setBookmarkText] = useState('');
  const [showFilterNameIput, setShowFilterNameInput] = useState(false);
  const { addBookmark } = useBookmarks(isAnnotation ? 'annotations' : 'documents');

  const classes = FiltersStyles();

  const createBookmark = () => {
    addBookmark({ filterCategory, name: bookmarkText });
    setBookmarkText('');
    setShowFilterNameInput(false);
  };

  return showFilterNameIput ? (
    <TextField
      autoFocus
      variant="standard"
      onChange={(e) => setBookmarkText(e.target.value)}
      onKeyDown={(e) => {
        e.key === 'Enter' && createBookmark();
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title={T.translate('filters.createBookmark')}>
              <IconButton
                onClick={() => {
                  createBookmark();
                }}
              >
                <BookmarkAdd />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }}
    />
  ) : (
    <Button
      variant="contained"
      size="small"
      color="primary"
      className={classnames(classes.clearButton)}
      onClick={() => setShowFilterNameInput(true)}
    >
      {T.translate('filters.createBookmark')}
    </Button>
  );
};
