import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  CASE_UPDATE_DOCUMENT_SORT_BEGIN,
  CASE_UPDATE_DOCUMENT_SORT_SUCCESS,
  CASE_UPDATE_DOCUMENT_SORT_FAILURE,
  CASE_UPDATE_DOCUMENT_SORT_DISMISS_FEEDBACK,
} from './constants';
import api from 'common/api';
import {
  withCurrentCaseId,
  selectDocumentIds,
  selectCurrentFolder,
} from '../../../common/selectors';
import addSortNumberToDocuments from './addSortNumberToDocuments';

export function updateDocumentSort({
  index = 0,
  ids = [],
  zeroBasedIndex,
  etag,
  isAttachments,
  agreed,
  singleDroppedDocId,
  hasFolderFieldId,
}) {
  return {
    type: CASE_UPDATE_DOCUMENT_SORT_BEGIN,
    payload: {
      index,
      ids,
      zeroBasedIndex,
      etag,
      isAttachments,
      agreed,
      singleDroppedDocId,
      hasFolderFieldId,
    },
  };
}

export function dismissUpdateDocumentSortFeedback() {
  return {
    type: CASE_UPDATE_DOCUMENT_SORT_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_UPDATE_META_BEGIN actions
export function* doUpdateDocumentSort(action) {
  const {
    payload: { index, ids, etag, isAttachments, caseId, singleDroppedDocId, hasFolderFieldId },
  } = action;
  const currentFolder = yield select(selectCurrentFolder);
  //if more than one we presort and documents are sorted before hand
  const fileSortOrder = yield select(selectDocumentIds);
  const folderId = currentFolder.id;
  let fileIds = [];
  if (!hasFolderFieldId && ids.length > 0) {
    fileIds = ids.map((id) => {
      const lastHyphenIndex = id.lastIndexOf('-');
      return lastHyphenIndex !== -1 ? id.substring(0, lastHyphenIndex) : id;
    });
    fileIds = [...fileIds];
  }
  const idsToUpdate = fileIds.length > 0 ? fileIds : ids;
  let processedFileSortOrder = fileSortOrder;
  if (!isAttachments && !hasFolderFieldId && fileSortOrder.length > 0) {
    processedFileSortOrder = fileSortOrder.map((id) => {
      const lastHyphenIndex = id.lastIndexOf('-');
      return lastHyphenIndex !== -1 ? id.substring(0, lastHyphenIndex) : id;
    });
    processedFileSortOrder = [...processedFileSortOrder];
  }
  const res = yield ids.length === 1
    ? call(api.put, `/cases/${caseId}/folders/${folderId}/files/${idsToUpdate[0]}`, {
        index,
        etag: etag ? etag : currentFolder.etag,
      })
    : call(api.put, `/cases/${caseId}/folders/${folderId}/files`, {
        ...(isAttachments
          ? { files: idsToUpdate.map(({ id }) => id) }
          : { files: processedFileSortOrder }),
        etag: currentFolder.etag,
      });

  if (res && res.error) {
    return yield put({
      type: CASE_UPDATE_DOCUMENT_SORT_FAILURE,
      feedback: {
        message: 'feedback.updateDocumentSortFailure',
        error: res.error,
        index,
        retryAction: action,
      },
    });
  }
  // Determine appropriate feedback message based on conditions
  let feedbackMessage;
  let feedbackParams;

  if (ids.length === 1) {
    // Single document moved - use singleDroppedDocId if available, otherwise use ids[0]
    feedbackMessage = 'feedback.changePositionSuccess';
    feedbackParams = {
      folderName: singleDroppedDocId || ids[0],
      newIndex: index + 1,
    };
  } else {
    // Multiple documents moved - use count message
    feedbackMessage = 'feedback.changeMultiplePositionsSuccess';
    feedbackParams = {
      count: ids.length,
      newIndex: index + 1,
    };
  }
  // Dispatch success action with the appropriate feedback
  yield put({
    type: CASE_UPDATE_DOCUMENT_SORT_SUCCESS,
    data: {
      documents: res.files,
      index: index,
      zeroBasedIndex: currentFolder.zeroBasedIndex,
      agreed: currentFolder.agreed,
      isAttachments,
      singleDroppedDocId,
    },
    feedback: {
      message: feedbackMessage,
      params: feedbackParams,
      id: new Date().getTime(), // Add unique ID to feedback
    },
  });
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchUpdateDocumentSort() {
  yield takeLatest(CASE_UPDATE_DOCUMENT_SORT_BEGIN, withCurrentCaseId(doUpdateDocumentSort));
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_UPDATE_DOCUMENT_SORT_BEGIN:
      if (action.payload.isAttachments) {
        return {
          ...state,
          updateDocumentSortPending: true,
          updateDocumentSortFeedback: null,
          prevSortedDocuments: state.documents,
          documents: addSortNumberToDocuments(
            action.payload.ids,
            action.payload.zeroBasedIndex,
            !action.payload.agreed,
          ),
        };
      } else {
        const initialStartTabIndex = parseInt(state?.documents[0]?.tab);
        const ids = action.payload.ids;
        const targetIndex = action.payload.index;
        // Special case: When ids.length equals state.documents.length, reorder according to ids array
        if (ids.length === state.documents.length) {
          // Create a map for quick document lookups
          const documentsMap = new Map();
          state.documents.forEach((doc) => {
            const docId = doc.folderFileId || doc.compositeKey || doc.id;
            documentsMap.set(docId, doc);
          });

          // Reorder documents exactly according to ids array
          const finalDocuments = ids.map((id) => documentsMap.get(id)).filter(Boolean); // Filter out any undefined entries

          return {
            ...state,
            updateDocumentSortPending: true,
            updateDocumentSortFeedback: null,
            prevSortedDocuments: state.documents,
            documents: addSortNumberToDocuments(
              finalDocuments,
              action.payload.zeroBasedIndex,
              !action.payload.agreed,
              initialStartTabIndex,
            ),
          };
        }

        // Original drag and drop logic for all other cases
        // 1. Find all items to be moved and their indices
        const itemsToMove = [];
        ids.forEach((id) => {
          const index = state.documents.findIndex(
            (doc) => (doc.folderFileId || doc.compositeKey || doc.id) === id,
          );
          if (index !== -1) {
            itemsToMove.push({
              item: state.documents[index],
              index,
            });
          }
        });
        // 2. Create a new array with non-selected items
        const nonSelectedItems = state.documents.filter(
          (doc) => !ids.includes(doc.folderFileId || doc.compositeKey || doc.id),
        );

        // 3. Get all selected items in their original order in the array
        let selectedItems = itemsToMove
          .sort((a, b) => a.index - b.index) // Sort by original index ascending
          .map((item) => item.item);

        // Determine if we're moving down or up
        const lowestSelectedIndex = Math.min(...itemsToMove.map((item) => item.index));
        const isMovingDown = lowestSelectedIndex < targetIndex;

        // Special case: Check if the target index is within the range of selected items
        const isTargetWithinSelectedItems = itemsToMove.some((item) => item.index === targetIndex);

        // Calculate how many items will be removed before the target position
        const itemsBeforeTargetThatWillBeRemoved = itemsToMove.filter(
          (item) => item.index < targetIndex,
        ).length;

        // For precise positioning, we need to adjust the insertion point
        let insertIndex;

        // Calculate insert index based on direction and target
        if (isTargetWithinSelectedItems) {
          // If target is within selected items, handle special case
          const itemAtTarget = itemsToMove.find((item) => item.index === targetIndex);

          if (itemAtTarget) {
            // Reorder selected items if needed (keep this logic)
            const positionInSelectedItems = selectedItems.findIndex(
              (item) =>
                (item.folderFileId || item.compositeKey || item.id) ===
                (itemAtTarget.item.folderFileId ||
                  itemAtTarget.item.compositeKey ||
                  itemAtTarget.item.id),
            );

            if (positionInSelectedItems > 0) {
              // Create reordered items array (keep this logic)
              const reorderedItems = [
                selectedItems[positionInSelectedItems],
                ...selectedItems.slice(0, positionInSelectedItems),
                ...selectedItems.slice(positionInSelectedItems + 1),
              ];

              selectedItems = reorderedItems;
            }
          }

          // When target is within selection
          insertIndex = Math.max(
            0,
            Math.min(targetIndex - itemsBeforeTargetThatWillBeRemoved, nonSelectedItems.length),
          );
        } else {
          // Normal case (target is not one of the selected items)
          if (isMovingDown) {
            // Moving DOWN: Keep the adjustment of +1 (was working correctly)
            insertIndex = Math.max(
              0,
              Math.min(
                targetIndex - itemsBeforeTargetThatWillBeRemoved + 1,
                nonSelectedItems.length,
              ),
            );
          } else {
            // Moving UP: Remove the +1 adjustment that was causing the off-by-one error
            insertIndex = Math.max(
              0,
              Math.min(targetIndex - itemsBeforeTargetThatWillBeRemoved, nonSelectedItems.length),
            );
          }
        }

        // Special case for target index 0 (already correct)
        if (targetIndex === 0) {
          insertIndex = 0;
        }

        // Special case for when target index is 0 (first position)
        if (targetIndex === 0) {
          insertIndex = 0;
        }

        // 5. Create the final array by inserting selected items at the target position
        const finalDocuments = [
          ...nonSelectedItems.slice(0, insertIndex),
          ...selectedItems,
          ...nonSelectedItems.slice(insertIndex),
        ];
        return {
          ...state,
          updateDocumentSortPending: true,
          updateDocumentSortFeedback: null,
          prevSortedDocuments: state.documents,
          documents: addSortNumberToDocuments(
            finalDocuments,
            action.payload.zeroBasedIndex,
            !action.payload.agreed,
            initialStartTabIndex,
          ),
        };
      }
    case CASE_UPDATE_DOCUMENT_SORT_SUCCESS:
      return {
        ...state,
        updateDocumentSortPending: false,
        ...(action.data.isAttachments && {
          documents: addSortNumberToDocuments(
            action.data.documents,
            action.data.zeroBasedIndex,
            !action.data.agreed,
          ),
        }),
        updateDocumentSortFeedback: action.feedback,
        prevSortedDocuments: null,
        fetchFolderAfterSortPending: true, // bcoz after sort we are fetching folders to get new etag.
      };

    case CASE_UPDATE_DOCUMENT_SORT_FAILURE:
      return {
        ...state,
        updateDocumentSortPending: false,
        updateDocumentSortFeedback: action.feedback,
        documents: state.prevSortedDocuments,
        prevSortedDocuments: null,
      };
    case CASE_UPDATE_DOCUMENT_SORT_DISMISS_FEEDBACK:
      return {
        ...state,
        updateDocumentSortFeedback: null,
      };

    default:
      return state;
  }
}
