import {
  CASE_UPDATE_SEARCH_HISTORY_ITEM_BEGIN,
  CASE_UPDATE_SEARCH_HISTORY_ITEM_SUCCESS,
  CASE_UPDATE_SEARCH_HISTORY_ITEM_FAILURE,
  CASE_UPDATE_SEARCH_HISTORY_ITEM_DISMISS_FEEDBACK,
} from './constants';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from 'common/selectors';
import { replaceItemImmutable } from 'utils/arrays';

export const updateSearchHistoryItem = createPromiseAction(CASE_UPDATE_SEARCH_HISTORY_ITEM_BEGIN);

export function dismissUpdateSearchHistoryItemFeedback() {
  return {
    type: CASE_UPDATE_SEARCH_HISTORY_ITEM_DISMISS_FEEDBACK,
  };
}

export function* doUpdateSearchHistoryItem(action) {
  const {
    payload: { caseId, searchHistoryItem },
  } = action;
  let res = yield call(api.put, `/cases/${caseId}/search-history/${searchHistoryItem.id}`, {
    name: searchHistoryItem.name,
  });

  if (res && res.error) {
    yield put({
      type: CASE_UPDATE_SEARCH_HISTORY_ITEM_FAILURE,
      feedback: {
        message: 'feedback.updateSearchHistoryItemFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_UPDATE_SEARCH_HISTORY_ITEM_SUCCESS,
    data: { searchHistoryItem: res },
    payload: { searchHistoryItem },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchUpdateSearchHistoryItem() {
  yield takeLatest(updateSearchHistoryItem, withCurrentCaseId(doUpdateSearchHistoryItem));
}

export function useUpdateSearchHistoryItem() {
  const dispatch = useDispatch();

  const { updateSearchHistoryItemPending, updateSearchHistoryItemFeedback } = useSelector(
    (state) => ({
      updateSearchHistoryItemPending: state.case.updateSearchHistoryItemPending,
      updateSearchHistoryItemFeedback: state.case.updateSearchHistoryItemFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(updateSearchHistoryItem(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissUpdateSearchHistoryItemFeedback());
  }, [dispatch]);

  return {
    updateSearchHistoryItem: boundAction,
    updateSearchHistoryItemPending,
    updateSearchHistoryItemFeedback,
    dismissUpdateSearchHistoryItemFeedback: boundDismissFeedback,
  };
}

export function reducer(state, action) {
  const getIndex = (itmId) =>
    state.searchHistory && state.searchHistory.findIndex(({ id }) => id === itmId);
  switch (action.type) {
    case CASE_UPDATE_SEARCH_HISTORY_ITEM_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        updateSearchHistoryItemPending: true,
        updateSearchHistoryItemFeedback: null,
      };

    case CASE_UPDATE_SEARCH_HISTORY_ITEM_SUCCESS:
      // The request is success
      return {
        ...state,
        updateSearchHistoryItemPending: false,
        updateSearchHistoryItemFeedback: action.feedback,
        searchHistory: replaceItemImmutable(
          state.searchHistory,
          action.data.searchHistoryItem,
          getIndex(action.payload.searchHistoryItem.id),
        ),
      };

    case CASE_UPDATE_SEARCH_HISTORY_ITEM_FAILURE:
      // The request is failed
      return {
        ...state,
        updateSearchHistoryItemPending: false,
        updateSearchHistoryItemFeedback: action.feedback,
      };

    case CASE_UPDATE_SEARCH_HISTORY_ITEM_DISMISS_FEEDBACK:
      // Dismiss the request failure error
      return {
        ...state,
        updateSearchHistoryItemFeedback: null,
      };

    default:
      return state;
  }
}
