import React, { useState } from 'react';
import { IconButton, Typography } from '@mui/material';
import history from 'common/history';
import { Launch } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { setDocSearch } from 'features/viewing/redux/setDocSearch';
import { setCurrentSelectedFile } from '../redux/setCurrentSelectedFile';
import { clearSelection } from 'utils/browser';
import useAIHandler from './hooks/useAIHandler';

interface MatchesContainerProps {
  selectedMatches: Array<any>;
  matches: any;
  setSelectedContainerId: Function;
}

const MatchesContainer = ({
  matches,
  selectedMatches,
  setSelectedContainerId,
}: MatchesContainerProps) => {
  const dispatch = useDispatch();
  const { setSelectedMatches } = useAIHandler();
  const [hoveredMatch, setHoveredMatch] = useState<null | string>(null);
  const [firstSelectedIndex, setFirstSelectedIndex] = useState<number | null>(null);
  const matchStyle = (matchItm: any) => ({
    backgroundColor: selectedMatches.some((match) => match.id === matchItm.id)
      ? '#E5E5E5'
      : hoveredMatch === matchItm.id
        ? '#efefef'
        : 'transparent',
  });

  const handleSelectMatches = (e: any, matchItm: any, index: number, matchesL: Array<any>) => {
    if (e.ctrlKey) {
      // For Ctrl key: Toggle individual match selection
      const isAlreadySelected = selectedMatches.some(
        (selectedMatch: any) => selectedMatch.id === matchItm.id,
      );
      let newSelectedMatches = isAlreadySelected
        ? selectedMatches.filter((selectedMatch: any) => selectedMatch.id !== matchItm.id)
        : [...selectedMatches, matchItm];
      setSelectedMatches(newSelectedMatches);
    } else if (e.shiftKey && firstSelectedIndex !== null) {
      clearSelection();
      // For Shift key: Select all matches in the range
      const range = [firstSelectedIndex, index].sort((a, b) => a - b);
      const newSelectedMatches = matchesL.slice(range[0], range[1] + 1);
      setSelectedMatches(newSelectedMatches);
    } else {
      setSelectedMatches([matchItm]);
      setFirstSelectedIndex(index); // Update the first selected index
    }
  };

  const openFile = (event: any, matchItm: any, index: number) => {
    clearSelection();
    handleSelectMatches(event, matchItm, index, []);
    setSelectedContainerId(matchItm.id);
    history.push(history.location.pathname.replace(/\/files.*/, ``) + `/files/${matchItm.file.id}`);
    dispatch(setCurrentSelectedFile(matchItm.file));
    dispatch(setDocSearch(matchItm.text, matchItm.location));
  };
  return (
    <div style={{ border: '1px solid rgba(0,0,0,.125)', backgroundColor: 'white', width: '100%' }}>
      {matches.map((matchItm: any, index: number) => (
        <div
          key={index}
          id={matchItm.id}
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            padding: '0.5rem',
            alignItems: 'baseline',
            ...matchStyle(matchItm),
          }}
          onMouseEnter={() => setHoveredMatch(matchItm.id)}
          onMouseLeave={() => setHoveredMatch(null)}
          onClick={(event) => handleSelectMatches(event, matchItm, index, matches)}
          onDoubleClick={(event) => openFile(event, matchItm, index)}
        >
          <Typography
            sx={{ color: 'rgba(0, 0, 0, 0.65)', fontWeight: 600, fontSize: '1.1rem', mr: '1rem' }}
          >
            {index + 1}
          </Typography>
          <div
            key={index}
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: 'calc(100% - 1.5rem)',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                color: 'rgba(0, 0, 0, 0.65)',
                fontWeight: 600,
                fontSize: '1.1rem',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <span>{matchItm.file.name} </span>
              <IconButton
                size="small"
                color="primary"
                onClick={(event) => openFile(event, matchItm, index)}
              >
                <Launch fontSize="small" />
              </IconButton>
            </div>
            <span style={{ color: 'rgba(0, 0, 0, 0.65)' }}>{matchItm.file.id}</span>
            <div>{matchItm.text}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MatchesContainer;
