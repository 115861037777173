import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import { addItemImmutable } from 'utils/arrays';

import {
  HOME_CREATE_CASE_BEGIN,
  HOME_CREATE_CASE_SUCCESS,
  HOME_CREATE_CASE_FAILURE,
  HOME_CREATE_CASE_DISMISS_FEEDBACK,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';

export const createCase = createPromiseAction(HOME_CREATE_CASE_BEGIN, (payload) => ({
  caseData: payload,
}));

export function dismissCreateCaseFeedback() {
  return {
    type: HOME_CREATE_CASE_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_FETCH_CASE_BEGIN actions
export function* doCreateCase(action) {
  const {
    payload: { caseData },
  } = action;

  let res = yield call(api.post, `/cases`, caseData);

  if (res && res.error) {
    yield put({
      type: HOME_CREATE_CASE_FAILURE,
      feedback: { message: 'feedback.createCaseFailure', error: res.error, retryAction: action },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  const newCase = { id: res.id, name: res.name };

  yield put({
    type: HOME_CREATE_CASE_SUCCESS,
    data: { case: newCase },
  });
  yield call(resolvePromiseAction, action, newCase);
}

export function* watchCreateCase() {
  yield takeLatest(createCase, doCreateCase);
}

export function useCreateCase() {
  const dispatch = useDispatch();

  const { createCasePending, createCaseFeedback } = useSelector(
    (state) => ({
      createCasePending: state.home.createCasePending,
      createCaseFeedback: state.home.createCaseFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(createCase(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissCreateCaseFeedback());
  }, [dispatch]);

  return {
    createCase: boundAction,
    createCasePending,
    createCaseFeedback,
    dismissCreateCaseFeedback: boundDismissFeedback,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_CREATE_CASE_BEGIN + '.TRIGGER':
      // Just after a request is sent
      const caseData = action.payload && action.payload.caseData;
      const caseRegion = caseData && caseData.countryCode;
      return {
        ...state,
        createCasePending: true,
        newCase: false,
        caseRegionTemp: caseRegion || null,
        createCaseFeedback: null,
      };

    case HOME_CREATE_CASE_SUCCESS:
      // The request is success
      return {
        ...state,
        createCasePending: false,
        newCase: true,
        caseRegionTemp: null,
        createCaseFeedback: action.feedback,
        cases: addItemImmutable(state.cases, action.data.case),
      };

    case HOME_CREATE_CASE_FAILURE:
      // The request is failed
      return {
        ...state,
        createCasePending: false,
        newCase: false,
        caseRegionTemp: null,
        createCaseFeedback: action.feedback,
      };

    case HOME_CREATE_CASE_DISMISS_FEEDBACK:
      // Dismiss the request failure error
      return {
        ...state,
        createCaseFeedback: null,
      };

    default:
      return state;
  }
}
