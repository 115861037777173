import React from 'react';
import T from 'i18n';
import Table from 'features/common/Table';
import { Trans } from 'react-i18next';

const capitalizeEveryFirstLetter = (val) => {
  const words = val.split(' ');
  return words
    .map((word) => {
      return word ? word[0].toUpperCase() + word.substring(1) : word;
    })
    .join(' ');
};

const SystemTestsTable = ({ data }) => {
  const isItemLoaded = ({ index }) => !!data[index];
  const loadMoreItems = () => {
    return;
  };
  const columns = [
    {
      id: 'test',
      Header: 'common.test',
      Cell: (result) => (
        <div className="customGrid">
          <span>{result.test}</span>
        </div>
      ),
      minWidth: '150px',
      width: '15rem',
      sort: false,
    },
    {
      id: 'status',
      Header: 'common.status',
      Cell: (result) => (
        <div className="customGrid">
          <span style={result.status === 'Pass' ? { color: '#4caf50' } : { color: 'red' }}>
            {result.status}
          </span>
        </div>
      ),
      minWidth: '90px',
      width: '10rem',
      sort: false,
    },
    {
      id: 'result',
      Header: 'common.result',
      Cell: (result) => (
        <div className="customGrid">
          {typeof result.result === 'object' ? (
            <div>
              {Object.entries(result.result).map(([key, value], idx) =>
                key !== 'Action' ? (
                  `${capitalizeEveryFirstLetter(key)} : ${value}, `
                ) : (
                  // prettier-ignore
                  <Trans i18nKey={'common.systemTestMsg'}>
                    Please <a target="_blank" rel="noreferrer" href="https://www.trialviewlive.com/public/TrialView%20Firewall%20Requirements%20Doc.pdf" download>
                      {{ document }}
                    </a>
                  </Trans>
                ),
              )}
            </div>
          ) : (
            <span>{result.result}</span>
          )}
        </div>
      ),
      minWidth: '200px',
      width: 'calc(100% - 25rem)',
      sort: false,
    },
  ];

  return (
    <div style={{ height: '30rem' }}>
      <Table
        key={Math.random()}
        data={data}
        columns={columns}
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        totalDocumentsCount={0}
        rowHeight={50}
        readOnly
      />
    </div>
  );
};

export default SystemTestsTable;
