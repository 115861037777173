import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  CASE_UNASSIGN_DOCUMENTS_MULTIPLE_BEGIN,
  CASE_UNASSIGN_DOCUMENTS_MULTIPLE_SUCCESS,
  CASE_UNASSIGN_DOCUMENTS_MULTIPLE_FAILURE,
  CASE_UNASSIGN_DOCUMENTS_MULTIPLE_DISMISS_FEEDBACK,
  CASE_UNASSIGN_ALL_DOCUMENTS_SUCCESS,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { selectCurrentFolder, selectParams, withCurrentCaseId } from '../../../common/selectors';
import history from 'common/history';
import addSortNumberToDocuments from './addSortNumberToDocuments';

export const unassignDocumentMultiple = createPromiseAction(
  CASE_UNASSIGN_DOCUMENTS_MULTIPLE_BEGIN,
  (payloadDocs) => ({
    documents: payloadDocs,
  }),
);

// export function unassignDocumentMultiple(documents) {
//   // If need to pass args to saga, pass it with the begin action.
//   return {
//     type: CASE_UNASSIGN_DOCUMENTS_MULTIPLE_BEGIN,
//     payload: { documents },
//   };
// }

export function dismissUnassignDocumentMultipleFeedback() {
  return {
    type: CASE_UNASSIGN_DOCUMENTS_MULTIPLE_DISMISS_FEEDBACK,
  };
}

function* doUnassignDocumentMultiple(action) {
  const {
    payload: { documents, caseId },
  } = action;

  const currentFolder = yield select(selectCurrentFolder);
  const { file } = yield select(selectParams);
  const data = documents.map((itm) => ({
    id: itm.id,
    name: itm.name,
    folderFileId: itm.folderFileId,
  }));

  const res = yield call(api.del, `/cases/${caseId}/folders/${currentFolder.id}/files`, data);

  if (res && res.error) {
    yield put({
      type: CASE_UNASSIGN_DOCUMENTS_MULTIPLE_FAILURE,
      feedback: {
        message: 'feedback.unassignDocumentMultipleFailure',
        error: res.error,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_UNASSIGN_DOCUMENTS_MULTIPLE_SUCCESS,
    data: { documents, zeroBasedIndex: currentFolder.zeroBasedIndex },
    feedback: {
      message: 'feedback.unassignDocumentsSuccess',
      params: { successful: documents.length },
      success: true,
    },
  });
  yield put({
    type: CASE_UNASSIGN_ALL_DOCUMENTS_SUCCESS,
  });
  yield call(resolvePromiseAction, action, res);
  if (file) history.push(history.location.pathname.replace(/\/files.*/, ``));
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchUnassignDocumentMultiple() {
  yield takeLatest(unassignDocumentMultiple, withCurrentCaseId(doUnassignDocumentMultiple));
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_UNASSIGN_DOCUMENTS_MULTIPLE_BEGIN + '.TRIGGER':
      return {
        ...state,
        unassignDocumentMultiplePending: true,
        unassignDocumentMultipleFeedback: null,
      };

    case CASE_UNASSIGN_DOCUMENTS_MULTIPLE_SUCCESS:
      return {
        ...state,
        unassignDocumentMultiplePending: false,
        unassignDocumentMultipleFeedback: action.feedback,
        documents: addSortNumberToDocuments(
          state.documents.filter(
            (itm) =>
              !action.data.documents.some(
                (doc) =>
                  doc.id === itm.id &&
                  (doc.folderFileId ? doc.folderFileId === itm.folderFileId : true),
              ),
          ),
          action.data.zeroBasedIndex,
        ),
      };

    case CASE_UNASSIGN_DOCUMENTS_MULTIPLE_FAILURE:
      return {
        ...state,
        unassignDocumentMultiplePending: false,
        unassignDocumentMultipleFeedback: action.feedback,
      };

    case CASE_UNASSIGN_DOCUMENTS_MULTIPLE_DISMISS_FEEDBACK:
      return {
        ...state,
        unassignDocumentMultipleFeedback: null,
      };

    default:
      return state;
  }
}
