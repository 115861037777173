/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from 'react';
import T from 'i18n';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  selectCaseClaimNo,
  selectCaseDataRoomSettings,
  selectCaseShortName,
  selectConfidentialityRing,
  selectCurrentFolder,
  selectCurrentUserConfidentialityRing,
} from 'common/selectors';
import { useForm } from 'react-hook-form';
import * as actions from '../../redux/actions';
import moment from 'moment';
import { MultiSelectForm, SelectForm, Spinner, TextForm, ToggleForm } from 'features/common';
import { Col, Form, Row } from 'react-bootstrap';
import { complexValuesToOptions } from 'utils/arrays';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MoveDocument from './MoveDocument';
import { Modal } from 'features/common/MaterialBasedComponents';

export const ModalMerge = ({ show, handleClose, selectedRows, folder, containers }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const dispatch = useDispatch();
  const [mergingInProgress, setMergingInProgress] = useState(false);
  useEffect(() => {
    if (show) {
      reset();
    }
  }, [selectedRows, folder, show, reset]);
  const currentFolder = useSelector(selectCurrentFolder);
  const dataRoomSettings = useSelector(selectCaseDataRoomSettings);
  const caseShortName = useSelector(selectCaseShortName);
  const caseClaimNo = useSelector(selectCaseClaimNo);
  const isConfidentialityRing = useSelector(selectConfidentialityRing);
  const currrentUserCR = useSelector(selectCurrentUserConfidentialityRing);
  const isInnerRing = isConfidentialityRing && currrentUserCR === 'innerRing';
  const defaultBundleTitleString =
    caseShortName || caseClaimNo
      ? ` (${caseClaimNo && `${caseClaimNo}, `}${caseShortName && caseShortName})`
      : '';
  const [showHyperlinkToggle, setShowHyperlinkToggle] = useState(false);
  const [generateIndexPage, setGenerateIndexPage] = useState(false);
  const [globalPagePrefix, setGlobalPagePrefix] = useState(true);
  const [globalPagePrefixVal, setGlobalPagePrefixVal] = useState(
    (folder && folder.globalPagePrefix) || '',
  );
  const [responseMsg, setResponseMsg] = useState('');
  const dataRoomColumns = dataRoomSettings.columns || [];
  const {
    labelBetween,
    labelVersus,
    labelClaimant,
    labelDefendant,
    indexPageJurisdiction,
    indexPageClaimant,
    indexPageDefendant,
    indexPageColumnsToDisplay,
    indexPageTableDateFormat,
  } = dataRoomSettings.mergeFilesOptions || {};
  const folderToMerge = folder && folder.id;
  const defaultIndexPageColumn =
    indexPageColumnsToDisplay ||
    [
      'trialviewId',
      'title',
      dataRoomColumns.includes('externalId') && 'externalId',
      dataRoomColumns.includes('docDate') && 'docDate',
    ].filter(Boolean);
  const [showIndexPageDateFormat, setShowIndexPageDateFormat] = useState(
    dataRoomColumns.includes('docDate') && defaultIndexPageColumn.includes('docDate'),
  );
  const defaultIndexPageTableDateFormat = indexPageTableDateFormat || 'dd/MM/yyyy';
  const defaultFileVariant = ['innerRing'];
  const simpleValuesToOptions = (options) =>
    options.map((value) => ({ label: moment().format(value.FE), value: value.BE }));
  const indexPageColumnsOptions = complexValuesToOptions(
    [
      { value: 'trialviewId', isFixed: false },
      { value: 'title', isFixed: false },
      { value: 'externalId', isFixed: false },
      { value: 'discoveryId', isFixed: false },
      { value: 'docDate', isFixed: false },
    ],
    'generic',
  );
  const fileVariantsOptions = complexValuesToOptions(
    [
      { value: 'innerRing', isFixed: false },
      { value: 'outerRing', isFixed: false },
      { value: 'noRing', isFixed: false },
    ],
    'generic',
  );
  const indexPageTableColumnsOptions = simpleValuesToOptions([
    { BE: 'dd/MM/yyyy HH:mm', FE: 'DD/MM/YYYY HH:mm' },
    { BE: 'dd/MM/yyyy', FE: 'DD/MM/YYYY' },
    { BE: 'dd.MM.yyyy', FE: 'DD.MM.YYYY' },
    { BE: 'dd MMM yyy', FE: 'DD MMM YYYY' },
  ]);
  const onColumnChange = (val) => {
    if (val && val.includes('docDate')) setShowIndexPageDateFormat(true);
    else setShowIndexPageDateFormat(false);
  };

  return (
    <Modal
      show={show}
      title={selectedRows ? T.translate('case.merge') : T.translate('case.mergeTrialbook')}
      handleClose={handleClose}
      handleSave={handleSubmit((props) => {
        setMergingInProgress(true);
        const {
          folder: folderId,
          name: mergedFileName,
          mergeFileVariants,
          generateIndexPage = false,
          generateIndexPageOnly = false,
          generateBookMarks = false,
          generateLocalPaging = false,
          generateGlobalPaging = false,
          coreBundleUseTrialBundlePaging = false,
          useDoubleSidedPrinting = false,
          burnCrossrefsFirst = false,
          addHyperlinkToCrossRefOnMerge = false,
          globalPagingPrefix = '',
          indexPageTableBorder,
          indexPageClaimNo,
          indexPageJurisdiction,
          indexPageClaimant,
          indexPageDefendant,
          indexPageBundleTitle,
          columnsToDisplay,
          indexPageDateFormat,
        } = props;
        dispatch(
          actions.mergeDocuments({
            mergedFileName,
            mergeFileVariants,
            generateIndexPage,
            generateIndexPageOnly,
            generateBookMarks,
            generateLocalPaging,
            generateGlobalPaging,
            globalPagingPrefix,
            ...(selectedRows && {
              filesToMerge: selectedRows.map(({ id, name }) => ({ id, name })),
            }),
            folderId,
            labelBetween,
            labelVersus,
            labelClaimant,
            labelDefendant,
            indexPageTableBorder,
            indexPageClaimNo,
            indexPageJurisdiction,
            indexPageClaimant,
            indexPageDefendant,
            indexPageBundleTitle,
            columnsToDisplay,
            indexPageDateFormat,
            folderToMerge,
            coreBundleUseTrialBundlePaging,
            useDoubleSidedPrinting,
            burnCrossrefsFirst,
            addHyperlinkToCrossRefOnMerge,
          }),
        )
          .then((res) => {
            setMergingInProgress(false);
            if (res && res.resMerge && res.resMerge.asyncMerge) {
              setResponseMsg(T.translate('case.mergeAsyncMsg'));
            } else {
              handleClose();
            }
          })
          .catch((res) => {
            setMergingInProgress(false);
            if (res && res.resMerge && res.resMerge.asyncMerge) {
              setResponseMsg(T.translate('case.mergeAsyncMsg'));
            } else {
              handleClose();
            }
          });
      })}
      saveTitle={
        mergingInProgress ? <Spinner style={{ height: '1.5rem' }} /> : T.translate('generic.create')
      }
      disableSave={mergingInProgress || !!responseMsg}
    >
      <PerfectScrollbar options={{ suppressScrollX: true }} style={{ maxHeight: '45rem' }}>
        {responseMsg ? (
          <span>{responseMsg}</span>
        ) : (
          <Form.Group style={{ marginBottom: '3rem' }}>
            <div style={{ marginBottom: '1rem' }}>
              {selectedRows
                ? T.translate('case.mergeTitle', {
                    numberOfFiles: selectedRows.length,
                    folderName: currentFolder.name || T.translate('case.allDocuments'),
                  })
                : T.translate('case.mergeTrialbookMsg', { folderName: folder.name })}
            </div>
            <Row>
              <Col>
                <TextForm
                  {...register('name')}
                  label={T.translate('case.mergeFileName')}
                  placeholder={T.translate('case.mergeFileNamePlaceholder')}
                  defaultValue={`${
                    (selectedRows
                      ? currentFolder.name || T.translate('case.allDocuments')
                      : folder.name || T.translate('case.allDocuments')) + defaultBundleTitleString
                  } (${moment().format('DD/MM/YYYY')})`}
                  readOnly={mergingInProgress}
                  autofocus
                />
              </Col>
            </Row>
            {isInnerRing && (
              <Row>
                <Col>
                  <MultiSelectForm
                    label={T.translate('case.selectFileVariants')}
                    name="mergeFileVariants"
                    defaultValue={defaultFileVariant}
                    options={fileVariantsOptions}
                    onChange={onColumnChange}
                    control={control}
                    errors={errors}
                    rules={{ required: true }}
                  />
                </Col>
                <Col />
              </Row>
            )}
            <Row>
              <Col>
                <ToggleForm
                  {...register('generateIndexPage', {
                    onChange: () => setGenerateIndexPage(!generateIndexPage),
                  })}
                  defaultValue={generateIndexPage}
                  label={T.translate(`case.generateIndexPage`)}
                  readOnly={mergingInProgress}
                />
              </Col>
              <Col />
              <Col />
            </Row>
            {generateIndexPage && (
              <div
                style={{
                  border: '1px solid #E3E3E3',
                  borderRadius: '0.5rem',
                  padding: '1rem',
                  marginBottom: '0.5rem',
                }}
              >
                <Row>
                  <Col>
                    <ToggleForm
                      {...register('indexPageTableBorder')}
                      defaultValue={true}
                      label={T.translate(`case.indexPageTableBorder`)}
                      readOnly={mergingInProgress}
                    />
                  </Col>
                  <Col />
                  <Col />
                </Row>
                <Row>
                  <Col>
                    <TextForm
                      {...register('indexPageClaimNo')}
                      as="textarea"
                      label={T.translate('case.indexPageClaimNo')}
                      placeholder={T.translate('case.indexPageClaimNoPlaceholder')}
                      defaultValue={caseClaimNo}
                      readOnly={mergingInProgress}
                    />
                  </Col>
                  <Col>
                    <TextForm
                      {...register('indexPageJurisdiction')}
                      as="textarea"
                      label={T.translate('case.indexPageJurisdiction')}
                      placeholder={T.translate('case.indexPageJurisdictionPlaceholder')}
                      defaultValue={indexPageJurisdiction}
                      readOnly={mergingInProgress}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextForm
                      {...register('indexPageClaimant')}
                      as="textarea"
                      label={labelClaimant || T.translate('case.indexPageClaimant')}
                      placeholder={T.translate('case.indexPageClaimantPlaceholder')}
                      defaultValue={indexPageClaimant}
                      readOnly={mergingInProgress}
                    />
                  </Col>
                  <Col>
                    <TextForm
                      {...register('indexPageDefendant')}
                      as="textarea"
                      label={labelDefendant || T.translate('case.indexPageDefendant')}
                      placeholder={T.translate('case.indexPageDefendantPlaceholder')}
                      defaultValue={indexPageDefendant}
                      readOnly={mergingInProgress}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextForm
                      {...register('indexPageBundleTitle')}
                      label={T.translate('case.indexPageBundleTitle')}
                      placeholder={T.translate('case.indexPageBundleTitlePlaceholder')}
                      defaultValue={
                        selectedRows
                          ? currentFolder.name || T.translate('case.allDocuments')
                          : folder.name
                      }
                      readOnly={mergingInProgress}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <MultiSelectForm
                      label={T.translate('case.heading4')}
                      name="columnsToDisplay"
                      defaultValue={defaultIndexPageColumn}
                      options={indexPageColumnsOptions}
                      onChange={onColumnChange}
                      control={control}
                      error={errors}
                    />
                  </Col>
                  {showIndexPageDateFormat && (
                    <Col>
                      <SelectForm
                        label={T.translate('case.IndexPageTableDateFormat')}
                        name="indexPageDateFormat"
                        defaultValue={defaultIndexPageTableDateFormat}
                        options={indexPageTableColumnsOptions}
                        control={control}
                      />
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col>
                    <ToggleForm
                      {...register('generateIndexPageOnly')}
                      defaultValue={false}
                      label={T.translate(`case.generateIndexPageOnly`)}
                      readOnly={mergingInProgress}
                    />
                  </Col>
                  <Col />
                  <Col />
                </Row>
              </div>
            )}
            <Row>
              <Col>
                <ToggleForm
                  {...register('generateBookMarks')}
                  defaultValue={true}
                  label={T.translate(`case.generateBookMarks`)}
                  readOnly={mergingInProgress}
                />
              </Col>
              <Col />
              <Col />
            </Row>
            <Row>
              <Col>
                <ToggleForm
                  {...register('generateLocalPaging')}
                  defaultValue={true}
                  label={T.translate(`case.generateLocalPaging`)}
                  readOnly={mergingInProgress}
                />
              </Col>
              <Col />
              <Col />
            </Row>
            <Row>
              <Col>
                <ToggleForm
                  {...register('generateGlobalPaging', { onChange: setGlobalPagePrefix })}
                  defaultValue={globalPagePrefix}
                  label={T.translate(`case.generateGlobalPaging`)}
                  readOnly={mergingInProgress}
                />
              </Col>
              <Col />
              <Col />
            </Row>
            <Row>
              <Col>
                <ToggleForm
                  {...register('coreBundleUseTrialBundlePaging')}
                  defaultValue={false}
                  label={T.translate(`case.coreBundleUseTrialBundlePaging`)}
                  readOnly={mergingInProgress}
                />
              </Col>
              <Col />
              <Col />
            </Row>
            <Row>
              <Col>
                <ToggleForm
                  {...register('useDoubleSidedPrinting')}
                  defaultValue={false}
                  label={T.translate(`case.useDoubleSidedPrinting`)}
                  readOnly={mergingInProgress}
                />
              </Col>
              <Col />
              <Col />
            </Row>
            <Row>
              <Col>
                <ToggleForm
                  {...register('burnCrossrefsFirst', {
                    onChange: () => setShowHyperlinkToggle(!showHyperlinkToggle),
                  })}
                  defaultValue={false}
                  label={T.translate(`case.includeCrossRef`)}
                  readOnly={mergingInProgress}
                />
              </Col>
              <Col />
              <Col />
            </Row>
            {showHyperlinkToggle && (
              <Row>
                <Col>
                  <ToggleForm
                    {...register('addHyperlinkToCrossRefOnMerge')}
                    defaultValue={false}
                    label={T.translate(`case.convertCrossRefToExternalHyperlink`)}
                    readOnly={mergingInProgress}
                  />
                </Col>
                <Col />
                <Col />
              </Row>
            )}
            {globalPagePrefix && (
              <Row>
                <Col>
                  <TextForm
                    {...register('globalPagingPrefix', {
                      onChange: (e) => {
                        e.target.value.length > 1
                          ? setGlobalPagePrefixVal(e.target.value.slice(0, 1))
                          : setGlobalPagePrefixVal(e.target.value);
                      },
                    })}
                    label={T.translate('case.globalPagePrefix')}
                    placeholder={T.translate('case.globalPagePrefixPlaceholder')}
                    value={globalPagePrefixVal}
                    readOnly={mergingInProgress}
                  />
                </Col>
                <Col />
              </Row>
            )}
            <MoveDocument
              control={control}
              errors={errors}
              canUnselect={true}
              readOnly={mergingInProgress}
              currentFolderType={folder ? folder.type : currentFolder.type || null}
            />
          </Form.Group>
        )}
      </PerfectScrollbar>
    </Modal>
  );
};

export default ModalMerge;
