import { call, put, takeEvery, select } from 'redux-saga/effects';
import {
  CASE_UPDATE_FOLDER_BEGIN,
  CASE_UPDATE_FOLDER_SUCCESS,
  CASE_UPDATE_FOLDER_AGREED_SUCCESS,
  CASE_UPDATE_FOLDER_FAILURE,
  CASE_UPDATE_FOLDER_DISMISS_FEEDBACK,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId, selectCurrentFolder } from 'common/selectors';
import {
  deleteItemImmutable,
  addItemImmutable,
  replaceItemImmutableDeepNested,
  findIndexOfDeepNestedFolder,
} from 'utils/arrays';
// import addSortNumberToDocuments from './addSortNumberToDocuments';

export const updateFolder = createPromiseAction(CASE_UPDATE_FOLDER_BEGIN, (payload) => ({
  folder: payload,
}));

export function dismissUpdateFolderFeedback() {
  return {
    type: CASE_UPDATE_FOLDER_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_UPDATE_BUNDLE_BEGIN actions
export function* doUpdateFolder(action) {
  const {
    payload: { folder, caseId },
  } = action;
  const {
    id,
    name,
    shared,
    sharedWith,
    type,
    etag,
    agreed,
    updateAgreed,
    generateIndexFolder,
    zeroBasedIndex,
    globalPaging,
    globalPagePrefix,
    predictiveIndex,
    groupId,
  } = folder;
  const res = yield call(api.put, `/cases/${caseId}/folders/${folder.id}`, {
    id,
    name,
    shared,
    sharedWith,
    type,
    etag,
    agreed,
    generateIndexFolder,
    zeroBasedIndex,
    globalPaging,
    globalPagePrefix: !globalPaging ? '' : globalPagePrefix,
    predictiveIndex,
    groupId,
  });

  if (res && res.error) {
    yield put({
      type: CASE_UPDATE_FOLDER_FAILURE,
      feedback: { message: 'feedback.updateFolderFailure', error: res.error, retryAction: action },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  const currentFolder = yield select(selectCurrentFolder);
  const isCurrentFolder = currentFolder.id === folder.id;
  //const isMovingFolder = currentFolder.type !== folder.type;

  if (updateAgreed) {
    yield put({
      type: CASE_UPDATE_FOLDER_AGREED_SUCCESS,
      data: { folder: res, isCurrentFolder, zeroBasedIndex: currentFolder.zeroBasedIndex },
      feedback: { message: 'feedback.updateFolderAgreedSuccess', params: { folderName: name } },
    });
    return yield call(resolvePromiseAction, action, res);
  }
  yield put({
    type: CASE_UPDATE_FOLDER_SUCCESS,
    data: { folder: res, isCurrentFolder, zeroBasedIndex: currentFolder.zeroBasedIndex },
    // ...(isMovingFolder && {
    //   feedback: {
    //     message: 'feedback.movingFoldermsg',
    //     params: { folderName: name, oldType: currentFolder.type, newType: type },
    //   },
    // }),
  });
  yield call(resolvePromiseAction, action, { ...res, currentFolder });
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchUpdateFolder() {
  yield takeEvery(updateFolder, withCurrentCaseId(doUpdateFolder));
}

const updateSpecificFolder = (folders, folder, type) => {
  //Nested search, this is covering multiple levels
  //const indexOfTheFolderToUpdate = folders.findIndex(x => x.id === folder.id);
  const indexOfTheFolderToUpdate = findIndexOfDeepNestedFolder(folders, folder);

  if (folder.type === type) {
    // if doesn't exist but is appropriate type -> ADD
    if (indexOfTheFolderToUpdate < 0) return addItemImmutable(folders, folder);
    // if exist and is appropriate type -> UPDATE
    return replaceItemImmutableDeepNested(folders, folder);
  }

  // if exist and is NOT an appropriate type -> DELETE
  if (indexOfTheFolderToUpdate >= 0) return deleteItemImmutable(folders, indexOfTheFolderToUpdate);
  // if doesn't exist and is NOT an appropriate type -> NO CHANGE
  return folders;
};

const getNewFolders = (folders, folder) => {
  const allFolderTitles = Object.keys(folders);
  return {
    ...folders,
    ...(allFolderTitles.includes('bundles') && {
      bundles: updateSpecificFolder(folders.bundles, folder, 'bundle'),
    }),
    ...(allFolderTitles.includes('teamBundles') && {
      teamBundles: updateSpecificFolder(folders.teamBundles, folder, 'team-bundle'),
    }),
    ...(allFolderTitles.includes('privateBundles') && {
      privateBundles: updateSpecificFolder(folders.privateBundles, folder, 'private-bundle'),
    }),
    courtBundles: updateSpecificFolder(folders.courtBundles, folder, 'court-bundle'),
    trialbooks: updateSpecificFolder(folders.trialbooks, folder, 'trialbook'),
  };
};

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_UPDATE_FOLDER_BEGIN:
      return {
        ...state,
        updateFolderPending: true,
        updateFolderFeedback: null,
      };

    case CASE_UPDATE_FOLDER_AGREED_SUCCESS:
    case CASE_UPDATE_FOLDER_SUCCESS:
      return {
        ...state,
        updateFolderPending: false,
        updateFolderFeedback: action.feedback,
        folders: getNewFolders(state.folders, action.data.folder),
        // ...(action.data.isCurrentFolder && {
        //   documents: addSortNumberToDocuments(action.data.folder.files, action.data.zeroBasedIndex),
        // }),
      };

    case CASE_UPDATE_FOLDER_FAILURE:
      const isConflict = action.feedback.error.status === 409;
      return {
        ...state,
        updateFolderPending: false,
        updateFolderFeedback: action.feedback,
        ...(isConflict && {
          folders: getNewFolders(state.folders, action.feedback.error.newData),
        }),
      };

    case CASE_UPDATE_FOLDER_DISMISS_FEEDBACK:
      return {
        ...state,
        updateFolderFeedback: null,
      };

    default:
      return state;
  }
}
