/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from 'react';
import T from 'i18n';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectCurrentFolder, selectParams, selectPermissions } from 'common/selectors';
import { useForm } from 'react-hook-form';
import * as actions from '../../redux/actions';
import { Spinner, Table, ToggleForm } from 'features/common';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { documentModalActions } from '../../enums';
import saveAs from 'save-as';
import equal from 'react-fast-compare';
import { fileVersionsTablecolumnSelector } from '../../DocumentsModal';
import * as actionsV from 'features/viewing/redux/actions';
import { Modal } from 'features/common/MaterialBasedComponents';

export const FileVersions = ({ show, handleClose, selectedRows, action }) => {
  const { register, reset, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const [showHyperlinkToggle, setShowHyperlinkToggle] = useState(false);
  const [fileVersions, setFileVersions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteVersion, setDeleteVersion] = useState(null);
  const { file } = useSelector(selectParams);
  const currentFolder = useSelector(selectCurrentFolder);
  const { documents: { canSeeFileVersions, canRemoveFileVersions } = {} } =
    useSelector(selectPermissions);
  const originalVersionsList = fileVersions.filter((itm) => !itm.type && itm.type !== 'dummy');
  const showDeleteFileVersion =
    canRemoveFileVersions &&
    originalVersionsList.length > 1 &&
    action === documentModalActions.fileVersions;
  const lastVersion = originalVersionsList[originalVersionsList.length - 1];
  const isAdminLevelPermisisons = canSeeFileVersions && canSeeFileVersions(selectedRows[0]);
  const containsPublicHyperlink = selectedRows[0]?.hasPublicHyperlinks;
  const isItemLoaded = ({ index }) => !!fileVersions[index];
  const loadMoreItems = () => {};
  const activeVersion = fileVersions.find((itm) => itm.active);

  const fileNameOptions = [
    {
      name: 'case.downloadFolderModal.fileNameOptions.originalFileName',
      fileName: 'original',
    },
    currentFolder &&
      currentFolder.id && {
        name: 'case.downloadFolderModal.fileNameOptions.fileName',
        fileName: 'folderSpecificName',
      },
    { name: 'case.downloadFolderModal.fileNameOptions.trialviewID', fileName: 'trialviewId' },
  ].filter(Boolean);

  const deleteFileVersion = (versionId) => {
    setLoading(true);
    dispatch(
      actions.deleteFileVersions({
        fileId: selectedRows[0].id || file,
        versions: [{ id: versionId }],
      }),
    )
      .then(() => {
        const filteredFileVersions = fileVersions.filter((itm) => itm.id !== versionId);
        setFileVersions(filteredFileVersions);
      })
      .finally(() => {
        setLoading(false);
        setDeleteVersion(null);
      });
  };

  const createTiffAction = () => {
    dispatch(
      actions.createFileVersion({
        fileId: selectedRows[0].id || file,
        newVersion: { source: 'tiff' },
      }),
    ).then((res) => {
      const filteredTiffList = fileVersions.filter((itm) => !itm.type && itm.type !== 'dummy');
      const newFileVersions = [...filteredTiffList, res];
      setFileVersions(newFileVersions);
    });
  };
  const downloadActiveFileVersion = (data) => {
    setLoading(true);
    dispatch(
      actionsV.downloadDocument({
        fileId: selectedRows[0].id || file,
        version: activeVersion.source,
        versionId: activeVersion.id,
        fileType: selectedRows[0].type,
        burnId: data.burnId,
        burnExternalId: data.burnExternalId,
        burnCrossRef: data.burnCrossRef,
        hyperlink: data.hyperlink,
        publichyperlink: data.publichyperlink,
        filenameSelector: data.filenameSelector,
        ...(data.filenameSelector === 'folderSpecificName' && { folderId: currentFolder?.id }),
      }),
    ).then((response) => {
      return response && saveAs(response.blob, response.fileName || selectedRows[0].id || file);
    });
    setTimeout(() => {
      handleClose();
      setLoading(false);
    }, 500);
  };
  const updateFileVersions = (versionId) => {
    const updatedFileVersions = fileVersions.map((item) => {
      if (item.id === versionId) return { ...item, active: true };
      else return { ...item, active: false };
    });
    if (!equal(fileVersions, updatedFileVersions) && action === documentModalActions.fileVersions)
      dispatch(
        actions.updateFileVersions({
          fileId: selectedRows[0].id || file,
          versions: updatedFileVersions,
        }),
      ).then((res) => setFileVersions(res));
    else if (action === documentModalActions.download) setFileVersions(updatedFileVersions);
  };
  const columns = fileVersionsTablecolumnSelector(
    updateFileVersions,
    createTiffAction,
    isAdminLevelPermisisons,
    action,
    setDeleteVersion,
    showDeleteFileVersion,
    lastVersion,
  );

  useEffect(() => {
    dispatch(actions.fetchFileVersions({ fileId: selectedRows[0].id || file }))
      .then((res) => {
        const containsTiff = res.find((itm) => itm.source === 'tiff');
        if (
          isAdminLevelPermisisons &&
          !containsTiff &&
          action === documentModalActions.fileVersions
        )
          setFileVersions([...res, { type: 'dummy' }]);
        else setFileVersions(res);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [show, selectedRows, dispatch, isAdminLevelPermisisons, file, action]);

  return (
    <>
      <Modal
        show={show}
        title={
          (action === documentModalActions.fileVersions
            ? T.translate('generic.fileVersions')
            : T.translate('generic.download')) + ` - ${selectedRows[0].id || file}`
        }
        handleClose={() => {
          if (loading) setLoading(false);
          setFileVersions([]);
          handleClose();
        }}
        saveTitle={
          action === documentModalActions.download
            ? T.translate('generic.download')
            : action === documentModalActions.fileVersions && deleteVersion
              ? T.translate('generic.delete')
              : null
        }
        handleSave={
          action === documentModalActions.download
            ? handleSubmit((data) => downloadActiveFileVersion(data))
            : action === documentModalActions.fileVersions && deleteVersion
              ? handleSubmit(() => deleteFileVersion(deleteVersion.id))
              : null
        }
        disableSave={loading}
      >
        {!loading &&
          !deleteVersion &&
          (fileVersions && fileVersions.length > 0 ? (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <Form.Group>
                <Card
                  style={{
                    height: '15rem',
                    ...(action === documentModalActions.fileVersions && {
                      margin: '-1.5rem',
                      height: '25rem',
                    }),
                  }}
                >
                  <Table
                    key={Math.random()}
                    data={fileVersions}
                    columns={columns}
                    isItemLoaded={isItemLoaded}
                    loadMoreItems={loadMoreItems}
                    totalDocumentsCount={0}
                    rowHeight={50}
                    readOnly
                  />
                </Card>
                {action === documentModalActions.download && (
                  <>
                    {activeVersion && activeVersion.source !== 'merge' && (
                      <>
                        <Row style={{ marginTop: '1rem' }}>
                          <Col>
                            <ToggleForm
                              {...register('burnId')}
                              defaultValue={false}
                              label={T.translate(`generic.includeTvId`)}
                            />
                          </Col>
                          <Col />
                          <Col />
                        </Row>
                        <Row style={{ marginTop: '1rem' }}>
                          <Col>
                            <ToggleForm
                              {...register('burnExternalId')}
                              defaultValue={false}
                              label={T.translate(`generic.includeExternalIdLabel`)}
                            />
                          </Col>
                          <Col />
                          <Col />
                        </Row>
                        <Row>
                          <Col>
                            <Form.Label>{T.translate('common.fileName')}</Form.Label>
                            <Form.Control
                              {...register('filenameSelector')}
                              as="select"
                              defaultValue={
                                fileNameOptions[fileNameOptions.length === 3 ? 1 : 0].fileName
                              }
                            >
                              {fileNameOptions.map((value) => (
                                <option key={value.name} value={value.fileName}>
                                  {T.translate(value.name)}
                                </option>
                              ))}
                            </Form.Control>
                          </Col>
                          <Col />
                          <Col />
                        </Row>
                      </>
                    )}
                    {activeVersion && activeVersion.crossRefCount > 0 && (
                      <>
                        <Row>
                          <Col>
                            <ToggleForm
                              {...register('burnCrossRef', {
                                onChange: () => {
                                  setShowHyperlinkToggle(!showHyperlinkToggle);
                                  reset({ hyperlink: false });
                                },
                              })}
                              defaultValue={false}
                              label={T.translate(`case.includeCrossRef`)}
                            />
                          </Col>
                          <Col />
                          <Col />
                        </Row>
                        <Row>
                          <Col>
                            <ToggleForm
                              {...register('hyperlink')}
                              defaultValue={false}
                              label={T.translate(`case.convertCrossRefToExternalHyperlink`)}
                              disabled={!showHyperlinkToggle}
                            />
                          </Col>
                          <Col />
                          <Col />
                        </Row>
                      </>
                    )}
                    {containsPublicHyperlink && (
                      <Row>
                        <Col>
                          <ToggleForm
                            {...register('publichyperlink')}
                            defaultValue={false}
                            label={T.translate(`case.includePublicHyperLinks`)}
                          />
                        </Col>
                        <Col />
                        <Col />
                      </Row>
                    )}
                  </>
                )}
              </Form.Group>
            </Form>
          ) : (
            T.translate('generic.noFileVersionsFound')
          ))}
        {loading && <Spinner />}
        {!loading && deleteVersion && (
          <span>{T.translate('case.deleteVersionWarning', { source: deleteVersion.source })}</span>
        )}
      </Modal>
    </>
  );
};
