import React, { forwardRef, useRef, useState, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectPresentQuery } from 'common/selectors';
import VideoViewerToolbox from './VideoViewerToolbox';
import Spinner from 'features/common/Spinner';
import VideoPlayerControls from './VideoPlayerControls';
import Transcript from './Transcript';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { CircularProgress } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { playerActions, subtitlesModes } from './enums';
import ReactPlayer from './ReactPlayer';
import Split from './Split';
import FileViewer from 'features/case/FileViewer';

interface VideoViewerProps {
  fileId: string;
  videoDetails: any;
  isFullScreen: boolean;
  hearingRoomMode?: 'private' | 'public';
  removeInput?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullScreenMainContainer: {
      display: 'flex',
      flexDirection: 'row',
      height: 'calc(100% - 7rem)',
    },
    splitViewMainContainer: {
      height: '100%',
    },
    fsChildContainer: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    svChildContainer: {
      height: '100%',
      width: '100% !important',
      display: 'flex',
    },
    videoContainer: {
      position: 'relative',
      margin: 'auto auto',
      width: '100%',
      height: 'auto',
      maxWidth: '1100px',
      maxHeight: 'calc(100vh - 150px)',
      aspectRatio: '16/9',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'visible',
      justifyContent: 'center',
      alignItems: 'center',
    },
    playerControlsContainer: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      width: '100%',
      zIndex: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transformOrigin: 'center bottom',
      transform: 'translateX(0)', // Reset any transforms
      margin: '0 auto', // Center horizontally
    },
    videoSpinner: {
      position: 'absolute',
      top: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    mutedIcon: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: 1,

      '& svg': {
        position: 'absolute',
        color: 'white',
        width: '100%',
        top: 'calc(50% - 5vh)',
        fontSize: '10vh',
      },
    },
  }),
);

const VideoViewer = forwardRef<any, VideoViewerProps>(
  ({ isFullScreen, hearingRoomMode, fileId, videoDetails, removeInput }, ref) => {
    const classes = useStyles();
    const playerRef = useRef<any>();
    const present = useSelector(selectPresentQuery);
    const dispatch = useDispatch();
    const isTheatreMode = !!hearingRoomMode;
    const [openPopoverWithAnchor, setOpenPopoverWithAnchor] = useState<HTMLElement | null>(null);
    const [showControls, setShowControls] = useState(true);
    const [videoReady, setVideoReady] = useState(false);
    const [playerAction, setPlayerAction] = useState('');
    const [playerState, setPlayerState] = useState({
      playing: true,
      muted: false,
      duration: 0,
    });
    const [showVideoIsMuted, setShowVideoIsMuted] = useState(false);
    const [presentedDoc, setPresentedDoc] = useState<any>(
      videoDetails.docsPresented &&
        videoDetails.docsPresented.findLast(({ offset }: any) => offset <= 0),
    );
    const [showSubTitles, setShowSubtitles] = useState(false);

    const _setShowSubtitles = (showSub: boolean) => {
      setShowSubtitles(showSub);
      const videoElement = document.getElementsByTagName('video')[0];
      const textTrack = videoElement.textTracks && videoElement.textTracks[0];
      if (textTrack)
        textTrack.mode = (
          showSub ? subtitlesModes.SHOWING : subtitlesModes.HIDDEN
        ) as TextTrackMode;
    };

    const hasTranscript = videoDetails.transcript && videoDetails.transcript.length > 0;

    useImperativeHandle(ref, () => ({
      pausePlayer: () => {
        handlePlayerActions(playerActions.PAUSE);
      },
    }));

    const handlePopoverClose = () => {
      setOpenPopoverWithAnchor(null);
    };

    const handleOpenPopOver = (e: React.MouseEvent<HTMLElement>) => {
      setOpenPopoverWithAnchor(e.currentTarget);
    };

    const handlePlayerActions = (action: string) => {
      setPlayerAction(action);
      switch (action) {
        case playerActions.PLAY:
          if (!playerState.playing) setPlayerState((ps) => ({ ...ps, playing: true }));
          break;
        case playerActions.PAUSE:
          if (playerState.playing) setPlayerState((ps) => ({ ...ps, playing: false }));
          break;
        case playerActions.MUTE:
          setPlayerState((ps) => ({ ...ps, muted: true }));
          break;
        case playerActions.UNMUTE:
          setShowVideoIsMuted(false);
          setPlayerState((ps) => ({ ...ps, muted: false }));
          break;
        case playerActions.SHOWCONTROLS:
          setShowControls(true);
          break;
        case playerActions.HIDECONTROLS:
          setShowControls(false);
          break;
        case playerActions.SHOWSUBTITLES:
          _setShowSubtitles(true);
          break;
        case playerActions.HIDESUBTITLES:
          _setShowSubtitles(false);
          break;
        default:
          break;
      }
    };

    const handleDuration = (duration: number) => {
      setPlayerState((ps) => ({ ...ps, duration }));
    };

    const handlePlayerSliderChange = (value: number) => {
      playerRef.current.seekTo(value);

      if (present) setPlayerState((ps) => ({ ...ps }));
    };

    const handleEndMedia = () => {
      handlePlayerActions(playerActions.PAUSE);
      handlePlayerSliderChange(0);
    };

    const handleVideoReady = () => {
      if (!videoReady) {
        //detect if autoplay is possible
        const videoElement = document.getElementsByTagName('video')[0];
        var promise = videoElement.play();

        if (promise !== undefined) {
          promise
            .then((_) => {
              setPlayerState((ps) => ({ ...ps, playing: true }));
            })
            .catch((error) => {
              // Autoplay not allowed!
              // Mute video and try to play again
              setPlayerState((ps) => ({ ...ps, playing: true, muted: true }));

              // Show something in the UI that the video is muted
              setShowVideoIsMuted(true);
            });
        }
        setVideoReady(true);
      }
    };

    const updatePlayerState = (updatedValue: any) => {
      setTimeout(() => {
        if (updatedValue.hasOwnProperty('videoAction')) {
          if (updatedValue.videoAction === playerActions.PAUSE)
            handlePlayerActions(playerActions.PAUSE);
          else if (updatedValue.videoAction === playerActions.PLAY)
            handlePlayerActions(playerActions.PLAY);
          else if (updatedValue.videoAction === playerActions.MUTE)
            handlePlayerActions(playerActions.MUTE);
          else if (updatedValue.videoAction === playerActions.UNMUTE)
            handlePlayerActions(playerActions.UNMUTE);
        }
      }, 0);
    };

    const startPlayerPresenting = () => {
      handlePlayerActions(playerActions.PAUSE);
      handlePlayerSliderChange(0);
    };

    const fileData =
      presentedDoc &&
      presentedDoc.messages &&
      presentedDoc.messages.length > 0 &&
      presentedDoc.messages[0];
    const showDocs = fileData && Object.keys(fileData).length > 0 && isFullScreen;

    setInterval(() => {
      const newSeconds = Math.round((playerRef.current && playerRef.current.getCurrentTime()) || 0);

      const newPresentedDocs =
        videoDetails.docsPresented &&
        videoDetails.docsPresented.findLast(({ offset }: any) => offset <= newSeconds);

      setPresentedDoc((prevPresentedDocs: any) =>
        prevPresentedDocs !== newPresentedDocs ? newPresentedDocs : prevPresentedDocs,
      );
    }, 1000);
    return videoDetails.videoLocation ? (
      <div
        className={isFullScreen ? classes.fullScreenMainContainer : classes.splitViewMainContainer}
      >
        <Split show={showDocs}>
          <div style={{ width: '100%', height: '100%' }}>
            {showDocs && (
              <FileViewer hearingRoomMode={hearingRoomMode} fileData={fileData} removeInput />
            )}
          </div>
          <Split show={hasTranscript && isFullScreen} reverse>
            <div
              className={isFullScreen ? classes.fsChildContainer : classes.svChildContainer}
              style={{ ...(hasTranscript && isFullScreen && { marginRight: '1rem' }) }}
            >
              <div
                className={classes.videoContainer}
                onMouseEnter={() =>
                  !isTheatreMode && videoReady && handlePlayerActions(playerActions.SHOWCONTROLS)
                }
                onMouseLeave={() =>
                  !isTheatreMode && videoReady && handlePlayerActions(playerActions.HIDECONTROLS)
                }
                onClick={() =>
                  !isTheatreMode &&
                  videoReady &&
                  handlePlayerActions(
                    playerState.playing ? playerActions.PAUSE : playerActions.PLAY,
                  )
                }
              >
                {showVideoIsMuted && (
                  <div className={classes.mutedIcon}>
                    <VolumeOffIcon />
                  </div>
                )}
                <ReactPlayer
                  ref={playerRef}
                  url={`${videoDetails.videoLocation}`}
                  width="100%"
                  height="auto"
                  style={{
                    position: 'relative',
                    margin: '0 auto',
                    maxHeight: 'calc(100vh - 5px)',
                    aspectRatio: '16/9',
                  }}
                  playing={playerState.playing}
                  muted={playerState.muted}
                  onDuration={handleDuration}
                  onEnded={handleEndMedia}
                  onReady={handleVideoReady}
                  onSeek={() => handlePlayerActions(playerActions.SEEK)}
                  config={{
                    file: {
                      attributes: {
                        crossOrigin: 'true',
                      },
                      tracks: [
                        {
                          kind: 'subtitles',
                          src: videoDetails.subtitlesLocation,
                          srcLang: 'en',
                          default: true,
                        },
                      ],
                    },
                  }}
                >
                  {!isTheatreMode && !removeInput ? (
                    videoReady ? (
                      <div className={classes.playerControlsContainer}>
                        <VideoPlayerControls
                          isFullSizeViewer={isFullScreen}
                          playerState={playerState}
                          handlePlayerActions={handlePlayerActions}
                          handlePlayerSliderChange={handlePlayerSliderChange}
                          handleOpenPopOver={handleOpenPopOver}
                          showControls={showControls}
                          showSubTitles={showSubTitles}
                          hasSubtitles={!!videoDetails.subtitlesLocation}
                          hasTranscript={hasTranscript}
                          playerRef={playerRef}
                        />
                      </div>
                    ) : (
                      <div className={classes.videoSpinner}>
                        <CircularProgress sx={{ color: 'whitesmoke' }} />
                      </div>
                    )
                  ) : null}
                </ReactPlayer>
              </div>
              <VideoViewerToolbox
                isTheatreMode={isTheatreMode}
                fileId={fileId}
                present={present}
                playerState={playerState}
                playerAction={playerAction}
                updatePlayerState={updatePlayerState}
                startPlayerPresenting={startPlayerPresenting}
                playerRef={playerRef}
              />
            </div>
            <div>
              {hasTranscript && (
                <Transcript
                  videoDetails={videoDetails}
                  isFullSizeViewer={isFullScreen}
                  transcript={videoDetails.transcript}
                  anchorEl={openPopoverWithAnchor}
                  openPopover={!!openPopoverWithAnchor}
                  handlePopoverClose={handlePopoverClose}
                  handlePlayerSliderChange={handlePlayerSliderChange}
                  recordingTime={videoDetails.recordingTime}
                  dispatch={dispatch}
                />
              )}
            </div>
          </Split>
        </Split>
      </div>
    ) : (
      <Spinner />
    );
  },
);

export default VideoViewer;
