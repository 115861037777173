import React, { createContext } from 'react';

export interface IFilterContext {
  filter: any;
}

export const FilterContext = createContext<IFilterContext>(null!);

export default function ({
  filter,
  children,
}: IFilterContext & {
  children: JSX.Element;
}) {
  return (
    <FilterContext.Provider
      value={{
        filter,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
}
