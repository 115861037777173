import { shallowEqual, useDispatch } from 'react-redux';
import T from 'i18n';
import {
  CASE_SET_AI_SEARCH_MATCHES,
  CASE_UPDATE_AI_SEARCH_MATCHES,
  CASE_CLEAR_AI_SEARCH_MATCHES,
  CASE_SET_AI_SEARCH_ANSWER_CHAT,
  CASE_SET_AI_SEARCH_SUGGESTED_QUESTIONS,
  CASE_CLEAR_AI_SEARCH_STATE,
} from './constants';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';

export function setAISearchMatches(payload) {
  return {
    type: CASE_SET_AI_SEARCH_MATCHES,
    payload,
  };
}

export function updateAISearchMatches(id, payload) {
  return {
    type: CASE_UPDATE_AI_SEARCH_MATCHES,
    id,
    payload,
  };
}

export function clearAISearchMatches() {
  return {
    type: CASE_CLEAR_AI_SEARCH_MATCHES,
  };
}

export function setAISearchAnswerChat(payload) {
  return {
    type: CASE_SET_AI_SEARCH_ANSWER_CHAT,
    payload,
  };
}

export function setAISearchSuggestedQuestions(payload) {
  return {
    type: CASE_SET_AI_SEARCH_SUGGESTED_QUESTIONS,
    payload,
  };
}

export function clearAISearchState() {
  return {
    type: CASE_CLEAR_AI_SEARCH_STATE,
  };
}

export function useSetAISearchState() {
  const dispatch = useDispatch();

  const { searchMatchesInput, searchAnsChat, searchSuggestedQuestions } = useSelector(
    (state) => ({
      searchMatchesInput: state.case.searchMatchesInput,
      searchAnsChat: state.case.searchAnsChat,
      searchSuggestedQuestions: state.case.searchSuggestedQuestions,
    }),
    shallowEqual,
  );

  const boundActionSetAISearchMatches = useCallback(
    (...args) => {
      return dispatch(setAISearchMatches(...args));
    },
    [dispatch],
  );

  const boundActionUpdateAISearchMatches = useCallback(
    (...args) => {
      return dispatch(updateAISearchMatches(...args));
    },
    [dispatch],
  );

  const boundActionClearAISearchMatches = useCallback(
    (...args) => {
      return dispatch(clearAISearchMatches(...args));
    },
    [dispatch],
  );

  const boundActionSetAISearchAnswerChat = useCallback(
    (...args) => {
      return dispatch(setAISearchAnswerChat(...args));
    },
    [dispatch],
  );

  const boundActionSetAISearchSuggestedQuestions = useCallback(
    (...args) => {
      return dispatch(setAISearchSuggestedQuestions(...args));
    },
    [dispatch],
  );

  const boundActionClearAISearchState = useCallback(
    (...args) => {
      return dispatch(clearAISearchState(...args));
    },
    [dispatch],
  );

  return {
    setAISearchMatches: boundActionSetAISearchMatches,
    updateAISearchMatches: boundActionUpdateAISearchMatches,
    clearAISearchMatches: boundActionClearAISearchMatches,
    setAISearchAnswerChat: boundActionSetAISearchAnswerChat,
    setAISearchSuggestedQuestions: boundActionSetAISearchSuggestedQuestions,
    clearAISearchState: boundActionClearAISearchState,
    searchMatchesInput: searchMatchesInput,
    searchAnsChat: searchAnsChat,
    searchSuggestedQuestions: searchSuggestedQuestions,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_SET_AI_SEARCH_MATCHES:
      return { ...state, searchMatchesInput: [...state.searchMatchesInput, action.payload] };
    case CASE_UPDATE_AI_SEARCH_MATCHES:
      return {
        ...state,
        searchMatchesInput: state.searchMatchesInput.map((itm) =>
          itm.id === action.id ? { ...itm, ...action.payload } : itm,
        ),
      };
    case CASE_CLEAR_AI_SEARCH_MATCHES:
      return { ...state, searchMatchesInput: [] };
    case CASE_SET_AI_SEARCH_ANSWER_CHAT:
      return {
        ...state,
        searchAnsChat: [
          ...state.searchAnsChat.filter((item) => item.text !== T.translate('case.searchingText')),
          ...action.payload,
        ],
      };
    case CASE_SET_AI_SEARCH_SUGGESTED_QUESTIONS:
      return { ...state, searchSuggestedQuestions: action.payload };
    case CASE_CLEAR_AI_SEARCH_STATE:
      return {
        ...state,
        searchMatchesInput: [],
        searchAnsChat: [],
        searchSuggestedQuestions: null,
      };
    default:
      return state;
  }
}
