import React from 'react';
import T from 'i18n';
import Grid from '@mui/material/Unstable_Grid2';
import ThreeStateToggle from './ThreeStateToggle';

export default ({
  isAnnotation,
  filterCategory,
  setFilters,
}: {
  isAnnotation: boolean;
  filterCategory: null | any;
  setFilters: (newFilterCategory: any) => void;
}) => {
  const handleToggle = (e: { target: { name: string; checked: any } }) => {
    if (e.target.name === 'withAnnotations' || e.target.name === 'withoutAnnotations') {
      const newFilterCategory = {
        ...filterCategory,
        withoutAnnotations: false,
        withAnnotations: false,
        [e.target.name]: e.target.checked,
      };
      setFilters(newFilterCategory);
    } else if (e.target.name === 'shared' || e.target.name === 'notShared') {
      const newFilterCategory = {
        ...filterCategory,
        shared: false,
        notShared: false,
        [e.target.name]: e.target.checked,
      };
      setFilters(newFilterCategory);
    } else if (e.target.name === 'public' || e.target.name === 'private') {
      const newFilterCategory = {
        ...filterCategory,
        private: false,
        public: false,
        [e.target.name]: e.target.checked,
      };
      setFilters(newFilterCategory);
    } else if (
      e.target.name === 'withPublicHyperlinks' ||
      e.target.name === 'withoutPublicHyperlinks'
    ) {
      const newFilterCategory = {
        ...filterCategory,
        withPublicHyperlinks: false,
        withoutPublicHyperlinks: false,
        [e.target.name]: e.target.checked,
      };
      setFilters(newFilterCategory);
    } else {
      const newFilterCategory = { ...filterCategory, [e.target.name]: e.target.checked };
      setFilters(newFilterCategory);
    }
  };

  const handleThreeStateToggle = (value: string, values: Array<string>) => {
    if (value === values[0]) {
      handleToggle({ target: { name: values[0], checked: true } });
    } else if (value === values[1]) {
      handleToggle({ target: { name: values[1], checked: true } });
    } else {
      handleToggle({ target: { name: values[0], checked: false } });
    }
  };

  return (
    <>
      <Grid xs={12}>
        {!isAnnotation && (
          <ThreeStateToggle
            label1={T.translate('filters.withoutAnnotations')}
            label2={T.translate('filters.allDocuments')}
            label3={T.translate('filters.withAnnotations')}
            style={{ padding: 0 }}
            defaultValue={
              filterCategory?.withAnnotations
                ? 'withAnnotations'
                : filterCategory?.withoutAnnotations
                  ? 'withoutAnnotations'
                  : 'null'
            }
            values={['withoutAnnotations', 'null', 'withAnnotations']}
            onChange={(value: any) =>
              handleThreeStateToggle(value, ['withAnnotations', 'withoutAnnotations'])
            }
          />
        )}
        {!isAnnotation && (
          <ThreeStateToggle
            label1={T.translate('filters.withoutPublicHyperlinks')}
            label2={T.translate('filters.withAllHyperlinks')}
            label3={T.translate('filters.withPublicHyperlinks')}
            style={{ padding: 0 }}
            defaultValue={
              filterCategory?.withoutPublicHyperlinks
                ? 'withoutPublicHyperlinks'
                : filterCategory?.withPublicHyperlinks
                  ? 'withPublicHyperlinks'
                  : 'null'
            }
            values={['withoutPublicHyperlinks', 'null', 'withPublicHyperlinks']}
            onChange={(value: any) =>
              handleThreeStateToggle(value, ['withPublicHyperlinks', 'withoutPublicHyperlinks'])
            }
          />
        )}
        {isAnnotation && (
          <ThreeStateToggle
            label1={T.translate('filters.privateAnnotations')}
            label2={T.translate('filters.allAnnotations')}
            label3={T.translate('filters.sharedAnnotations')}
            style={{ padding: 0 }}
            defaultValue={
              filterCategory?.notShared ? 'notShared' : filterCategory?.shared ? 'shared' : 'null'
            }
            values={['notShared', 'null', 'shared']}
            onChange={(value: any) => handleThreeStateToggle(value, ['shared', 'notShared'])}
          />
        )}
        {!isAnnotation && (
          <ThreeStateToggle
            label1={T.translate('filters.privateOnly')}
            label2={T.translate('filters.allAnnotations')}
            label3={T.translate('filters.publicOnly')}
            style={{ padding: 0 }}
            defaultValue={
              filterCategory?.private ? 'private' : filterCategory?.public ? 'public' : 'null'
            }
            values={['private', 'null', 'public']}
            onChange={(value: any) => handleThreeStateToggle(value, ['private', 'public'])}
          />
        )}
      </Grid>
    </>
  );
};
