import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import { Controller, useForm } from 'react-hook-form';
import Auth from '@aws-amplify/auth';
import CircularProgress from '@mui/material/CircularProgress';
import { emailValidation } from 'utils/regex';
import Logger from 'utils/logger';
import T from 'i18n';
import { Box } from '@mui/material';

const useStyles = makeStyles({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '1rem',
  },
  avatar: {
    backgroundColor: '#f50057 !important',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  magicText: {
    display: 'flex',
    padding: '1rem 2rem 1rem 1rem',
    backgroundColor: '#F3F3F3',
    borderRadius: '0.25rem',
  },
  error: { height: '1rem' },
});

function TroubleSigningIn({
  onSignupClick,
  onSignInClick,
  onForgotPasswordClicked,
  onResendVerificationEmailClicked,
}) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  const forgotPassword = (data) => {
    const { email } = data;
    const username = email;
    setLoading(true);
    Auth.forgotPassword(username)
      .then((result) => {
        onForgotPasswordClicked(email, result);
      })
      .catch((e) => {
        Logger.ERROR(e);
        if (e.code === 'UserNotFoundException') onForgotPasswordClicked(email, {});
        else setMessage(T.translate('auth.otherIssueMessage'));
      })
      .finally(() => setLoading(false));
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper} style={{ marginTop: 0 }}>
        <Avatar className={classes.avatar} sx={{ m: 1 }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" sx={{ mb: '1.5rem' }}>
          {T.translate('auth.forgotPasswordLink')}
        </Typography>
        <Typography className={classes.error} color="error" component="h1" variant="h6">
          {message}
        </Typography>
        <form onSubmit={handleSubmit(forgotPassword)} className={classes.form} noValidate>
          <Box className={classes.magicText}>
            <span>{T.translate('auth.forgotPasswordMsg')}</span>
          </Box>
          <Controller
            name="email"
            rules={{ required: true, pattern: emailValidation }}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                id="email"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label={T.translate('auth.email')}
                autoComplete="email"
                autoFocus
                error={errors.email}
                helperText={
                  errors.email &&
                  T.translate(
                    errors.email.type === 'required' ? 'auth.emailRequired' : 'auth.emailInvalid',
                  )
                }
                style={{ minHeight: '6rem', marginTop: '2rem' }}
                InputLabelProps={{ style: { fontSize: '1.2rem', fontWeight: 500 } }}
                InputProps={{ style: { fontSize: '1.2rem' } }}
              />
            )}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mb: 2 }}
            disabled={loading}
          >
            {loading && <CircularProgress size={17} />}
            {!loading && T.translate('auth.submit')}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link
                component="button"
                variant="body2"
                onClick={onSignInClick}
                sx={{ fontSize: '1rem' }}
              >
                {T.translate('auth.signIn')}
              </Link>
            </Grid>
            <Grid item>
              <Link
                component="button"
                variant="body2"
                onClick={onSignupClick}
                sx={{ fontSize: '1rem' }}
              >
                {T.translate('auth.signUp')}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}

TroubleSigningIn.propTypes = {};
TroubleSigningIn.defaultProps = {};

export default TroubleSigningIn;
