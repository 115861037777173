import { shallowEqual, useDispatch } from 'react-redux';
import { CASE_SHOW_DOCFINDER_FILTER } from './constants';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';

export function setShowDocFinderFilter(value) {
  return {
    type: CASE_SHOW_DOCFINDER_FILTER,
    value,
  };
}

export function useSetShowDocFinderFilter() {
  const dispatch = useDispatch();

  const { showDocFinderFilter } = useSelector(
    (state) => ({
      showDocFinderFilter: state.case.showDocFinderFilter,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(setShowDocFinderFilter(...args));
    },
    [dispatch],
  );

  return {
    setShowDocFinderFilter: boundAction,
    showDocFinderFilter,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_SHOW_DOCFINDER_FILTER:
      return {
        ...state,
        showDocFinderFilter: action.value,
        showDocFinderBookmarks:
          state.showDocFinderBookmarks && action.value ? false : state.showDocFinderBookmarks,
      };

    default:
      return state;
  }
}
