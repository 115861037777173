import { call, put, takeEvery } from 'redux-saga/effects';
import {
  CASE_FETCH_FILE_DETAILS_BEGIN,
  CASE_FETCH_FILE_DETAILS_SUCCESS,
  CASE_FETCH_FILE_DETAILS_FAILURE,
  CASE_FETCH_FILE_DETAILS_FEEDBACK,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import { withCurrentCaseId } from 'common/selectors';
import api from 'common/api';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import addSortNumberToDocuments from './addSortNumberToDocuments';
import { replaceItemImmutable } from 'utils/arrays';

export const fetchFileDetails = createPromiseAction(CASE_FETCH_FILE_DETAILS_BEGIN);

export function dismissfetchFileDetailsFeedback() {
  return {
    type: CASE_FETCH_FILE_DETAILS_FEEDBACK,
  };
}

// worker Saga: will be fired on HOME_FETCH_TSANDCS_BEGIN actions
export function* doFetchFileDetails(action) {
  const {
    payload: {
      caseId,
      fileId,
      folderFileId,
      folderId,
      updateDocuments,
      firstTrialBookOnly = false,
    },
  } = action;

  const res = yield call(
    api.get,
    `/cases/${caseId}${folderId ? `/folders/${folderId}` : ''}/files/${folderFileId ? folderFileId : fileId}${
      !folderId ? `/details` : ''
    }?firstTrialBookOnly=${firstTrialBookOnly}`,
  );

  if (res && res.error) {
    yield put({
      type: CASE_FETCH_FILE_DETAILS_FAILURE,
      feedback: {
        message: 'feedback.fetchFileDetailsFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }
  // Dispatch success action out of try/catch so that render errors are not catched.
  yield put({
    type: CASE_FETCH_FILE_DETAILS_SUCCESS,
    data: res,
    payload: { updateDocuments },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchFetchFileDetails() {
  yield takeEvery(fetchFileDetails, withCurrentCaseId(doFetchFileDetails));
}

export function useFetchFileDetails() {
  const dispatch = useDispatch();

  // const { fetchFileDetailsPending, fetchFileDetailsFeedback } = useSelector(
  //   state => ({
  //     fetchFileDetailsPending: state.case.fetchFileDetailsPending,
  //     fetchFileDetailsFeedback: state.case.fetchFileDetailsFeedback,
  //   }),
  //   shallowEqual,
  // );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(fetchFileDetails(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissfetchFileDetailsFeedback());
  }, [dispatch]);

  return {
    fetchFileDetails: boundAction,
    // fetchFileDetailsPending,
    // fetchFileDetailsFeedback,
    dismissfetchFileDetailsFeedback: boundDismissFeedback,
  };
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_FETCH_FILE_DETAILS_BEGIN + '.TRIGGER':
      return {
        ...state,
        fetchFileDetailsPending: true,
        fetchFileDetailsFeedback: null,
      };

    case CASE_FETCH_FILE_DETAILS_SUCCESS: {
      const getIndex = (documentId, folderFileId) =>
        state.documents.findIndex(({ id }) => id === documentId || folderFileId === documentId);
      return {
        ...state,
        fetchFileDetailsPending: false,
        ...(action.payload.updateDocuments &&
          action.payload.updateDocuments === 'confidentialityRing' && {
            documents: addSortNumberToDocuments(
              replaceItemImmutable(
                state.documents,
                {
                  ...state.documents[getIndex(action.data.id, action.data.folderFileId)],
                  confidentialityRing: action.data.confidentialityRing,
                },
                getIndex(action.data.id, action.data.folderFileId),
              ),
              action.data.zeroBasedIndex,
            ),
          }),
        fetchFileDetailsFeedback: action.feedback,
      };
    }

    case CASE_FETCH_FILE_DETAILS_FAILURE:
      return {
        ...state,
        fetchFileDetailsPending: false,
        fetchFileDetailsFeedback: action.feedback,
      };

    default:
      return state;
  }
}
