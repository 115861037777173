import React, { useRef, useEffect } from 'react';
// import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { Controller } from 'react-hook-form';
import { objectValueFromPath } from 'utils/object';
import ErrorFeedback from './ErrorFeedback';
import Select from './Select';
import AsyncSelect from './AsyncSelect';

const SelectForm = ({
  options,
  loadOptions,
  labelKey = 'label',
  valueKey = 'value',
  onChange,
  isClearable = false,
  control,
  errors,
  rules,
  defaultValue,
  name,
  label,
  autofocus,
  getOptionLabel,
  defaultOptions,
  isMulti,
  readOnly,
  customOption,
  noIndicator,
  placeholder,
  backspaceRemovesValue = true,
  menuPosition,
  fullWidthLabel = false,
}) => {
  const selectRef = useRef();

  useEffect(() => {
    autofocus && selectRef.current && selectRef.current.focus && selectRef.current.focus();
  }, [autofocus, selectRef]);

  const getFlatOptions = (opts) =>
    opts.reduce(
      (acc, item) => (item.options ? [...acc, ...getFlatOptions(item.options)] : [...acc, item]),
      [],
    );

  const FormControl = ({ field }) => {
    return (
      <>
        {label && (
          <Form.Label style={{ ...(fullWidthLabel && { width: '100%' }) }}>{label}</Form.Label>
        )}
        <Form.Control
          style={{ height: 'auto', padding: 0 }}
          as={loadOptions ? AsyncSelect : Select}
          ref={selectRef}
          onChange={(props) => {
            const changeValue = loadOptions
              ? props
              : isMulti
                ? props
                  ? props.map((option) => option[valueKey])
                  : []
                : props && props[valueKey];
            onChange && onChange(changeValue);
            field.onChange(changeValue);
          }}
          openMenuOnFocus={false}
          onBlur={field.onBlur}
          defaultValue={
            loadOptions
              ? field.value
              : isMulti
                ? (field.value || []).map((v) =>
                    (getFlatOptions(options) || []).find((item) => item && item[valueKey] === v),
                  )
                : (getFlatOptions(options) || []).find(
                    (item) =>
                      item &&
                      (typeof field.value === 'string'
                        ? item[valueKey] === field.value
                        : field.value && item[valueKey].id && item[valueKey].id === field.value.id),
                  )
          }
          options={options}
          labelKey={labelKey}
          valueKey={valueKey}
          isInvalid={errors && name && !!objectValueFromPath(name, errors)}
          isClearable={isClearable}
          loadOptions={loadOptions}
          getOptionLabel={getOptionLabel}
          isMulti={isMulti}
          defaultOptions={defaultOptions}
          readOnly={readOnly}
          customOption={customOption}
          noIndicator={noIndicator}
          placeholder={placeholder}
          backspaceRemovesValue={backspaceRemovesValue}
          menuPosition={menuPosition}
        />
        {errors && name && <ErrorFeedback name={name} errors={errors} />}
      </>
    );
  };

  return name ? (
    <>
      <Controller
        control={control}
        render={FormControl}
        rules={rules}
        defaultValue={defaultValue}
        name={name}
      />
    </>
  ) : (
    <FormControl value={defaultValue} />
  );
};

SelectForm.propTypes = {};
SelectForm.defaultProps = {};

export default SelectForm;
