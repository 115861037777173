import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n';
import { withStyles, makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import { Grow } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow in ref={ref} {...props} />;
});

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    color: 'black',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, title, closeButton, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {title && <Typography variant="h5">{title}</Typography>}
      {closeButton ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const dialogStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const CustomDialog = ({
  disableOnOutsideClick = true,
  className,
  show,
  handleClose,
  title,
  disableClose,
  children,
  buttons,
  handleSave,
  disableSave,
  saveTitle,
  closeTitle,
  wide,
}) => {
  const classes = dialogStyles();
  useEffect(() => {
    const listener = (event) => {
      if (show && (event.code === 'Enter' || event.code === 'NumpadEnter')) {
        if (disableSave) !buttons && handleClose && handleClose();
        else handleSave && handleSave();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [show, handleSave, buttons, disableSave, handleClose]);

  return (
    <Dialog
      className={classnames(className)}
      classes={{
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
      }}
      open={show}
      scroll={'paper'}
      fullWidth
      maxWidth={wide ? 'lg' : 'md'}
      TransitionComponent={Transition}
      transitionDuration={400}
      onClose={(event, reason) => {
        if (disableOnOutsideClick && reason === 'backdropClick') {
          return;
        }
        handleClose();
      }}
    >
      <DialogTitle
        id="customized-dialog-title"
        title={title}
        closeButton={!disableClose}
        onClose={handleClose}
      />
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        {!disableClose && (
          <Button type="button" variant="contained" onClick={handleClose} color="default">
            {closeTitle || T.translate('generic.close')}
          </Button>
        )}
        {buttons}
        {handleSave && (
          <Button
            type="submit"
            onClick={handleSave}
            variant="contained"
            color="primary"
            disabled={disableSave}
          >
            {saveTitle || T.translate('generic.save')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

CustomDialog.propTypes = {
  handleSave: PropTypes.func,
  handleClose: PropTypes.func,
  show: PropTypes.bool.isRequired,
  title: PropTypes.string,
  saveTitle: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  disableClose: PropTypes.bool,
  disableSave: PropTypes.bool,
  buttons: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
};
CustomDialog.defaultProps = {};

export default CustomDialog;
