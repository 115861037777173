import React from 'react';
import withAuth from 'features/auth/withAuth';
import Logo from 'features/common/Logo';
import NavBar from 'features/common/NavBar';
import Footer from 'features/common/Footer';
import RolesTable from './RolesTable';
import TeamAdmin from './TeamAdmin';
import { useSelector } from 'react-redux';
import {
  selectIsAuthenticated,
  selectIsAdmin,
  selectIsTeamAdmin,
  selectIsCaseAdmin,
} from 'common/selectors';
import { Spinner } from 'features/common';
import CaseSettingsButton from './CaseSettingsButton';

export const SettingsWrapper = ({ settingsType }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isSystemAdmin = useSelector(selectIsAdmin);
  const isTeamAdmin = useSelector(selectIsTeamAdmin);
  const isCaseAdmin = useSelector(selectIsCaseAdmin);

  const logo = <Logo />;

  const fetchCaseDetailsPending = useSelector((state) => state.case.fetchCaseDetailsPending);
  const fetchCasesPending = useSelector((state) => state.home.fetchCasesPending);

  if (fetchCasesPending || fetchCaseDetailsPending) return null;

  return (
    <div className="settings-content">
      {isAuthenticated ? (
        <>
          <NavBar
            brandText={logo}
            additionalButton={
              <CaseSettingsButton
                isTeamAdmin={isTeamAdmin}
                isSystemAdmin={isSystemAdmin}
                isCaseAdmin={isCaseAdmin}
              />
            }
          />
          {settingsType === 'rolesSettings' ? <RolesTable /> : <TeamAdmin />}
          <Footer />
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default withAuth(SettingsWrapper, { forceLogin: true });
