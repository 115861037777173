import React, { useCallback, useEffect, useState } from 'react';
import T from 'i18n';
import { useDispatch } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { Modal } from 'features/common/MaterialBasedComponents';
import { updateFolderSeq } from './redux/updateFolderSeq';
import { useSelector } from 'react-redux';
import { selectFolderContainers } from 'common/selectors';
import { findContainerAndIndex } from './utils';

interface RepositionModalProps {
  show: boolean;
  handleClose: () => void;
  folder: any;
  bundles: any;
  code: string;
}

const RepositionModal = ({ show, handleClose, folder }: RepositionModalProps) => {
  const dispatch = useDispatch();
  const containers = useSelector(selectFolderContainers);

  const [newIndex, setNewIndex] = useState<number | null>(null);
  const [error, setError] = useState(false);

  const [maxIndex, setMaxIndex] = useState<number>(0);

  useEffect(() => {
    if (folder?.id && containers) {
      const found = findContainerAndIndex(containers, folder.id);
      if (found) {
        setMaxIndex(found.arr.length);
        setNewIndex(found.index + 1);
      }
    }
  }, [folder, containers]);

  const handleUpdatePosition = useCallback(
    (targetIndex: number) => {
      if (targetIndex < 1 || targetIndex > maxIndex || isNaN(targetIndex)) {
        setError(true);
        return;
      }
      setError(false);

      const zeroBasedIndex = targetIndex - 1;

      const found = findContainerAndIndex(containers, folder.id);
      if (!found) return;

      dispatch(
        updateFolderSeq({
          folder,
          containerId: found.container.id,
          containerEtag: found.container.etag,
          index: zeroBasedIndex,
        }),
      ).catch((err: any) => {
        console.error(err);
      });

      handleClose();
    },
    [dispatch, containers, folder, maxIndex, handleClose],
  );

  const handleMoveFirst = useCallback(() => {
    handleUpdatePosition(1);
  }, [handleUpdatePosition]);

  const handleMoveLast = useCallback(() => {
    handleUpdatePosition(maxIndex);
  }, [handleUpdatePosition, maxIndex]);

  const handleSave = useCallback(() => {
    if (newIndex !== null) {
      handleUpdatePosition(newIndex);
    }
  }, [newIndex, handleUpdatePosition]);

  const indexHandler = (val: any) => {
    setError(false);
    const numVal = parseInt(val, 10);
    if (isNaN(numVal)) {
      setNewIndex(null);
      setError(true);
    } else {
      setNewIndex(numVal);
    }
  };

  const renderSingleFileContent = () => (
    <>
      <p>
        {T.translate('generic.changePositionModal.currentIndex')}: {newIndex ?? '...'}
      </p>

      <TextField
        fullWidth
        variant="outlined"
        autoComplete="off"
        placeholder={`${T.translate('generic.changePositionModal.placeholder')}`}
        onChange={(e) => indexHandler(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !error && newIndex !== null) {
            e.preventDefault();
            e.stopPropagation();
            handleSave();
          }
        }}
        value={newIndex ?? ''}
        autoFocus
        error={error}
      />

      <p className="max-length">
        {T.translate('generic.updateIndex.maxValue', { index: maxIndex })}
      </p>
      {error && <p style={{ color: 'red' }}>{T.translate('generic.notAValidEntry')}</p>}
    </>
  );

  return (
    <Modal
      show={show}
      title={`${T.translate('generic.changePosition')} of ${folder?.name}`}
      handleClose={handleClose}
      handleSave={handleSave}
      saveTitle={T.translate('generic.changePosition')}
      disableSave={error}
      buttons={
        <>
          <Button
            type="button"
            id="firstIndexMove"
            onClick={handleMoveFirst}
            variant="contained"
            color="primary"
          >
            {T.translate('generic.updateIndex.moveFirst')}
          </Button>
          <Button
            type="button"
            id="lastIndexMove"
            onClick={handleMoveLast}
            variant="contained"
            color="primary"
          >
            {T.translate('generic.updateIndex.moveLast')}
          </Button>
        </>
      }
    >
      <PerfectScrollbar style={{ maxHeight: 300 }}>{renderSingleFileContent()}</PerfectScrollbar>
    </Modal>
  );
};

export default RepositionModal;
