import {
  CASE_REMOVE_TEAM_BEGIN,
  CASE_REMOVE_TEAM_SUCCESS,
  CASE_REMOVE_TEAM_FAILURE,
  CASE_REMOVE_TEAM_DISMISS_FEEDBACK,
} from './constants';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';
import { deleteItemImmutable } from 'utils/arrays';

export const removeTeam = createPromiseAction(CASE_REMOVE_TEAM_BEGIN);

export function dismissRemoveTeamFeedback() {
  return {
    type: CASE_REMOVE_TEAM_DISMISS_FEEDBACK,
  };
}

export function* doRemoveTeam(action) {
  const { caseId, teamId } = action.payload;
  let res = yield call(api.del, `/cases/${caseId}/teams/${teamId}`);

  if (res && res.error) {
    yield put({
      type: CASE_REMOVE_TEAM_FAILURE,
      feedback: { message: 'feedback.removeTeamFailure', error: res.error, retryAction: action },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_REMOVE_TEAM_SUCCESS,
    data: { teamId },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchRemoveTeam() {
  yield takeLatest(removeTeam, withCurrentCaseId(doRemoveTeam));
}

export function useRemoveTeam() {
  const dispatch = useDispatch();

  const { removeTeamPending, removeTeamFeedback } = useSelector(
    (state) => ({
      removeTeamPending: state.case.removeTeamPending,
      removeTeamFeedback: state.case.removeTeamFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(removeTeam(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissRemoveTeamFeedback());
  }, [dispatch]);

  return {
    removeTeam: boundAction,
    removeTeamPending,
    removeTeamFeedback,
    dismissRemoveTeamFeedback: boundDismissFeedback,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_REMOVE_TEAM_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        removeTeamPending: true,
        removeTeamFeedback: null,
      };

    case CASE_REMOVE_TEAM_SUCCESS:
      // The request is success
      const index = state.teams.findIndex((x) => x.id === action.data.teamId);
      return {
        ...state,
        removeTeamPending: false,
        removeTeamFeedback: action.feedback,
        teams: deleteItemImmutable(state.teams, index),
      };

    case CASE_REMOVE_TEAM_FAILURE:
      // The request is failed
      return {
        ...state,
        removeTeamPending: false,
        removeTeamFeedback: action.feedback,
      };

    case CASE_REMOVE_TEAM_DISMISS_FEEDBACK:
      // Dismiss the request failure error
      return {
        ...state,
        removeTeamFeedback: null,
      };

    default:
      return state;
  }
}
