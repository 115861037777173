import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

export default class CustomViewerButton extends Component {
  static propTypes = {
    icon: PropTypes.object,
  };

  render() {
    const { icon, icon2, forwardedRef, noTransform, className, reddot, muiIcon, ...rest } =
      this.props;
    return (
      <button
        ref={forwardedRef}
        className={classnames({ noTransform, reddot }, className)}
        {...rest}
        type="button"
      >
        {icon && (
          <span className="fa-layers fa-fw">
            <FontAwesomeIcon icon={icon} />
            {icon2 && <FontAwesomeIcon icon={icon2} transform="shrink-5 up-4 right-6" />}
          </span>
        )}
        {muiIcon && muiIcon}
        {this.props.children}
      </button>
    );
  }
}
