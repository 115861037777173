import React, { createContext } from 'react';

export interface ISearchContext {
  searchHandler: (stuff: any) => void;
  searchFunctionsHandler: (stuff: any) => void;
  clearSearch: null | (() => void) | undefined;
}

export const SearchContext = createContext<ISearchContext>(null!);

export default function ({
  searchHandler,
  searchFunctionsHandler,
  clearSearch,
  children,
}: ISearchContext & {
  children: JSX.Element;
}) {
  return (
    <SearchContext.Provider
      value={{
        searchHandler,
        searchFunctionsHandler,
        clearSearch,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}
