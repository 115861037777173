import React, { useCallback, useRef, useState } from 'react';
import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import T from 'i18n';
import clsx from 'clsx';
import { Controller, useForm } from 'react-hook-form';
import { faEdit, faTrash, faUpload, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { CustomViewerButton, SimpleTooltip } from 'features/common';
import moment from 'moment';
import TagsSelect from './TagsSelect';
import { useSelector } from 'react-redux';
import { selectUserId, selectUserName } from 'common/selectors';

const useStyles = makeStyles(() => ({
  noBorder: {
    border: 'none',
  },
  uploadIcon: {
    padding: '0.2rem',
    borderRadius: '50% !important',
    fontSize: '2rem',
    color: 'white',
    margin: '0.5rem',
    cursor: 'pointer',
    backgroundColor: '#4567e5',
  },
  disabled: {
    backgroundColor: '#A5A4A1',
  },
  error: {
    backgroundColor: 'red',
  },
  markButton: {
    padding: '0.25rem',
    position: 'relative',
    transition: 'opacity 150ms, visibility 0ms 150ms',
    whiteSpace: 'nowrap',
    backgroundColor: '#4567e5',
    border: 'none',
    boxShadow: '0 4px 6px 0 #666',
    color: 'white',
    textAlign: 'center',
    opacity: '0.85',
    margin: '0.1rem',
    borderRadius: '10%',
    a: {
      color: 'white',
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
    "&[type='button']:hover": {
      boxShadow: '0 4px 10px 0 #666',
      opacity: 1,
    },
  },
  markButtonWhite: {
    color: 'black',
    backgroundColor: 'white',
    boxShadow: '0 4px 6px 0 #666',
    '&:focus': {
      boxShadow: '0 4px 6px 0 #666',
    },
  },
  markButtonOrange: {
    backgroundColor: '#ff9200',
    boxShadow: '0 4px 6px 0 #666',
    '&:focus': {
      boxShadow: '0 4px 6px 0 #666',
    },
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    borderRadius: '0.5rem',
    width: 200,
    height: 100,
    padding: '1rem',
  },
}));

type CommentInputPropsType = {
  onClick: () => void;
  comment?: string;
  selectedTags?: any[];
  update?: boolean;
  deleteAnnotation: any;
  createdBy: any;
  selectMark: any;
  page: any;
  shared: any;
  onSharedClick: any;
};

export default ({
  onClick,
  comment: defaultComment = '',
  selectedTags = [],
  update = false,
  deleteAnnotation,
  createdBy,
  selectMark,
  page,
  shared,
  onSharedClick,
}: CommentInputPropsType) => {
  const classes = useStyles();

  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty }, // this line is an absolute must in order to subscribe to getFieldState
  } = useForm({
    defaultValues: {
      comment: defaultComment,
      tags: selectedTags,
    },
  });

  const [edit, setEdit] = useState(false);
  const [areYouSureDelete, setAreYouSureDelete] = useState(false);
  const [areYouSureLeave, setAreYouSureLeave] = useState<any | null>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const commentRef = useRef<HTMLInputElement>();
  const userId = useSelector(selectUserId);
  const userName = useSelector(selectUserName);

  const closePopup = useCallback(() => {
    if (page) {
      page.dispatchEvent(
        new MouseEvent('mousedown', {
          view: window,
          bubbles: true,
          cancelable: true,
        }),
      );
      page.dispatchEvent(
        new MouseEvent('mouseup', {
          view: window,
          bubbles: true,
          cancelable: true,
        }),
      );
    }
  }, [page]);

  const handleMouseDown = useCallback(
    (event: any) => {
      if ((update && isDirty) || areYouSureDelete) {
        if (!areYouSureDelete && isDirty) {
          setAreYouSureLeave(event.target);
        }
        event.stopPropagation();
        event.preventDefault();
        return false;
      } else {
        closePopup();
        document.removeEventListener('mousedown', handleMouseDown, true);
      }
    },
    [isDirty, update, closePopup, areYouSureDelete],
  );

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div
        style={{ position: 'fixed', bottom: 0, top: 0, right: 0, left: 0, zIndex: -1 }}
        onClick={handleMouseDown}
      />
      <div ref={containerRef} style={{ width: '100%', height: '100%', zIndex: 1 }}>
        <Modal
          open={areYouSureDelete || !!areYouSureLeave}
          container={containerRef.current}
          sx={{ position: 'absolute', '& .MuiModal-backdrop': { position: 'absolute' } }}
        >
          <Box className={classes.modal}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{
                textAlign: 'center',
              }}
            >
              {areYouSureDelete ? 'Delete. Are you sure?' : 'Leave without saving?'}
            </Typography>

            <div
              style={{ width: '100%', padding: '1rem', display: 'flex', justifyContent: 'center' }}
            >
              <Button
                style={{ margin: '0.2rem', background: '#4567e5', color: 'white' }}
                onClick={() => {
                  if (areYouSureDelete) deleteAnnotation();
                  else {
                    closePopup();
                    document.removeEventListener('mousedown', handleMouseDown, true);
                  }
                }}
              >
                {T.translate('generic.yes')}
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  if (areYouSureDelete) setAreYouSureDelete(false);
                  else setAreYouSureLeave(null);
                }}
                style={{ margin: '0.2rem', borderColor: '#4567e5' }}
              >
                {T.translate('generic.no')}
              </Button>
            </div>
          </Box>
        </Modal>
        <div
          style={{
            display: 'flex',
            flexDirection: update ? 'column' : 'row',
            ...(update && { padding: '1rem' }),
          }}
        >
          <div style={{ position: 'absolute', right: '0.5rem', top: '0.5rem' }}>
            {!edit && update && (createdBy?.id === userId || !createdBy) && (
              <SimpleTooltip
                title={T.translate(shared ? 'viewing.unshareToTeam' : 'viewing.shareToTeam')}
              >
                <CustomViewerButton
                  onClick={onSharedClick}
                  className={clsx(classes.markButton, shared ? classes.markButtonOrange : null)}
                  icon={faShareAlt}
                />
              </SimpleTooltip>
            )}
            {!edit && update && (createdBy?.id === userId || !createdBy) && (
              <SimpleTooltip title={T.translate('viewing.editAnnotation')}>
                <CustomViewerButton
                  onClick={() => {
                    setEdit(true);
                    if (commentRef.current) {
                      commentRef.current.focus();
                      const caretPosition = commentRef.current.value?.length || 0;
                      commentRef.current.selectionEnd = caretPosition;
                      commentRef.current.selectionStart = caretPosition;
                    }
                    if (selectMark) selectMark();
                  }}
                  className={classes.markButton}
                  icon={faEdit}
                />
              </SimpleTooltip>
            )}
            {edit && update && (
              <SimpleTooltip title={T.translate('viewing.saveAnnotation')}>
                <CustomViewerButton
                  onClick={handleSubmit(onClick)}
                  className={clsx(classes.markButton, {
                    [classes.disabled]: !isValid,
                  })}
                  icon={faUpload}
                />
              </SimpleTooltip>
            )}
            {update && (createdBy?.id === userId || !createdBy) && (
              <SimpleTooltip title={T.translate('viewing.deleteAnnotation')}>
                <CustomViewerButton
                  onClick={() => setAreYouSureDelete(true)}
                  className={classes.markButton}
                  icon={faTrash}
                />
              </SimpleTooltip>
            )}
          </div>
          {update && <Typography variant="h6">{createdBy?.name || userName}</Typography>}
          {update && (
            <Typography style={{ color: 'gray' }} variant="subtitle2">
              {`Created on ${moment(createdBy?.date).utc().format('DD MMM HH:mm')}`}
            </Typography>
          )}
          {update && (
            <Typography style={{ marginTop: '1rem' }} variant="h6" component="h6">
              {'Comment'}
            </Typography>
          )}
          <Controller
            name="comment"
            control={control}
            rules={{ required: !update }}
            render={({ field }: any) => {
              return (
                <TextField
                  {...field}
                  sx={{ padding: '8px 0', flexGrow: 1 }}
                  inputRef={commentRef}
                  autoFocus
                  placeholder={
                    !edit && update
                      ? T.translate('0 comments')
                      : T.translate('case.annotationNotePlaceholder')
                  }
                  margin="none"
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      handleSubmit(onClick)();
                    }
                  }}
                  InputProps={{
                    readOnly: !edit && update,
                    classes: { notchedOutline: classes.noBorder },
                  }}
                  multiline={update}
                  maxRows={5}
                />
              );
            }}
          />
          {update && (
            <Typography style={{ marginTop: '1rem' }} variant="h6" component="h6">
              {'Tags'}
            </Typography>
          )}
          {update && (
            <Controller
              name="tags"
              control={control}
              render={({ field }: any) => {
                return (
                  <TagsSelect
                    {...field}
                    placeholder={
                      !edit && update ? T.translate('0 tags') : T.translate('Select tags')
                    }
                    readOnly={!edit && update}
                  />
                );
              }}
            />
          )}
          {!update && (
            <SimpleTooltip title={T.translate('viewing.createAnnotation')}>
              <CustomViewerButton
                onClick={handleSubmit(onClick)}
                className={clsx(classes.markButton, {
                  [classes.disabled]: !isValid,
                })}
                style={{
                  margin: '0.5rem',
                  width: '2.5rem',
                  borderRadius: '10%',
                  height: '2.5rem',
                  alignSelf: 'center',
                }}
                icon={faUpload}
              />
            </SimpleTooltip>
          )}
        </div>
      </div>
    </div>
  );
};
