import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useFetchSystemStatus } from './redux/fetchSystemStatus';

export default function TopNotificationWrapper({ children }) {
  const [show, setShow] = useState(null);
  const { systemStatus, fetchSystemStatus } = useFetchSystemStatus();
  useEffect(() => {
    fetchSystemStatus().then((message) => setShow(!!(message && message.systemStatus)));
  }, [fetchSystemStatus]);

  return (
    <>
      <div className={classnames({ show }, 'common-top-notification-wrapper')}>
        <div className="message">
          <FontAwesomeIcon
            className="warning"
            icon={faExclamationTriangle}
            alt={faExclamationTriangle.iconName}
          />
          <span>{systemStatus}</span>
        </div>
        <FontAwesomeIcon
          className="button"
          icon={faTimes}
          alt={faTimes.iconName}
          onClick={() => setShow(false)}
        />
      </div>
      {children}
    </>
  );
}

TopNotificationWrapper.propTypes = {};
TopNotificationWrapper.defaultProps = {};
