import React, { useState } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ConnectionFailed } from './ConnectionFailed/ConnectionFailed';
import { ConnectionModal } from './ConnectionModal/ConnectionModal';
import { ConnectionSuccess } from './ConnectionSuccess/ConnectionSuccess';
import { useAppStateContext } from '../../AppStateProvider/AppStateProvider';
import { Spinner } from 'features/common';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textBox: {
    width: '340px',
    height: '48px',
    textAlign: 'center',
  },
});

export function Connectivity() {
  const { state } = useAppStateContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const classes = useStyles();

  const signalingGateway = state.preflightTest.signalingGatewayReachable
    ? 'Reachable'
    : 'Unreachable';
  const turnServers = state.preflightTest.turnServersReachable ? 'Reachable' : 'Unreachable';

  const connectionFailed =
    state.preflightTestFinished &&
    (state.preflightTest.error !== null ||
      !state.preflightTest.signalingGatewayReachable ||
      !state.preflightTest.turnServersReachable);

  return (
    <>
      <ConnectionModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        serviceStatuses={state.twilioStatus}
        signalingGateway={signalingGateway}
        turnServers={turnServers}
      />
      {
        /* If preflight test or bitrate test haven't completed, display loading screen otherwise, display connectivity results: */
        state.preflightTestInProgress || state.bitrateTestInProgress ? (
          <Container>
            <Grid container className={classes.container}>
              <Typography variant="h5" gutterBottom>
                Hang tight!
              </Typography>
              <Typography variant="body1" gutterBottom className={classes.textBox}>
                We are analying your network connectivity.
              </Typography>
              <div>
                <Spinner />
              </div>
            </Grid>
          </Container>
        ) : connectionFailed ? (
          <ConnectionFailed openModal={() => setIsModalOpen(true)} />
        ) : (
          <ConnectionSuccess openModal={() => setIsModalOpen(true)} />
        )
      }
    </>
  );
}
