import React from 'react';
import Select from './Select';
// import PropTypes from 'prop-types';

const MultiSelect = (props) => {
  return <Select {...props} style={{ height: 'auto' }} isMulti={true} isClearable={false} />;
};

MultiSelect.propTypes = {};
MultiSelect.defaultProps = {};

export default MultiSelect;
