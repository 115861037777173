import React, { Component } from 'react';
import T from 'i18n';
import classnames from 'classnames';
import { isMobile } from 'utils';
import { selectCurrentCase } from 'common/selectors';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';

class Footer extends Component {
  static propTypes = {};

  render() {
    const year = new Date().getFullYear();
    const email = T.translate('footer.copyrightEmail');
    return (
      <div className={classnames(this.props.theme, 'footer')}>
        <div className="tc">
          {isMobile ? (
            T.translate('footer.copyrightMobile', {
              year,
            })
          ) : (
            // prettier-ignore
            <Trans i18nKey={'footer.copyright'}>
              Copyright © {{ year }}. All Rights Reserved. Built by TrialView. Please contact us on <a href={`mailto:${email}${this.props.currentCaseName ? `?subject=Case - ${this.props.currentCaseName}` : ''}`}>{{ email }}</a>.
            </Trans>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const currentCase = selectCurrentCase(state);
  return {
    currentCaseName: currentCase.name || null,
  };
};

export default connect(mapStateToProps)(Footer);
