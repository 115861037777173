import { call, put, takeEvery, all, select } from 'redux-saga/effects';
import {
  CASE_ASSIGN_ANNOTATIONS_BEGIN,
  CASE_ASSIGN_ANNOTATIONS_SUCCESS,
  CASE_ASSIGN_ANNOTATIONS_FAILURE,
  CASE_ASSIGN_ANNOTATIONS_DISMISS_FEEDBACK,
} from './constants';
import api from 'common/api';
import { selectAnnotations, withCurrentCaseId } from '../../../common/selectors';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';

export const assignAnnotations = createPromiseAction(CASE_ASSIGN_ANNOTATIONS_BEGIN);

export function dismissAssignAnnotationsFeedback() {
  return {
    type: CASE_ASSIGN_ANNOTATIONS_DISMISS_FEEDBACK,
  };
}

export function* doAssignAnnotations(action) {
  const {
    payload: { annotationIds, tagId, caseId },
  } = action;
  const allAnnotations = yield select(selectAnnotations);
  // if no folder no assign
  if (!tagId) {
    return yield put({
      type: CASE_ASSIGN_ANNOTATIONS_FAILURE,
      feedback: {
        message: 'feedback.assignAnnotationsFailure',
        params: { unsuccessful: annotationIds.length },
        error: 'No folder id specified',
      },
    });
  }

  const annotations = allAnnotations.filter((itm) =>
    annotationIds.includes(itm.compositeKey || itm.id),
  );

  const res = yield call(api.post, `/cases/${caseId}/tags/${tagId}/locations`, annotations);

  if (res && res.error) {
    yield put({
      type: CASE_ASSIGN_ANNOTATIONS_FAILURE,
      feedback: {
        message: 'feedback.assignAnnotationsFailure',
        params: { unsuccessful: annotationIds.length },
        error: res.error,
      },
      /*retryAction: action,*/
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield all(
    res.map((doc) =>
      put({
        type: CASE_ASSIGN_ANNOTATIONS_SUCCESS,
        data: { document: doc },
      }),
    ),
  );
  yield call(resolvePromiseAction, action, res);
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchAssignAnnotations() {
  yield takeEvery(assignAnnotations, withCurrentCaseId(doAssignAnnotations));
}

// Redux reducer
export function reducer(state, action) {
  let assignAnnotationsTempFeed;
  let annotationsLeft;
  switch (action.type) {
    case CASE_ASSIGN_ANNOTATIONS_BEGIN + '.TRIGGER':
      return {
        ...state,
        assignAnnotationsPending:
          state.assignAnnotationsPending + action.payload.annotationIds.length,
        // clear only if batch finished
        ...(!state.assignAnnotationsPending && { assignAnnotationsFeedback: null }),
      };

    case CASE_ASSIGN_ANNOTATIONS_SUCCESS:
      annotationsLeft = state.assignAnnotationsPending - 1;
      assignAnnotationsTempFeed = {
        message:
          state.assignAnnotationsTempFeed &&
          state.assignAnnotationsTempFeed.params &&
          state.assignAnnotationsTempFeed.params.unsuccesful
            ? 'feedback.assignAnnotationsFailureAndSuccess'
            : 'feedback.assignAnnotationsSuccess',
        params: {
          ...(state.assignAnnotationsTempFeed && state.assignAnnotationsTempFeed.params),
          successful:
            ((state.assignAnnotationsTempFeed &&
              state.assignAnnotationsTempFeed.params &&
              state.assignAnnotationsTempFeed.params.successful) ||
              0) + 1,
        },
      };
      return {
        ...state,
        assignAnnotationsPending: annotationsLeft,
        assignAnnotationsTempFeed,
        ...(!annotationsLeft && {
          assignAnnotationsFeedback: assignAnnotationsTempFeed,
          assignAnnotationsTempFeed: null,
        }),
      };

    case CASE_ASSIGN_ANNOTATIONS_FAILURE:
      return {
        ...state,
        assignAnnotationsPending: false,
        assignAnnotationsFeedback: action.feedback,
      };

    case CASE_ASSIGN_ANNOTATIONS_DISMISS_FEEDBACK:
      return {
        ...state,
        assignAnnotationsFeedback: null,
      };

    default:
      return state;
  }
}
