import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faTimes,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import ReactResizeDetector from 'react-resize-detector';

const Icon = ({ icon, ...props }) => <FontAwesomeIcon {...props} icon={icon} alt={icon.iconName} />;
const AlertIcon = (props) => <Icon {...props} icon={faExclamationTriangle} />;
const SuccessIcon = (props) => <Icon {...props} icon={faCheckCircle} />;
const ErrorIcon = (props) => <Icon {...props} icon={faExclamationCircle} />;
const InfoIcon = (props) => <Icon {...props} icon={faInfoCircle} />;
const CloseIcon = (props) => <Icon {...props} icon={faTimes} />;

const appearances = {
  success: {
    icon: SuccessIcon,
    fg: '#36B37E',
  },
  error: {
    icon: ErrorIcon,
    fg: '#FF5630',
  },
  warning: {
    icon: AlertIcon,
    fg: '#FFAB00',
  },
  info: {
    icon: InfoIcon,
    fg: '#2684FF',
  },
  infoStrong: {
    icon: InfoIcon,
    fg: '#2684FF',
  },
};

const CloseButton = (props) => (
  <div className="react-toast-notifications__toast__dismiss-button-container">
    <div role="button" className="react-toast-notifications__toast__dismiss-button" {...props} />
  </div>
);

const Content = (props) => <div className="react-toast-notifications__toast__content" {...props} />;

// NOTE: invoke animation when NOT `autoDismiss` with opacity of 0 to avoid a
// paint bug in FireFox.
// https://bugzilla.mozilla.org/show_bug.cgi?id=625289
const Countdown = ({ autoDismissTimeout, opacity, isRunning, ...props }) => (
  <div
    className="react-toast-notifications__toast__countdown"
    style={{
      animationPlayState: isRunning ? 'running' : 'paused',
      animationDuration: `${autoDismissTimeout}ms`,
      opacity,
    }}
    {...props}
  />
);

const SideIndicator = ({ appearance, autoDismiss, autoDismissTimeout, isRunning }) => {
  const meta = appearances[appearance];

  return (
    <>
      <div
        className="react-toast-notifications__toast__side"
        style={{
          backgroundColor: meta.fg,
        }}
      >
        <Countdown
          opacity={autoDismiss ? 1 : 0}
          autoDismissTimeout={autoDismissTimeout}
          isRunning={isRunning}
        />
      </div>
    </>
  );
};

const IconWrapper = ({ appearance }) => {
  const meta = appearances[appearance];
  const Glyph = meta.icon;

  return (
    <div className="react-toast-notifications__toast__icon-wrapper">
      <Glyph className="react-toast-notifications__toast__icon" style={{ color: meta.fg }} />
    </div>
  );
};

function getTranslate(placement) {
  const pos = placement.split('-');
  const relevantPlacement = pos[1] === 'center' ? pos[0] : pos[1];
  const translateMap = {
    right: 'translate3d(120%, 0, 0)',
    left: 'translate3d(-120%, 0, 0)',
    bottom: 'translate3d(0, 120%, 0)',
    top: 'translate3d(0, -120%, 0)',
  };

  return translateMap[relevantPlacement];
}

const toastStates = (placement) => ({
  entering: { transform: getTranslate(placement) },
  entered: { transform: 'translate3d(0,0,0)' },
  exiting: { transform: 'scale(0.66)', opacity: 0 },
  exited: { transform: 'scale(0.66)', opacity: 0 },
});

const ToastElement = ({ appearance, placement, transitionDuration, transitionState, ...props }) => {
  const [height, setHeight] = useState(0);
  const [elementOffsetHeight, setElementOffsetHeight] = useState();

  useEffect(() => {
    if (transitionState === 'entered') {
      setHeight(elementOffsetHeight + 4);
    }
    if (transitionState === 'exiting') {
      setHeight(0);
    }
  }, [transitionState, transitionDuration, elementOffsetHeight]);

  return (
    <div
      style={{
        height: !Number.isNaN(height) ? height : 0,
        transition: `height ${transitionDuration - 100}ms 100ms`,
      }}
    >
      <ReactResizeDetector handleHeight>
        {({ height: H }) => {
          return (
            <div
              ref={() => H && !Number.isNaN(H) && setElementOffsetHeight(H)}
              className={`react-toast-notifications__toast react-toast-notifications__toast--${appearance}`}
              style={{
                color: appearances[appearance].text,
                transition: `transform ${transitionDuration}ms cubic-bezier(0.2, 0, 0, 1), opacity ${transitionDuration}ms`,
                ...toastStates(placement)[transitionState],
              }}
              {...props}
            />
          );
        }}
      </ReactResizeDetector>
      ;
    </div>
  );
};

const CustomToast = ({
  appearance,
  autoDismiss,
  autoDismissTimeout,
  children,
  isRunning,
  onDismiss,
  placement,
  transitionDuration,
  transitionState,
  onMouseEnter,
  onMouseLeave,
  ...otherProps
}) => {
  return (
    <ToastElement
      appearance={appearance}
      placement={placement}
      transitionState={transitionState}
      transitionDuration={transitionDuration}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...otherProps}
    >
      <SideIndicator
        appearance={appearance}
        autoDismiss={autoDismiss}
        autoDismissTimeout={autoDismissTimeout}
        isRunning={isRunning}
      />
      <div className="react-toast-notifications__toast__content-wrapper">
        <IconWrapper appearance={appearance} />
        <Content>{children}</Content>
        {onDismiss && appearance !== 'infoStrong' ? (
          <CloseButton onClick={onDismiss}>
            <CloseIcon className="react-toast-notifications__toast__dismiss-icon" />
          </CloseButton>
        ) : null}
      </div>
    </ToastElement>
  );
};

CustomToast.defaultProps = {
  onDismiss: () => {},
};

export default CustomToast;
