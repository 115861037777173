import {
  CASE_UPDATE_TEAM_BEGIN,
  CASE_UPDATE_TEAM_SUCCESS,
  CASE_UPDATE_TEAM_FAILURE,
  CASE_UPDATE_TEAM_DISMISS_FEEDBACK,
} from './constants';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';
import { replaceItemImmutable } from 'utils/arrays';

export const updateTeam = createPromiseAction(CASE_UPDATE_TEAM_BEGIN);

export function dismissUpdateTeamFeedback() {
  return {
    type: CASE_UPDATE_TEAM_DISMISS_FEEDBACK,
  };
}

export function* doUpdateTeam(action) {
  const { caseId, team } = action.payload;
  let res = yield call(api.put, `/cases/${caseId}/teams/${team.id}`, team);

  if (res && res.error) {
    yield put({
      type: CASE_UPDATE_TEAM_FAILURE,
      feedback: { message: 'feedback.updateTeamFailure', error: res.error, retryAction: action },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_UPDATE_TEAM_SUCCESS,
    data: { team: res },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchUpdateTeam() {
  yield takeLatest(updateTeam, withCurrentCaseId(doUpdateTeam));
}

export function useUpdateTeam() {
  const dispatch = useDispatch();

  const { updateTeamPending, updateTeamFeedback } = useSelector(
    (state) => ({
      updateTeamPending: state.case.updateTeamPending,
      updateTeamFeedback: state.case.updateTeamFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(updateTeam(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissUpdateTeamFeedback());
  }, [dispatch]);

  return {
    updateTeam: boundAction,
    updateTeamPending,
    updateTeamFeedback,
    dismissUpdateTeamFeedback: boundDismissFeedback,
  };
}

export function reducer(state, action) {
  const getIndex = (teamId) => state.teams.findIndex(({ id }) => id === teamId);
  const getNewTeams = (newEtag, teamId) =>
    state.teams.map((team) => {
      if (teamId === team.id) return { ...team, etag: newEtag };
      else return team;
    });
  switch (action.type) {
    case CASE_UPDATE_TEAM_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        updateTeamPending: true,
        updateTeamFeedback: null,
      };

    case CASE_UPDATE_TEAM_SUCCESS:
      // The request is success
      return {
        ...state,
        updateTeamPending: false,
        updateTeamFeedback: action.feedback,
        teams: replaceItemImmutable(
          getNewTeams(action.data.team.etag, action.data.team.id),
          action.data.team,
          getIndex(action.data.team.id),
        ),
      };

    case CASE_UPDATE_TEAM_FAILURE:
      // The request is failed
      const concurencyError = action.feedback.error.status === 412;
      return {
        ...state,
        updateTeamPending: false,
        updateTeamFeedback: action.feedback,
        ...(concurencyError && {
          teams: replaceItemImmutable(
            getNewTeams(action.feedback.error.body.etag),
            action.feedback.error.body,
            getIndex(action.feedback.error.body.id),
          ),
        }),
      };

    case CASE_UPDATE_TEAM_DISMISS_FEEDBACK:
      // Dismiss the request failure error
      return {
        ...state,
        updateTeamFeedback: null,
      };

    default:
      return state;
  }
}
