import React, { useState, useCallback, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { Close, Edit, Undo } from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';
import api from 'common/api';
import T from 'i18n';
import { parseCSV } from './utils';
import { selectCurrentCaseId, selectCurrentFolderId } from 'common/selectors';
import { useSelector, useDispatch } from 'react-redux';
import PreviewAssignFiles from './PreviewAssignFiles';
import * as actions from 'features/case/redux/actions';
import { Spinner } from 'features/common';

type Props = {
  show: boolean;
  handleClose: () => void;
};

const FILE_IDENTIFIER_CHOICES = ['id', 'externalId', 'discoveryId'];

const AssignFilesFromCSV: React.FC<Props> = ({ show, handleClose }) => {
  const caseId = useSelector(selectCurrentCaseId);
  const folderId = useSelector(selectCurrentFolderId);
  const dispatch = useDispatch();

  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [columns, setColumns] = useState<string[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [selectedColumn, setSelectedColumn] = useState<string>('');
  const [fileIdentifier, setFileIdentifier] = useState<string>('');

  const [matchedDocs, setMatchedDocs] = useState<any[]>([]);
  const [unmatchedDocs, setUnmatchedDocs] = useState<any[]>([]);

  const [loadingPreview, setLoadingPreview] = useState(false);
  const [assigning, setAssigning] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const [undoStack, setUndoStack] = useState<any[]>([]);
  const [isEditing, setIsEditing] = useState(false);

  // Clear state
  const clearAll = () => {
    setCsvFile(null);
    setCsvData([]);
    setColumns([]);
    setSelectedColumn('');
    setFileIdentifier('');
    setMatchedDocs([]);
    setUnmatchedDocs([]);
    setErrorMsg(null);
    setUndoStack([]);
    setIsEditing(false);
  };

  const onClose = () => {
    clearAll();
    handleClose();
  };

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (!acceptedFiles?.length) return;
    const file = acceptedFiles[0];
    setCsvFile(file);

    try {
      const text = await file.text();
      const parsedRows = parseCSV(text);
      setCsvData(parsedRows);
      setColumns(Object.keys(parsedRows[0] || {}));
      setErrorMsg(null);
    } catch (err) {
      if (err instanceof Error) {
        setErrorMsg(err.message || T.translate('case.assign.dialog.errorParsingCSV'));
      } else {
        setErrorMsg(T.translate('case.assign.dialog.errorParsingCSV'));
      }
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: ['.csv'],
    disabled: !!csvFile,
  });

  useEffect(() => {
    const autoPreview = async () => {
      if (!fileIdentifier || !selectedColumn || !csvData.length) return;
      try {
        setErrorMsg(null);
        setLoadingPreview(true);

        const arrayOfIDs = csvData.map((row) => row[selectedColumn]?.trim?.()).filter(Boolean);
        if (!arrayOfIDs.length) {
          setErrorMsg(T.translate('case.assign.dialog.noValidIDsFound'));
          setLoadingPreview(false);
          return;
        }

        const requestBody = { fileIdentifier, data: arrayOfIDs };
        const foundDocs = await api.post(`/cases/${caseId}/find-files`, requestBody);

        if (!Array.isArray(foundDocs)) {
          setErrorMsg(T.translate('case.assign.dialog.unexpectedResponseFormat'));
          setLoadingPreview(false);
          return;
        }

        setMatchedDocs(foundDocs);
        const matchedIDs = foundDocs.map((doc: any) => doc[fileIdentifier]);
        const unmatchedIDs = arrayOfIDs.filter((id) => !matchedIDs.includes(id));
        setUnmatchedDocs(unmatchedIDs.map((id) => ({ [fileIdentifier]: id })));
      } catch (err) {
        if (err instanceof Error) {
          setErrorMsg(err.message || T.translate('case.assign.dialog.errorGeneratingPreview'));
        } else {
          setErrorMsg(T.translate('case.assign.dialog.errorGeneratingPreview'));
        }
      } finally {
        setLoadingPreview(false);
      }
    };

    autoPreview();
  }, [fileIdentifier, selectedColumn, csvData, caseId]);

  // Deletion from matched docs
  const handleDeleteRow = (index: number) => {
    const docToRemove = matchedDocs[index];
    setUndoStack((prev) => [...prev, docToRemove]);
    setMatchedDocs((prev) => {
      const updated = [...prev];
      updated.splice(index, 1);
      return updated;
    });
  };

  const handleUndo = () => {
    setUndoStack((prev) => {
      if (!prev.length) return prev;
      const clone = [...prev];
      const lastDeleted = clone.pop();

      setMatchedDocs((m) => [...m, lastDeleted]);

      return clone;
    });
  };

  const handleAssign = async () => {
    if (!matchedDocs.length) {
      setErrorMsg(T.translate('case.assign.dialog.noMatchedFilesForAssign'));
      return;
    }
    try {
      setAssigning(true);
      setErrorMsg(null);

      const docsToAssign = matchedDocs.map((doc: any) => ({ id: doc[fileIdentifier] }));
      await api.post(
        `/cases/${caseId}/folders/${folderId}/files?includeFileList=false`,
        docsToAssign,
      );

      setTimeout(() => {
        dispatch(actions.fetchDocuments({} as any));
      }, 1000);

      onClose();
    } catch (err) {
      if (err instanceof Error) {
        setErrorMsg(err.message || T.translate('case.assign.dialog.errorAssigningFiles'));
      } else {
        setErrorMsg(T.translate('case.assign.dialog.errorAssigningFiles'));
      }
    } finally {
      setAssigning(false);
    }
  };

  if (!show) return null;

  return (
    <Dialog open={show} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">
          {T.translate('case.assign.dialog.title', { defaultValue: 'Assign Files from CSV' })}
        </Typography>
        <IconButton onClick={onClose} aria-label="close" size="small">
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ minHeight: 500, display: 'flex', flexDirection: 'column' }}>
        {!csvFile && (
          <Box
            {...getRootProps()}
            sx={{
              border: `2px dashed ${isDragActive ? '#0078d4' : '#ccc'}`,
              p: 2,
              textAlign: 'center',
              borderRadius: 2,
              my: 2,
              cursor: 'pointer',
              backgroundColor: isDragActive ? '#f0f8ff' : 'transparent',
            }}
          >
            <input {...getInputProps()} />
            <Typography variant="body2">
              {T.translate('case.assign.dialog.csvDropzonePlaceholder')}
            </Typography>
          </Box>
        )}

        {csvFile && (
          <Box sx={{ my: 2, flexDirection: 'row', display: 'flex' }}>
            <Typography>
              {T.translate('case.assign.dialog.selectedCSV')} {csvFile.name}
            </Typography>
            <Button variant="outlined" onClick={clearAll} sx={{ ml: 2 }}>
              {T.translate('case.assign.dialog.reset')}
            </Button>
          </Box>
        )}

        {errorMsg && (
          <Typography color="error" variant="body2" sx={{ mt: 1 }}>
            {errorMsg}
          </Typography>
        )}

        {csvFile && (
          <FormControl margin="normal" fullWidth size="small">
            <InputLabel id="fileIdentifierLabel">
              {T.translate('case.assign.dialog.fileIdentifierLabel')}
            </InputLabel>
            <Select
              labelId="fileIdentifierLabel"
              value={fileIdentifier}
              label="File Identifier"
              onChange={(evt) => setFileIdentifier(evt.target.value as string)}
            >
              {FILE_IDENTIFIER_CHOICES.map((choice) => (
                <MenuItem key={choice} value={choice}>
                  {choice}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {fileIdentifier && columns.length > 0 && (
          <FormControl margin="normal" fullWidth size="small">
            <InputLabel id="csv-column-label">
              {T.translate('case.assign.dialog.csvColumnLabel')}
            </InputLabel>
            <Select
              labelId="csv-column-label"
              label="CSV Column"
              value={selectedColumn}
              onChange={(evt) => setSelectedColumn(evt.target.value as string)}
            >
              {columns.map((col) => (
                <MenuItem key={col} value={col}>
                  {col}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <Box sx={{ flex: 1, overflow: 'auto', mt: 2, border: '1px solid #ccc' }}>
          {loadingPreview ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <Spinner />
            </Box>
          ) : fileIdentifier && selectedColumn ? (
            <Box padding={1} minWidth="800px">
              <Box display="flex" alignItems="center" mb={1}>
                <Typography variant="h6" sx={{ mr: 1 }}>
                  {T.translate('case.assign.dialog.matchedFilesHeader')}({matchedDocs.length})
                </Typography>
                <IconButton
                  size="small"
                  onClick={() => setIsEditing((e) => !e)}
                  title={T.translate('case.assign.dialog.toggleEditMode')}
                  color={isEditing ? 'primary' : 'default'}
                  sx={{ ml: 1 }}
                >
                  <Edit />
                </IconButton>
                {undoStack.length > 0 && (
                  <IconButton
                    size="small"
                    onClick={handleUndo}
                    title={T.translate('case.assign.dialog.undoLastDelete')}
                    sx={{ ml: 1 }}
                  >
                    <Undo />
                  </IconButton>
                )}
              </Box>

              {matchedDocs.length > 0 ? (
                <PreviewAssignFiles
                  docs={matchedDocs}
                  isEditing={isEditing}
                  onDeleteRow={handleDeleteRow}
                />
              ) : (
                <Typography variant="body2" sx={{ mb: 2 }}>
                  {T.translate('case.assign.dialog.noMatchedDocsRemain')}
                </Typography>
              )}

              <Typography variant="h6" sx={{ mt: 3 }}>
                {T.translate('case.assign.dialog.unmatchedIdsHeader')}({unmatchedDocs.length})
              </Typography>
              {unmatchedDocs.length > 0 ? (
                <PreviewAssignFiles docs={unmatchedDocs} />
              ) : (
                <Typography variant="body2" sx={{ mb: 2 }}>
                  {T.translate('case.assign.dialog.noUnmatchedIdsRemain')}
                </Typography>
              )}
            </Box>
          ) : null}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          disabled={!matchedDocs.length || assigning}
          onClick={handleAssign}
        >
          {assigning ? (
            <CircularProgress size={16} />
          ) : (
            T.translate('assignFromCSV.assign', { defaultValue: 'Assign' })
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignFilesFromCSV;
