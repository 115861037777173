import React, { useState } from 'react';
import T from 'i18n';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Switch from '@mui/material/Switch';
import Badge from '@mui/material/Badge';
import { Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { FiltersStyles } from 'features/common/Filters/FiltersStyles.js';

const FilterRoles = ({ defaultFilters, filterHandler }) => {
  const classes = FiltersStyles();
  const [filterCategory, setFilterCategory] = useState(defaultFilters);
  const [dropDown, setDropDown] = useState(true);
  const permissionCatFilters = ['documents', 'folders', 'hearingRoom', 'admin'];

  const handleToggle = (e) => {
    const newFilterCategory = { ...filterCategory, [e.target.name]: e.target.checked };
    setFilters(newFilterCategory);
  };

  const setFilters = (newFilterCategory) => {
    setFilterCategory(newFilterCategory);
    filterHandler(newFilterCategory);
  };

  const clearFilters = () => {
    const clearFilter = {
      documents: false,
      folders: false,
      hearingRoom: false,
      admin: false,
    };
    setFilterCategory(clearFilter);
    filterHandler(clearFilter);
  };

  const handleDropDown = () => {
    setDropDown(!dropDown);
  };

  return (
    <Box>
      <List
        subheader={
          <ListSubheader className={classes.listSubheaderHeading}>
            {T.translate('filters.filters')}
            <Button
              variant="contained"
              size="small"
              color="primary"
              className={classes.clearButton}
              onClick={clearFilters}
            >
              {T.translate('filters.clear')}
            </Button>
          </ListSubheader>
        }
        className={classes.paper}
      >
        <Divider />
        <ListItem button={true} onClick={() => handleDropDown()}>
          <ListItemText primary={T.translate('filters.permissionsCat')} />
          <Badge
            variant="dot"
            color="primary"
            overlap="circular"
            badgeContent={
              filterCategory.documents || filterCategory.folders || filterCategory.hearingRoom
                ? 1
                : 0
            }
          >
            {dropDown ? <ExpandMore /> : <ExpandLess />}
          </Badge>
        </ListItem>
        <Collapse in={dropDown} timeout="auto" unmountOnExit>
          {filterCategory &&
            permissionCatFilters.map((filter, index) => (
              <ListItem key={index} style={{ paddingLeft: '2rem' }}>
                <ListItemText primary={T.translate(`filters.${filter}`)} />
                <ListItemSecondaryAction>
                  <Switch
                    name={filter}
                    size="small"
                    color="primary"
                    onChange={handleToggle}
                    checked={filterCategory[filter]}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
        </Collapse>
      </List>
    </Box>
  );
};

export default FilterRoles;
