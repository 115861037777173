/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useOriginalCopy } from '@huse/previous-value';
import { usePrevious, useFunctionAsState } from 'utils/hooks';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import ListRow from './ListRow';
import DroppableBody from './DroppableBody';
import moment from 'moment';
import { getSortFunction } from './utils';
import DroppableBodyContainerProvider from './DroppableBodyContainerProvider';
import CustomScrollbarsVirtualList, { ScrollDataContext } from './CustomScrollbarsVirtualList';
import NoDataMessage from './NoDataMessage';
import _ from 'lodash';
import T from 'i18n';
import equal from 'react-fast-compare';
import { isObject } from 'utils/object';
import { isEmpty } from 'utils/objects';
import { useDispatch } from 'react-redux';
import { updateDocument } from 'features/case/redux/updateDocument';
import { updateDocumentMeta } from 'features/case/redux/updateDocumentMeta';
import { useSelector } from 'react-redux';
import { selectBundleTabPage, selectDataMapFailedRows } from 'common/selectors';

export const getStringValue = (row: any, column: any) => {
  const id = typeof column === 'object' ? column.id : column;
  const value = typeof column === 'object' ? column.accessor(row) : row[id];

  if (!value) return '';

  if (typeof value === 'object') return JSON.stringify(value);

  return value.toString();
};

type FilterProps = {
  data: Array<any>;
  filter: any;
  filterByColumns: Array<any>;
  isBundleTabPage: boolean;
};

// TO-DO remove business logic - no filter by this by that shite

const filterByGlobalPageIdx = (row: any, goToGlobalPageIdx: any) => {
  if (goToGlobalPageIdx) {
    if (!goToGlobalPageIdx.includes('.') && !row['startPage'].includes('.')) {
      const startPage = parseInt(row['startPage'], 10);
      const endPage = parseInt(row['lastPage'], 10);
      const goToPage = parseInt(goToGlobalPageIdx, 10);
      return goToPage >= startPage && goToPage <= endPage;
    } else if (
      (!goToGlobalPageIdx.includes('.') && row['startPage'].includes('.')) ||
      (goToGlobalPageIdx.includes('.') && !row['startPage'].includes('.'))
    ) {
      return false;
    } else if (goToGlobalPageIdx.includes('.') && row['startPage'].includes('.')) {
      const goToGlobalPage = goToGlobalPageIdx.split('.');
      const startPageVal = row['startPage'].split('.');
      const endPageVal = row['lastPage'].split('.');
      const isStartPageFirstDigitAfterDecimalZero =
        startPageVal && startPageVal.length > 1 && startPageVal[1].startsWith('0');
      const isFilterTermFirstDigitAfterDecimalZero =
        goToGlobalPage && goToGlobalPage.length > 1 && goToGlobalPage[1].startsWith('0');
      if (goToGlobalPage.length === 2 && goToGlobalPage[0] === startPageVal[0]) {
        if (isStartPageFirstDigitAfterDecimalZero && isFilterTermFirstDigitAfterDecimalZero) {
          //condition to filter late late inserts - when startpage starts with e.g. 55.01
          const startPage = startPageVal[1].slice(1, startPageVal[1].length);
          const goToPage = goToGlobalPage[1].slice(1, goToGlobalPage[1].length);
          const endPage = endPageVal[1].slice(1, endPageVal[1].length);
          return goToPage >= startPage && goToPage <= endPage;
        } else if (
          !isStartPageFirstDigitAfterDecimalZero &&
          !isFilterTermFirstDigitAfterDecimalZero
        ) {
          const startPage = parseInt(startPageVal[1], 10);
          const endPage = parseInt(endPageVal[1], 10);
          const goToPage = parseInt(goToGlobalPage[1], 10);
          return goToPage >= startPage && goToPage <= endPage;
        }
      }
      return false;
    }
  }
  return true;
};

const filterByArray = (row: any, arr: any, rowKey: any) => {
  if (arr && Object.entries(arr).length > 0) {
    const array: string[] = [];
    for (const key in arr) {
      if (!arr[key]) continue;
      const item = arr[key].toLowerCase();
      array.push(item);
    }
    return (
      row[rowKey] &&
      array.find((item: any) =>
        row[rowKey] && Array.isArray(row[rowKey])
          ? row[rowKey].map((i: any) => i && i.toLowerCase()).includes(item)
          : row[rowKey].toLowerCase().includes(item),
      )
    );
  } else return true;
};

const filterByDate = (row: any, dateFrom: any, dateTo: any) => {
  const rowDate = row['docDate']
    ? new Date(moment(row['docDate'], 'DD/MM/YYYY HH:mm:ss').toDate())
    : null;

  const startDate = dateFrom ? new Date(dateFrom) : null;
  const endDate = dateTo ? new Date(dateTo) : null;

  if (startDate && endDate) {
    return rowDate ? rowDate >= startDate && rowDate <= endDate : false;
  } else if (startDate) {
    return rowDate ? rowDate >= startDate : false;
  } else if (endDate) {
    return rowDate ? rowDate <= endDate : false;
  }
  return true;
};

const filterByAnnotations = (row: any, withAnnotations: any, withoutAnnotations: any) => {
  if (withAnnotations) return row['hasAnnotations'];
  else if (withoutAnnotations) return !row['hasAnnotations'];
  else return true;
};

const filterByShared = (row: any, shared: any, notShared: any) => {
  if (shared) return row['shared'];
  else if (notShared) return !row['shared'];
  else return true;
};

const filterByVisibility = (row: any, publicFiles: any, privateFiles: any) => {
  if (publicFiles) return !row['private'];
  else if (privateFiles) return row['private'];
  else return true;
};

const filterByPublicHyperlinks = (
  row: any,
  withPublicHyperlinks: any,
  withoutPublicHyperlinks: any,
) => {
  if (withPublicHyperlinks) return row['hasPublicHyperlinks'];
  else if (withoutPublicHyperlinks) return !row['hasPublicHyperlinks'];
  else return true;
};

export const filterByTabAndPage = (row: any, filterVal: any, isBundleTabPage: boolean) => {
  const pageNo = filterVal.length > 2 ? parseInt(filterVal[2], 10) : 1;
  if (getStringValue(row, 'tab').toLowerCase() === filterVal[1].toLowerCase()) {
    if (isBundleTabPage) {
      if (getStringValue(row, 'globalPagePrefix').toLowerCase() === filterVal[0].toLowerCase()) {
        return pageNo >= 1 && pageNo <= parseInt(row['pageCount'], 10);
      } else return false;
    }
    return pageNo >= 1 && pageNo <= parseInt(row['pageCount'], 10);
  } else return false;
};

export const filterByLocalPage = (row: any, filterVal: any) => {
  const pageNo = parseInt(filterVal[1], 10);
  if (getStringValue(row, 'id').toLowerCase().includes(filterVal[0].toLowerCase())) {
    return pageNo >= 1 && pageNo <= parseInt(row['pageCount'], 10);
  } else return false;
};

// 1. Update filterByGlobalPage method (around line 172)
export const filterByGlobalPage = (row: any, filterTerm: any) => {
  if (filterTerm) {
    // Check for the complex global page formats with dots (A9.99, A99.99, etc.)
    if (/^[a-zA-Z][A-Za-z0-9]*(\.\d{1,2})?$/.test(filterTerm) && !!row['globalPagePrefix']) {
      // Check if the filter term starts with the document's global page prefix
      const rowPrefix = row.globalPagePrefix.toLowerCase();

      // Case 1: Exact prefix match (like "G4.1" or "A99.99" matching a document with that prefix)
      if (filterTerm.toLowerCase() === rowPrefix) {
        return true;
      }

      // Case 2: Prefix + page number (like "G4.11" where "G4.1" is the prefix)
      if (filterTerm.toLowerCase().startsWith(rowPrefix)) {
        const valueAfterPrefix = filterTerm.toLowerCase().replace(rowPrefix, '');

        // If there's a value after the prefix, it should be a page number
        if (valueAfterPrefix && !isNaN(Number(valueAfterPrefix))) {
          return filterByGlobalPageIdx(row, valueAfterPrefix);
        }
      }

      // No match for this document
      return false;
    }

    // Legacy pattern (kept for backward compatibility)
    else if (/^[a-zA-Z]{1,2}[0-9.]{1,9}?$/.test(filterTerm) && !!row['globalPagePrefix']) {
      const index = filterTerm.toLowerCase().indexOf(row.globalPagePrefix.toLowerCase());
      if (index === 0) {
        const valueAfterPrefix = filterTerm
          .toLowerCase()
          .replace(row.globalPagePrefix.toLowerCase(), '');
        if (!isNaN(Number(valueAfterPrefix))) {
          return filterByGlobalPageIdx(row, valueAfterPrefix);
        } else return false;
      } else return false;
    }

    return false;
  }
  return true;
};

export const filterByTrialBundles = (row: any, arr: any) => {
  if (arr && Object.entries(arr).length > 0) {
    const array: string[] = [];
    for (const key in arr) {
      if (!arr[key]) continue;
      const item = arr[key];
      array.push(item);
    }

    return !!(
      row.bundleLocations.length > 0 &&
      row.bundleLocations.find((itm: any) => array.includes(itm.folderId))
    );
  } else return true;
};
export const filterByTeamBundles = (row: any, arr: any) => {
  if (arr && Object.entries(arr).length > 0) {
    const array: string[] = [];
    for (const key in arr) {
      if (!arr[key]) continue;
      const item = arr[key];
      array.push(item);
    }

    return !!(
      row.bundleLocations.length > 0 &&
      row.bundleLocations.find((itm: any) => array.includes(itm.folderId))
    );
  } else return true;
};

export const filterByPrivateBundles = (row: any, arr: any) => {
  if (arr && Object.entries(arr).length > 0) {
    const array: string[] = [];
    for (const key in arr) {
      if (!arr[key]) continue;
      const item = arr[key];
      array.push(item);
    }

    return !!(
      row.bundleLocations.length > 0 &&
      row.bundleLocations.find((itm: any) => array.includes(itm.folderId))
    );
  } else return true;
};

export const filterByFilterTerm = (
  row: any,
  filterByColumns: any,
  term: string,
  isBundleTabPage: boolean,
) => {
  // Return true for empty terms
  if (!term || term.trim() === '') return true;

  // Trim the term to remove any whitespace
  term = term.trim();

  // Get columns to search in
  const columns = filterByColumns ? filterByColumns : Object.keys(row);

  // Bypass special format checks for short text or partial words that don't match special formats
  const isSimpleTextSearch =
    // Single letters or short words (less than 3 chars)
    term.length < 3 ||
    // Basic words (for "Day", "Da", etc.) - standard text without special format chars
    /^[a-zA-Z\s]+$/.test(term) ||
    // Words with hyphen but not special format patterns
    (/^[a-zA-Z\s-]+$/.test(term) && !term.includes('/'));

  // For simple text searches, go directly to text search
  if (isSimpleTextSearch) {
    for (let i = 0; i < columns.length; i++) {
      const column = columns[i];
      if (!row.hasOwnProperty(column)) {
        // Special case for recipient
        if (column === 'recipient' && row.recipient === null && row.recipientsLists) {
          if (
            row.recipientsLists.some((recipient: string) =>
              recipient.toLowerCase().includes(term.toLowerCase()),
            )
          ) {
            return true;
          }
        }
        continue;
      }

      const value = getStringValue(row, column);
      if (value.toLowerCase().includes(term.toLowerCase())) {
        return true;
      }
    }
    return false;
  }

  // SPECIAL FORMAT CHECKS - Only run these for terms that match special patterns

  // Format 1: Complex prefix with tab like "G4.1/2" or "A99.99/5" or "F1/5.1"
  if (/^[A-Za-z][A-Za-z0-9]*(\.\d{1,2})?\/\d+(\.\d+)?(?:\/\d+)?$/.test(term)) {
    const parts = term.split('/');
    const complexPrefix = parts[0]; // "G4.1" or "A99.99" or "F1"
    const tabNumber = parts[1]; // "2" or "5" or "5.1"
    const pageNumber = parts.length > 2 ? parseInt(parts[2], 10) : null;

    if (
      row.globalPagePrefix &&
      row.globalPagePrefix.toLowerCase() === complexPrefix.toLowerCase() &&
      getStringValue(row, 'tab').toLowerCase() === tabNumber.toLowerCase()
    ) {
      if (pageNumber !== null) {
        return pageNumber >= 1 && pageNumber <= parseInt(row.pageCount || '1', 10);
      }
      return true;
    }
    return false;
  }

  // Format 2: Simple prefix with tab like "E/3/5" or "E/3.5"
  if (/^[A-Za-z]\/\d+(\.\d+)?(?:\/\d+)?$/.test(term)) {
    const parts = term.split('/');
    const prefix = parts[0]; // E
    const tabNumber = parts[1]; // 3 or 3.5
    const pageNumber = parts.length > 2 ? parseInt(parts[2], 10) : null; // 5 or null

    if (
      row.globalPagePrefix &&
      row.globalPagePrefix.toLowerCase() === prefix.toLowerCase() &&
      getStringValue(row, 'tab').toLowerCase() === tabNumber.toLowerCase()
    ) {
      if (pageNumber !== null) {
        return pageNumber >= 1 && pageNumber <= parseInt(row.pageCount || '1', 10);
      }
      return true;
    }
    return false;
  }

  // Format 3: Global Page format like "E3", "B116", "A9.99", "A99.99"
  if (/^[a-zA-Z][A-Za-z0-9]*(\.\d{1,2})?$/.test(term) && row.globalPagePrefix) {
    if (isBundleTabPage) {
      return false;
    }

    const prefix = row.globalPagePrefix;
    if (term.toLowerCase().startsWith(prefix.toLowerCase())) {
      // For complex formats with dots
      if (term.includes('.')) {
        // This is a direct match like "A9.99" === "A9.99" (prefix match)
        if (term.toLowerCase() === prefix.toLowerCase()) {
          return true;
        }

        // This could be a page number after a pattern like "A9.991" (where A9.99 is the prefix)
        const valueAfterPrefix = term.toLowerCase().replace(prefix.toLowerCase(), '');
        if (valueAfterPrefix && !isNaN(Number(valueAfterPrefix))) {
          const pageNumber = parseInt(valueAfterPrefix, 10);
          const startPage = parseInt(row.startPage, 10);
          const lastPage = parseInt(row.lastPage, 10);
          return pageNumber >= startPage && pageNumber <= lastPage;
        }
      } else {
        // For simple formats without dots like "A1" or "E456"
        const pageNumber = parseInt(term.slice(prefix.length), 10);
        const startPage = parseInt(row.startPage, 10);
        const lastPage = parseInt(row.lastPage, 10);
        return pageNumber >= startPage && pageNumber <= lastPage;
      }
    }
    return false;
  }

  // Format 4: Trial View ID with page number like "TV-426/11"
  if (/^[Tt][Vv]-\d{2,}\/\d+$/.test(term)) {
    const parts = term.split('/');
    const trialViewId = parts[0]; // e.g., "TV-426"
    const pageNumber = parseInt(parts[1], 10);

    // Check both ID and name for this pattern
    const matchesId = row.id && row.id.toLowerCase().includes(trialViewId.toLowerCase());
    const matchesName = row.name && row.name.toLowerCase().includes(trialViewId.toLowerCase());

    if (matchesId || matchesName) {
      return pageNumber >= 1 && pageNumber <= parseInt(row.pageCount || '1', 10);
    }
    return false;
  }

  // Format 5: Ticket-style ID with page number like "TV-426-9885/5"
  if (/^[A-Za-z]+-\d+-\d+\/\d+$/.test(term)) {
    const parts = term.split('/');
    const ticketId = parts[0]; // e.g., "TV-426-9885"
    const pageNumber = parseInt(parts[1], 10);

    // Check both ID and name for this pattern
    const matchesName = row.name && row.name.toLowerCase().includes(ticketId.toLowerCase());
    const matchesId = row.id && row.id.toLowerCase().includes(ticketId.toLowerCase());

    if (matchesName || matchesId) {
      return pageNumber >= 1 && pageNumber <= parseInt(row.pageCount || '1', 10);
    }
    return false;
  }

  // Format 6: Basic ID/page pattern like 0137/6
  if (/^\d+\/\d+$/.test(term)) {
    const parts = term.split('/');
    const docId = parts[0];
    const pageNumber = parseInt(parts[1], 10);

    if (row.id && getStringValue(row, 'id').toLowerCase().includes(docId.toLowerCase())) {
      return pageNumber >= 1 && pageNumber <= parseInt(row.pageCount || '1', 10);
    }
    return false;
  }

  // STANDARD TEXT SEARCH - run for all non-matching patterns
  for (let i = 0; i < columns.length; i++) {
    const column = columns[i];
    if (!row.hasOwnProperty(column)) {
      // Special case for recipient
      if (column === 'recipient' && row.recipient === null && row.recipientsLists) {
        if (
          row.recipientsLists.some((recipient: string) =>
            recipient.toLowerCase().includes(term.toLowerCase()),
          )
        ) {
          return true;
        }
      }
      continue;
    }

    const value = getStringValue(row, column);
    if (value.toLowerCase().includes(term.toLowerCase())) {
      return true;
    }
  }

  return false;
};
export const filterData = ({ data, filter, filterByColumns, isBundleTabPage }: FilterProps) => {
  if (isEmpty(filter)) {
    return null;
  } else {
    const {
      term,
      goToGlobalPageIdx,
      authors = {},
      recipients = {},
      createdBy = {},
      tags = {},
      dateFrom,
      dateTo,
      withAnnotations,
      withoutAnnotations,
      shared,
      notShared,
      public: publicFiles,
      private: privateFiles,
      withPublicHyperlinks,
      withoutPublicHyperlinks,
      trialBundles = {},
      teamBundles = {},
      privateBundles = {},
    } = filter;
    const hasOnlyTerm =
      term &&
      !goToGlobalPageIdx &&
      !Object.entries(authors).length &&
      !Object.entries(recipients).length &&
      !Object.entries(createdBy).length &&
      !dateFrom &&
      !dateTo &&
      !withAnnotations &&
      !withoutAnnotations &&
      !shared &&
      !notShared &&
      !publicFiles &&
      !privateFiles &&
      !withPublicHyperlinks &&
      !withoutPublicHyperlinks &&
      !Object.entries(trialBundles).length &&
      !Object.entries(teamBundles).length &&
      !Object.entries(privateBundles).length &&
      !Object.entries(tags).length;

    const filteredData = data.filter((row) => {
      // Special case for global prefixes with dots (like "G4.1", "A9.99", "A99.99")
      if (term && /^[A-Za-z][A-Za-z0-9]*(\.\d{1,2})?$/.test(term) && row.globalPagePrefix) {
        // Direct prefix match
        if (row.globalPagePrefix.toLowerCase() === term.toLowerCase()) {
          return true;
        }
      }

      // For complex formats with slashes like "A99.99/5/6"
      if (term && /^[A-Za-z][A-Za-z0-9]*(\.\d{1,2})?\/\d+\/\d+$/.test(term)) {
        const parts = term.split('/');
        const prefix = parts[0]; // "A99.99"
        const tab = parts[1]; // "5"
        const pageNumber = parseInt(parts[2], 10); // 6

        if (
          row.globalPagePrefix &&
          row.globalPagePrefix.toLowerCase() === prefix.toLowerCase() &&
          getStringValue(row, 'tab').toLowerCase() === tab.toLowerCase() &&
          pageNumber >= 1 &&
          pageNumber <= parseInt(row.pageCount || '1', 10)
        ) {
          return true;
        }
      }
      const isInGrid =
        filterByFilterTerm(row, filterByColumns, term, isBundleTabPage) ||
        (row && row.matches && row.matches.length > 0) ||
        (term && filterByGlobalPage(row, term)); // Check by global page

      if (hasOnlyTerm) {
        return isInGrid;
      } else {
        const isInCategory =
          filterByGlobalPageIdx(row, goToGlobalPageIdx) &&
          filterByTrialBundles(row, filter.trialBundles) &&
          filterByTeamBundles(row, filter.teamBundles) &&
          filterByPrivateBundles(row, filter.privateBundles) &&
          filterByArray(row, authors, 'author') &&
          filterByArray(row, recipients, 'recipient') &&
          filterByArray(row.createdBy, createdBy, 'id') &&
          filterByArray(row, tags, 'tags') &&
          filterByDate(row, dateFrom, dateTo) &&
          filterByAnnotations(row, withAnnotations, withoutAnnotations) &&
          filterByShared(row, shared, notShared) &&
          filterByVisibility(row, publicFiles, privateFiles) &&
          filterByPublicHyperlinks(row, withPublicHyperlinks, withoutPublicHyperlinks);

        const entitiesIsInCategoryAndGrid =
          row.entities && isObject(row.entities)
            ? Object.keys(row.entities).reduce(
                (acc, key) =>
                  acc && (key === 'cost' ? true : filterByArray(row.entities, filter[key], key)),
                true,
              )
            : true;

        return isInGrid && isInCategory && entitiesIsInCategoryAndGrid;
      }
    });
    return filteredData;
  }
};

type TableProps = {
  data: Array<any>;
  filter: any;
  filterByColumns: Array<any>;
  onDoubleClick: (row: any) => void;
  columns: Array<any>;
  sortMode: boolean;
  editMode?: boolean;
  additionalDragInfo?: object;
  onRowSelected: (selectedRows: Array<any>) => void;
  updateSort: (index: any, ids: any, singleDroppedDocId: string, hasFolderFieldId: boolean) => void;
  onFilterChanged?: (filteredRows: Array<any> | null, value: string | null) => void;
  rowHeight?: number;
  readOnly?: boolean;
  defaultMessage?: string;
  setGridList?: Function;
  mappedRowsWithErrors?: any[];
};
export default React.memo(
  React.forwardRef(
    (
      {
        data: dataIn,
        onDoubleClick,
        columns,
        sortMode,
        additionalDragInfo,
        onRowSelected,
        updateSort,
        filter,
        filterByColumns,
        onFilterChanged,
        rowHeight = 37,
        readOnly,
        defaultMessage,
        setGridList,
        editMode,
        mappedRowsWithErrors,
      }: TableProps,
      ref,
    ) => {
      const [sortFn, setSortFn] = useFunctionAsState(null);
      const [sortStuff, setSortStuff] = useState<any>({});

      const distinctData = useOriginalCopy(dataIn, _.isEqual);

      const prevDistinctData = usePrevious(distinctData);
      const prevFilter = usePrevious(filter);
      const prevSortFn = usePrevious(sortFn);

      const [filteredData, setFilteredData] = useState<null | Array<any>>(null);
      const [sortedData, setSortedData] = useState<null | Array<any>>(null);
      const data = filteredData ? filteredData : sortedData ? sortedData : distinctData;
      const prevData = usePrevious(data, []) as Array<any>;
      const filterRowsByMappingErrors = useSelector(selectDataMapFailedRows);
      const isBundleTabPage = useSelector(selectBundleTabPage);
      useEffect(() => {
        if (!equal(prevData, data)) {
          setGridList && setGridList(data);
        }
      }, [data, prevData, setGridList]);

      useEffect(() => {
        if (_.isEqual(prevFilter, filter)) return;

        if (isEmpty(filter)) {
          setFilteredData(null);
          onFilterChanged && onFilterChanged(null, null);
          return;
        }

        const filteredDataTemp = filterData({
          data: sortedData || distinctData,
          filter,
          filterByColumns,
          isBundleTabPage,
        });

        setFilteredData(filteredDataTemp);
        onFilterChanged && onFilterChanged(filteredDataTemp, filter);
      }, [filter, data, sortedData, distinctData]);

      useEffect(() => {
        if (filterRowsByMappingErrors && mappedRowsWithErrors && mappedRowsWithErrors?.length > 0) {
          const onlyErrorRows = data?.filter((row) => mappedRowsWithErrors.includes(row.name));
          setFilteredData(onlyErrorRows as any);
          onFilterChanged && onFilterChanged(onlyErrorRows as any, filter);
        } else {
          const filteredDataTemp = filterData({
            data: sortedData || distinctData,
            filter,
            filterByColumns,
            isBundleTabPage,
          });
          setFilteredData(filteredDataTemp);
        }
      }, [filterRowsByMappingErrors, mappedRowsWithErrors]);

      useEffect(() => {
        if (prevSortFn === sortFn) return;

        if (!sortFn) {
          // preserve selected rows
          distinctData.forEach((item) => {
            const foundItem = data.find(
              ({ folderFileId, compositeKey, id }: any) =>
                (folderFileId || compositeKey || id) ===
                (item.folderFileId || item.compositeKey || item.id),
            );
            if (foundItem) item.selected = foundItem.selected;
            else item.selected = false;
          });
          // clear sort data
          setSortedData(null);
          // if filtered re-filter regular order
          if (filteredData) {
            const filteredDataTemp = filterData({
              data: distinctData,
              filter,
              filterByColumns,
              isBundleTabPage,
            });
            setFilteredData(filteredDataTemp);
          }
          return;
        }

        // sort data
        const sortedDataTemp = distinctData.slice().sort(sortFn);
        setSortedData(sortedDataTemp);
        // sort filtered data
        if (filteredData) {
          const filteredDataTemp = filteredData.slice().sort(sortFn);
          setFilteredData(filteredDataTemp);
        }
      }, [prevSortFn, sortFn, distinctData, filteredData, filter, filterByColumns, data]);

      // is new data
      useEffect(() => {
        if (prevDistinctData === distinctData) return;

        const isSorted = !!sortFn;
        const isFiltered = !!filter;

        // preserve selected rows
        distinctData.forEach((item) => {
          const foundItem = prevData?.find(
            ({ folderFileId, compositeKey }: { folderFileId: string; compositeKey: string }) =>
              (folderFileId || compositeKey) === (item.folderFileId || item.compositeKey),
          );
          if (foundItem) item.selected = foundItem.selected;
          else item.selected = false;
        });

        // if it's sorted we re-sort and it will get re-filtered automatically
        if (isSorted) {
          setSortedData(distinctData.slice().sort(sortFn));
        }
        // if it's filtered then filter the new data
        else if (isFiltered) {
          setFilteredData(
            filterData({ data: distinctData, filter, filterByColumns, isBundleTabPage }),
          );
        }

        // update row selected
        onRowSelected &&
          onRowSelected(distinctData.filter(({ selected }: { selected: boolean }) => !!selected));
        return () => {
          if (distinctData.length === 0) {
            setSortFn(null);
            setSortStuff({});
          }
        };
      }, [
        prevDistinctData,
        distinctData,
        filter,
        filterByColumns,
        sortFn,
        onRowSelected,
        prevData,
        setSortFn,
      ]);

      const listRef = useRef();
      const dispatch = useDispatch();
      const firstEditableCellRefs = useRef<React.RefObject<HTMLInputElement>[]>([]);

      useEffect(() => {
        if (firstEditableCellRefs.current.length !== data.length) {
          firstEditableCellRefs.current = data.map(
            (_, i) => firstEditableCellRefs.current[i] || React.createRef(),
          );
        }
      }, [data]);

      const focusNextRow = useCallback(
        (currentRowIndex) => {
          if (currentRowIndex + 1 < data.length) {
            const nextRef = firstEditableCellRefs.current[currentRowIndex + 1];
            if (nextRef && nextRef.current) {
              nextRef.current.focus();
            }
          }
        },
        [data],
      );

      const onSortHandler = useCallback(
        ({ sortByColumn, sortDirection, onCustomSort }) => {
          setSortFn(getSortFunction(sortByColumn, sortDirection, onCustomSort));
          setSortStuff({ sortedColumn: sortByColumn, sortedDirection: sortDirection });
        },
        [setSortFn],
      );
      const [currentlyEditingRowIndex, setCurrentlyEditingRowIndex] = useState<number | null>(null);

      const folderSpecificProp = ['name', 'tab', 'startPage'];

      interface FileData {
        [key: string]: any;
      }

      const getFileData = (data: FileData, folderSpecificData: boolean): FileData => {
        return Object.entries(data).reduce((previousItem, [key, value]) => {
          if (folderSpecificData) {
            if (folderSpecificProp.includes(key)) {
              return { ...previousItem, [key]: value };
            } else {
              return previousItem;
            }
          } else {
            if (!folderSpecificProp.includes(key)) {
              return { ...previousItem, [key]: value };
            } else {
              return previousItem;
            }
          }
        }, {});
      };

      interface Data {
        [key: string]: any;
      }

      interface CleanData {
        [key: string]: any;
      }

      const getCleanData = (data: Data, originalData: Data): CleanData => {
        return Object.entries(data).reduce((cleanData, [key, value]) => {
          if (value !== originalData[key]) {
            return { ...cleanData, [key]: value };
          }
          return cleanData;
        }, {});
      };

      const handleSaveChanges = useCallback(
        (rowId, folderFileId, updatedData, originalData) => {
          // Extract and compare folder-specific data
          const folderSpecificData = getFileData(updatedData, true);
          const cleanFolderSpecificData = getCleanData(folderSpecificData, originalData);

          if (Object.keys(cleanFolderSpecificData).length > 0) {
            dispatch(updateDocument(cleanFolderSpecificData, rowId, folderFileId));
          }

          // Extract and compare file-level metadata
          const fileMetaData = getFileData(updatedData, false);
          const cleanFileMetaData = getCleanData(fileMetaData, originalData);

          if (Object.keys(cleanFileMetaData).length > 0) {
            dispatch(updateDocumentMeta(cleanFileMetaData, rowId));
          }
        },
        [dispatch],
      );

      const onRowClick = useCallback((rowIndex: number) => {
        if (currentlyEditingRowIndex !== null && currentlyEditingRowIndex !== rowIndex) {
          setCurrentlyEditingRowIndex(rowIndex);
        } else {
          setCurrentlyEditingRowIndex(rowIndex);
        }
      }, []);
      const selectedRowIds = data.filter((row) => row.selected).map((row) => row.id);
      return (
        <div className="common-table">
          {
            //needed so that it doesn't go outside card boundaries
            <div style={{ height: '100%' }}>
              {data && data.length > 0 ? (
                <ScrollDataContext.Provider value={{ dataLength: data.length }}>
                  <AutoSizer>
                    {({ height, width }: { height: number; width: number }) => (
                      <DroppableBodyContainerProvider
                        updateSort={updateSort}
                        columns={columns}
                        data={data}
                        sortMode={sortMode}
                        forwardedRef={listRef}
                        onSortHandler={onSortHandler}
                        sortedColumn={sortStuff.sortedColumn}
                        sortedDirection={sortStuff.sortedDirection}
                      >
                        <List
                          outerElementType={CustomScrollbarsVirtualList}
                          innerElementType={DroppableBody}
                          outerRef={listRef}
                          height={height}
                          width={width}
                          itemCount={data.length}
                          itemSize={rowHeight}
                          itemData={{
                            data: data,
                            onDoubleClick,
                            columns,
                            sortMode,
                            editMode,
                            additionalDragInfo,
                            onRowSelected,
                            readOnly,
                            forwardedRef: ref,
                            selectedRowIds,
                            focusNextRow,
                            handleSaveChanges,
                            handleCancelChanges: () => {}, // TODO get rid of later
                            onRowClick,
                            currentlyEditingRowIndex,
                            firstEditableCellRefs: firstEditableCellRefs.current,
                            mappedRowsWithErrors,
                          }}
                        >
                          {ListRow}
                        </List>
                      </DroppableBodyContainerProvider>
                    )}
                  </AutoSizer>
                </ScrollDataContext.Provider>
              ) : (
                <>
                  {filteredData && <NoDataMessage message={T.translate('case.filterNoMatch')} />}
                  {!filteredData && defaultMessage && <NoDataMessage message={defaultMessage} />}
                </>
              )}
            </div>
          }
        </div>
      );
    },
  ),
);
