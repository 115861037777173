import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// import Notifications from './Notifications';
import HearingRoom from './HearingRoom';
import User from './User';
import { selectCurrentCase, selectPermissions } from 'common/selectors';
import classnames from 'classnames';

class NavBarLinks extends Component {
  static propTypes = {
    caseId: PropTypes.string,
    showHearingRoom: PropTypes.bool,
  };

  static defaultProps = {
    showHearingRoom: true,
  };

  render() {
    return (
      <div className={classnames(this.props.theme, 'nav-bar-links')}>
        {
          //<Notifications />
        }
        {this.props.additionalButton &&
          React.Children.map(this.props.additionalButton, (element) =>
            React.cloneElement(element, {
              className: classnames(this.props.additionalButton.className, { 'd-l-none': true }),
            }),
          )}
        {this.props.showHearingRoom && this.props.caseId && this.props.hearingRoom.isShow && (
          <HearingRoom className="d-l-none" />
        )}
        <User />
        {this.props.showHearingRoom && this.props.caseId && this.props.hearingRoom.isShow && (
          <HearingRoom className="d-none" />
        )}
        {this.props.additionalButton &&
          React.Children.map(this.props.additionalButton, (element) =>
            React.cloneElement(element, {
              className: classnames(this.props.additionalButton.className, { 'd-none': true }),
            }),
          )}
        <div className="divider" />
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  const { hearingRoom } = selectPermissions(state);

  return {
    caseId: selectCurrentCase(state).id,
    hearingRoom,
  };
}

export default connect(mapStateToProps)(NavBarLinks);
