import { put, takeEvery, select, call } from 'redux-saga/effects';
import {
  VIEWING_ADD_ANNOTATION_BEGIN,
  VIEWING_ADD_ANNOTATION_SUCCESS,
  VIEWING_ADD_ANNOTATION_FAILURE,
  VIEWING_ADD_ANNOTATION_DISMISS_FEEDBACK,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId, selectCurrentFile, withCurrentUserId } from 'common/selectors';
import { addItemImmutable } from 'utils/arrays';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { DEFAULT_ANNOTATION_FILTER, createFilter, filterAnnotations } from './filterAnnotations';

export const addAnnotation = createPromiseAction(VIEWING_ADD_ANNOTATION_BEGIN);

export function dismissAddAnnotationFeedback() {
  return {
    type: VIEWING_ADD_ANNOTATION_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on VIEWING_ADD_ANNOTATION_BEGIN actions
export function* doAddAnnotation(action) {
  const {
    payload: { annotation, fileId, folderId, caseId, tags, currentUserId: userId },
  } = action;
  const file = yield select(selectCurrentFile);

  const res = yield call(api.post, `/cases/${caseId}/files/${file || fileId}/annotations`, {
    annotation,
    tags,
    folderId,
  });

  if (res && res.error) {
    yield put({
      type: VIEWING_ADD_ANNOTATION_FAILURE,
      feedback: { message: 'feedback.addAnnotationFailure', error: res.error, retryAction: action },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: VIEWING_ADD_ANNOTATION_SUCCESS,
    data: { annotation: res },
    userId,
  });
  yield call(resolvePromiseAction, action, res);
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchAddAnnotation() {
  yield takeEvery(addAnnotation, withCurrentUserId(withCurrentCaseId(doAddAnnotation)));
}

export function useAddAnnotation() {
  const dispatch = useDispatch();

  const { addAnnotationPending, addAnnotationFeedback, annotations } = useSelector(
    (state) => ({
      addAnnotationPending: state.viewing.addAnnotationPending,
      addAnnotationFeedback: state.viewing.addAnnotationFeedback,
      annotations: state.viewing.annotations,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(addAnnotation(...args));
    },
    [dispatch],
  );

  return {
    annotations,
    addAnnotation: boundAction,
    addAnnotationPending,
    addAnnotationFeedback,
  };
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case VIEWING_ADD_ANNOTATION_BEGIN:
      return {
        ...state,
        addAnnotationPending: true,
        addAnnotationFeedback: null,
      };

    case VIEWING_ADD_ANNOTATION_SUCCESS:
      const newAnnotations = addItemImmutable(state.annotations, action.data.annotation);
      return {
        ...state,
        addAnnotationPending: false,
        addAnnotationFeedback: action.feedback,
        annotations: newAnnotations,
        filteredAnnotations: filterAnnotations(
          newAnnotations,
          state.updatedFilters || DEFAULT_ANNOTATION_FILTER,
          action.userId,
        ),
        updatedFilters: createFilter(
          newAnnotations,
          state.updatedFilters || DEFAULT_ANNOTATION_FILTER,
        ),
      };

    case VIEWING_ADD_ANNOTATION_FAILURE:
      return {
        ...state,
        addAnnotationPending: false,
        addAnnotationFeedback: action.feedback,
      };

    case VIEWING_ADD_ANNOTATION_DISMISS_FEEDBACK:
      return {
        ...state,
        addAnnotationFeedback: null,
      };

    default:
      return state;
  }
}
