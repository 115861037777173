import React from 'react';
import { useAppStateContext } from '../../../AppStateProvider/AppStateProvider';
import { Button, Container, Grid, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    illustrationContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    viewButton: {
      marginTop: '2em',
      '& svg': {
        position: 'relative',
        left: '-5px',
      },
    },
  }),
);

interface ConnectionSuccessProps {
  openModal: () => void;
}

export function ConnectionSuccess({ openModal }: ConnectionSuccessProps) {
  const classes = useStyles();
  const { dispatch } = useAppStateContext();

  return (
    <>
      <Container>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item md={5}>
            <Typography variant="h5" gutterBottom>
              Connection success
            </Typography>

            <Typography variant="body1" gutterBottom>
              All connections to Trialview servers are working correctly.
            </Typography>

            <Button
              variant="contained"
              color="primary"
              onClick={() => dispatch({ type: 'next-pane' })}
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
