import { useEffect, useRef } from 'react';
import PerfectScrollbar from 'perfect-scrollbar';
import { getScrollContainer } from '../utils';

export const usePerfectScrollbarUpdater = (viewerControlRef: any) => {
  const perfectScrollbarRef = useRef<any>();
  const resizeTimerRef = useRef<any>();

  const updatePerfectScrollbar = () => {
    const scrollContainer = getScrollContainer(viewerControlRef.current);
    if (!perfectScrollbarRef.current && scrollContainer)
      perfectScrollbarRef.current = new PerfectScrollbar(scrollContainer);

    if (resizeTimerRef.current) {
      clearTimeout(resizeTimerRef.current);
    }
    resizeTimerRef.current = setTimeout(function () {
      perfectScrollbarRef.current.update();
    }, 250);
  };

  useEffect(() => {
    window.addEventListener('resize', updatePerfectScrollbar);

    return () => {
      window.removeEventListener('resize', updatePerfectScrollbar);
    };
    // MOUNT
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return updatePerfectScrollbar as any;
};
