/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState } from 'react';
import T from 'i18n';
import { useSelector } from 'react-redux';
import { selectCurrentCaseTeam, selectPermissions } from 'common/selectors';
import { useForm } from 'react-hook-form';
import { SelectForm, Spinner, TextForm } from 'features/common';
import { Col, Form, Row } from 'react-bootstrap';
import { Modal } from 'features/common/MaterialBasedComponents';

const TranscriptNotes = ({ show, handleClose, handleSave, action, selectedRows }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  const {
    documents: { createNotesOnBehalfOfOtherUser },
  } = useSelector(selectPermissions);
  const currentCaseTeam = useSelector(selectCurrentCaseTeam);
  const [loading, setLoading] = useState(false);
  const userOptions =
    (createNotesOnBehalfOfOtherUser &&
      currentCaseTeam &&
      currentCaseTeam.users &&
      currentCaseTeam.users.map((user) => {
        return { value: user.id, label: user.mail };
      })) ||
    [];

  return (
    <Modal
      show={show}
      title={T.translate('generic.transcriptNotes')}
      handleClose={handleClose}
      handleSave={handleSubmit((data) => {
        setLoading(true);
        handleSave(null, action, selectedRows, data, handleClose, setLoading);
      })}
      saveTitle={T.translate('generic.upload')}
      disableSave={loading}
    >
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div style={{ ...(createNotesOnBehalfOfOtherUser && { marginBottom: '1rem' }) }}>
            {T.translate('case.uploadTranscriptMsg', {
              documentName: selectedRows[0].name || selectedRows[0].originalName,
            })}
          </div>
          {createNotesOnBehalfOfOtherUser && (
            <Row>
              <Col>
                <SelectForm
                  label={T.translate('case.onBehalfOfUser')}
                  placeholder={T.translate('case.onBehalfOfUserPlaceholder')}
                  name="onBehalfOfUserId"
                  options={userOptions}
                  control={control}
                  isClearable
                  menuPosition="fixed"
                />
              </Col>
              <Col />
            </Row>
          )}
          <br />
          <Form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Form.Group>
              <Row>
                <Col>
                  <TextForm
                    {...register('newFile', { required: true })}
                    type="file"
                    errors={errors}
                  />
                </Col>
                <Col />
                <Col />
              </Row>
            </Form.Group>
          </Form>
        </>
      )}
    </Modal>
  );
};

export default TranscriptNotes;
