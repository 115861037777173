import { MappingProblem } from './types';
import { exportRawRowsToCSV } from './utils';
import T from 'i18n'; // Adjust the import path as necessary

export const validFileExts = [
  'pdf',
  'doc',
  'dot',
  'docx',
  'docm',
  'dotx',
  'dotm',
  'rtf',
  'xls',
  'xlt',
  'xlsx',
  'xlsm',
  'xltx',
  'xltm',
  'ppt',
  'pot',
  'pps',
  'pptx',
  'pptm',
  'potx',
  'potm',
  'ppsx',
  'ppsm',
  'odt',
  'ott',
  'fodt',
  'ods',
  'ots',
  'fods',
  'odp',
  'otp',
  'fodp',
  'odf',
  'odg',
  'otg',
  'fodg',
  'dwg',
  'dxf',
  'dwf',
  'dgn',
  'html',
  'htm',
  'xhtml',
  'xhtm',
  'msg',
  'eml',
  'txt',
  'csv',
  'dcm',
  'dicom',
  'dcim',
  'dicm',
  'tif',
  'tiff',
  'jpg',
  'jpeg',
  'jp2',
  'jpc',
  'gif',
  'png',
  'psd',
  'psb',
  'bmp',
  'dib',
  'pct',
  'pic',
  'pict',
  'wmf',
  'emf',
  'pcx',
  'dcx',
  'ras',
  'pcd',
  'tga',
  'tpic',
  'cal',
  'cals',
  'ico',
  'cur',
  'ncr',
  'xwd',
  'sgi',
  'wbmp',
  'sct',
  'wpg',
  'xbm',
  'pbm',
  'pgm',
  'ppm',
  'img',
  'cut',
  'mp4',
];

export const validFileExtsShareOnly = ['lp', 'do', 'zip', 'xer', 'plf', 'inc', 'mpp'];

export const fileIdentifiers = new Map([
  ['tab', 'case.tab'],
  ['id', 'case.documentId'],
  ['name', 'case.name'],
  ['description', 'case.description'],
  ['globalpages', 'case.globalPages'],
  ['externalid', 'generic.externalId'],
  ['discoveryid', 'generic.discoveryId'],
  ['author', 'case.author'],
  ['recipient', 'case.recipient'],
  ['viewed', 'case.viewed'],
  ['dateviewed', 'case.dateViewed'], // Note: Conditional translation to 'case.datePresented' may apply
  ['docdate', 'case.docDate'],
  ['altdate', 'case.alternativeDate'],
  ['type', 'case.type'],
  ['pagecount', 'case.pageCount'],
  ['size', 'case.size'],
  ['info', 'case.info'],
]);

export const fileIdsArray = [
  'tab',
  'doc. id',
  'name',
  'description',
  'global pages',
  'external id',
  'discovery id',
  'author',
  'recipient',
  'viewed',
  'date',
  'docDate',
  'tab',
  'doc. id',
];

export const PROBLEM_CATEGORIES = {
  missingPrimaryKey: 'missingPrimaryKey',
  missingFileIdentifier: 'missingFileIdentifier',
  missingRequiredField: 'missingRequiredField',
  notFoundInCSV: 'notFoundInCSV',
  invalidDate: 'invalidDate',
  orphanRow: 'orphanRow',
};
// TODO: Translate categories
export const PROBLEM_CATEGORIES_LABELS = {
  missingPrimaryKey: 'Primary Key Missing',
  missingFileIdentifier: 'File Identifier Missing',
  missingRequiredField: 'Required Field Missing',
  notFoundInCSV: 'Not Found in CSV',
  invalidDate: 'Invalid Date',
  orphanRow: 'Row not associated with any file',
};
