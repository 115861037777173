import { isPlainObject } from 'is-plain-object';
import { isMobile } from 'utils/device';

// This function ensures that the user has granted the browser permission to use audio and video
// devices. If permission has not been granted, it will cause the browser to ask for permission
// for audio and video at the same time (as opposed to separate requests).
export function ensureMediaPermissions() {
  return navigator.mediaDevices
    .enumerateDevices()
    .then((devices) => devices.every((device) => !(device.deviceId && device.label)))
    .then((shouldAskForMediaPermissions) => {
      if (shouldAskForMediaPermissions) {
        return navigator.mediaDevices
          .getUserMedia({ audio: true, video: true })
          .then((mediaStream) => mediaStream.getTracks().forEach((track) => track.stop()));
      }
    });
}

export const fetchUserName = (identity: string) => {
  const { u, userName } = fetchUserDetails(identity);
  return u || userName;
};

export const fetchTeamName = (identity: string) => {
  const { t, teamName } = fetchUserDetails(identity);
  return t || teamName;
};

export const fetchUserId = (identity: string) => {
  // backward compatible
  const { i, userId } = fetchUserDetails(identity);
  return i || userId;
};

const fetchUserDetails = (identity: string) => {
  try {
    const details = JSON.parse(identity);
    return details;
  } catch (e) {
    return { i: identity, u: identity };
  }
};

export const setColorTitleAndLanguageForParticipant = (
  participant: any,
  activeParticipants = [],
) => {
  const activeParticipant = (activeParticipants &&
    activeParticipants.find(
      ({ user }: { user: any }) => user && user.id === fetchUserId(participant.identity),
    )) as any;
  const color = activeParticipant && activeParticipant.team && activeParticipant.team.color;
  const title = activeParticipant && activeParticipant.team && activeParticipant.team.name;
  const translator = activeParticipant && activeParticipant.translator;
  const language = activeParticipant && activeParticipant.language;
  participant.color = color || participant.color;
  participant.title = title || participant.title;
  participant.translator = translator || participant.translator;
  participant.language = language || participant.language;
};

export const filterParticipants = (
  isPublicHearingRoom: any,
  participants: any,
  activeParticipants: any,
) => {
  // if public room return only active participants
  return isPublicHearingRoom
    ? participants.filter(
        (participant: any) =>
          activeParticipants &&
          activeParticipants.find(
            ({ user }: { user: any }) => user && user.id === fetchUserId(participant.identity),
          ),
      )
    : participants;
};

export const isParticipantActive = (participant: any, activeParticipants: any) =>
  activeParticipants.find(
    ({ user }: { user: any }) => (user && user.id) === fetchUserId(participant.identity),
  );

export const getAudioTrack = (participant: any) => {
  const publications = Array.from(participant.tracks.values());
  const audioPublication = publications.find((p: any) => p.kind === 'audio');
  return audioPublication || {};
};

// Recursively removes any object keys with a value of undefined
export function removeUndefineds(obj: any) {
  if (!isPlainObject(obj)) return obj;

  const target = {} as any;

  for (const key in obj) {
    const val = obj[key];
    if (typeof val !== 'undefined') {
      target[key] = removeUndefineds(val);
    }
  }

  return target;
}

export const ThumbnailSize = [
  {
    label: 'Super extra large',
    value: 'superExtraLarge',
    sizes: { sidebarWidth: 36, sidebarMobileHeight: 18 },
  },
  {
    label: 'Extra Large',
    value: 'extraLarge',
    sizes: { sidebarWidth: 32, sidebarMobileHeight: 16 },
  },
  {
    label: 'Large',
    value: 'large',
    sizes: { sidebarWidth: 28, sidebarMobileHeight: 14 },
  },
  {
    label: 'Medium',
    value: 'medium',
    sizes: { sidebarWidth: 24, sidebarMobileHeight: 12 },
  },
  {
    label: 'Small',
    value: 'small',
    sizes: { sidebarWidth: 20, sidebarMobileHeight: 10 },
  },
];

export function getThumbnailSize(value: any) {
  if (!value) {
    return ThumbnailSize[3].sizes;
  }

  const thumbnailSize = ThumbnailSize.find((item) => item.value === value);

  return thumbnailSize ? thumbnailSize.sizes : ThumbnailSize[3].sizes;
}
export const quickUploadOptions = [10, 25, 50, 75, 100];

export const RenderDimensions = [
  {
    label: 'Low (160 x 90)',
    value: 'low',
    resolution: { width: 176, height: 144 },
  },
  {
    label: 'CIF (352 x 288)',
    value: 'cif',
    resolution: { width: 352, height: 288 },
  },
  {
    label: 'VGA (640 x 480)',
    value: 'vga',
    resolution: { width: 640, height: 480 },
  },
  {
    label: 'WVGA (800 x 480)',
    value: 'wvga',
    resolution: { width: 800, height: 480 },
  },
  {
    label: 'HD 540P (960 x 540)',
    value: '540p',
    resolution: { width: 960, height: 540 },
  },
  {
    label: 'HD 720P (1280 x 720)',
    value: '720p',
    resolution: { width: 1280, height: 720 },
  },
  {
    label: 'HD 960P (1280 x 960)',
    value: '960p',
    resolution: { width: 1280, height: 960 },
  },
  {
    label: 'HD Standard 1080P (1440 x 1080)',
    value: 'standard1080p',
    resolution: { width: 1440, height: 1080 },
  },
  {
    label: 'HD Widescreen 1080P (1920 x 1080)',
    value: 'wide1080p',
    resolution: { width: 1920, height: 1080 },
  },
  {
    label: 'Server Default',
    value: 'default',
    resolution: undefined,
  },
];

export function getResolution(value: any) {
  if (typeof value === 'undefined') {
    return undefined;
  }

  const renderDimension = RenderDimensions.find((item) => item.value === value);

  return renderDimension && renderDimension.resolution;
}

const getPrefferedVideoCodec = (videoCodec: any) => {
  switch (videoCodec) {
    case 'H.264':
      return 'H.264';
    case 'VP9':
      return 'VP9';
    default:
      return { codec: 'VP8', simulcast: true };
  }
};

export function generateConnectionOptions(settings: any) {
  // See: https://media.twiliocdn.com/sdk/js/video/releases/2.0.0/docs/global.html#ConnectOptions
  // for available connection options.
  const connectionOptions = {
    // Bandwidth Profile, Dominant Speaker, and Network Quality
    // features are only available in Small Group or Group Rooms.
    // Please set "Room Type" to "Group" or "Small Group" in your
    // Twilio Console: https://www.twilio.com/console/video/configure
    bandwidthProfile: {
      video: {
        mode: settings.bandwidthProfileMode,
        dominantSpeakerPriority: settings.dominantSpeakerPriority,
        contentPreferencesMode: settings.contentPreferencesMode,
        clientTrackSwitchOffControl: settings.clientTrackSwitchOffControl,
      },
    },
    dominantSpeaker: true,
    networkQuality: { local: 1, remote: 1 },

    // Comment this line if you are playing music.
    maxAudioBitrate: Number(settings.maxAudioBitrate),

    // VP8 simulcast enables the media server in a Small Group or Group Room
    // to adapt your encoded video quality for each RemoteParticipant based on
    // their individual bandwidth constraints. This has no effect if you are
    // using Peer-to-Peer Rooms.
    preferredVideoCodecs: [getPrefferedVideoCodec(settings.videoCodec)],
  };

  // For mobile browsers, limit the maximum incoming video bitrate to 2.5 Mbps.
  if (
    isMobile &&
    connectionOptions &&
    connectionOptions.bandwidthProfile &&
    connectionOptions.bandwidthProfile.video
  ) {
    //@ts-ignore
    connectionOptions.bandwidthProfile.video.maxSubscriptionBitrate = 2500000;
  }

  // Here we remove any 'undefined' values. The twilio-video SDK will only use defaults
  // when no value is passed for an option. It will throw an error when 'undefined' is passed.
  return removeUndefineds(connectionOptions);
}
