import { shallowEqual, useDispatch } from 'react-redux';
import { CASE_SHOW_DOCFINDER_BOOKMARKS } from './constants';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';

export function setShowDocFinderBookmarks(value) {
  return {
    type: CASE_SHOW_DOCFINDER_BOOKMARKS,
    value,
  };
}

export function useSetShowDocFinderBookmarks() {
  const dispatch = useDispatch();

  const { showDocFinderBookmarks } = useSelector(
    (state) => ({
      showDocFinderBookmarks: state.case.showDocFinderBookmarks,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(setShowDocFinderBookmarks(...args));
    },
    [dispatch],
  );

  return {
    setShowDocFinderBookmarks: boundAction,
    showDocFinderBookmarks,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_SHOW_DOCFINDER_BOOKMARKS:
      return {
        ...state,
        showDocFinderBookmarks: action.value,
        showDocFinderFilter:
          state.showDocFinderFilter && action.value ? false : state.showDocFinderFilter,
      };

    default:
      return state;
  }
}
