import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';
import T from 'i18n';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import Button from 'react-bootstrap/Button';
import { useOriginalCopy } from '@huse/previous-value';
import equal from 'react-fast-compare';
import TimeAgo from 'react-timeago';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { fastDeepEqualSelectorCreator } from 'common/selectors';
import * as immutable from 'object-path-immutable';
import { appInsights } from 'common/appInsights';
import { usePrevious } from 'utils/hooks';
import { selectUserId } from 'common/selectors';
import { isObject } from 'utils/object';

// function generateUEID() {
//   let first = (Math.random() * 46656) | 0;
//   let second = (Math.random() * 46656) | 0;
//   first = ('000' + first.toString(36)).slice(-3);
//   second = ('000' + second.toString(36)).slice(-3);
//   return first + second;
// }

function isError(error) {
  return error instanceof Error || (error.message && error.errorCode);
}

const parseError = (error, showStatusCode = true) => {
  if (isError(error)) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {showStatusCode && error.name && <span>{error.name}</span>}
        {error.message && <span>{error.message}</span>}
        {showStatusCode && error.errorCode && <span>{error.errorCode}</span>}
      </div>
    );
  }
  if (Array.isArray(error))
    return error.map((err, index) => (
      <div style={{ display: 'flex', marginBottom: '0.5rem' }} key={index}>
        {error.length > 1 && <span style={{ marginRight: '0.5rem' }}>{`${index + 1}.`}</span>}
        {parseError(err, showStatusCode)}
      </div>
    ));
  if (isObject(error)) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {showStatusCode && <span>{`${error.status} ${error.statusText}`}</span>}
        <span>
          {error.status === 412
            ? T.translate('feedback.concurencyIssue')
            : error.body && error.body.toString()}
        </span>
      </div>
    );
  } else return error;
};

const getToastOptions = ({ error, warning, info, infoSticky, id }) => {
  const hasError = !!error;
  const isWarning = !!warning;
  const isInfo = !!info;
  const isInfoSticky = !!infoSticky;
  const onDismiss = () => {};
  return {
    id,
    appearance: hasError
      ? 'error'
      : isWarning
        ? 'warning'
        : isInfoSticky
          ? 'infoStrong'
          : isInfo
            ? 'info'
            : 'success',
    autoDismiss: !(hasError || isWarning || isInfoSticky),
    onDismiss: !isInfoSticky && onDismiss,
  };
};

const filterObject = (path) => (object) => {
  return Object.keys(object)
    .filter((key) => key.toLowerCase().includes('feedback'))
    .map((key) => path + '.' + key);
};

const homeSelector = createSelector((state) => state.home, filterObject('home'));
const commonSelector = createSelector((state) => state.common, filterObject('common'));
const caseSelector = createSelector((state) => state.case, filterObject('case'));
const authSelector = createSelector((state) => state.auth, filterObject('auth'));
const viewingSelector = createSelector((state) => state.viewing, filterObject('viewing'));
const conferenceSelector = createSelector((state) => state.conference, filterObject('conference'));

const feedbackSelector = fastDeepEqualSelectorCreator(
  homeSelector,
  commonSelector,
  caseSelector,
  authSelector,
  viewingSelector,
  conferenceSelector,
  (
    homeSelector,
    commonSelector,
    caseSelector,
    authSelector,
    viewingSelector,
    conferenceSelector,
  ) => {
    return [
      ...homeSelector,
      ...commonSelector,
      ...caseSelector,
      ...authSelector,
      ...viewingSelector,
      ...conferenceSelector,
    ];
  },
);

const empty = {};

const feedbackByPathSelector = createSelector(
  (state, path) => immutable.get(state, path) || empty,
  (feedback) => feedback,
);

const feedbackByPathWithSnapshotSelector = createSelector(
  feedbackByPathSelector,
  (state, path, feedbackSnapshot) => feedbackSnapshot,
  (feedback, feedbackSnapshot) => {
    return {
      ...feedbackSnapshot,
      ...(feedbackSnapshot.id === feedback.id ? feedback : {}),
      pending: feedback?.pending || false,
    };
  },
);

const InfoIcon = ({ error }) =>
  error ? (
    <OverlayTrigger
      placement="left"
      delay={{ show: 250, hide: 400 }}
      overlay={
        <Popover style={{ width: '350px', maxWidth: '350px', fontSize: '0.75rem' }}>
          <React.Fragment>
            <Popover.Content>{parseError(error)}</Popover.Content>
          </React.Fragment>
        </Popover>
      }
    >
      <FontAwesomeIcon
        style={{ color: '#7BA0F4', marginLeft: '0.5rem' }}
        icon={faInfoCircle}
        alt={faInfoCircle.iconName}
      />
    </OverlayTrigger>
  ) : null;

const ToastContent = ({ feedbackSnapshot, path, removeToast, date }) => {
  const dispatch = useDispatch();

  const feedback = useSelector((state) =>
    feedbackByPathWithSnapshotSelector(state, path, feedbackSnapshot),
  );

  const isPending = feedback?.pending;

  if (!feedback.message && !isPending) return null;

  const hasError = !!feedback.error;
  const isWarning = !!feedback.warning;
  const isInfo = !!feedback.info || !!feedback.infoSticky;
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <strong>
          {isPending
            ? 'Loading...'
            : T.translate(
                hasError
                  ? 'generic.error'
                  : isWarning
                    ? 'generic.warning'
                    : isInfo
                      ? 'generic.info'
                      : 'generic.success',
              )}
        </strong>
        <TimeAgo
          style={{ color: '#AAAAAE' }}
          date={date}
          minPeriod={60}
          formatter={(first, second, third, timeEpoch) => {
            if (second !== 'second') return `${first} ${second} ${third} `;
          }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          color: '#AAAAAE',
          alignItems: 'center',
        }}
      >
        {isPending ? (
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <span>
            {feedback.error && (!!feedback.error.body || Array.isArray(feedback.error))
              ? parseError(feedback.error, false)
              : T.translate(feedback.message, feedback.params)}
          </span>
        )}
        {!isPending &&
          !(feedback.error && (!!feedback.error.body || Array.isArray(feedback.error))) && (
            <InfoIcon error={feedback.error} />
          )}
      </div>
      {hasError && feedback.retryAction && feedback.error.status !== 412 && (
        <div>
          <Button
            variant="link"
            onClick={() => {
              dispatch(feedback.retryAction);
              removeToast();
            }}
          >
            {T.translate('generic.retry')}
          </Button>
        </div>
      )}
    </>
  );
};
const timeouts = [];

const ToastHandler = React.memo(({ path }) => {
  const { addToast, removeToast } = useToasts();
  const feedback = useSelector((state) => feedbackByPathSelector(state, path));
  const prevFeedback = usePrevious(feedback);

  const [diff, setDiff] = useState({});

  useEffect(() => {
    const pickAndSetDiff = ({ id, error, warning, info, infoSticky, message, params }) =>
      setDiff({ id, error, warning, info, infoSticky, message, params, timestamp: new Date() });
    if (
      !equal(prevFeedback?.error, feedback?.error) ||
      !equal(prevFeedback?.warning, feedback?.warning) ||
      !equal(prevFeedback?.info, feedback?.info) ||
      !equal(prevFeedback?.infoSticky, feedback?.infoSticky) ||
      !equal(!!prevFeedback?.message, !!feedback?.message) ||
      !equal(prevFeedback?.id, feedback?.id) ||
      prevFeedback?.timestamp !== feedback?.timestamp
    ) {
      pickAndSetDiff(feedback);
    }
  }, [path, prevFeedback, feedback, setDiff]);

  const currentUserId = useSelector(selectUserId);

  useEffect(() => {
    const findAndClearTimeout = (id) => {
      const foundTimeout = timeouts.find((x) => x.id === id);
      if (foundTimeout) {
        const index = timeouts.indexOf(foundTimeout);
        timeouts.splice(index, 1);
        clearTimeout(foundTimeout.timeout);
      }
    };

    removeToast(diff.id || path);
    findAndClearTimeout(diff.id || path);

    if (!!diff.error) {
      appInsights?.trackException({
        error: diff.error,
        exception: diff.error,
        severityLevel: 'Error',
        properties: { message: diff.message, currentUserId },
      });
    }

    if (!diff.message) return;

    // const id = generateUEID();
    const content = (
      <ToastContent
        feedbackSnapshot={diff}
        path={path}
        removeToast={() => removeToast(path)}
        date={new Date()}
      />
    );

    findAndClearTimeout(diff.id || path);

    // waiting for transition to be over before we trigger another same toast
    const timeout = setTimeout(() => {
      addToast(content, getToastOptions({ ...diff, id: diff.id || path }));
      findAndClearTimeout(diff.id || path);
    }, 500);
    timeouts.push({ timeout, id: diff.id || path });
  }, [path, diff, addToast, removeToast, currentUserId]);

  return null;
});

export default function FeedbackListener() {
  const feedbackPaths = useOriginalCopy(useSelector(feedbackSelector), equal);

  return feedbackPaths.map((path) => <ToastHandler key={path} path={path} />);
}

FeedbackListener.propTypes = {};
FeedbackListener.defaultProps = {};
