import { call, put, takeEvery, select } from 'redux-saga/effects';
import {
  CASE_DELETE_ANNOTATIONS_BEGIN,
  CASE_DELETE_ANNOTATIONS_SUCCESS,
  CASE_DELETE_ANNOTATIONS_FAILURE,
  CASE_DELETE_ANNOTATIONS_DISMISS_FEEDBACK,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { selectCurrentFolder, withCurrentCaseId } from 'common/selectors';
import { deleteItemImmutable } from 'utils/arrays';

export const deleteAnnotations = createPromiseAction(CASE_DELETE_ANNOTATIONS_BEGIN);

export function dismissDeleteAnnotationsFeedback() {
  return {
    type: CASE_DELETE_ANNOTATIONS_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_DELETE_ANNOTATIONS_BEGIN actions
export function* doDeleteAnnotations(action) {
  const {
    payload: { annotation, caseId },
  } = action;
  const currentFolder = yield select(selectCurrentFolder);

  const res = yield call(
    api.del,
    `/cases/${caseId}/tags/${currentFolder.id}/locations/${annotation.id}`,
  );

  if (res && res.error) {
    yield put({
      type: CASE_DELETE_ANNOTATIONS_FAILURE,
      feedback: {
        message: 'feedback.deleteAnnotationsFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_DELETE_ANNOTATIONS_SUCCESS,
    data: { ...annotation },
  });
  yield call(resolvePromiseAction, action, res);
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run[].
*/
export function* watchDeleteAnnotations() {
  yield takeEvery(deleteAnnotations, withCurrentCaseId(doDeleteAnnotations));
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_DELETE_ANNOTATIONS_BEGIN + '.TRIGGER':
      return {
        ...state,
        deleteAnnotationsPending: true,
        deleteAnnotationsFeedback: null,
      };

    case CASE_DELETE_ANNOTATIONS_SUCCESS:
      const indexOfAnnotationsToDelete = state.annotations.findIndex(
        (x) => x && x.id === action.data.annotation.id,
      );
      return {
        ...state,
        deleteAnnotationsPending: false,
        deleteAnnotationsFeedback: action.feedback,
        ...(state.annotations &&
          state.annotations.length > 0 &&
          indexOfAnnotationsToDelete > -1 && {
            annotations: deleteItemImmutable(state.annotations, indexOfAnnotationsToDelete),
          }),
      };

    case CASE_DELETE_ANNOTATIONS_FAILURE:
      return {
        ...state,
        deleteAnnotationsPending: false,
        deleteAnnotationsFeedback: action.feedback,
      };

    case CASE_DELETE_ANNOTATIONS_DISMISS_FEEDBACK:
      return {
        ...state,
        deleteAnnotationsFeedback: null,
      };

    default:
      return state;
  }
}
