import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { AUTH_LOGIN_REQUIRED, AUTH_LOGIN_REQUIRED_REMOVE } from './constants';

export function loginRequired(showScreen) {
  return {
    type: AUTH_LOGIN_REQUIRED,
    payload: { showScreen },
  };
}

export function removeLoginRequired() {
  return {
    type: AUTH_LOGIN_REQUIRED_REMOVE,
  };
}

export function useLoginRequired() {
  const dispatch = useDispatch();

  const { loginRequiredValue, showScreen } = useSelector(
    (state) => ({
      loginRequiredValue: state.auth.loginRequired,
      showScreen: state.auth.showScreen,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...params) => dispatch(loginRequired(...params)), [dispatch]);
  const boundAction2 = useCallback(
    (...params) => dispatch(removeLoginRequired(...params)),
    [dispatch],
  );

  return {
    loginRequired: loginRequiredValue,
    showScreen: showScreen,
    makeLoginRequired: boundAction,
    removeLoginRequired: boundAction2,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case AUTH_LOGIN_REQUIRED:
      return {
        ...state,
        loginRequired: true,
        showScreen: action.payload.showScreen,
      };
    case AUTH_LOGIN_REQUIRED_REMOVE:
      return {
        ...state,
        loginRequired: false,
        showScreen: null,
      };

    default:
      return state;
  }
}
