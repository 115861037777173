import React, { PureComponent } from 'react';
import T from 'i18n';
import ErrorPage from './ErrorPage';

export default class PageNotFound extends PureComponent {
  render() {
    return <ErrorPage text={T.translate('generic.404')} />;
  }
}
// We can't seem to find the page you are looking for.
