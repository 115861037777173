import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import ViewerWrapper from './ViewerWrapper';
import Split from 'react-split';
import classnames from 'classnames';
import {
  selectParams,
  selectMatchedUrl,
  selectCurrentCaseId,
  selectCurrentSelectedFileMetaData,
  selectUserSettingSearchMode,
  selectUserSettingDocFinder,
  selectUserSettingAllAnnotations,
  selectUserSettingPageRefs,
  selectedDocument,
  selectedAnnotation,
  selectUserAiTools,
  selectUseBackEndForPresentMode,
  selectDocFinderFilter,
} from 'common/selectors';
import { isMobile } from 'utils/device';

import TablePortion from './TablePortion';

export class TableAndViewer extends PureComponent {
  static propTypes = {};

  state = {
    fileType: this.props.fileType,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.fileType !== this.props.fileType)
      this.setState({ fileType: this.props.fileType });
    else if (this.props.file && !this.props.selectedDocument && !this.props.fileType) {
      this.props.actions.fetchFileDetails({ fileId: this.props.file }).then((res) => {
        this.setState({ fileType: res.type });
      });
    }
  }

  render() {
    const { file } = this.props;
    return isMobile ? (
      file ? (
        <ViewerWrapper
          fileId={file}
          fileType={this.state.fileType}
          isDocFinder={this.props.isDocFinder}
        />
      ) : (
        <TablePortion {...this.props} />
      )
    ) : (
      <div style={{ height: '100%', display: 'flex' }}>
        <Split
          sizes={file ? [45, 55] : [100, 0]}
          minSize={file ? 100 : 0}
          expandToMin={false}
          gutterSize={10}
          gutterAlign="center"
          snapOffset={30}
          dragInterval={1}
          direction="horizontal"
          cursor="col-resize"
          className={classnames({ 'no-gutter': !file }, 'case-table-and-viewer')}
        >
          <div>
            <TablePortion {...this.props} />
          </div>
          <div>
            {file && (
              <ViewerWrapper
                fileId={file}
                fileType={this.state.fileType}
                isDocFinder={this.props.isDocFinder && this.props.hasDocFinderFilter}
              />
            )}
          </div>
        </Split>
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  // It's gonna rerender due to other props, location, history, ...
  const {
    bundle,
    teamBundle,
    privateBundle,
    trialbook,
    courtBundle,
    groupBundle,
    quickLink,
    compositeKey,
    file,
  } = selectParams(state);
  const caseId = selectCurrentCaseId(state);
  const currentUrl = selectMatchedUrl(state);
  const currentlySelectedRow = selectCurrentSelectedFileMetaData(state);
  const isAiTools = selectUserAiTools(state);
  const showSearchMode = selectUserSettingSearchMode(state);
  const showDocFinder = selectUserSettingDocFinder(state);
  const showAllAnnotations = selectUserSettingAllAnnotations(state);
  const showPageRefs = selectUserSettingPageRefs(state);
  const useBackEndForPresentMode = selectUseBackEndForPresentMode(state);

  return {
    isBundle: !!bundle,
    isTeamBundle: !!teamBundle,
    isPrivateBundle: !!privateBundle,
    isTrialbook: !!trialbook,
    isCourtBundle: !!courtBundle,
    isGroupBundle: !!groupBundle,
    isQuickLink: !!quickLink,
    file,
    compositeKey,
    fileType: selectedDocument ? selectedDocument.type : selectedAnnotation ? 'doc' : undefined,
    selectedDocument,
    isPresentMode: !useBackEndForPresentMode && currentUrl.includes(`${caseId}/present-mode`),
    isPresentModeWithBackEnd:
      useBackEndForPresentMode && currentUrl.includes(`${caseId}/present-mode`),
    isAiTools: isAiTools && currentUrl.includes(`${caseId}/ai-tools`),
    isSearchMode: showSearchMode && currentUrl.includes(`${caseId}/search-mode`),
    isDocFinder: showDocFinder && currentUrl.includes(`${caseId}/docFinder`),
    isAllAnnotations: showAllAnnotations && currentUrl.includes(`${caseId}/all-annotations`),
    isPageRefs: showPageRefs && currentUrl.includes(`${caseId}/page-refs`),
    hasDocFinderFilter: selectDocFinderFilter(state),
    currentlySelectedRow,
    quickLink,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TableAndViewer);
