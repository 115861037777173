import React, { useState } from 'react';
import T from 'i18n';
import { useForm } from 'react-hook-form';
import { Spinner, TextForm, ToggleForm } from 'features/common';
import { Col, Form, Row } from 'react-bootstrap';
import { Modal } from 'features/common/MaterialBasedComponents';

const ModalGenerateHyperlinks = ({ show, handleClose, handleSave, action, selectedRows }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      show={show}
      title={T.translate('generic.generateHyperlinks')}
      handleClose={() => {
        handleClose();
      }}
      handleSave={handleSubmit((data) => {
        setLoading(true);
        handleSave(null, action, selectedRows, data, handleClose, setLoading);
      })}
      disableSave={loading}
      saveTitle={T.translate('generic.submit')}
    >
      {loading ? (
        <Spinner />
      ) : (
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Form.Group>
            <Row>
              <Col>
                <TextForm
                  {...register('matchUsingPrefix', { required: true })}
                  label={T.translate('case.matchUsingPrefix')}
                  placeholder={T.translate('case.matchUsingPrefixPlaceholder')}
                  errors={errors}
                  autofocus
                />
              </Col>
              <Col />
              <Col />
            </Row>
            <Row>
              <Col>
                <ToggleForm
                  {...register('overwriteExistingHyperlinks')}
                  defaultValue={true}
                  label={T.translate(`case.overwriteExistingHyperlinks`)}
                />
              </Col>
              <Col />
              <Col />
            </Row>
          </Form.Group>
        </Form>
      )}
    </Modal>
  );
};

export default ModalGenerateHyperlinks;
