import { call, put, takeLatest } from 'redux-saga/effects';

import {
  CASE_FETCH_TIMELINES_BEGIN,
  CASE_FETCH_TIMELINES_SUCCESS,
  CASE_FETCH_TIMELINES_FAILURE,
  CASE_FETCH_TIMELINES_DISMISS_FEEDBACK,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

export const fetchTimelines = createPromiseAction(CASE_FETCH_TIMELINES_BEGIN);

export function dismissFetchTimelinesFeedback() {
  return {
    type: CASE_FETCH_TIMELINES_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_FETCH_TIMELINES_BEGIN actions
export function* doFetchTimelines(action) {
  const {
    payload: { caseId },
  } = action;
  const res = yield call(api.get, `/cases/${caseId}/timelines`);

  if (res && res.error) {
    yield put({
      type: CASE_FETCH_TIMELINES_FAILURE,
      feedback: {
        message: 'feedback.fetchTimelinesFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_FETCH_TIMELINES_SUCCESS,
    data: res,
  });
  yield call(resolvePromiseAction, action, res);
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchFetchTimelines() {
  yield takeLatest(fetchTimelines, withCurrentCaseId(doFetchTimelines));
}

export function useFetchTimelines() {
  const dispatch = useDispatch();

  const { timelines, fetchTimelinesPending, fetchTimelinesFeedback } = useSelector(
    (state) => ({
      timelines: state.case.timelines,
      fetchTimelinesPending: state.case.fetchTimelinesPending,
      fetchTimelinesFeedback: state.case.fetchTimelinesFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(fetchTimelines(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissFetchTimelinesFeedback());
  }, [dispatch]);

  return {
    timelines,
    fetchTimelines: boundAction,
    fetchTimelinesPending,
    fetchTimelinesFeedback,
    dismissFetchTimelinesFeedback: boundDismissFeedback,
  };
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_FETCH_TIMELINES_BEGIN + '.TRIGGER':
      return {
        ...state,
        fetchTimelinesPending: true,
        fetchTimelinesFeedback: null,
      };

    case CASE_FETCH_TIMELINES_SUCCESS:
      return {
        ...state,
        fetchTimelinesPending: false,
        fetchTimelinesFeedback: action.feedback,
        timelines: action.data,
      };

    case CASE_FETCH_TIMELINES_FAILURE:
      return {
        ...state,
        fetchTimelinesPending: false,
        fetchTimelinesFeedback: action.feedback,
      };

    case CASE_FETCH_TIMELINES_DISMISS_FEEDBACK:
      return {
        ...state,
        fetchTimelinesFeedback: null,
      };

    default:
      return state;
  }
}
