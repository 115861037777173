import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n';
import { Button, IconButton, Tooltip, Radio, Grid, Paper, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import moment from 'moment';
import { confidentialityRingCategoryLabel, documentModalActions } from './enums';
import { FileDropZone, SelectConfidentialityRing } from 'features/common';
import { bytesToMegaBytes } from 'utils/formating';
import PreviewHyperlinks from './DocumentsModal/modals/hyperlinks/PreviewHyperlinks';
import ConcealedPreviewHyperlinks from './DocumentsModal/modals/hyperlinks/ConcealedPreviewHyperlinks';
import ModalCopyRemove from './DocumentsModal/modals/ModalCopyRemove';
import ModalUpdateMeta from './DocumentsModal/modals/ModalUpdateMeta';
import ModalMerge from './DocumentsModal/modals/ModalMerge';
import ModalReplace from './DocumentsModal/modals/ModalReplace';
import ModalGenerateHyperlinks from './DocumentsModal/modals/ModalGenerateHyperlinks';
import MoreInfoModal from './DocumentsModal/modals/MoreInfoModal';
import UpdateIndexModal from './DocumentsModal/modals/UpdateIndexMdal';
import { FileVersions } from './DocumentsModal/modals/FileVersions';
import FileVariants from './DocumentsModal/modals/FileVariants';
import TranscriptNotes from './DocumentsModal/modals/TranscriptNotes';
import ManageHyperlinks from './DocumentsModal/modals/hyperlinks/ManageHyperlinks';
import PreviewTranscriptNotes from './DocumentsModal/modals/hyperlinks/PreviewTranscriptNotes';

const GridTooltipContent = ({ data }) => {
  const { searchIndexTimestamp, searchIndexXmlTimestamp } = data;
  const searchIndexesData = {
    searchIndex: {
      label: 'searchIndex',
      createdTime: searchIndexTimestamp,
    },
    searchIndexXml: {
      label: 'searchIndexXml',
      createdTime: searchIndexXmlTimestamp,
    },
  };

  return (
    <Paper
      sx={{
        px: 1,
        backgroundColor: '#fff',
      }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{
          textAlign: 'left',
          width: 'auto',
          margin: 0,
          p: 2,
        }}
      >
        <Grid item xs={6}>
          <Typography
            variant="subtitle2"
            sx={{ color: '#9A9A9A', fontWeight: 400, fontSize: '1rem' }}
          >
            {T.translate('common.source').toUpperCase()}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            variant="subtitle2"
            sx={{ color: '#9A9A9A', fontWeight: 400, fontSize: '1rem' }}
          >
            {T.translate('common.created').toUpperCase()}
          </Typography>
        </Grid>
        {Object.values(searchIndexesData).map((item, index) => (
          <React.Fragment key={index}>
            <Grid item xs={6}>
              <Typography sx={{ color: '#212529', fontSize: '1rem' }}>{item.label}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ color: '#212529', fontSize: '1rem' }}>
                {moment(item.createdTime).format('DD/MM/YYYY HH:mm')}
              </Typography>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Paper>
  );
};

export const fileVersionsTablecolumnSelector = (
  updateFileVersions,
  createTiffAction,
  isAdminLevelPermisisons,
  action,
  setDeleteVersion,
  showDeleteFileVersion,
  lastVersion,
) => {
  return [
    (isAdminLevelPermisisons || action === documentModalActions.download) && {
      id: 'active',
      Header: action === documentModalActions.download ? 'common.selected' : 'common.active',
      Cell: (version) => (
        <div className="customGrid">
          <span>
            {version.type && version.type === 'dummy' ? (
              ''
            ) : (
              <Radio checked={version.active} onClick={() => updateFileVersions(version.id)} />
            )}
          </span>
        </div>
      ),
      minWidth: '60px',
      width: '10rem',
      sort: false,
    },
    {
      id: 'size',
      Header: 'common.size',
      Cell: (version) => (
        <div className="customGrid">
          <span>
            {!version.type ? (version.size ? bytesToMegaBytes(version.size) : '0 MB') : ''}
          </span>
        </div>
      ),
      minWidth: '80px',
      width: '15rem',
      sort: false,
    },
    {
      id: 'pageCount',
      Header: 'generic.pages',
      Cell: (version) => (
        <div className="customGrid">
          <span>{version.pageCount}</span>
        </div>
      ),
      minWidth: '80px',
      width: '10rem',
      sort: false,
    },
    {
      id: 'source',
      Header: 'common.source',
      Cell: (version) => {
        if (version.type === 'dummy') {
          return '';
        }

        if (
          version.source === 'ocr' &&
          (version.searchIndexTimestamp || version.searchIndexXmlTimestamp)
        ) {
          return (
            <div>
              <span>{version.source}</span>
              <Tooltip
                title={<GridTooltipContent data={version} />}
                placement="top"
                componentsProps={{
                  tooltip: {
                    sx: { bgcolor: 'transparent', p: 0 },
                  },
                }}
                PopperProps={{
                  modifiers: [
                    {
                      name: 'preventOverflow',
                      options: {
                        boundary: 'window',
                      },
                    },
                    {
                      name: 'offset',
                      options: {
                        offset: [0, 10],
                      },
                    },
                  ],
                }}
              >
                <IconButton size="small">
                  <InfoIcon sx={{ fontSize: 15, color: '#748CEC' }} />
                </IconButton>
              </Tooltip>
            </div>
          );
        }

        return <span>{version.source}</span>;
      },
      minWidth: '150px',
      width: '15rem',
      sort: false,
    },
    {
      id: 'created',
      Header: 'common.created',
      Cell: (version) => (
        <div className="customGrid">
          <span>
            {version.type && version.type === 'dummy'
              ? ''
              : moment(version.created).format('DD/MM/YYYY HH:mm')}
          </span>
        </div>
      ),
      minWidth: '150px',
      width: `calc(100% - ${(isAdminLevelPermisisons ? 10 : 0) + 55}rem )`,
      sort: false,
    },
    {
      id: 'action',
      Header: 'common.action',
      Cell: (version) => (
        <div className="customGrid">
          {version.type && version.type === 'dummy' && (
            <Button
              variant="text"
              sx={{ paddingLeft: 0, justifyContent: 'start' }}
              size="small"
              onClick={() => {
                createTiffAction();
              }}
              disabled={
                (version.type &&
                  version.type === 'dummy' &&
                  action !== documentModalActions.fileVersions) ||
                version.size === 0
              }
            >
              {T.translate('generic.createTiff')}
            </Button>
          )}
          {showDeleteFileVersion && lastVersion.id !== version.id && version?.type !== 'dummy' && (
            <Button
              variant="text"
              sx={{ paddingLeft: 0, justifyContent: 'start' }}
              size="small"
              onClick={() => {
                setDeleteVersion(version);
              }}
              disabled={action !== documentModalActions.fileVersions}
            >
              {T.translate('generic.delete')}
            </Button>
          )}
        </div>
      ),
      minWidth: '110px',
      width: '15rem',
      sort: false,
    },
  ].filter(Boolean);
};

export const fileVariantsColumnSelector = (
  handleFileUpload,
  handleChangeRing,
  ringMenuItems,
  handleDeleteRing,
  fileVariants,
  downloadRingDoc,
) => {
  return [
    {
      id: 'ring',
      Header: 'common.ring',
      Cell: (variant) =>
        variant.type !== 'dummy' && (
          <div className="customGrid">
            {ringMenuItems.length < 2 ? (
              <span>{variant.ring && confidentialityRingCategoryLabel[variant.ring]}</span>
            ) : (
              <SelectConfidentialityRing
                showLabel={false}
                defaultValue={variant.ring}
                onChange={({ target: { value } }) => handleChangeRing({ ...variant, ring: value })}
                disableNoRingMenu={!ringMenuItems.includes('noRing')}
                disableOuterRingMenu={!ringMenuItems.includes('outerRing')}
                disableInnerRingMenu={!ringMenuItems.includes('innerRing')}
              />
            )}
          </div>
        ),
      minWidth: '150px',
      width: '15rem',
      sort: false,
    },
    {
      id: 'pageCount',
      Header: 'common.pageCount',
      Cell: (version) => (
        <div className="customGrid">
          <span>{version.pageCount}</span>
        </div>
      ),
      minWidth: '110px',
      width: '10rem',
      sort: false,
    },
    {
      id: 'originalFilename',
      Header: 'common.fileName',
      Cell: (version) => (
        <div className="customGrid">
          <span>{version.originalFilename}</span>
        </div>
      ),
      minWidth: '190px',
      width: '20rem',
      sort: false,
    },
    {
      id: 'created',
      Header: 'common.created',
      Cell: (variant) => (
        <div className="customGrid">
          <span>
            {variant.type === 'dummy' ? '' : moment(variant.created).format('DD/MM/YYYY HH:mm')}
          </span>
        </div>
      ),
      minWidth: '110px',
      width: `10rem`,
      sort: false,
    },
    {
      id: 'action',
      Header: 'common.action',
      Cell: (variant) => {
        const dropzoneRef = createRef();
        const originalFileVariants = fileVariants.filter((itm) => itm.id);
        return variant.type === 'dummy' ? (
          <div className="customGrid">
            <FileDropZone
              refLink={dropzoneRef}
              showInfo
              confidentialityRing={variant.name}
              onDropCustom={(acceptedFiles) => handleFileUpload(acceptedFiles, variant.name)}
            >
              <div style={{ display: 'flex', flexDirection: 'column', padding: 0 }}>
                <Button
                  variant="text"
                  sx={{ paddingLeft: 0, justifyContent: 'start' }}
                  size="small"
                  onClick={() => openDialog(dropzoneRef)}
                >
                  {T.translate('generic.uploadRingDocBtnLabel', {
                    name: confidentialityRingCategoryLabel[variant.name],
                  })}
                </Button>
              </div>
            </FileDropZone>
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', padding: 0 }}>
            <Button
              variant="text"
              sx={{ paddingLeft: 0, justifyContent: 'start' }}
              size="small"
              onClick={() => downloadRingDoc(variant.ring)}
            >
              {T.translate('generic.download')}
            </Button>
            {fileVariants[0].id && originalFileVariants.length > 1 && (
              <Button
                variant="text"
                sx={{ paddingLeft: 0, justifyContent: 'start' }}
                size="small"
                onClick={() => handleDeleteRing(variant.id)}
              >
                {T.translate('generic.delete')}
              </Button>
            )}
          </div>
        );
      },
      minWidth: '150px',
      width: `calc(100% - 55rem)`,
      sort: false,
    },
  ].filter(Boolean);
};

const openDialog = (ref) => {
  if (ref.current) {
    ref.current.open();
  }
};

export default class DocumentsModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    handleSave: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    action: PropTypes.string.isRequired,
    selectedRows: PropTypes.array.isRequired,
    fileCount: PropTypes.number.isRequired,
  };

  render() {
    if (this.props.action === documentModalActions.merge) return <ModalMerge {...this.props} />;
    if (this.props.action === documentModalActions.updateMeta)
      return <ModalUpdateMeta {...this.props} />;
    if (this.props.action === documentModalActions.replace) return <ModalReplace {...this.props} />;
    if (this.props.action === documentModalActions.generateHyperlinks)
      return <ModalGenerateHyperlinks {...this.props} />;
    if (this.props.action === documentModalActions.generateTranscriptsbasedHyperlinks)
      return <ConcealedPreviewHyperlinks {...this.props} />;
    if (this.props.action === documentModalActions.findAndReplaceHyperlinks)
      return (
        <PreviewHyperlinks
          {...this.props}
          fromAllExternalIDs={true}
          fromAllDiscoveryIDs={false}
          fromPageRefs={true}
        />
      );
    if (this.props.action === documentModalActions.previewHyperlinks)
      return (
        <PreviewHyperlinks
          {...this.props}
          fromAllExternalIDs={true}
          fromAllDiscoveryIDs={false}
          fromPageRefs={false}
        />
      );
    if (this.props.action === documentModalActions.previewHyperlinksWithDiscoveryID)
      return (
        <PreviewHyperlinks
          {...this.props}
          fromAllExternalIDs={true}
          fromAllDiscoveryIDs={true}
          fromPageRefs={false}
        />
      );
    if (this.props.action === documentModalActions.manageHyperlinks) {
      return <ManageHyperlinks {...this.props} />;
    }
    if (this.props.action === documentModalActions.moreInfo)
      return <MoreInfoModal {...this.props} />;
    if (
      this.props.action === documentModalActions.fileVersions ||
      this.props.action === documentModalActions.download
    )
      return <FileVersions {...this.props} />;
    if (this.props.action === documentModalActions.fileVariants)
      return <FileVariants {...this.props} />;
    if (this.props.action === documentModalActions.uploadTranscriptNotes)
      return <TranscriptNotes {...this.props} />;

    if (this.props.action === documentModalActions.previewTranscriptNotes)
      return (
        <PreviewTranscriptNotes {...this.props} fromAllExternalIDs={true} fromPageRefs={false} />
      );
    if (this.props.action === documentModalActions.changePosition)
      return <UpdateIndexModal {...this.props} />;
    return <ModalCopyRemove {...this.props} />;
  }
}
