import api from 'common/api';
import {
  BatchUploadReport,
  FileMetaData,
  FolderNode,
  MappingProblem,
  ParsedTemplate,
  SasResponse,
} from './types';
import T from 'i18n';
import { BlobServiceClient } from '@azure/storage-blob';
import moment from 'moment';
import { PROBLEM_CATEGORIES, PROBLEM_CATEGORIES_LABELS, validFileExts } from './constants';

export const getMimeType = (extension: string): string => {
  const mimeTypes: {
    [key: string]: string;
  } = {
    pdf: 'application/pdf',
    png: 'image/png',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    txt: 'text/plain',
    doc: 'application/msword',
    dot: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    xls: 'application/vnd.ms-excel',
    xla: 'application/vnd.ms-excel',
    xlt: 'application/vnd.ms-excel',
    ppt: 'application/vnd.ms-powerpoint',
    ppa: 'application/vnd.ms-powerpoint',
    pot: 'application/vnd.ms-powerpoint',
    pps: 'application/vnd.ms-powerpoint',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    tiff: 'image/tiff',
  };

  return mimeTypes[extension.toLowerCase()] || 'application/octet-stream'; // default from mimetypes.cs
};

export const getFileExtension = (mimeType: string): string => {
  const mimeExtensions: { [key: string]: string } = {
    'application/pdf': 'pdf',
    'image/png': 'png',
    'image/jpeg': 'jpg',
    'text/plain': 'txt',
    'application/msword': 'doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'application/vnd.ms-excel': 'xls',
    'application/vnd.ms-powerpoint': 'ppt',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
    'image/tiff': 'tiff',
  };

  return mimeExtensions[mimeType] || '';
};

export const buildFolderTree = (files: FileMetaData[]) => {
  const root: FolderNode = { name: 'root', path: '', files: [], subfolders: {} };
  const folderPathsSet = new Set<string>();

  files.forEach((file) => {
    const pathParts = file.folderPath ? file.folderPath.split('/') : [];
    let currentNode = root;
    let currentPath = '';

    pathParts.forEach((part) => {
      currentPath = currentPath ? `${currentPath}/${part}` : part;
      folderPathsSet.add(currentPath);

      if (!currentNode.subfolders[part]) {
        currentNode.subfolders[part] = {
          name: part,
          path: currentPath,
          files: [],
          subfolders: {},
        };
      }
      currentNode = currentNode.subfolders[part];
    });

    currentNode.files.push(file);
  });

  folderPathsSet.forEach((folderPath) => {
    const pathParts = folderPath.split('/');
    let currentNode = root;
    let currentPath = '';

    pathParts.forEach((part) => {
      currentPath = currentPath ? `${currentPath}/${part}` : part;

      if (!currentNode.subfolders[part]) {
        currentNode.subfolders[part] = {
          name: part,
          path: currentPath,
          files: [],
          subfolders: {},
        };
      }
      currentNode = currentNode.subfolders[part];
    });
  });

  return root;
};

export const flattenFolderTree = (root: FolderNode) => {
  const result: { node: FolderNode; depth: number }[] = [];
  const stack: { node: FolderNode; depth: number }[] = [{ node: root, depth: 0 }];

  while (stack.length > 0) {
    const { node, depth } = stack.pop()!;
    result.push({ node, depth });

    const subfolderNames = Object.keys(node.subfolders).sort().reverse();
    subfolderNames.forEach((subfolderName) => {
      stack.push({ node: node.subfolders[subfolderName], depth: depth + 1 });
    });
  }

  return result;
};

export const findNodeByPath = (node: FolderNode, path: string): FolderNode | null => {
  if (node.path === path) {
    return node;
  }
  for (const subfolder of Object.values(node.subfolders)) {
    const result = findNodeByPath(subfolder, path);
    if (result) {
      return result;
    }
  }
  return null;
};

export const getAllSubfolderPaths = (node: FolderNode): string[] => {
  let paths: string[] = [];
  Object.values(node.subfolders).forEach((subfolder) => {
    paths.push(subfolder.path);
    paths = paths.concat(getAllSubfolderPaths(subfolder));
  });
  return paths;
};

export const areAllFilesUploaded = (node: FolderNode): boolean => {
  const allFilesInCurrentFolderUploaded = node.files.every(
    (file) => file.status === 'uploaded' || file.status === 'cancelled',
  );

  const allSubfoldersUploaded = Object.values(node.subfolders).every((subfolder) =>
    areAllFilesUploaded(subfolder),
  );

  return allFilesInCurrentFolderUploaded && allSubfoldersUploaded;
};

export const hasUnsupportedFiles = (node: FolderNode): boolean => {
  const hasUnsupportedInCurrentFolder = node.files.some((file) => file.status === 'unsupported');

  const hasUnsupportedInSubfolders = Object.values(node.subfolders).some((subfolder) =>
    hasUnsupportedFiles(subfolder),
  );

  return hasUnsupportedInCurrentFolder || hasUnsupportedInSubfolders;
};

export const getExportLink = async (caseId: string, currentBatchId: number | null) => {
  try {
    const response: any = await api.get(`/cases/${caseId}/batch/${currentBatchId}?export=csv`);
    const url = window.URL.createObjectURL(new Blob([response], { type: 'text/csv' }));
    const timeStamp = `${new Date().getFullYear()}-${new Date().getDate()}-${
      new Date().getMonth() + 1
    }--${new Date().getHours()}-${new Date().getMinutes()}-${new Date().getSeconds()}`;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Batch-upload-${timeStamp}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error downloading CSV:', error);
  }
};

export const notificationsExport = async (exportLink: string) => {
  try {
    const response: any = await api.get(exportLink);
    const url = window.URL.createObjectURL(new Blob([response], { type: 'text/csv' }));
    const timeStamp = `${new Date().getFullYear()}-${new Date().getDate()}-${
      new Date().getMonth() + 1
    }--${new Date().getHours()}-${new Date().getMinutes()}-${new Date().getSeconds()}`;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Batch-upload-${timeStamp}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error downloading CSV:', error);
  }
};

export const hasProtectedFiles = (node: FolderNode): boolean => {
  const hasInvalidFilesInCurrentFolder = node.files.some((file) => file.status === 'protected');

  const hasInvalidFilesInSubfolders = Object.values(node.subfolders).some((subfolder) =>
    hasProtectedFiles(subfolder),
  );

  return hasInvalidFilesInCurrentFolder || hasInvalidFilesInSubfolders;
};

export const isPDFEncrypted = (file: File): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    if (file.type !== 'application/pdf') {
      resolve(false);
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const data = event.target?.result as ArrayBuffer;
      const uint8Array = new Uint8Array(data);
      const text = new TextDecoder('utf-8').decode(uint8Array);
      if (text.includes('/Encrypt')) {
        resolve(true);
      } else {
        resolve(false);
      }
    };
    reader.onerror = () => {
      resolve(false);
    };
    reader.readAsArrayBuffer(file.slice(0, 1024)); // Read first 1KB
  });
};

export const parseCSV = (csvText: string): any[] => {
  const lines = csvText.trim().split('\n');
  const headers = parseCSVLine(lines[0]);

  const data = lines.slice(1).map((line) => {
    const values = parseCSVLine(line);
    const obj: any = {};
    headers.forEach((header, index) => {
      obj[header] = values[index] || '';
    });
    return obj;
  });

  return data;
};

function parseCSVLine(line: string): string[] {
  const values: string[] = [];
  let currentField = '';
  let inQuotes = false;

  for (let i = 0; i < line.length; i++) {
    const char = line[i];

    if (char === '"') {
      if (inQuotes && line[i + 1] === '"') {
        currentField += '"';
        i++;
      } else {
        inQuotes = !inQuotes;
      }
    } else if (char === ',' && !inQuotes) {
      values.push(currentField);
      currentField = '';
    } else {
      currentField += char;
    }
  }
  values.push(currentField);

  return values.map((field) => field.trim());
}

export const getSasToken = async (caseId: string) => {
  try {
    const response = (await api.get(`/cases/${caseId}/batch-token`)) as
      | SasResponse
      | { error: any };
    if ('error' in response) {
      throw new Error(T.translate('case.batchUpload.fetchSasTokenError'));
    }

    const sasUrl = response?.sasToken;
    const containerName = response?.container;

    if (!sasUrl || !containerName) {
      throw new Error(T.translate('case.batchUpload.missingSasToken'));
    }
    return { sasUrl, containerName };
  } catch (error) {
    throw error;
  }
};

export const getContainerClient = async (caseId: string) => {
  const { sasUrl } = await getSasToken(caseId);
  const blobServiceClient = new BlobServiceClient(sasUrl);
  const newContainerClient = blobServiceClient.getContainerClient('');
  return newContainerClient;
};

export const createBatch = async (
  caseId?: string,
  currentFolderId?: string,
  payload?: any[], // data array from DataMapper or MappingPreview
  templateId?: string,
  route?: string,
) => {
  try {
    const fieldsSet = new Set<string>();

    payload?.forEach((row) => {
      Object.keys(row || {}).forEach((key) => {
        fieldsSet.add(key);
      });
    });
    const excludedKeys = new Set<string>([
      'rowNumber', // read-only row index
      '__primaryKey', // file identifier or internal PK
    ]);

    const fieldsToMap = [...fieldsSet].filter((key) => !excludedKeys.has(key));

    const response = await api.post(`/cases/${caseId}/batch`, {
      folderId: currentFolderId,
      data: payload,
      templateId,
      fieldsToMap,
      rootFolderLink: route,
    });

    if (!response || (response as any)?.error) {
      throw new Error((response as any)?.error);
    }
    return response;
  } catch (error) {
    throw new Error(error as any);
  }
};

export const triggerBatchProcessing = async (batchId: any, caseId: string) => {
  try {
    const response = await api.put(`/cases/${caseId}/batch/${batchId}`);
    if (!response || (response as any)?.error) {
      throw new Error(T.translate('case.batchUpload.triggerBatchError'));
    }
  } catch (error) {
    throw new Error(T.translate('case.batchUpload.triggerBatchError'));
  }
};

export const fetchBatchReport = async (caseId: string, batchId: any, setBatchUploadReport: any) => {
  try {
    const report = await api.get(`/cases/${caseId}/batch/${batchId}`);
    if (report && (report as any)?.error) {
      throw new Error(T.translate('case.batchUpload.fetchReportWarning'));
    }
    setBatchUploadReport(report as BatchUploadReport);
  } catch {
    console.warn(T.translate('case.batchUpload.fetchReportWarning'));
  }
};

export const parseTemplates = (templatesData: any, setTemplates: any) => {
  const parsedTemplates: ParsedTemplate[] = templatesData.map((template: any) => {
    let requiredFields: string[] = [];
    let dateFormats: string[] = [];
    let fileIdentifier = '';
    let formatsForDates: string[] = [];
    let fieldsWithAppend: string[] = [];
    template?.stages.forEach((stage: any) => {
      if (stage.fileIdentifier) {
        fileIdentifier = stage.fileIdentifier;
      }
      requiredFields = [...requiredFields, ...stage.requiredFields];
      if (stage.dateFormats) {
        if (stage.dateFormats.length > 0) {
          dateFormats = stage.dateFormats;
        }
      }
      if (stage.formatsForDates) {
        if (stage.formatsForDates.length > 0) {
          formatsForDates = stage.formatsForDates;
        }
      }
      if (stage.fieldsWithAppend) {
        if (stage.fieldsWithAppend.length > 0) {
          fieldsWithAppend = stage.fieldsWithAppend;
        }
      }
    });

    return {
      id: template?.id,
      name: template?.name,
      requiredFields,
      dateFormats,
      fileIdentifier,
      formatsForDates,
      fieldsWithAppend,
    };
  });

  setTemplates(parsedTemplates);
};

export const isRowEmpty = (row: any) => {
  return Object.values(row).every((value) => value === '' || value === null || value === undefined);
};

export const createExportTimeStamp = () => {
  const timeStamp = `${new Date().getFullYear()}-${new Date().getDate()}-${
    new Date().getMonth() + 1
  }--${new Date().getHours()}-${new Date().getMinutes()}-${new Date().getSeconds()}`;
  return timeStamp;
};

export const exportErrorsToCSV = (failedRows: MappingProblem[]) => {
  if (!failedRows || failedRows.length === 0) {
    alert(T.translate('case.batchUpload.dataMapper.noErrorsToExport'));
    return;
  }
  const timeStamp = createExportTimeStamp();
  const headers = ['Type', 'Problem Category', 'Reason', 'Row Number'];
  const sortedRows = [...failedRows].sort((a, b) => {
    if (a.problemCategory < b.problemCategory) return -1;
    if (a.problemCategory > b.problemCategory) return 1;
    if (a.type < b.type) return -1;
    if (a.type > b.type) return 1;
    return 0;
  });
  let csvContent = headers.join(',') + '\n';

  sortedRows.forEach((row) => {
    const rowData = [
      row.type.charAt(0).toUpperCase() + row.type.slice(1),
      PROBLEM_CATEGORIES_LABELS[row.problemCategory as keyof typeof PROBLEM_CATEGORIES_LABELS] ||
        row.problemCategory,
      row.reason.replace(/"/g, '""'),
      row.rowNumber !== null ? row.rowNumber : '',
    ];

    csvContent += rowData.map((cell) => `"${cell}"`).join(',') + '\n';
  });

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', `${timeStamp}-mapping_errors.csv`);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const exportRawRowsToCSV = (rows: any[]) => {
  if (!rows || rows.length === 0) return;

  const timeStamp = createExportTimeStamp();

  const headers = Object.keys(rows[0]);

  const csvLines: string[] = [];
  csvLines.push(headers.join(','));

  for (const row of rows) {
    const line = headers.map((header) => (row[header] != null ? row[header] : '')).join(',');
    csvLines.push(line);
  }

  const csvBlob = new Blob([csvLines.join('\n')], { type: 'text/csv;charset=utf-8;' });
  const csvURL = URL.createObjectURL(csvBlob);

  const link = document.createElement('a');
  link.href = csvURL;
  link.download = `${timeStamp}_orphanRows.csv`;
  link.style.visibility = 'hidden';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export function validateDateFieldNoPush(
  field: string,
  value: string,
  formatsForDates: string[] | undefined,
) {
  if (field.toLowerCase().includes('date') && formatsForDates && formatsForDates.length > 0) {
    let isValidDate = false;
    formatsForDates.some((format) => {
      let formatUpper = format.toUpperCase();
      if (formatUpper.includes('HH')) {
        formatUpper = formatUpper.replace('MM', 'mm');
        formatUpper = formatUpper.replace('SS', 'ss');
      }
      const date = moment(value, formatUpper, true).isValid();
      if (date) {
        isValidDate = true;
      }
      return date;
    });
    return !isValidDate;
  }
  return false;
}

export function validateDateField(
  field: string,
  value: string,
  formatsForDates: string[] | undefined,
  csvRow: any,
  columnName: string,
  invalidRows: MappingProblem[],
) {
  if (field.toLowerCase().includes('date') && formatsForDates && formatsForDates.length > 0) {
    let isValidDate = false;
    formatsForDates.some((format) => {
      let formatUpper = format.toUpperCase();
      if (formatUpper.includes('HH')) {
        formatUpper = formatUpper.replace('MM', 'mm');
        formatUpper = formatUpper.replace('SS', 'ss');
      }
      const date = moment(value, formatUpper, true).isValid();
      if (date) {
        isValidDate = true;
      }
      return date;
    });

    if (!isValidDate) {
      invalidRows.push({
        rowNumber: csvRow.__rowNum,
        reason: T.translate('case.batchUpload.dataMapper.reasons.invalidDate', {
          row: csvRow.__rowNum,
          columnName,
          value,
        }),
        columnName: field,
        type: 'warning',
        problemCategory: PROBLEM_CATEGORIES.invalidDate,
      });
    }
  }
}

export const removeTrailingSpacesFromFilesWithAndWithoutExtensions = (file: string) => {
  const fileParts = file?.split('.');
  if (fileParts?.length > 1) {
    // foo. bar.txt
    const fileName = fileParts?.slice(0, -1).join('.');
    const fileExtension = fileParts[fileParts?.length - 1]; //.txt
    const isLastPartInValidFileExts = validFileExts?.includes(fileExtension); // true
    if (isLastPartInValidFileExts) {
      // join up the parts before the extension and add on the extension itself
      const allPartsBeforeExtensionAsString = file?.substring(0, file?.lastIndexOf('.'))?.trim();
      return `${allPartsBeforeExtensionAsString}.${fileExtension?.trim()}`;
    }
    return `${fileName?.trim()}.${fileExtension?.trim()}`;
  }
  return file?.trim();
};

export const exportConfig = ({ failedRows, documents, rawCSVRows, columns }: any) => {
  const handleExportNotFoundInCSV = () => {
    const notFoundRows = failedRows.filter((r: any) => r.problemCategory === 'notFoundInCSV');
    if (!documents) return;
    const notFoundDocs = documents.filter((doc: any) =>
      notFoundRows.some((rr: any) => rr.fileName === doc.name),
    );
    if (notFoundDocs.length > 0) {
      const trimmed = notFoundDocs.map((doc: any) => {
        const row: Record<string, any> = {};
        columns.forEach((col: string) => {
          row[col] = doc[col];
        });
        return row;
      });

      exportRawRowsToCSV(trimmed);
    }
  };

  const handleExportOrphanRows = () => {
    const orphanRows = failedRows.filter(
      (r: any) => r.problemCategory === 'orphanRow' && r.rowNumber != null,
    );
    if (!rawCSVRows) return;
    const matchedOrphans = orphanRows
      .map((orphan: any) => {
        return rawCSVRows.find((csvRow: any) => csvRow.__rowNum === orphan.rowNumber);
      })
      .filter(Boolean);
    if (matchedOrphans.length > 0) {
      exportRawRowsToCSV(matchedOrphans);
    }
  };

  const handleExportWarning = () => {
    const missingReqWarnings = failedRows.filter(
      (r: any) => r.type === 'warning' && r.problemCategory === 'missingRequiredField',
    );
    if (missingReqWarnings.length > 0) {
      exportRawRowsToCSV(missingReqWarnings);
    }
  };

  return [
    {
      key: 'notFoundInCSV',
      label: T.translate('case.batchUpload.errors.notFoundInCSV', {
        defaultValue: 'Not Found in CSV',
      }),
      filterFn: (row: MappingProblem) => row.problemCategory === 'notFoundInCSV',
      handlerFn: handleExportNotFoundInCSV,
    },
    {
      key: 'orphanRow',
      label: T.translate('case.batchUpload.errors.orphanRow', {
        defaultValue: 'Row not associated with any file',
      }),
      filterFn: (row: MappingProblem) =>
        row.problemCategory === 'orphanRow' && row.rowNumber != null,
      handlerFn: handleExportOrphanRows,
    },
    {
      key: 'separator',
      label: '-',
      filterFn: () => false,
      handlerFn: () => {},
    },
    {
      key: 'invalidDate',
      label: T.translate('case.batchUpload.errors.invalidDate', {
        defaultValue: 'Invalid Date',
      }),
      filterFn: (row: MappingProblem) => row.problemCategory === 'invalidDate',
      handlerFn: handleExportWarning,
    },

    {
      key: 'missingRequiredField',
      label: T.translate('case.batchUpload.errors.missingRequiredField', {
        defaultValue: 'Missing Required Field',
      }),
      filterFn: (row: MappingProblem) => row.problemCategory === 'missingRequiredField',
      handlerFn: handleExportWarning,
    },
  ];
};
