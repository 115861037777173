import {
  CASE_FETCH_TEMPLATES_BEGIN,
  CASE_FETCH_TEMPLATES_SUCCESS,
  CASE_FETCH_TEMPLATES_FAILURE,
  CASE_FETCH_TEMPLATES_DISMISS_FEEDBACK,
} from './constants';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';

export const fetchTemplates = createPromiseAction(CASE_FETCH_TEMPLATES_BEGIN, (payload) => ({
  inputData: payload,
}));

export function dismissFetchTemplatesFeedback() {
  return {
    type: CASE_FETCH_TEMPLATES_DISMISS_FEEDBACK,
  };
}

export function* doFetchTemplates(action) {
  let res = yield call(api.get, '/cases-templates');

  if (res && res.error) {
    yield put({
      type: CASE_FETCH_TEMPLATES_FAILURE,
      feedback: {
        message: 'feedback.fetchTemplatesFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_FETCH_TEMPLATES_SUCCESS,
    data: { templates: res },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchFetchTemplates() {
  yield takeLatest(fetchTemplates, doFetchTemplates);
}

export function useFetchTemplates() {
  const dispatch = useDispatch();

  const { templates, fetchTemplatesPending, fetchTemplatesFeedback } = useSelector(
    (state) => ({
      templates: state.case.templates,
      fetchTemplatesPending: state.case.fetchTemplatesPending,
      fetchTemplatesFeedback: state.case.fetchTemplatesFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(fetchTemplates(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissFetchTemplatesFeedback());
  }, [dispatch]);

  return {
    templates,
    fetchTemplates: boundAction,
    fetchTemplatesPending,
    fetchTemplatesFeedback,
    dismissFetchTemplatesFeedback: boundDismissFeedback,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_FETCH_TEMPLATES_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        fetchTemplatesPending: true,
        fetchTemplatesFeedback: null,
      };

    case CASE_FETCH_TEMPLATES_SUCCESS:
      // The request is success
      return {
        ...state,
        fetchTemplatesPending: false,
        fetchTemplatesFeedback: action.feedback,
        templates: action.data.templates,
      };

    case CASE_FETCH_TEMPLATES_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchTemplatesPending: false,
        fetchTemplatesFeedback: action.feedback,
      };

    case CASE_FETCH_TEMPLATES_DISMISS_FEEDBACK:
      // Dismiss the request failure error
      return {
        ...state,
        fetchTemplatesFeedback: null,
      };

    default:
      return state;
  }
}
