import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import T from 'i18n';
import classnames from 'classnames';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import { makeStyles } from '@mui/styles';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { faTrashAlt, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import * as actions from './redux/actions';
import ActionsButton from '../common/ActionsButton';
import Table from '../common/Table';
import SortHeader from './TableCase/SortHeader';
import CaseRolesModal from './CaseRolesModal';
import { caseRolesModalAction } from './enums';
import { useFetchCaseDetails } from './redux/fetchCaseDetails';
import { PageNotFound } from 'features/common';
import { selectIsAdmin, selectIsCaseAdmin } from 'common/selectors';

const caseSpecificRoles = [
  { roleId: 'caseAdmin', name: 'Case admin', description: 'manages all teams' },
  { roleId: 'moderator', name: 'Moderator', description: 'manages hearing room' },
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#F7F7F8',
    padding: theme.spacing(2, 4),
    height: '100%',
    padding: '0 0.7rem',
    overflow: 'overlay',
  },
  fullsize: {
    height: '100%',
  },
  main: {
    backgroundColor: '#F7F7F8',
    padding: theme.spacing(2, 2),
    height: '100%',
  },
  avatarSize: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: '0.9rem',
  },
  accepted: {
    color: '#fff',
    backgroundColor: '#4CAF50',
  },
}));

const columnSelector = (classes) => {
  const getInitials = (val) => {
    const names = val.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };
  const getRoleDetails = (roleId) => {
    const selectedRole = caseSpecificRoles.find((item) => item.roleId === roleId);
    if (selectedRole) return `${selectedRole.name} - ${selectedRole.description}`;
    else return roleId;
  };
  return [
    {
      Header: () => <SortHeader text="case.name" />,
      id: 'name',
      Cell: (user) => (
        <div className="customGrid">
          {user.name && (
            <Avatar className={classnames(classes.avatarSize, classes[user.teamInviteStatus])}>
              {getInitials(user.name)}
            </Avatar>
          )}
          <span style={{ marginLeft: '0.5rem' }}>{user.name}</span>
        </div>
      ),
      minWidth: '360px',
      width: 'calc(100% - 90rem)',
    },
    {
      id: 'email',
      Header: () => <SortHeader text="case.emailAddress" />,
      Cell: (user) => (
        <div className="customGrid">
          <span>{user.email}</span>
        </div>
      ),
      minWidth: '360px',
      width: '45rem',
    },
    {
      id: 'roleId',
      Header: () => <SortHeader text="case.caseRoles" />,
      Cell: (user) => (
        <div className="customGrid">
          <span>{getRoleDetails(user.roleId)}</span>
        </div>
      ),
      minWidth: '360px',
      width: '45rem',
    },
  ];
};

const CaseRolesTableCase = React.memo(({ onRowSelected, users }) => {
  const classes = useStyles();
  const columns = columnSelector(classes);
  const isItemLoaded = ({ index }) => !!users[index];
  const loadMoreItems = () => {
    return;
  };

  return (
    <>
      {users && users.length > 0 ? (
        <Table
          key={Math.random()}
          onRowSelected={onRowSelected}
          data={users}
          columns={columns}
          isItemLoaded={isItemLoaded}
          loadMoreItems={loadMoreItems}
          totalDocumentsCount={0}
          rowHeight={50}
        />
      ) : (
        <div className="no-users">{T.translate('case.noUsersAssignedMsg')}</div>
      )}
    </>
  );
});

const CaseRoles = () => {
  const dispatch = useDispatch();
  const { caseDetails } = useFetchCaseDetails();
  const classes = useStyles();
  const [caseAdminsAndModerators, setCaseAdminsAndModerators] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [modalAction, setModalAction] = useState('');
  const [showModal, setShowModal] = useState(false);
  const isCaseAdmin = useSelector(selectIsCaseAdmin);
  const isSystemAdmin = useSelector(selectIsAdmin);

  const onRowSelected = useCallback(
    (newlytSelectedRows) => {
      setSelectedRows(newlytSelectedRows);
    },
    [setSelectedRows],
  );

  const handleClose = () => {
    setShowModal(false);
    setModalAction('');
  };

  const handleShow = (modAction) => {
    setShowModal(true);
    setModalAction(modAction);
  };

  const getOriginalId = (id) => {
    return id.substring(0, id.lastIndexOf('_'));
  };

  const handleSave = (action, data) => {
    switch (action) {
      case caseRolesModalAction.removeMember:
        dispatch(
          actions.removeCaseRolesUser({
            roleType: data.roleId,
            userId: getOriginalId(data.id),
          }),
        )
          .then(() => getAllUsers())
          .catch((err) => console.error(err));
        break;
      case caseRolesModalAction.addNewMember:
        dispatch(
          actions.addCaseRolesUser({
            roleType: data.roleId,
            user: { email: data.email },
          }),
        )
          .then(() => getAllUsers())
          .catch((err) => console.error(err));
        break;
      default:
    }
  };

  const buttonActions = () => {
    return [
      {
        title: T.translate('case.addNewMember'),
        handler: () => handleShow(caseRolesModalAction.addNewMember),
        icon: faPlusCircle,
      },
      {
        title: T.translate('generic.remove'),
        handler: () => handleShow(caseRolesModalAction.removeMember),
        icon: faTrashAlt,
        disabled: selectedRows.length < 1,
      },
    ];
  };

  const getAllUsers = useCallback(() => {
    dispatch(actions.fetchCaseRoles())
      .then((res) => {
        const getFlatUsers = (options) =>
          options.reduce(
            (acc, item) => [
              ...acc,
              ...item.users.map((user) => {
                return { roleId: item.role, ...user, id: user.id + '_' + item.role };
              }),
            ],
            [],
          );
        setCaseAdminsAndModerators(getFlatUsers(res));
      })
      .catch((err) => console.error(err));
  }, [dispatch]);

  useEffect(() => caseDetails && getAllUsers(), [dispatch, caseDetails, getAllUsers]);

  if (!isCaseAdmin && !isSystemAdmin) return <PageNotFound />;

  return (
    <div className={classes.root}>
      <Grid container spacing={2} className={classes.fullsize}>
        {caseAdminsAndModerators && (
          <Grid container item xs={12}>
            <div className="case-table-wrapper admin-table-wrapeer" key="table-wrapper">
              <Container fluid className="h-100">
                <Row className="justify-content-between align-items-center p-2 px-3">
                  <Breadcrumb>
                    <Breadcrumb.Item active={true}>{T.translate('case.caseROLES')}</Breadcrumb.Item>
                  </Breadcrumb>
                  <ActionsButton buttonActions={buttonActions()} />
                </Row>
                <Row className="secondRow">
                  <Col md={12} className="h-100">
                    <Card>
                      <CaseRolesTableCase
                        onRowSelected={onRowSelected}
                        users={caseAdminsAndModerators}
                      />
                    </Card>
                  </Col>
                </Row>
              </Container>
              <CaseRolesModal
                show={showModal}
                action={modalAction}
                handleClose={handleClose}
                handleSave={handleSave}
                selectedRows={selectedRows}
                roles={caseSpecificRoles}
              />
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default CaseRoles;
