import loggingOn from './loggingOn';
import { isObject, isString } from 'utils/object';

const VERBOSE = (message, ...optionalParams) => {
  // eslint-disable-next-line no-console
  if (loggingOn) {
    console.log(...['[VERBOSE] ' + message].concat(optionalParams));
  }
};

const INFO = (message, ...optionalParams) => {
  // eslint-disable-next-line no-console
  if (loggingOn) {
    console.info(...['[INFO] ' + message].concat(optionalParams));
  }
};

const WARN = (message, ...optionalParams) => {
  // eslint-disable-next-line no-console
  if (loggingOn) {
    console.warn(...['[WARN] ' + message].concat(optionalParams));
  }
};
const DIR = (message) => {
  // eslint-disable-next-line no-console
  if (loggingOn) {
    console.dir(message);
  }
};
const ERROR = (message, ...optionalParams) => {
  // eslint-disable-next-line no-console
  if (loggingOn) {
    console.error(
      ...[
        '[ERROR] ' +
          (isObject(message) && !isString(message) ? Object.values(message).join(' - ') : message),
      ].concat(optionalParams),
    );
  }
};

export default { VERBOSE, INFO, WARN, ERROR, DIR };
