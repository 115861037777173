import React from 'react';
import {
  Pause,
  PlayArrow,
  Subject,
  Subtitles,
  SubtitlesOff,
  VolumeOff,
  VolumeUp,
} from '@mui/icons-material';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { playerActions } from '../enums';
import { createStyles, makeStyles } from '@mui/styles';
import T from 'i18n';
import Slider from './Slider';
import Duration from './Duration';

const useStyles = makeStyles(() =>
  createStyles({
    playerControlContainer: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      padding: '1rem',
      color: 'whitesmoke',
      zIndex: 1,
    },
    playerBtnContainer: {
      width: '100%',
      justifyContent: 'space-between',
    },
  }),
);

export default ({
  isFullSizeViewer,
  playerState,
  handlePlayerActions,
  handlePlayerSliderChange,
  handleOpenPopOver,
  showControls,
  showSubTitles,
  hasSubtitles,
  hasTranscript,
  playerRef,
}) => {
  const classes = useStyles();
  const { playing, muted, duration } = playerState;

  return (
    <Grid
      container
      className={classes.playerControlContainer}
      onClick={(e) => e.stopPropagation()}
      sx={{
        ...(showControls
          ? { opacity: 1, transition: 'opacity 1s' }
          : { opacity: 0, transition: 'opacity 2s linear 1s' }),
      }}
    >
      <Grid container sx={{ p: '0 0.5rem' }}>
        <Slider
          duration={duration}
          playerRef={playerRef}
          handlePlayerSliderChange={handlePlayerSliderChange}
        />
      </Grid>
      <Grid container item className={classes.playerBtnContainer}>
        <Grid item>
          <Grid container columnSpacing={2}>
            <Grid item>
              {playing ? (
                <IconButton
                  sx={{ color: 'whitesmoke' }}
                  onClick={(e) => {
                    handlePlayerActions(playerActions.PAUSE);
                    e.stopPropagation();
                  }}
                >
                  <Pause fontSize="large" />
                </IconButton>
              ) : (
                <IconButton
                  sx={{ color: 'whitesmoke' }}
                  onClick={(e) => {
                    handlePlayerActions(playerActions.PLAY);
                    e.stopPropagation();
                  }}
                >
                  <PlayArrow fontSize="large" />
                </IconButton>
              )}
            </Grid>
            <Grid item sx={{ alignSelf: 'center' }}>
              <Duration duration={duration} playerRef={playerRef} />
            </Grid>
            <Grid item>
              {muted ? (
                <IconButton
                  sx={{ color: 'whitesmoke' }}
                  onClick={(e) => {
                    handlePlayerActions(playerActions.UNMUTE);
                    e.stopPropagation();
                  }}
                >
                  <VolumeOff fontSize="large" />
                </IconButton>
              ) : (
                <IconButton
                  sx={{ color: 'whitesmoke' }}
                  onClick={(e) => {
                    handlePlayerActions(playerActions.MUTE);
                    e.stopPropagation();
                  }}
                >
                  <VolumeUp fontSize="large" />
                </IconButton>
              )}
            </Grid>
            {/* <Grid item sx={{ width: '8rem' }}>
              <Slider valueLabelDisplay="auto" size="small" sx={{ color: 'whitesmoke' }} />
            </Grid> */}
          </Grid>
        </Grid>
        <Grid item>
          <Grid container columnSpacing={2}>
            {hasSubtitles && (
              <Grid item>
                <Tooltip
                  title={
                    showSubTitles
                      ? T.translate('viewing.hideSubTitles')
                      : T.translate('viewing.showSubTitles')
                  }
                  arrow
                  placement="top-start"
                >
                  <IconButton
                    sx={{ color: 'whitesmoke' }}
                    onClick={(e) => {
                      handlePlayerActions(
                        showSubTitles ? playerActions.HIDESUBTITLES : playerActions.SHOWSUBTITLES,
                      );
                      e.stopPropagation();
                    }}
                  >
                    {showSubTitles ? (
                      <SubtitlesOff fontSize="large" />
                    ) : (
                      <Subtitles fontSize="large" />
                    )}
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
            {hasTranscript && !isFullSizeViewer && (
              <Grid item>
                <Tooltip title={T.translate('viewing.showTranscript')} arrow placement="top-start">
                  <IconButton
                    sx={{ color: 'whitesmoke' }}
                    onClick={(e) => {
                      handleOpenPopOver(e);
                      e.stopPropagation();
                    }}
                  >
                    <Subject fontSize="large" />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
