import { useCallback, useEffect, useRef, useState } from 'react';
import {
  SCROLL_MARK_TYPE,
  PRESENT_COLOR,
  SELECTION_COLOR,
  PAGENUMBER_MARK_TYPE,
} from '../constants';
import logger from 'utils/logger';

export const useMarks = (
  viewerControl: any,
  isPresenting: boolean,
  isTheatreMode: boolean,
  pauseCreateMarkHandlerRef: React.MutableRefObject<boolean>,
  pauseCreateMarkHandlerRef2: React.MutableRefObject<boolean>,
) => {
  const [markToBeCreated, setMarkToBeCreated] = useState<any>();
  const [markToBeUpdated, _setMarkToBeUpdated] = useState<any>();

  const pauseRemovedMarkHandlerRef = useRef<any>(null);

  const oldMarkToBeUpdatedColorRef = useRef<any>(null);

  const setMarkToBeUpdated = (mark: any) => {
    _setMarkToBeUpdated((prev: any) => {
      if (prev !== mark) {
        if (mark) {
          oldMarkToBeUpdatedColorRef.current = mark.fillColor;
          mark.fillColor = SELECTION_COLOR;
        } else {
          prev.fillColor = oldMarkToBeUpdatedColorRef.current;
          oldMarkToBeUpdatedColorRef.current = null;
        }
        return mark;
      } else {
        return prev;
      }
    });
  };

  const deleteMarkToBeCreated = useCallback(() => {
    try {
      pauseRemovedMarkHandlerRef.current = true;

      if (markToBeCreated) {
        setMarkToBeCreated(null);
        try {
          // if there's no such mark, it will throw an error
          viewerControl.deleteMarks([markToBeCreated]);
        } catch (e) {
          logger.ERROR(e);
        }
      }
    } finally {
      setTimeout(() => {
        pauseRemovedMarkHandlerRef.current = false;
      }, 250);
    }
  }, [markToBeCreated, viewerControl]);

  const markCreatedHandler = useCallback(
    ({ mark }: any) => {
      if (pauseCreateMarkHandlerRef.current || pauseCreateMarkHandlerRef2.current) return;

      const isScrollMark = mark.type === SCROLL_MARK_TYPE;
      const isPageNumberMark = mark.type === PAGENUMBER_MARK_TYPE;
      const isPresentMark = mark.fillColor === PRESENT_COLOR;

      if (
        (!isPresenting && !isTheatreMode) ||
        (isTheatreMode && !isPresentMark && !isScrollMark && !isPageNumberMark)
      ) {
        setMarkToBeCreated((prev: any) => {
          if (prev !== mark) {
            setMarkToBeUpdated(undefined);
            return mark;
          } else {
            return prev;
          }
        });
      }
    },
    [isPresenting, isTheatreMode, pauseCreateMarkHandlerRef, pauseCreateMarkHandlerRef2],
  );

  useEffect(() => {
    viewerControl?.on('MarkCreated', markCreatedHandler);

    return () => {
      viewerControl?.off('MarkCreated', markCreatedHandler);
    };
  }, [markCreatedHandler, viewerControl]);

  return {
    deleteMarkToBeCreated,
    setMarkToBeUpdated,
    setMarkToBeCreated,
    markToBeUpdated,
    markToBeCreated,
  };
};
