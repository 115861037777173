export const CONFERENCE_GET_TOKEN_BEGIN = 'CONFERENCE_GET_TOKEN_BEGIN';
export const CONFERENCE_GET_TOKEN_SUCCESS = 'CONFERENCE_GET_TOKEN_SUCCESS';
export const CONFERENCE_GET_TOKEN_FAILURE = 'CONFERENCE_GET_TOKEN_FAILURE';
export const CONFERENCE_GET_TOKEN_DISMISS_FEEDBACK = 'CONFERENCE_GET_TOKEN_DISMISS_FEEDBACK';
export const CONFERENCE_FETCH_HEARING_ROOM_SESSION_BEGIN =
  'CONFERENCE_FETCH_HEARING_ROOM_SESSION_BEGIN';
export const CONFERENCE_FETCH_HEARING_ROOM_SESSION_SUCCESS =
  'CONFERENCE_FETCH_HEARING_ROOM_SESSION_SUCCESS';
export const CONFERENCE_FETCH_HEARING_ROOM_SESSION_FAILURE =
  'CONFERENCE_FETCH_HEARING_ROOM_SESSION_FAILURE';
export const CONFERENCE_FETCH_HEARING_ROOM_SESSION_DISMISS_FEEDBACK =
  'CONFERENCE_FETCH_HEARING_ROOM_SESSION_DISMISS_FEEDBACK';
export const CONFERENCE_GET_TOKEN_DISMISS_TOKEN = 'CONFERENCE_GET_TOKEN_DISMISS_TOKEN';
export const CONFERENCE_GET_STREAM_BEGIN = 'CONFERENCE_GET_STREAM_BEGIN';
export const CONFERENCE_GET_STREAM_SUCCESS = 'CONFERENCE_GET_STREAM_SUCCESS';
export const CONFERENCE_GET_STREAM_FAILURE = 'CONFERENCE_GET_STREAM_FAILURE';
export const CONFERENCE_GET_STREAM_DISMISS_FEEDBACK = 'CONFERENCE_GET_STREAM_DISMISS_FEEDBACK';
export const CONFERENCE_FETCH_RECENTLY_PRESENTED_TODAY_BEGIN =
  'CONFERENCE_FETCH_RECENTLY_PRESENTED_TODAY_BEGIN';
export const CONFERENCE_FETCH_RECENTLY_PRESENTED_TODAY_SUCCESS =
  'CONFERENCE_FETCH_RECENTLY_PRESENTED_TODAY_SUCCESS';
export const CONFERENCE_FETCH_RECENTLY_PRESENTED_TODAY_FAILURE =
  'CONFERENCE_FETCH_RECENTLY_PRESENTED_TODAY_FAILURE';
export const CONFERENCE_FETCH_RECENTLY_PRESENTED_TODAY_DISMISS_FEEDBACK =
  'CONFERENCE_FETCH_RECENTLY_PRESENTED_TODAY_DISMISS_FEEDBACK';
export const CONFERENCE_GET_TURN_CREDENTIALS_BEGIN = 'CONFERENCE_GET_TURN_CREDENTIALS_BEGIN';
export const CONFERENCE_GET_TURN_CREDENTIALS_SUCCESS = 'CONFERENCE_GET_TURN_CREDENTIALS_SUCCESS';
export const CONFERENCE_GET_TURN_CREDENTIALS_FAILURE = 'CONFERENCE_GET_TURN_CREDENTIALS_FAILURE';
export const CONFERENCE_GET_TURN_CREDENTIALS_DISMISS_FEEDBACK =
  'CONFERENCE_GET_TURN_CREDENTIALS_DISMISS_FEEDBACK';
