import {
  CASE_UPDATE_TAG_BEGIN,
  CASE_UPDATE_TAG_SUCCESS,
  CASE_UPDATE_TAG_FAILURE,
  CASE_UPDATE_TAG_DISMISS_FEEDBACK,
} from './constants';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';
import { replaceItemImmutable } from 'utils/arrays';

export const updateTag = createPromiseAction(CASE_UPDATE_TAG_BEGIN);

export function dismissUpdateTagFeedback() {
  return {
    type: CASE_UPDATE_TAG_DISMISS_FEEDBACK,
  };
}

export function* doUpdateTag(action) {
  const { caseId, tag } = action.payload;
  let res = yield call(api.put, `/cases/${caseId}/tags/${tag.id}`, tag);

  if (res && res.error) {
    yield put({
      type: CASE_UPDATE_TAG_FAILURE,
      feedback: { message: 'feedback.updateTagFailure', error: true, retryAction: action },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_UPDATE_TAG_SUCCESS,
    data: { tag: res },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchUpdateTag() {
  yield takeLatest(updateTag, withCurrentCaseId(doUpdateTag));
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_UPDATE_TAG_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        updateTagPending: true,
        updateTagFeedback: null,
      };

    case CASE_UPDATE_TAG_SUCCESS:
      // The request is success
      return {
        ...state,
        updateTagPending: false,
        updateTagFeedback: action.feedback,
        ...(state.tags && {
          tags: replaceItemImmutable(
            state.tags,
            action.data.tag,
            state.tags.findIndex((tag) => tag.id === action.data.tag.id),
          ),
        }),
      };

    case CASE_UPDATE_TAG_FAILURE:
      // The request is failed
      return {
        ...state,
        updateTagPending: false,
        updateTagFeedback: action.feedback,
      };

    case CASE_UPDATE_TAG_DISMISS_FEEDBACK:
      // Dismiss the request failure error
      return {
        ...state,
        updateTagFeedback: null,
      };

    default:
      return state;
  }
}
