import React from 'react';
import T from 'i18n';
import { ClickAwayListener, MenuItem, Paper, Popper, Typography } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useUpdateUserSettings } from 'features/common/redux/updateUserSettings';
import { AccessTime } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { selectCurrentCaseId } from 'common/selectors';

interface RecentSearchesListProps {
  anchorEl: any;
  openRecentSearchesMenu: boolean;
  handleRecentSearchesMenuClose: any;
  isAnswersMode?: boolean;
  topic?: string;
  handleChangeFilters: Function;
  handleSearch: Function;
}

const RecentSearchesList = ({
  anchorEl,
  openRecentSearchesMenu,
  handleRecentSearchesMenuClose,
  isAnswersMode,
  topic,
  handleChangeFilters,
  handleSearch,
}: RecentSearchesListProps) => {
  const { userSettings } = useUpdateUserSettings();
  const currentCaseId = useSelector(selectCurrentCaseId);
  const aiInputs = userSettings?.aiSearchInputs || [];
  const aiSearchInputs = aiInputs[currentCaseId] ? aiInputs[currentCaseId] : [];
  const recentSearches = isAnswersMode
    ? aiSearchInputs.find((itm: any) => itm.topic === topic)?.questions || []
    : aiSearchInputs.map((item: any) => item.topic);
  return (
    <ClickAwayListener onClickAway={handleRecentSearchesMenuClose}>
      <Popper
        id="recent-searches-menu"
        anchorEl={anchorEl}
        open={openRecentSearchesMenu && recentSearches.length > 0}
        placement={isAnswersMode ? 'top-start' : 'bottom-start'}
      >
        <Paper elevation={3} sx={{ width: '36rem' }}>
          <PerfectScrollbar options={{ suppressScrollX: true }} style={{ maxHeight: '30vh' }}>
            <Typography sx={{ color: '#0000007a', mt: '0.5rem', ml: '1.5rem' }}>
              {T.translate('case.recentSearchesMsg')}
            </Typography>
            {recentSearches.map((item: any, index: number) => (
              <MenuItem
                key={index}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleChangeFilters({ phrase: item });
                  handleRecentSearchesMenuClose();
                }}
              >
                <AccessTime fontSize="medium" sx={{ mr: '0.5rem' }} />
                <Typography sx={{ fontWeight: 600, color: '#0000007a' }}>{item}</Typography>
              </MenuItem>
            ))}
          </PerfectScrollbar>
        </Paper>
      </Popper>
    </ClickAwayListener>
  );
};

export default RecentSearchesList;
