import React, { forwardRef, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import { objectValueFromPath } from 'utils/object';
import ErrorFeedback from './ErrorFeedback';
import InputGroup from 'react-bootstrap/InputGroup';
import 'bootstrap-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
// import PropTypes from 'prop-types';

const DateForm = forwardRef(
  (
    {
      placeholder,
      errors,
      onChange,
      onBlur,
      defaultValue,
      name,
      label,
      readOnly,
      value,
      type,
      autofocus,
    },
    ref,
  ) => {
    const firstInputRef = useRef();

    useEffect(
      () =>
        autofocus &&
        firstInputRef.current &&
        firstInputRef.current.focus &&
        firstInputRef.current.focus(),
      [autofocus, firstInputRef],
    );

    return (
      <>
        {label && <Form.Label>{label}</Form.Label>}
        <InputGroup
          className="date"
          data-provide="datepicker"
          data-date-format="dd/mm/yyyy"
          data-date-autoclose="true"
          data-date-keyboard-navigation="false"
          data-date-force-parse="false"
          data-date-show-on-focus="false"
        >
          <Form.Control
            ref={(el) => {
              ref && ref(el);
              firstInputRef.current = el;
            }}
            name={name}
            placeholder={placeholder}
            defaultValue={defaultValue}
            onChange={onChange}
            isInvalid={!!objectValueFromPath(name, errors)}
            readOnly={readOnly}
            value={value}
            type={type}
            onBlur={onBlur}
          />
          <InputGroup.Append>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faCalendarAlt} alt={faCalendarAlt.iconName} />
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
        <ErrorFeedback style={{ display: 'block' }} name={name} errors={errors} />
      </>
    );
  },
);

DateForm.propTypes = {};
DateForm.defaultProps = {};

export default DateForm;
