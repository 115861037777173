import React, { useState, useMemo, useEffect } from 'react';
import { CustomViewerButton } from 'features/common';
import { makeStyles } from '@mui/styles';
import T from 'i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTags } from '@fortawesome/free-solid-svg-icons';
import {
  Select,
  MenuItem,
  Checkbox,
  TextField,
  ListSubheader,
  ListItemText,
  InputAdornment,
  IconButton,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useFetchTags } from 'features/case/redux/fetchTags';
import PerfectScrollbar from 'perfect-scrollbar';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { createTag } from 'features/case/redux/CreateTag';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(() => ({
  markButtonContainer: {
    position: 'fixed',
    visibility: 'hidden',
    opacity: 0,
    display: 'flex',
    borderRadius: '0.5rem',
    zIndex: 100,

    '&>:first-child': {
      flexGrow: 1,
      borderTopLeftRadius: '0.5rem',
      borderBottomLeftRadius: '0.5rem',
    },

    '&>:last-child': {
      borderTopRightRadius: '0.5rem',
      borderBottomRightRadius: '0.5rem',
    },

    '&.visible': {
      opacity: 1,
      transition: 'opacity 150ms',
      visibility: 'visible',
    },
  },
  markButton: {
    padding: '0.25rem',
    position: 'relative',
    transition: 'opacity 150ms, visibility 0ms 150ms',
    whiteSpace: 'nowrap',
    backgroundColor: '#4567e5',

    border: 'none',
    boxShadow: '0 4px 6px 0 #666',

    color: 'white',
    textAlign: 'center',
    opacity: '0.85',

    '> div': {
      fontSize: '1.2rem',
    },

    a: {
      color: 'white',
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },

    "&[type='button']:hover": {
      boxShadow: '0 4px 10px 0 #666',
      opacity: 1,
    },
  },
  noBorder: {
    border: 'none',
  },
  uploadIcon: {
    padding: '0.2rem',
    borderRadius: '50% !important',
    fontSize: '2rem',
    color: 'white',
    margin: '0.5rem',
    cursor: 'pointer',
    backgroundColor: '#4567e5',
  },
  disabled: {
    backgroundColor: '#A5A4A1',
  },
  error: {
    backgroundColor: 'red',
  },
}));

const containsText = (text: any, searchText: any) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

const DropdownPortion = React.memo(
  ({ startOpen, onOpen, onClose, selectedTags, updateSelectedTag }: any) => {
    const classes = useStyles();

    const dispatch = useDispatch();

    const [open, setOpen] = useState(startOpen);

    useEffect(() => {
      if (startOpen) setOpen(true);
    }, [startOpen]);

    const [selectedOptions, setSelectedOptions] = useState<Array<any>>(selectedTags);

    const [searchText, setSearchText] = useState('');

    const { fetchTags, tags } = useFetchTags();

    const handleClose = () => {
      setOpen(false);
    };

    const handleOpen = () => {
      setOpen(true);
    };

    // MOUNT
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => fetchTags(), []);

    const displayedOptions = useMemo(
      () => tags.filter((tag: any) => containsText(tag.name, searchText)),
      [searchText, tags],
    );

    useEffect(() => {
      setSelectedOptions(selectedTags);
    }, [selectedTags]);

    const handleClickCreateTag = () => {
      dispatch(createTag({ tag: { name: searchText } }));
    };

    return (
      <Select
        key={'tag-select'}
        // Disables auto focus on MenuItems and allows TextField to be in focus
        MenuProps={{
          open: open, //required
          autoFocus: false,
        }}
        open={open}
        labelId="search-select-label"
        id="search-select"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          opacity: 0,
          width: '100%',
          height: '100%',
          '& #search-select': { padding: '0 !important' },
        }}
        value={selectedOptions}
        label="Options"
        onChange={(e) => {
          setSelectedOptions(e.target.value as any);
          updateSelectedTag(e.target.value as any);
        }}
        onOpen={(e) => {
          handleOpen();
          // only way I could find to get perfect scrollbar in
          let perfectScrollbar = null as any;
          setTimeout(() => {
            const paper = document.getElementsByClassName('MuiPaper-root')[0];
            perfectScrollbar = new PerfectScrollbar(paper);
          });
          setTimeout(() => perfectScrollbar?.update(), 250);
          onOpen && onOpen();
        }}
        onClose={() => {
          onClose && onClose();
          handleClose();
          setSearchText('');
        }}
        // This prevents rendering empty string in Select's value
        // if search text would exclude currently selected option.
        // renderValue={() => selectedOption}
        multiple
      >
        {/* TextField is put into ListSubheader so that it doesn't
        act as a selectable item in the menu
        i.e. we can click the TextField without triggering any selection.*/}
        <ListSubheader sx={{ zIndex: 0, lineHeight: '1rem' }}>
          <TextField
            size="small"
            // Autofocus on textfield
            autoFocus
            variant="outlined"
            autoComplete="off"
            placeholder={T.translate('viewing.searchTag')}
            fullWidth
            InputProps={{
              classes: { notchedOutline: classes.noBorder },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {searchText &&
                    !displayedOptions
                      .map((tag: any) => tag.name.toLowerCase())
                      .includes(searchText.toLowerCase()) && (
                      <IconButton onClick={handleClickCreateTag} edge="end">
                        <AddBoxIcon />
                      </IconButton>
                    )}
                </InputAdornment>
              ),
            }}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key !== 'Escape') {
                // Prevents autoselecting item while typing (default Select behaviour)
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>
        {/* needs to be a flat list for select control  - when using groups to be remembered*/}
        <ListSubheader sx={{ zIndex: 0, lineHeight: '1rem' }}>
          {searchText ? 'Search Results' : 'Tags'}
        </ListSubheader>
        {displayedOptions.map((tag: any) => (
          <MenuItem key={tag.id} value={tag.id}>
            <Checkbox
              checked={selectedOptions.includes(tag.id)}
              sx={{ padding: '0.25rem 0.5rem' }}
            />
            <ListItemText primary={tag.name} />
          </MenuItem>
        ))}
      </Select>
    );
  },
);

const TagSelect = React.memo(
  ({ updateSelectedTag, onOpen, startOpen = false, selectedTags, ...rest }: any) => {
    const classes = useStyles();
    const [selectedTagsTemp, setSelectedTagsTemp] = useState(selectedTags);

    return (
      <CustomViewerButton key={'tag-select-button'} {...rest} className={classes.markButton}>
        <FontAwesomeIcon style={{ margin: '0 0.25rem' }} icon={faTags} />
        {T.translate('case.tag')}
        <DropdownPortion
          startOpen={startOpen}
          onOpen={onOpen}
          onClose={() => updateSelectedTag(selectedTagsTemp)}
          selectedTags={selectedTags}
          updateSelectedTag={(st: any) => setSelectedTagsTemp(st)}
        />
      </CustomViewerButton>
    );
  },
);

export default TagSelect;
