import { call, put, takeLatest } from 'redux-saga/effects';
import {
  VIEWING_FETCH_THEATRE_DATA_BEGIN,
  VIEWING_FETCH_THEATRE_DATA_SUCCESS,
  VIEWING_FETCH_THEATRE_DATA_FAILURE,
  VIEWING_FETCH_THEATRE_DATA_DISMISS_FEEDBACK,
} from './constants';
import api from 'common/api';
import { useCallback } from 'react';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { withCurrentCaseId, withHubConnectionId } from 'common/selectors';

export const fetchTheatreData = createPromiseAction(VIEWING_FETCH_THEATRE_DATA_BEGIN);

export function dismissFetchTheatreDataFeedback() {
  return {
    type: VIEWING_FETCH_THEATRE_DATA_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_FETCH_THEATRE_DATA_BEGIN actions
export function* doFetchTheatreData(action) {
  const {
    payload: { caseId, connectionId, hearingRoomMode },
  } = action;

  const res = yield call(api.post, `/cases/${caseId}/receive`, { connectionId, hearingRoomMode });

  if (res && res.error) {
    yield put({
      type: VIEWING_FETCH_THEATRE_DATA_FAILURE,
      feedback: {
        message: 'feedback.fetchTheatreDataFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  // Dispatch success action out of try/catch so that render errors are not catched.
  yield put({
    type: VIEWING_FETCH_THEATRE_DATA_SUCCESS,
    data: res,
  });

  yield call(resolvePromiseAction, action, res);
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchFetchTheatreData() {
  yield takeLatest(fetchTheatreData, withCurrentCaseId(withHubConnectionId(doFetchTheatreData)));
}

export function useFetchTheatreData() {
  const dispatch = useDispatch();

  const { theatreData, privateTheatreData, fetchTheatreDataPending, fetchTheatreDataFeedback } =
    useSelector(
      (state) => ({
        theatreData: state.viewing.theatreData,
        privateTheatreData: state.viewing.privateTheatreData,
        fetchTheatreDataPending: state.viewing.fetchTheatreDataPending,
        fetchTheatreDataFeedback: state.viewing.fetchTheatreDataFeedback,
      }),
      shallowEqual,
    );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(fetchTheatreData(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissFetchTheatreDataFeedback());
  }, [dispatch]);

  return {
    theatreData,
    privateTheatreData,
    fetchTheatreData: boundAction,
    fetchTheatreDataPending,
    fetchTheatreDataFeedback,
    dismissFetchTheatreDataFeedback: boundDismissFeedback,
  };
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case VIEWING_FETCH_THEATRE_DATA_BEGIN + '.TRIGGER':
      return {
        ...state,
        fetchTheatreDataPending: true,
        fetchTheatreDataFeedback: null,
      };

    case VIEWING_FETCH_THEATRE_DATA_SUCCESS:
      return {
        ...state,
        fetchTheatreDataPending: false,
        fetchTheatreDataFeedback: null,
        ...(action.data.room === 'private' &&
          action.data.version > (state.privateTheatreData.version || -1) && {
            privateTheatreData: action.data,
          }),
        ...(action.data.room === 'public' &&
          action.data.version > (state.theatreData.version || -1) && {
            theatreData: action.data,
          }),
      };

    case VIEWING_FETCH_THEATRE_DATA_FAILURE:
      return {
        ...state,
        fetchTheatreDataPending: false,
        fetchTheatreDataFeedback: action.feedback,
      };

    case VIEWING_FETCH_THEATRE_DATA_DISMISS_FEEDBACK:
      return {
        ...state,
        fetchTheatreDataFeedback: null,
      };

    default:
      return state;
  }
}
