import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NavDropdown from 'react-bootstrap/NavDropdown';

export default class StickyDropdown extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      menuOpen: true,
      mouseOver: false,
    };
  }

  openMenu = () => this.setState({ menuOpen: true });

  closeMenu = () => this.setState({ menuOpen: false });

  openMenuIfMouseOver = () => {
    this.state.mouseOver && this.openMenu();
  };

  dropdownToggle = (shouldOpen, event, { source }) => {
    if (source === 'click') {
      shouldOpen ? this.openMenu() : this.closeMenu();
      this.props.onToggle && this.props.onToggle();
    }
  };

  handleOnMouseEnter = () => {
    if (this.state.menuOpen) return;
    this.setState({ mouseOver: true });
    this.timeout = setTimeout(this.openMenuIfMouseOver, 1000);
  };

  handleOnMouseLeave = () => {
    clearTimeout(this.timeout);
    if (this.state.menuOpen) return;
    this.setState({ mouseOver: false });
  };

  componentDidMount() {
    // for animation to work from start
    this.setState({
      menuOpen: this.props.opened,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.opened !== this.props.opened && this.props.opened)
      this.setState({
        menuOpen: this.props.opened,
      });
  }

  render() {
    const { children, hide, opened, ...rest } = this.props;

    return (
      <NavDropdown
        {...rest}
        show={hide ? false : this.state.menuOpen}
        onToggle={this.dropdownToggle}
        onDragEnter={this.handleOnMouseEnter}
        onDragLeave={this.handleOnMouseLeave}
      >
        {React.Children.toArray(children).map((element) =>
          React.cloneElement(element, { hide: hide || !this.state.menuOpen }),
        )}
      </NavDropdown>
    );
  }
}
