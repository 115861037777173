import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import T from 'i18n';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap-datepicker';
import { useUpdateUserSettings } from './redux/hooks';
import { SelectForm, ToggleForm, MultiSelectForm } from 'features/common';
import { complexValuesToOptions } from 'utils/arrays';
import { Modal } from 'features/common/MaterialBasedComponents';
import Collapsible from 'features/common/Collapsible';
// import PropTypes from 'prop-types';
import {
  selectDeepSearch,
  selectIsRegistrar,
  selectShowAllDocuments,
  selectUserDataRoomSettings,
} from 'common/selectors';
import { useSelector } from 'react-redux';

export default function UserSettings({ show, handleClose }) {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const { userSettings, updateUserSettings } = useUpdateUserSettings();

  const isRegistrar = useSelector(selectIsRegistrar);
  const deepSearch = useSelector(selectDeepSearch);
  const dataRoom = useSelector(selectUserDataRoomSettings);
  const [updateDataRoomColumns, setUpdateDataRoomColumns] = useState(!!dataRoom.columns);
  const [updateDataRoomColumnsSmallView, setUpdateDataRoomColumnsSmallView] = useState(
    !!dataRoom.columnsSmallView,
  );
  const [presentModeVal, setPresentModeVal] = useState(dataRoom.presentMode || false);
  const caseSettingsShowAllDocuments = useSelector(selectShowAllDocuments);
  if (!userSettings) return null;

  const {
    askForMessageUpdateOnStop = true,
    autoGainControl,
    echoCancellation,
    noiseSuppression,
    highpassFilter,
    googCpuOveruseDetection,
    googCpuOveruseEncodeUsage,
    videoCodec, // "VP8",	"VP9", "H.264"
    speechDetection = true,
    overlayedThumbnails,
    autohideThumbnails,
    autohideMenus,
    lastFolder = {},
  } = userSettings;

  const defaultColumns = ['index', 'id', 'name', 'info'];
  const {
    columns,
    columnsSmallView,
    presentMode = false,
    clickEnterToPresent = false,
    searchMode = true,
    docFinder = true,
    allDocuments = true,
    allAnnotations = true,
    pageRefs = false,
  } = dataRoom || {};

  const dataRoomColumnsOptions = complexValuesToOptions(
    [
      { value: 'index', isFixed: true },
      { value: 'id', isFixed: true },
      { value: 'name', isFixed: true },
      { value: 'info', isFixed: true },
      { value: 'description', isFixed: false },
      { value: 'externalId', isFixed: false },
      { value: 'discoveryId', isFixed: false },
      { value: 'docDate', isFixed: false },
      { value: 'altDate', isFixed: false },
      { value: 'type', isFixed: false },
      { value: 'pageCount', isFixed: false },
      { value: 'size', isFixed: false },
      { value: 'author', isFixed: false },
      { value: 'recipient', isFixed: false },
    ],
    'generic',
  );

  return (
    <Modal
      show={show}
      title={T.translate('common.userSettings')}
      handleClose={() => {
        setUpdateDataRoomColumns(!!dataRoom.columns);
        setUpdateDataRoomColumnsSmallView(!!dataRoom.columnsSmallView);
        setPresentModeVal(!!dataRoom.presentMode);
        handleClose();
      }}
      handleSave={handleSubmit((userSettings) => {
        updateUserSettings({
          userSettings: {
            ...userSettings,
            dataRoom: {
              ...userSettings.dataRoom,
              columns: updateDataRoomColumns ? userSettings.dataRoom.columns : null,
              columnsSmallView: updateDataRoomColumnsSmallView
                ? userSettings.dataRoom.columnsSmallView
                : null,
            },
            lastFolder: {
              ...lastFolder,
            },
          },
        });
        handleClose();
      })}
      saveTitle={T.translate('generic.update')}
    >
      <Form
        className="common-user-settings"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Form.Group>
          <Row>
            <Col>
              <ToggleForm
                autofocus
                onChange={() => {
                  setUpdateDataRoomColumns(!updateDataRoomColumns);
                  if (updateDataRoomColumns) setValue('dataRoom.columns', defaultColumns);
                }}
                defaultValue={updateDataRoomColumns}
                label={T.translate('generic.updateDataRoomColumns')}
              />
            </Col>
            <Col />
            <Col />
          </Row>
          {updateDataRoomColumns && (
            <Row>
              <Col>
                <MultiSelectForm
                  label={T.translate('generic.dataRoomColumns')}
                  defaultValue={columns || defaultColumns}
                  name="dataRoom.columns"
                  autofocus
                  readOnly={!updateDataRoomColumns}
                  options={dataRoomColumnsOptions}
                  control={control}
                  error={errors}
                  backspaceRemovesValue={false}
                ></MultiSelectForm>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <ToggleForm
                autofocus
                onChange={() => {
                  setUpdateDataRoomColumnsSmallView(!updateDataRoomColumnsSmallView);
                  if (updateDataRoomColumnsSmallView)
                    setValue('dataRoom.columnsSmallView', defaultColumns);
                }}
                defaultValue={updateDataRoomColumnsSmallView}
                label={T.translate('generic.updateDataRoomColumnsSmallView')}
              />
            </Col>
            <Col />
            <Col />
          </Row>
          {updateDataRoomColumnsSmallView && (
            <Row>
              <Col>
                <MultiSelectForm
                  label={T.translate('generic.dataRoomColumns')}
                  defaultValue={columnsSmallView || defaultColumns}
                  name="dataRoom.columnsSmallView"
                  autofocus
                  readOnly={!updateDataRoomColumnsSmallView}
                  options={dataRoomColumnsOptions}
                  control={control}
                  error={errors}
                  backspaceRemovesValue={false}
                ></MultiSelectForm>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <ToggleForm
                {...register('dataRoom.presentMode', {
                  onChange: () => setPresentModeVal(!presentModeVal),
                })}
                defaultValue={presentMode}
                label={T.translate('generic.presentMode')}
              />
            </Col>
            <Col />
            <Col />
          </Row>
          {presentModeVal && (
            <Row>
              <Col>
                <ToggleForm
                  {...register('dataRoom.clickEnterToPresent')}
                  defaultValue={clickEnterToPresent}
                  label={T.translate('generic.clickEnterToPresent')}
                />
              </Col>
              <Col />
              <Col />
            </Row>
          )}
          {deepSearch && (
            <>
              <Row>
                <Col>
                  <ToggleForm
                    {...register('dataRoom.searchMode')}
                    defaultValue={searchMode}
                    label={T.translate('generic.searchMode')}
                  />
                </Col>
                <Col />
                <Col />
              </Row>
              <Row>
                <Col>
                  <ToggleForm
                    {...register('dataRoom.docFinder')}
                    defaultValue={docFinder}
                    label={T.translate('generic.docFinder')}
                  />
                </Col>
                <Col />
                <Col />
              </Row>
            </>
          )}
          <Row>
            <Col>
              {caseSettingsShowAllDocuments && (
                <ToggleForm
                  {...register('dataRoom.allDocuments')}
                  defaultValue={allDocuments}
                  label={T.translate('generic.allDocuments')}
                />
              )}
            </Col>
            <Col />
            <Col />
          </Row>
          <Row>
            <Col>
              <ToggleForm
                {...register('dataRoom.allAnnotations')}
                defaultValue={allAnnotations}
                label={T.translate('case.allAnnotations')}
              />
            </Col>
            <Col />
            <Col />
          </Row>
          <Row>
            <Col>
              <ToggleForm
                {...register('dataRoom.pageRefs')}
                defaultValue={pageRefs}
                label={T.translate('case.pageRefs')}
              />
            </Col>
            <Col />
            <Col />
          </Row>
          <Collapsible title={T.translate('generic.twilio')}>
            <Row>
              <Col>
                <SelectForm
                  label={T.translate('common.videoCodec')}
                  name="videoCodec"
                  defaultValue={videoCodec} // true
                  options={[
                    { value: 'VP8', label: 'VP8' },
                    { value: 'VP9', label: 'VP9' },
                    { value: 'H.264', label: 'H.264' },
                  ]}
                  control={control}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <ToggleForm
                  {...register('autoGainControl')}
                  defaultValue={autoGainControl} // true
                  label={T.translate(`common.autoGainControl`)}
                />
              </Col>
              <Col />
              <Col />
            </Row>
            <Row>
              <Col>
                <ToggleForm
                  {...register('echoCancellation')}
                  defaultValue={echoCancellation} // true
                  label={T.translate(`common.echoCancellation`)}
                />
              </Col>
              <Col />
              <Col />
            </Row>
            <Row>
              <Col>
                <ToggleForm
                  {...register('noiseSuppression')}
                  defaultValue={noiseSuppression} // true
                  label={T.translate(`common.noiseSuppression`)}
                />
              </Col>
              <Col />
              <Col />
            </Row>
            <Row>
              <Col>
                <ToggleForm
                  {...register('highpassFilter')}
                  defaultValue={highpassFilter} // true
                  label={T.translate(`common.highpassFilter`)}
                />
              </Col>
              <Col />
              <Col />
            </Row>
            {isRegistrar && (
              <Row>
                <Col>
                  <ToggleForm
                    {...register('askForMessageUpdateOnStop')}
                    defaultValue={askForMessageUpdateOnStop} // true
                    label={T.translate(`common.askForMessageUpdateOnStop`)}
                  />
                </Col>
                <Col />
                <Col />
              </Row>
            )}
            <Row>
              <Col>
                <ToggleForm
                  {...register('speechDetection')}
                  defaultValue={speechDetection} // true
                  label={T.translate(`conference.speechDetection`)}
                />
              </Col>
              <Col />
              <Col />
            </Row>
            <Row>
              <Col>
                <ToggleForm
                  {...register('overlayedThumbnails')}
                  defaultValue={overlayedThumbnails}
                  label={T.translate(`conference.overlayedThumbnails`)}
                />
              </Col>
              <Col />
              <Col />
            </Row>
            <Row>
              <Col>
                <ToggleForm
                  {...register('autohideThumbnails')}
                  defaultValue={autohideThumbnails}
                  label={T.translate(`conference.autohideThumbnails`)}
                />
              </Col>
              <Col />
              <Col />
            </Row>
            <Row>
              <Col>
                <ToggleForm
                  {...register('autohideMenus')}
                  defaultValue={autohideMenus}
                  label={T.translate(`conference.autohideMenus`)}
                />
              </Col>
              <Col />
              <Col />
            </Row>
          </Collapsible>
          <Collapsible title={T.translate('generic.hardwareChrome')}>
            <Row>
              <Col>
                <ToggleForm
                  {...register('googCpuOveruseDetection')}
                  defaultValue={googCpuOveruseDetection} // true
                  label={T.translate(`common.googCpuOveruseDetection`)}
                />
              </Col>
              <Col />
              <Col />
            </Row>
            <Row>
              <Col>
                <ToggleForm
                  {...register('googCpuOveruseEncodeUsage')}
                  defaultValue={googCpuOveruseEncodeUsage} // true
                  label={T.translate(`common.googCpuOveruseEncodeUsage`)}
                />
              </Col>
              <Col />
              <Col />
            </Row>
          </Collapsible>
        </Form.Group>
      </Form>
    </Modal>
  );
}

UserSettings.propTypes = {};
UserSettings.defaultProps = {};
