import { ViewerControl, Mark, MouseTools, MouseTool, FitType } from '@prizmdoc/viewer-core';
import { easeUpToAValue } from 'utils/animate';
import { addAlpha } from 'utils/color';
import { HIGHLIGHTSDISAPPEAR, PRESENT_COLOR, SELECTION_COLOR, SHARED_COLOR } from './constants';
import { ZoomType, MouseToolType } from '../types';
import logger from 'utils/logger';

export const fixPosition = (
  { x, y }: { x: number; y: number },
  { width, height }: { width: number; height: number },
) => {
  //Make sure the tooltip doesn't go off the top or left side of the window or off the right or
  return {
    x: Math.max(0, Math.min(x, window.innerWidth - width)),
    y: Math.max(0, Math.min(y, window.innerHeight - height)),
  };
};

export const cropCanvas = (
  canvas: HTMLCanvasElement,
  width: number,
  height: number,
): HTMLCanvasElement => {
  const realWidth = Math.round(width * (canvas.width / parseInt(canvas.style.width.slice(0, -2))));
  const realHeight = Math.round(
    height * (canvas.height / parseInt(canvas.style.height.slice(0, -2))),
  );

  const newCanvas = document.createElement('canvas');
  newCanvas.width = realWidth;
  newCanvas.height = realHeight;
  newCanvas
    .getContext('2d')
    ?.drawImage(canvas, 0, 0, realWidth, realHeight, 0, 0, realWidth, realHeight);
  newCanvas.style.height = `${height}px`;
  newCanvas.style.width = `${width}px`;
  return newCanvas;
};

interface BoundingRectangle {
  x: number;
  y: number;
  width: number;
  height: number;
}

interface Coordinates {
  clientX: number;
  clientY: number;
}

interface ClientDimensions {
  clientWidth: number;
  clientHeight: number;
}

type ConvertedCoordinates = Coordinates & ClientDimensions;

interface ConversionParams {
  boundingRectangle: BoundingRectangle;
  pageNumber: number;
}

export const convert = (
  { boundingRectangle: { x, y, width, height }, pageNumber }: ConversionParams,
  viewerControl: typeof ViewerControl,
): ConvertedCoordinates => {
  // convert start point
  const { clientX, clientY } = viewerControl.convertPageToWindowCoordinates(pageNumber, {
    x,
    y,
  });

  // convert end point
  const { clientX: endX, clientY: endY } = viewerControl.convertPageToWindowCoordinates(
    pageNumber,
    {
      x: x + width,
      y: y + height,
    },
  );

  return { clientX, clientY, clientWidth: endX - clientX, clientHeight: endY - clientY };
};

type MarkDisappearCallback = (mark: typeof Mark) => void;
type ShouldExitFunction = () => boolean;

export const makeMarkDisappear = (
  mark: typeof Mark,
  callback?: MarkDisappearCallback,
  shouldExitFn?: ShouldExitFunction,
): void => {
  const fillColor = mark.fillColor;
  easeUpToAValue(
    mark.opacity || 255,
    0,
    (value: number) => {
      if (mark.type === Mark.Type.HighlightAnnotation) {
        mark.fillColor = addAlpha(fillColor, value);
      } else {
        mark.opacity = value;
      }
      if (callback && value === 0) callback(mark);
    },
    0,
    1000,
    shouldExitFn,
  ).catch(() => {});
};

const _setScrollTop = (element: Element, Y: number): Promise<void> => {
  const centerElement = element.clientHeight / 2;
  const startPoint = element.scrollTop + centerElement;
  const maxPoint = element.scrollHeight - centerElement;
  return easeUpToAValue(
    startPoint,
    Y * element.scrollHeight,
    (value: number) => element.scrollTo(element.scrollLeft, value - centerElement),
    maxPoint,
  );
};

const _setScrollLeft = (element: HTMLElement, X: number): Promise<void> => {
  const centerElement = element.clientWidth / 2;
  const startPoint = element.scrollLeft + centerElement;
  const maxPoint = element.scrollWidth - centerElement;
  return easeUpToAValue(
    startPoint,
    X * element.scrollWidth,
    (value: number) => element.scrollTo(value - centerElement, element.scrollTop),
    maxPoint,
  );
};

export const setScroll = (element: HTMLElement, X: number, Y: number): Promise<void> =>
  new Promise<void>((resolve) => {
    let count = 2;
    _setScrollTop(element, Y).then(() => {
      --count;
      if (!count) return setTimeout(resolve, 25);
    });
    _setScrollLeft(element, X).then(() => {
      --count;
      if (!count) return setTimeout(resolve, 25);
    });
  });

export const getZoomScaleInfo = (viewerControl: typeof ViewerControl) => {
  const scaleFactor = viewerControl?.getScaleFactor();
  return { scaleFactor };
};

export const getDocumentRotationInfo = (viewerControl: typeof ViewerControl) => {
  return { documentRotation: viewerControl.getPageRotation() };
};

export function deriveLocalPageFromGlobalPage(
  userInput: string, // e.g. "E5"
  doc: any,
  zeroBasedIndexing: boolean = false,
): number {
  const docStart = parseInt(doc.startPage, 10); // e.g. 3
  const docPageCount = parseInt(doc.pageCount, 10);

  if (!doc.globalPagePrefix || !userInput.startsWith(doc.globalPagePrefix)) {
    return 1;
  }

  // 1) Strip out the prefix to get the numeric portion
  //    e.g. userInput = "E5" => numericPart = "5"
  const numericPart = userInput.slice(doc.globalPagePrefix.length);

  // 2) Convert that string to a number
  const userGlobalNum = parseInt(numericPart, 10);
  if (isNaN(userGlobalNum)) {
    // If parse fails, fallback to page 1
    return 1;
  }

  // 3) local page = userGlobalNum - docStart + offset
  let localPage = userGlobalNum - docStart + (zeroBasedIndexing ? 0 : 1);

  // 4) clamp localPage
  if (localPage < 1) localPage = 1;
  if (localPage > docPageCount) localPage = docPageCount;

  // 5) done
  return localPage;
}

export const getScrollInfo = (scrollContainer: HTMLElement) => {
  const X =
    (scrollContainer.scrollLeft + scrollContainer.clientWidth / 2) / scrollContainer.scrollWidth;
  const Y =
    (scrollContainer.scrollTop + scrollContainer.clientHeight / 2) / scrollContainer.scrollHeight;

  return { scroll: { X, Y } };
};

export const getScrollContainer = (viewerControl?: any) => {
  const pccPageListContainerWrappers = document.getElementsByClassName(
    'pccPageListContainerWrapper',
  );

  const pccPageListContainerWrapper =
    pccPageListContainerWrappers.length > 0
      ? (pccPageListContainerWrappers[0] as any)
      : (viewerControl?._cs?._tM?.firstElementChild?.firstElementChild as HTMLElement);
  return pccPageListContainerWrapper;
};

const isEmpty = (obj: any) => !obj || Object.keys(obj).length === 0;

export const createAnnotations = (
  viewerControl: typeof ViewerControl,
  highlights: Array<any>,
  userId: any,
  diff?: any,
) => {
  const fixHighlight = (highlight: any, marks: any) => {
    if (highlight.burned) {
      const burnedCrossRef = marks.find(({ uid }: any) => uid === highlight.annotation.uid);
      burnedCrossRef.setText('');
    }
    if (highlight.createdBy && highlight.createdBy.id !== userId) {
      const sharedMark = marks.find(({ uid }: any) => uid === highlight.annotation.uid);
      //don't change color of shared cross refs per Frank's request 13.11.23
      if (sharedMark.type !== Mark.Type.TextAnnotation) sharedMark.setFillColor(SHARED_COLOR);
    }
  };

  if (!diff) {
    return new Promise((resolve) => {
      const newMarks = viewerControl.deserializeMarks(highlights.map((x: any) => x.annotation));

      highlights.forEach((highlight: any) => {
        fixHighlight(highlight, newMarks);
      });

      resolve(newMarks);
    });
  }

  if (isEmpty(diff)) {
    return new Promise((resolve) => resolve(viewerControl.getAllMarks()));
  }

  if (
    Object.values(diff).length === highlights.length &&
    Object.values(diff).filter(Boolean).length === 0
  ) {
    return new Promise((resolve) => {
      viewerControl.deleteAllMarks();
      resolve([]);
    });
  }

  const marksToDeserialize: any[] = [];
  const marksToDelete: any[] = [];

  const newMarks = viewerControl.getAllMarks();
  Object.keys(diff).forEach((key: any) => {
    if (diff[key] === undefined) {
      const markToDelete = newMarks.find(({ uid }: any) => uid === highlights[key].annotation.uid);
      if (markToDelete) marksToDelete.push(markToDelete); // happened once that there was less marks than hightlights
    } else {
      const markToUpdate = newMarks.find(({ uid }: any) => uid === highlights[key].annotation.uid);
      if (markToUpdate) marksToDelete.push(markToUpdate);
      marksToDeserialize.push(highlights[key].annotation);
    }
  });

  if (marksToDelete.length > 0) viewerControl.deleteMarks(marksToDelete);

  if (marksToDeserialize.length > 0)
    return new Promise((resolve) => {
      viewerControl.deserializeMarks(marksToDeserialize);
      resolve(viewerControl.getAllMarks());
    });

  return new Promise((resolve) =>
    resolve(marksToDelete.length === 0 ? newMarks : viewerControl.getAllMarks()),
  );
};

export const setSearchFunction = (
  viewerControl: any,
  searchHandler: any,
  searchFunctionsHandler: any,
) => {
  let searchResults: any[] = [];
  let currentSearchResultIndex = -1;
  let searchRequest: any = null;

  const search = (searchTerm: string) => {
    if (searchRequest && !searchRequest.isComplete) {
      searchRequest.cancel();
    }

    if (searchHandler) searchHandler(getSearchPayload(true));
    if (searchFunctionsHandler) {
      searchFunctionsHandler(getSearchFunctions());
    }

    setTimeout(() => {
      searchRequest = viewerControl.search(searchTerm);

      const searchHandlerFn = ({ partialSearchResults }: any) => {
        searchResults = partialSearchResults || [];
        currentSearchResultIndex = -1;
        searchNext();
        searchRequest.off('PartialSearchResultsAvailable', searchHandlerFn);
      };

      const searchHandlerFullFn = ({ completedSearchResults }: any) => {
        searchResults = completedSearchResults || [];
        if (searchHandler) {
          searchHandler(getSearchPayload(false));
        }
        if (searchFunctionsHandler) {
          searchFunctionsHandler(getSearchFunctions());
        }
        searchRequest.off('SearchCompleted', searchHandlerFullFn);
      };

      searchRequest.on('PartialSearchResultsAvailable', searchHandlerFn);
      searchRequest.on('SearchCompleted', searchHandlerFullFn);
    });
  };

  const searchNext = () => {
    if (searchResults && searchResults.length > 0) {
      if (currentSearchResultIndex >= searchResults.length - 1) currentSearchResultIndex = 0;
      else ++currentSearchResultIndex;

      viewerControl.scrollTo(searchResults[currentSearchResultIndex]);
    }
  };

  const searchPrev = () => {
    if (searchResults && searchResults.length > 0) {
      if (currentSearchResultIndex <= 0) currentSearchResultIndex = 0;
      else --currentSearchResultIndex;

      viewerControl.scrollTo(searchResults[currentSearchResultIndex]);
    }
  };

  const getSearchPayload = (isLoading: boolean = false) => ({
    isReady: true,
    isLoading,
  });

  const getSearchFunctions = () => ({
    search,
    searchNext,
    searchPrev,
    searchChanged: () => {
      if (searchResults.length > 0 && true) {
        viewerControl.clearSearch();
      }
    },
    highlightLocation: (location: any) => {
      if (location) {
        viewerControl
          .scrollToAsync({ pageNumber: location.pageNumber, x: location.x, y: location.y })
          .then(
            function () {
              viewerControl.requestPageText(location.pageNumber).then(
                function () {
                  const highlightedArea = viewerControl
                    .addMark(location.pageNumber, 'HighlightAnnotation')
                    .setPosition({
                      startIndex: location.startIndex,
                      length: location.textLength,
                    })
                    .setData('subType', 'highlightEvent');
                  highlightedArea.setFillColor('#AFD0F9');
                },
                function (error: any) {
                  logger.ERROR('requestPageText failed', error);
                },
              );
            },
            function (error: any) {
              logger.ERROR('scrollToAsync failed', error);
            },
          );
      }
    },
  });

  if (searchHandler) {
    searchHandler(getSearchPayload());
  }
  if (searchFunctionsHandler) {
    searchFunctionsHandler(getSearchFunctions());
  }
};

export const setMouse = (selMouseTool: string, isPresenting: boolean, viewerControl: any) => {
  switch (selMouseTool) {
    case MouseToolType.HighlightAnnotation: {
      const myMouseToolName = 'MyHighlightTool';
      const myMouseTool = MouseTools.createMouseTool(
        myMouseToolName,
        MouseTool.Type.HighlightAnnotation,
      );
      myMouseTool.getTemplateMark().setFillColor(isPresenting ? PRESENT_COLOR : SELECTION_COLOR);
      viewerControl.setCurrentMouseTool(myMouseToolName);
      return;
    }
    case MouseToolType.RectangleAnnotation: {
      const myMouseToolName = 'MyRectangleTool';
      const myMouseTool = MouseTools.createMouseTool(
        myMouseToolName,
        MouseTool.Type.RectangleAnnotation,
      );
      myMouseTool.getTemplateMark().opacity = HIGHLIGHTSDISAPPEAR && isPresenting ? 150 : 80;
      myMouseTool.getTemplateMark().borderThickness = 1;
      myMouseTool.getTemplateMark().borderColor = '#000000';
      myMouseTool.getTemplateMark().setFillColor(isPresenting ? PRESENT_COLOR : SELECTION_COLOR);
      viewerControl?.setCurrentMouseTool(myMouseToolName);
      return;
    }
    default: {
      const myMouseToolName = 'MyPanTool';
      MouseTools.createMouseTool(myMouseToolName, MouseTool.Type.PanAndEdit);
      viewerControl?.setCurrentMouseTool(myMouseToolName);
      return;
    }
  }
};

export const zoom = (type: string, viewerControl: any) => {
  switch (type) {
    case ZoomType.In:
      return viewerControl.zoomIn(1.25);
    case ZoomType.Out:
      return viewerControl.zoomOut(1.25);
    case ZoomType.Page:
      return viewerControl.fitContent(FitType.FullPage);
    case ZoomType.Width:
      return viewerControl.fitContent(FitType.FullWidth);
    case ZoomType.Summary:
      return viewerControl.zoomOut(1.2);
    default:
      return;
  }
};

export const containsText = (text: any, st: any) =>
  text.toLowerCase().indexOf(st.toLowerCase()) > -1;

export const setOverflowHidden = () => {
  const pccPageListContainerWrapper = getScrollContainer();
  if (pccPageListContainerWrapper) pccPageListContainerWrapper.style.overflow = 'hidden';
};
