import React from 'react';
import T from 'i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAmountDownAlt, faSortAmountUp } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { selectCurrentFolderAgreed } from 'common/selectors';

export default ({ text, onSortDownClick, onSortUpClick, sortMode, columnId }: any) => {
  const pendingSort = useSelector((state: any) => state.case.updateFolderPending);
  const fetchFolderAfterSortPending = useSelector(
    (state: any) => state.case.fetchFolderAfterSortPending,
  );
  const updateDocumentSortPending = useSelector(
    (state: any) => state.case.updateDocumentSortPending,
  );
  const sortedByDirection = useSelector((state: any) => state.case.sortDirection);
  const sortedColumn = useSelector((state: any) => state.case.sortByColumn);
  const isAgreedFolder = useSelector(selectCurrentFolderAgreed);

  return (
    <div className="sort-column-header">
      <div className="text">{T.translate(text)}</div>
      {sortMode && !isAgreedFolder && (
        <div className="icons">
          <FontAwesomeIcon
            className={clsx('icon', {
              'disabled-button':
                pendingSort ||
                fetchFolderAfterSortPending ||
                updateDocumentSortPending ||
                (sortedColumn === columnId && sortedByDirection === 'asc'),
            })}
            icon={faSortAmountDownAlt}
            onClick={onSortDownClick}
          />
          <FontAwesomeIcon
            className={clsx('icon', {
              'disabled-button':
                pendingSort ||
                fetchFolderAfterSortPending ||
                updateDocumentSortPending ||
                (sortedColumn === columnId && sortedByDirection === 'desc'),
            })}
            icon={faSortAmountUp}
            onClick={onSortUpClick}
          />
        </div>
      )}
    </div>
  );
};
