import React, { useEffect } from 'react';
import T from 'i18n';
import { Form } from 'react-bootstrap';
import { Button, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SelectForm, Spinner, TextForm } from 'features/common';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  selectCurrentCaseTeamId,
  selectCurrentCaseTeams,
  selectGroups,
  selectParams,
} from 'common/selectors';
import { Edit } from '@mui/icons-material';

export const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: '#F7F7F8',
    padding: theme.spacing(2, 2),
    height: '100%',
  },
  form: {
    height: 'calc(100% - 10rem)',
  },
  caseSettings: {
    height: '100%',
    backgroundColor: '#FFFFFF',
    backgroundClip: 'initial',
    border: '1px solid rgba(0,0,0,.125)',
    borderRadius: '.25rem',
  },
  heading: {
    fontSize: '1.5rem',
    lineHeight: '1.75rem',
    fontWeight: 400,
    color: '#9a9a9a',
    margin: '1rem',
  },
  saveButton: {
    margin: theme.spacing(2),
    fontSize: '1rem',
    right: 40,
    height: '2.5rem',
    position: 'fixed',
  },
  groupDetails: {
    width: '100%',
    padding: '1rem 2rem 0 2rem',
  },
}));

const GroupAdmin = () => {
  const classes = useStyles();
  const {
    register,
    //handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
  } = useForm();
  const { groupId } = useSelector(selectParams);
  const teams = useSelector(selectCurrentCaseTeams);
  const teamOptions = teams.map((team) => ({ name: team.name, id: team.id }));
  const groups = useSelector(selectGroups);
  const group = groups.find((g) => g.id === groupId);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (group) {
      setLoading(true);
      setTimeout(() => {
        setValue('name', group.name);
        setValue('teams', group.teams);
        setLoading(false);
      }, 0);
    }
    return () => reset({});
  }, [group, setValue, reset]);

  return (
    <div className={classes.main}>
      <div className={classes.caseSettings}>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.heading} color="inherit" variant="h6" component="div">
              <Edit style={{ fontSize: '1.8rem' }} />
              {T.translate('case.editGroup')}
            </Typography>
          </Grid>
        </Grid>
        {!loading && group && (
          <>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <Form.Group>
                <Grid container className={classes.groupDetails} spacing={2}>
                  <Grid item xs={6}>
                    <TextForm
                      {...register('name', { required: true })}
                      defaultValue={group?.name}
                      label={T.translate('case.groupName')}
                      placeholder={T.translate('case.groupNamePlaceholder')}
                      errors={errors}
                      autofocus
                    />
                  </Grid>
                  <Grid item xs={6}></Grid>
                  <Grid item xs={6}>
                    <SelectForm
                      menuPosition={'fixed'}
                      name={'teams'}
                      isMulti
                      label={T.translate('case.selectTeam')}
                      defaultValue={group?.teams}
                      valueKey="id"
                      labelKey="name"
                      isClearable
                      options={teamOptions}
                      errors={errors}
                      control={control}
                      rules={{ required: true }}
                      onBlur={() => {}}
                    />
                  </Grid>
                </Grid>
              </Form.Group>
            </Form>
            <Grid container className={classes.groupDetails} spacing={2}>
              <Button
                variant="contained"
                color="primary"
                className={classes.saveButton}
                disabled
                //onClick={handleSubmit(data => handleUpdateCaseSettings(data))}
              >
                {loading ? (
                  <Spinner style={{ height: '1rem', width: '1rem' }} />
                ) : (
                  T.translate('generic.save')
                )}
              </Button>
            </Grid>
          </>
        )}
      </div>
    </div>
  );
};

export default GroupAdmin;
