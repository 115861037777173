import {
  JsonHubProtocol,
  HttpTransportType,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';
import Logger from 'utils/logger';
import { getAccessToken } from 'common/api';
import {
  HOME_FETCH_CASES_SUCCESS,
  HOME_FETCH_CASES_SUCCESS_DUMMY,
} from 'features/home/redux/constants';
import {
  CASE_FETCH_CASE_DETAILS_FAILURE,
  CASE_FETCH_CASE_DETAILS_SUCCESS,
} from 'features/case/redux/constants';
import { AUTH_SET_HUB_CONNECTION_ID_FAILURE } from 'features/auth/redux/constants';
import {
  selectCurrentCaseId,
  selectMatchedUrl,
  selectQuery,
  selectTheatreData,
  selectDocumentsOnlyWhenInRoom,
  selectRoomToken,
  selectHubConnectionId,
  selectIsHome,
  selectCountryCode,
  selectCaseDetails,
} from 'common/selectors';
import { setHubConnectionId } from 'features/auth/redux/setHubConnectionId';
import { fetchCaseDetails } from 'features/case/redux/actions';
import { fetchTheatreData } from 'features/viewing/redux/fetchTheatreData';
import { startPresenting } from 'features/viewing/redux/startPresenting';
import runningInDev from 'utils/runningInDev';
import { leaveAGroup } from 'features/viewing/redux/leaveAGroup';
import { setHubConnectionTransportType } from 'features/auth/redux/setHubConnectionTransportType';

const signalRMiddleware =
  ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    //needed to make cases load
    const nextAction = next(action);

    const state = getState();
    const hubConnectionId = selectHubConnectionId(state);
    const countryCode = selectCountryCode(state);
    const caseDetails = selectCaseDetails(state);
    // register signalR after the user logged in
    if (
      [
        HOME_FETCH_CASES_SUCCESS,
        HOME_FETCH_CASES_SUCCESS_DUMMY,
        CASE_FETCH_CASE_DETAILS_SUCCESS,
      ].includes(action.type)
    ) {
      const caseId = selectCurrentCaseId(state);

      if (typeof caseId === 'undefined') {
        Logger.ERROR("User has no cases so we can't connect him to SignalR or fetch details");

        const isHome = selectIsHome(state);
        if (!isHome)
          dispatch({
            type: CASE_FETCH_CASE_DETAILS_FAILURE,
            feedback: {
              error: 'There is no such case.',
            },
          });

        return nextAction;
      }
      if (!caseDetails) dispatch(fetchCaseDetails());

      if (!hubConnectionId && caseDetails) {
        const startSignalRConnection = (connection) =>
          connection
            .start()
            .then(() => {
              dispatch(setHubConnectionId(connection.connectionId));
              Logger.INFO(`SignalR Connected with connectionId: ${connection.connectionId}`);
              const connectionTypeObj = Object.keys(connection.connection.transport);
              let connectionType = null;
              if (connectionTypeObj.includes('_webSocket')) connectionType = 'WebSockets';
              else if (connectionTypeObj.includes('_pollAbort')) connectionType = 'LongPolling';
              else if (connectionTypeObj.includes('_eventSource'))
                connectionType = 'ServerSentEvents';
              else connectionType = 'unknown';
              if (connectionType === 'unknown') {
                Logger.INFO(
                  'SignalR is using unknown transport type, connection object:',
                  connection,
                );
              }
              dispatch(setHubConnectionTransportType(connectionType));
              Logger.INFO(`SignalR is using transport type: ${connectionType}`);
            })
            .catch((err) => {
              Logger.ERROR('SignalR Connection Error: ', err);
              dispatch({
                type: AUTH_SET_HUB_CONNECTION_ID_FAILURE,
                feedback: {
                  message: 'feedback.signalRError',
                  error: 'SignalR Connection Error: ' + err,
                },
              });
            });
        const urlKey = `REACT_APP_API_${countryCode}`;
        const url = process.env[urlKey];
        const protocol = new JsonHubProtocol();

        // allow client and server to negotiate the best protocol available
        //const transport = HttpTransportType.None;
        const transport = HttpTransportType.WebSockets | HttpTransportType.LongPolling;

        const options = {
          accessTokenFactory: getAccessToken,
          transport,
        };

        const hubConnection = new HubConnectionBuilder()
          .withUrl(`${url}/cases/${caseId}`, options)
          .withAutomaticReconnect([
            0, 2000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000,
            5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000,
            5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000,
            5000, 5000, 5000, 5000, 5000, 5000, 5000,
          ])
          .configureLogging(runningInDev ? LogLevel.Debug : LogLevel.Information)
          .withHubProtocol(protocol)
          .build();

        hubConnection.onreconnected((connectionId) => {
          const state = getState();
          const oldHubConnectionId = selectHubConnectionId(state);
          const query = selectQuery(state);
          const shouldRestartPresent = !!query.present;
          const url = selectMatchedUrl(state);
          const isPublicRoom = url.includes('public-hearing-room');
          const isPrivateRoom = url.includes('private-team-room');
          const shouldRefetchData = isPublicRoom || isPrivateRoom;
          if (oldHubConnectionId) {
            if (shouldRestartPresent) dispatch(leaveAGroup('present'));
            if (shouldRefetchData) dispatch(leaveAGroup('receive'));
          }
          dispatch(setHubConnectionId(connectionId));
          if (shouldRefetchData) {
            const state = getState();
            const hearingRoomMode = isPublicRoom ? 'public' : 'private';
            const documentsOnlyWhenInRoom = selectDocumentsOnlyWhenInRoom(state);
            const isJoinedToRoom = selectRoomToken(state, hearingRoomMode);
            if (!documentsOnlyWhenInRoom || isJoinedToRoom) {
              dispatch(fetchTheatreData({ hearingRoomMode }));
            }
          }
          if (shouldRestartPresent) {
            const state = getState();
            const query = selectQuery(state);
            const theatreData = selectTheatreData(state, query.present);
            theatreData.messages?.forEach((message) => {
              dispatch(
                startPresenting({
                  message: message,
                  hearingRoomMode: query.present,
                }),
              );
            });
          }
        });

        // event handlers, you can use these to dispatch actions to update your Redux store
        hubConnection.on('ai-answer', (res) => {
          dispatch({ type: 'CASE_FETCH_SIGNALR_ANSWER_SUCCESS', data: res });
        });
        hubConnection.on('notification', (res) => {
          dispatch({
            type: 'CASE_FETCH_SIGNALR_NOTIFICATION_SUCCESS',
            data: { notification: res },
            feedback: null,
          });
          if (!res.transient)
            dispatch({
              type: 'CASE_FETCH_SIGNALR_NOTIFICATION_FEEDBACK',
              feedback: {
                error: res.status === 'failed',
                message: res.message || 'feedback.mergeDocumentsSuccess',
              },
            });
        });

        hubConnection.on('present', (res) => {
          dispatch({ type: 'VIEWING_FETCH_THEATRE_DATA_SUCCESS', data: res });
        });

        hubConnection.on('hearing-room-session-changed', (res) =>
          dispatch({
            type: 'CONFERENCE_FETCH_HEARING_ROOM_SESSION_SUCCESS',
            data: {
              hearingRoomSessionStarted: res.sessionState === 'started',
              hearingRoomMessage: res.message,
            },
          }),
        );
        hubConnection.on('system', (res) => Logger.INFO('****** SYSTEM NOTIFICATION ******', res));

        hubConnection.onclose(() => Logger.INFO('SignalR disconnected'));

        startSignalRConnection(hubConnection);
      }
    }
    return nextAction;
  };

export default signalRMiddleware;
