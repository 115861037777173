import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import * as actions from 'features/auth/redux/actions';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faUser, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import T from 'i18n';
import Nav from 'react-bootstrap/Nav';
import {
  selectUserName,
  selectCurrentCase,
  selectCurrentCaseId,
  selectIsAuthenticated,
  selectConfidentialityRing,
  selectCurrentUserConfidentialityRing,
} from 'common/selectors';
import { Modal } from 'features/common/MaterialBasedComponents';
import InfoRow from 'features/common/InfoRow';
import UserSettings from 'features/common/UserSettings';
import SystemDiagnostics from 'features/common/SystemDiagnostics';

import StickyDropdown from './StickyDropdown';
import FilterProvider from 'features/case/TableCase/FilterProvider';
import { cfRingColorMapping } from 'features/case/TableCase/TableInfo';

const InfoModal = () => {
  const { name: caseName, team } = useSelector(selectCurrentCase);
  const currentUserCR = useSelector(selectCurrentUserConfidentialityRing);
  const isConfidentialityRing =
    useSelector(selectConfidentialityRing) && currentUserCR !== 'noRing';
  const { name: teamName, users: teamMembers } = team || {};

  return (
    <>
      <InfoRow field={caseName} title={'case.caseName'} />
      <InfoRow field={teamName} title={'case.teamName'} />
      <InfoRow
        field={(teamMembers || []).map(
          ({ name, mail, dataRoomRole, confidentialityRing }, index) => (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {isConfidentialityRing && (
                <FontAwesomeIcon
                  style={{
                    color: confidentialityRing ? cfRingColorMapping[confidentialityRing] : 'white',
                    marginRight: '1rem',
                  }}
                  icon={faCircle}
                />
              )}
              <InfoRow
                key={index}
                noPadding
                field={`${name ? name : mail} (${dataRoomRole ? dataRoomRole : 'pending'})`}
              />
            </div>
          ),
        )}
        title={'case.teamMembers'}
      />
    </>
  );
};

class User extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
  };

  state = { showCaseInfoModal: false, showUserSettingsModal: false, showDiagnosticsModal: false };

  render() {
    const user = (
      <div className="d-inline-flex">
        <FontAwesomeIcon icon={faUser} />
        <p className="d-none ml-3">{this.props.userName}</p>
      </div>
    );

    const userName = (
      <div className="d-flex align-items-center">
        <span>{this.props.userName}</span>
        <h5 className="m-0">
          <FontAwesomeIcon className="ml-3" icon={faUserCircle} />
        </h5>
      </div>
    );

    const loggedIn = (
      <React.Fragment>
        <NavDropdown.Header className="d-l-none">{userName}</NavDropdown.Header>
        <NavDropdown.Item onClick={() => this.setState({ showUserSettingsModal: true })}>
          <p>{T.translate('case.settings')}</p>
        </NavDropdown.Item>
        {this.props.currentCaseId && (
          <NavDropdown.Item onClick={() => this.setState({ showCaseInfoModal: true })}>
            <p>{T.translate('case.info')}</p>
          </NavDropdown.Item>
        )}
        {this.props.currentCaseId && (
          <NavDropdown.Item onClick={() => this.setState({ showDiagnosticsModal: true })}>
            <p>{T.translate('case.diagnostics')}</p>
          </NavDropdown.Item>
        )}
        <NavDropdown.Item onClick={() => window.open('https://trialview.stonly.com/kb/en?lang=en')}>
          <p>{T.translate('common.helpCenter')}</p>
        </NavDropdown.Item>
        <NavDropdown.Divider className="d-l-none" />
        <NavDropdown.Item onClick={this.props.actions.logout}>
          <p>{T.translate('case.logOut')}</p>
        </NavDropdown.Item>
      </React.Fragment>
    );

    const loggedOut = (
      <React.Fragment>
        <NavDropdown.Item onClick={() => this.props.actions.loginRequired()}>
          <p>{T.translate('case.logIn')}</p>
        </NavDropdown.Item>
        <NavDropdown.Item onClick={() => this.setState({ showDiagnosticsModal: true })}>
          <p>{T.translate('case.diagnostics')}</p>
        </NavDropdown.Item>
      </React.Fragment>
    );

    const LogIn = (props) => (
      <div className={props.className}>
        <Nav.Link onClick={() => this.props.actions.loginRequired()}>
          <div className="d-inline-flex">
            <FontAwesomeIcon icon={faUser} />
            <p className="d-none  ml-3">{T.translate('case.logIn')}</p>
          </div>
        </Nav.Link>
      </div>
    );

    return (
      <div className="user">
        {this.props.isAuthenticated ? (
          <StickyDropdown alignRight title={user} className="d-none">
            {loggedIn}
          </StickyDropdown>
        ) : (
          <LogIn className="d-none nav-item" />
        )}
        <NavDropdown id="user" alignRight title={user} className="d-l-none">
          {this.props.isAuthenticated ? loggedIn : loggedOut}
        </NavDropdown>
        <Modal
          show={this.state.showCaseInfoModal}
          title={T.translate('case.info')}
          handleClose={() => this.setState({ showCaseInfoModal: false })}
        >
          <FilterProvider>
            <InfoModal />
          </FilterProvider>
        </Modal>
        <UserSettings
          show={this.state.showUserSettingsModal}
          handleClose={() => this.setState({ showUserSettingsModal: false })}
        />
        <SystemDiagnostics
          show={this.state.showDiagnosticsModal}
          handleClose={() => this.setState({ showDiagnosticsModal: false })}
        />
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    isAuthenticated: selectIsAuthenticated(state),
    userName: selectUserName(state),
    currentCaseId: selectCurrentCaseId(state),
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(User);
