import React, { useEffect, useState } from 'react';
import T from 'i18n';
import { useDispatch } from 'react-redux';
import * as actions from '../../redux/actions';
import { Spinner, Table } from 'features/common';
import { Card } from 'react-bootstrap';
import saveAs from 'save-as';
import { fileVariantsColumnSelector } from '../../DocumentsModal';
import * as actionsV from 'features/viewing/redux/actions';
import { useUploadConfidentialityRingFiles } from '../../redux/uploadConfidentialityRingFiles';
import { Modal } from 'features/common/MaterialBasedComponents';

const FileVariants = ({ show, selectedRows, handleClose }) => {
  const dispatch = useDispatch();
  const [fileVariants, setFileVariants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ringMenuItems, setRingMenuItems] = useState([]);
  const { uploadConfidentialityRingFilesPending } = useUploadConfidentialityRingFiles();
  const isItemLoaded = ({ index }) => !!fileVariants[index];
  const loadMoreItems = () => {};

  const updateFileCR = () => {
    dispatch(
      actions.fetchFileDetails({
        fileId: selectedRows[0].id,
        folderFileId: selectedRows[0].folderFileId,
        updateDocuments: 'confidentialityRing',
      }),
    );
  };

  const handleDeleteRing = (ringId) => {
    setLoading(true);
    dispatch(actions.deleteFileRing({ fileId: selectedRows[0].id, ringId }))
      .then((res) => {
        updateFileCR();
        const updatedFileVariants = fileVariants.filter((itm) => itm.id && itm.id !== ringId);
        handleFileVariants(updatedFileVariants);
      })
      .catch(() => setLoading(false));
  };

  const downloadRingDoc = (ring) => {
    setLoading(true);
    dispatch(
      actionsV.downloadDocument({
        fileId: selectedRows[0].id,
        fileType: selectedRows[0].type,
        variant: ring,
      }),
    )
      .then((response) => {
        return response && saveAs(response.blob, response.fileName || selectedRows[0].id);
      })
      .finally(() => setLoading(false));
  };

  const handleChangeRing = (updatedRing) => {
    setLoading(true);
    dispatch(
      actions.updateConfidentialityRing({
        fileId: selectedRows[0].id,
        ring: updatedRing,
      }),
    )
      .then((res) => {
        const updatedFileVariants = fileVariants.reduce(
          (acc, itm) => (itm.id ? (itm.id === res.id ? [...acc, res] : [...acc, itm]) : acc),
          [],
        );
        handleFileVariants(updatedFileVariants);
      })
      .catch(() => setLoading(false));
  };

  const handleFileUpload = (files, variantName) => {
    setLoading(true);
    dispatch(
      actions.uploadConfidentialityRingFiles({
        fileId: selectedRows[0].id,
        file: files[0],
        ring: variantName,
      }),
    ).catch(() => setLoading(false));
  };
  const columns = fileVariantsColumnSelector(
    handleFileUpload,
    handleChangeRing,
    ringMenuItems,
    handleDeleteRing,
    fileVariants,
    downloadRingDoc,
  );

  const handleFileVariants = (res) => {
    const rings = res.map((itm) => itm.ring);
    const allRings =
      res.length === 3
        ? res
        : [
            ...res,
            !rings.includes('noRing') && { type: 'dummy', name: 'noRing' },
            !rings.includes('outerRing') && { type: 'dummy', name: 'outerRing' },
            !rings.includes('innerRing') && { type: 'dummy', name: 'innerRing' },
          ].filter(Boolean);
    setFileVariants(allRings);
    setRingMenuItems([
      ...['noRing', 'innerRing', 'outerRing'].filter((itm) => !rings.includes(itm)),
    ]);
    setLoading(false);
  };

  useEffect(() => {
    if (!uploadConfidentialityRingFilesPending)
      dispatch(actions.fetchFileRings({ fileId: selectedRows[0].id }))
        .then((res) => handleFileVariants(res))
        .catch(() => setLoading(false));
  }, [selectedRows, dispatch, uploadConfidentialityRingFilesPending]);
  return (
    <Modal
      show={show}
      title={T.translate('generic.fileVariants') + ` for ${selectedRows[0].id}`}
      handleClose={() => {
        setFileVariants([]);
        handleClose();
      }}
    >
      {!loading ? (
        fileVariants && fileVariants.length > 0 ? (
          <Card style={{ height: '25rem', margin: '-1.5rem' }}>
            <Table
              key={Math.random()}
              data={fileVariants}
              columns={columns}
              isItemLoaded={isItemLoaded}
              loadMoreItems={loadMoreItems}
              totalDocumentsCount={0}
              rowHeight={50}
              hoverableRows={false}
            />
          </Card>
        ) : (
          T.translate('generic.noFileVariantsFound')
        )
      ) : (
        <Spinner />
      )}
    </Modal>
  );
};

export default FileVariants;
