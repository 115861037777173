/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Paper,
  Box,
  SelectChangeEvent,
  Button,
  Menu,
  Divider,
  ListItemText,
  Tooltip,
  IconButton,
} from '@mui/material';
import MuiButton from '@mui/material/Button';
import { useDropzone } from 'react-dropzone';
import T from 'i18n';
import {
  isRowEmpty,
  removeTrailingSpacesFromFilesWithAndWithoutExtensions,
  validateDateField,
  exportConfig,
} from './utils';
import { FileMetaData, MappingProblem, ParsedTemplate } from './types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import api from 'common/api';
import MappingErrors from './MappingErrors';
import { Spinner } from 'features/common';
import MappingPreview from './MappingPreview';
import { fileIdsArray, PROBLEM_CATEGORIES, fileIdentifiers } from './constants';
import MappingSummary from './MappingSummary';
import { InfoOutlined, KeyboardArrowDown } from '@mui/icons-material';
import { selectUserDataRoomSettings } from 'common/selectors';
import { useSelector } from 'react-redux';
import { useCSVMapping } from './hooks/useCSVMapping';

// TODO: Make the handleSubmit and validation work with useCSVMapping.ts

type AdvancedSettingsProps = {
  onMappingComplete?: (mappingData: any[]) => void;
  onMappingFileAttached: () => void;
  setAllFieldsMapped: React.Dispatch<React.SetStateAction<boolean>>;
  allFieldsMapped: boolean;
  failSignal?: boolean;
  fileMetaData?: FileMetaData[];
  setMappingData: React.Dispatch<React.SetStateAction<any[]>>;
  mappingData?: any[];
  caseId: string;
  selectedTemplate: ParsedTemplate;
  setSelectedTemplate: React.Dispatch<React.SetStateAction<ParsedTemplate>>;
  base: boolean;
};

const AdvancedSettings: React.FC<AdvancedSettingsProps> = ({
  onMappingFileAttached,
  failSignal,
  fileMetaData,
  setMappingData,
  allFieldsMapped,
  setAllFieldsMapped,
  caseId,
  selectedTemplate,
  setSelectedTemplate,
  mappingData,
  base,
}) => {
  const { mappingFile, setMappingFile, parsedData, setParsedData, columns, setColumns, parseFile } =
    useCSVMapping();

  const [primaryKey, setPrimaryKey] = useState<string>('');
  const [columnMappings, setColumnMappings] = useState<{ [key: string]: string }>({});
  const [errors, setErrors] = useState<{ [key: string]: boolean }>({});
  const [failedRows, setFailedRows] = useState<any[]>([]);
  const [templates, setTemplates] = useState<ParsedTemplate[]>([]);
  const [templateError, setTemplateError] = useState<string>('');
  const [fetchingTemplates, setFetchingTemplates] = useState<boolean>(false);
  const [previewOpen, setPreviewOpen] = useState(false);

  const { columns: visibleColumns } = useSelector(selectUserDataRoomSettings);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ['.csv'],
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        setMappingFile(file);
        parseFile(file);
        onMappingFileAttached();
      }
    },
  });

  const getTemplates = async () => {
    try {
      setFetchingTemplates(true);
      const response: any = await api.get(`/cases/${caseId}/batch-templates?type=batch`);
      parseTemplatesToRequiredFields(response);
      setFetchingTemplates(false);
    } catch (error) {
      setTemplateError(T.translate('case.batchUpload.advancedSettings.template.templateError'));
      console.error('Error fetching templates', error);
    }
  };

  const parseTemplatesToRequiredFields = (t: any) => {
    for (const template of t) {
      let req: string[] = [];
      let dateFormats: string[] = [];
      for (const stage of template.stages) {
        if (stage.id === 'map-file') {
          req = stage.requiredFields;
          if (stage.dateFormats) {
            dateFormats = stage.dateFormats.split(',').map((fmt: string) => fmt.trim());
          }
        }
      }

      const parsedTemplate: ParsedTemplate = {
        id: template.id,
        requiredFields: req,
        dateFormats: dateFormats,
        name: template.name,
        fileIdentifier: primaryKey,
        formatsForDates: template.formatsForDates,
        fieldsWithAppend: template.fieldsWithAppend,
      };
      setTemplates((prev) => [...prev, parsedTemplate]);
    }
  };

  const handlePrimaryKeyChange = (event: SelectChangeEvent<string>) => {
    setPrimaryKey(event.target.value as string);
    if (errors.primaryKey) {
      setErrors((prev) => ({ ...prev, primaryKey: false }));
    }
  };

  const handleMappingChange = (requiredField: string) => (event: SelectChangeEvent<string>) => {
    setColumnMappings((prev) => ({
      ...prev,
      [requiredField]: event.target.value as string,
    }));
    if (errors[requiredField]) {
      setErrors((prev) => ({ ...prev, [requiredField]: false }));
    }
  };

  const handleSubmit = () => {
    const newErrors: { [key: string]: boolean } = {};
    selectedTemplate?.requiredFields?.forEach((field) => {
      if (!columnMappings[field]) {
        newErrors[field] = true;
      }
    });
    if (!primaryKey) {
      newErrors.primaryKey = true;
    }
    setErrors(newErrors);

    const invalidRows: MappingProblem[] = [];

    // Build a map of CSV rows keyed by the (lowercase) primaryKey
    const csvDataMap: Record<string, any> = {};
    parsedData.forEach((row) => {
      const primaryKeyValue = row[primaryKey]?.trim().toLowerCase();
      if (primaryKeyValue) {
        csvDataMap[primaryKeyValue] = row;
      } else {
        invalidRows.push({
          rowNumber: row.__rowNum,
          reason: T.translate(
            'case.batchUpload.advancedSettings.template.reasons.missingPrimaryKey',
            {
              row: row.__rowNum,
            },
          ),
          type: 'error',
          problemCategory: PROBLEM_CATEGORIES.missingPrimaryKey,
        });
      }
    });

    const processedData: any[] = [];
    const matchedKeys = new Set<string>();

    if (fileMetaData && fileMetaData.length > 0) {
      fileMetaData.forEach((file) => {
        const fileName = removeTrailingSpacesFromFilesWithAndWithoutExtensions(
          file.name,
        ).toLowerCase();
        const dotIndex = fileName.lastIndexOf('.');
        const fileNameNoExt = dotIndex !== -1 ? fileName.substring(0, dotIndex).trim() : fileName;

        // Attempt to find matching row
        const csvRow = csvDataMap[fileName] || csvDataMap[fileNameNoExt];
        if (!csvRow) {
          // fallback or partial match logic if needed
        }

        if (csvRow) {
          // 1) copy all columns but remove potential case-duplicates
          const mappedObject = { ...csvRow };

          // Remove columns that only differ by case from a requiredField
          selectedTemplate?.requiredFields.forEach((requiredField) => {
            const conflict = Object.keys(mappedObject).find(
              (colName) =>
                colName.toLowerCase() === requiredField.toLowerCase() && colName !== requiredField,
            );
            if (conflict) {
              delete mappedObject[conflict];
            }
          });

          if (base) {
            mappedObject.uniqueId = file.uniqueId;
            mappedObject.folderPath = file.folderPath;
            mappedObject.originalFilename = file.name;
          }

          // Now override any required fields from the CSV
          selectedTemplate?.requiredFields.forEach((field) => {
            const columnName = columnMappings[field];
            const value = csvRow[columnName];
            mappedObject[field] = value;

            if (value === '' || value === undefined || value === null) {
              invalidRows.push({
                rowNumber: csvRow.__rowNum,
                reason: T.translate(
                  'case.batchUpload.advancedSettings.template.reasons.missingRequiredField',
                  {
                    columnName,
                  },
                ),
                type: 'warning',
                problemCategory: PROBLEM_CATEGORIES.missingRequiredField,
              });
            } else if (field.toLowerCase().includes('date')) {
              validateDateField(
                field,
                value,
                selectedTemplate.dateFormats,
                csvRow,
                columnName,
                invalidRows,
              );
            }
          });

          mappedObject.__primaryKey = csvRow[primaryKey];
          processedData.push(mappedObject);

          // We matched this CSV row
          matchedKeys.add(csvRow[primaryKey].trim().toLowerCase());
        } else {
          // No matching CSV row => file not found in CSV
          invalidRows.push({
            rowNumber: null,
            reason: T.translate(
              'case.batchUpload.advancedSettings.template.reasons.notFoundInCSV',
              {
                fileName: file.name,
              },
            ),
            type: 'error',
            problemCategory: PROBLEM_CATEGORIES.notFoundInCSV,
          });
        }
      });
    }

    // 2) AFTER the file loop, do one pass for orphan rows
    parsedData.forEach((row) => {
      const keyVal = row[primaryKey]?.trim().toLowerCase();
      if (keyVal && !matchedKeys.has(keyVal)) {
        invalidRows.push({
          rowNumber: row.__rowNum,
          reason: T.translate('case.batchUpload.dataMapper.reasons.orphanRow', {
            row: row.__rowNum,
          }),
          type: 'error',
          problemCategory: PROBLEM_CATEGORIES.orphanRow,
        });
      }
    });

    // Finally store the results
    setFailedRows(invalidRows);
    return processedData;
  };

  const errorRows = failedRows.filter((item) => item.type === 'error');
  const warnings = failedRows.filter((item) => item.type === 'warning');

  const clearMappingData = () => {
    setMappingFile(null);
    setColumnMappings({});
    setPrimaryKey('');
    setErrors({});
    setParsedData([]);
    setColumns([]);
    setMappingData([]);
    setAllFieldsMapped(false);
    setFailedRows([]);
    setSelectedTemplate(undefined);
    setTemplateError('');
  };

  const attemptAutoMapping = () => {
    if (!selectedTemplate || !columns.length) return;

    setColumnMappings((prev) => {
      const newMappings = { ...prev };

      selectedTemplate?.requiredFields?.forEach((field) => {
        if (newMappings[field]) {
          return;
        }

        let autoMappedColumn = columns?.find((c) => c.toLowerCase() === field.toLowerCase());

        if (!autoMappedColumn) {
          if (fileIdsArray?.includes(field.toLowerCase())) {
            autoMappedColumn = columns?.find((c) => c.toLowerCase() === field.toLowerCase());
          }
        }
        if (autoMappedColumn) {
          newMappings[field] = autoMappedColumn;
        }
      });
      return newMappings;
    });
  };

  useEffect(() => {
    if (selectedTemplate && columns.length > 0) {
      attemptAutoMapping();
    }
  }, [selectedTemplate, columns]);

  useEffect(() => {
    setAllFieldsMapped(false);
    const allFieldsAreMapped: any = selectedTemplate?.requiredFields?.every(
      (field) => columnMappings[field] && columnMappings[field].trim() !== '',
    );

    setAllFieldsMapped(allFieldsAreMapped);
  }, [columnMappings, setAllFieldsMapped]);

  useEffect(() => {
    if (
      parsedData.length > 0 &&
      selectedTemplate?.requiredFields &&
      selectedTemplate?.id &&
      primaryKey
    ) {
      const jsonData = handleSubmit();
      if (jsonData) {
        setMappingData(jsonData);
      }
    }
  }, [parsedData, columnMappings, selectedTemplate, primaryKey]);

  useEffect(() => {
    if (mappingFile && !selectedTemplate && templates?.length === 0) {
      getTemplates();
    }
  }, [mappingFile, selectedTemplate, templates?.length]);
  // on unmount clear the mapping data
  useEffect(() => {
    return () => {
      clearMappingData();
    };
  }, []);
  const [exportMenuAnchor, setExportMenuAnchor] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setExportMenuAnchor(event.currentTarget);
  };
  const handleMenuClose = () => {
    setExportMenuAnchor(null);
  };

  const exportMenuConfig = useMemo(
    () =>
      exportConfig({
        failedRows,
        documents: [''] as any,
        parsedData,
        columns: visibleColumns,
      }),
    [failedRows, parsedData],
  );

  const exportCounts = useMemo(() => {
    const counts: Record<string, number> = {};
    exportMenuConfig.forEach((item) => {
      counts[item.key] = failedRows.filter(item.filterFn).length;
    });
    return counts;
  }, [failedRows, exportMenuConfig]);
  return (
    <Paper elevation={0} style={{ padding: '10px', width: '100%' }}>
      {/* Need this button to be on the right but not absolute */}

      {mappingFile && (
        <Box display="flex" justifyContent="flex-end">
          <MuiButton
            variant="text"
            onClick={() => {
              clearMappingData();
            }}
          >
            {T.translate('case.batchUpload.advancedSettings.reset')}
          </MuiButton>
        </Box>
      )}
      {fileMetaData && fileMetaData.length === 0 ? (
        <div
          style={{
            border: `2px dashed #cccccc`,
            padding: '20px',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 6,
            marginBottom: '20px',
            backgroundColor: 'transparent',
            opacity: 0.7,
          }}
        >
          <p>{T.translate('case.batchUpload.advancedSettings.noFilesAttached')}</p>
        </div>
      ) : (
        <>
          <div
            {...getRootProps()}
            style={{
              border: `2px dashed ${isDragActive ? '#0078d4' : '#cccccc'}`,
              padding: '20px',
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 6,
              cursor: allFieldsMapped ? 'not-allowed' : 'pointer',
              marginBottom: '20px',
              backgroundColor: allFieldsMapped ? '#f5f5f5' : 'transparent',
            }}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>{T.translate('case.batchUpload.advancedSettings.dropHere')}</p>
            ) : (
              <p>{T.translate('case.batchUpload.advancedSettings.uploadMappingFile')}</p>
            )}
          </div>
        </>
      )}
      {mappingFile && (
        <span
          style={{
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Typography variant="body1" sx={{ color: allFieldsMapped ? 'grey.500' : 'inherit' }}>
            {mappingFile.name}
          </Typography>
          <Tooltip
            title="Name on disk refers to the column in the CSV file which matches the filenames on disk"
            arrow
          >
            <IconButton size="medium" disableRipple>
              <InfoOutlined fontSize="small" />
            </IconButton>
          </Tooltip>
        </span>
      )}

      {columns.length > 0 && (
        <FormControl
          fullWidth
          variant="outlined"
          style={{ marginBottom: '20px', position: 'relative' }}
          error={Boolean(errors.primaryKey)}
          required
        >
          <InputLabel id="primary-key-label">
            {T.translate('case.batchUpload.advancedSettings.primaryKey')}
          </InputLabel>
          <Select
            labelId="primary-key-label"
            value={primaryKey}
            onChange={handlePrimaryKeyChange}
            label={T.translate('case.batchUpload.advancedSettings.primaryKey')}
          >
            {columns.map((col) => (
              <MenuItem key={col} value={col}>
                {col}
              </MenuItem>
            ))}
          </Select>
          {errors.primaryKey && (
            <>
              <Typography variant="caption" color="error">
                {T.translate('case.batchUpload.advancedSettings.primaryRequired')}
              </Typography>
              <FontAwesomeIcon
                icon={faExclamationCircle}
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  color: 'red',
                }}
              />
            </>
          )}
        </FormControl>
      )}
      {templates.length > 0 &&
        mappingFile &&
        (fetchingTemplates ? (
          <Spinner style={{ fontSize: '0.5rem' }} />
        ) : (
          <FormControl
            fullWidth
            variant="outlined"
            style={{ marginBottom: '20px', position: 'relative' }}
            required
            error={Boolean(templateError)}
          >
            <InputLabel id="template-select-label">
              {T.translate('case.batchUpload.advancedSettings.selectTemplate')}
            </InputLabel>
            <Select
              labelId="template-select-label"
              value={selectedTemplate?.id || ''}
              onChange={(event: SelectChangeEvent<string>) => {
                const selectedId = event.target.value as string;
                const template = templates.find((t) => (t?.id as string) === selectedId);
                setSelectedTemplate(template);
              }}
              label={T.translate('case.batchUpload.advancedSettings.selectTemplate')}
            >
              {templates.map((template) => (
                <MenuItem key={template?.id} value={template?.id}>
                  {template?.name}
                </MenuItem>
              ))}
            </Select>

            {templateError && (
              <>
                <Typography variant="caption" color="error">
                  {templateError}
                </Typography>
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    color: 'red',
                  }}
                />
              </>
            )}
          </FormControl>
        ))}
      {columns.length > 0 && (selectedTemplate?.requiredFields?.length ?? 0) > 0 && (
        <Box display="flex" flexDirection="column" gap={3}>
          {selectedTemplate?.requiredFields.map((field) => (
            <FormControl key={field} fullWidth variant="outlined" required>
              <InputLabel id={`${field}-mapping-label`}>
                {T.translate(fileIdentifiers?.get(field) as string) || field}
              </InputLabel>
              <Select
                labelId={`${field}-mapping-label`}
                value={columnMappings[field] || ''}
                onChange={handleMappingChange(field)}
                label={T.translate(`case.batchUpload.advancedSettings.${field}`)}
                error={failSignal}
              >
                {columns
                  .filter(
                    (col) =>
                      !Object.values(columnMappings).includes(col) || columnMappings[field] === col,
                  )
                  .map((col) => (
                    <MenuItem key={col} value={col}>
                      {col}
                    </MenuItem>
                  ))}
              </Select>
              {field.toLowerCase().includes('date') && selectedTemplate?.dateFormats.length > 0 && (
                <Typography variant="caption" color="textSecondary" style={{ marginTop: '4px' }}>
                  {`Format: ${selectedTemplate.dateFormats.join(', ')}`}
                </Typography>
              )}
              {columnMappings[field] && (
                <Typography variant="body2" key={field}>
                  {`${columnMappings[field]} from CSV → ${T.translate(fileIdentifiers?.get(field) as string) || field}`}
                </Typography>
              )}
              {errors[field] && (
                <>
                  <Typography variant="caption" color="error">
                    {T.translate('case.batchUpload.advancedSettings.required')}
                  </Typography>
                  <FontAwesomeIcon
                    icon={faExclamationCircle}
                    style={{
                      position: 'absolute',
                      right: '10px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      color: 'red',
                    }}
                  />
                </>
              )}
            </FormControl>
          ))}
        </Box>
      )}
      {mappingData && mappingData.length > 0 && (
        <MappingSummary failedRows={failedRows} mappingData={mappingData} />
      )}
      <Box display="flex" justifyContent="space-between" mt={3}>
        {failedRows.length > 0 && (
          <>
            <Button
              id="export-menu-button"
              aria-haspopup="true"
              variant="contained"
              disableElevation
              onClick={handleMenuOpen}
              endIcon={<KeyboardArrowDown />}
            >
              {T.translate('case.batchUpload.dataMapper.exportByError', {
                defaultValue: 'Export',
              })}
            </Button>

            <Menu
              anchorEl={exportMenuAnchor}
              open={Boolean(exportMenuAnchor)}
              onClose={handleMenuClose}
              sx={{ mt: 1 }}
              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
              {exportMenuConfig?.map((menuItem: any) => {
                const count = exportCounts[menuItem.key] || 0;
                const disabled = count === 0;
                const labelWithCount = `${menuItem.label} (${count})`;

                return menuItem.key === 'separator' ? (
                  <Divider />
                ) : (
                  <MenuItem
                    key={menuItem.key}
                    disabled={disabled}
                    onClick={() => {
                      handleMenuClose();
                      menuItem.handlerFn();
                    }}
                  >
                    <ListItemText>{labelWithCount}</ListItemText>
                  </MenuItem>
                );
              })}
            </Menu>
          </>
        )}
        {mappingFile && selectedTemplate && mappingData && (
          <Button
            variant="outlined"
            color="primary"
            style={{ marginTop: 10, alignSelf: 'center' }}
            onClick={() => setPreviewOpen(true)}
            sx={{ alignSelf: 'center' }}
          >
            {T.translate('case.batchUpload.dataMapper.preview.previewButton')}
          </Button>
        )}
      </Box>
      <MappingErrors
        failedRows={failedRows}
        exclusionMessage={
          errorRows?.length ? T.translate('case.batchUpload.advancedSettings.rowsExcluded') : ''
        }
        normalMessage={
          warnings?.length ? T.translate('case.batchUpload.advancedSettings.fieldsNotMapped') : ''
        }
        rawCSVRows={parsedData}
      />

      <MappingPreview
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
        mappingData={mappingData as any[]}
        fileIdentifier="__primaryKey"
        setMappingData={setMappingData}
        failedRows={failedRows}
        setFailedRows={setFailedRows}
        formatsForDates={selectedTemplate?.formatsForDates as string[]}
        requiredFields={selectedTemplate?.requiredFields as string[]}
      />
    </Paper>
  );
};

export default AdvancedSettings;
