import { call, put, takeEvery } from 'redux-saga/effects';
import {
  CASE_ADD_FOLDER_BEGIN,
  CASE_ADD_FOLDER_SUCCESS,
  CASE_ADD_FOLDER_FAILURE,
  CASE_ADD_FOLDER_DISMISS_FEEDBACK,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';
import { addItemImmutableDeepNested } from 'utils/arrays';

export const addFolder = createPromiseAction(CASE_ADD_FOLDER_BEGIN, (payload) => ({
  folder: payload,
}));

export function dismissAddFolderFeedback() {
  return {
    type: CASE_ADD_FOLDER_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_ADD_FOLDER_BEGIN actions
export function* doAddFolder(action) {
  const {
    payload: { folder, caseId },
  } = action;
  const res = yield call(api.post, `/cases/${caseId}/folders`, folder);

  if (res && res.error) {
    yield put({
      type: CASE_ADD_FOLDER_FAILURE,
      feedback: {
        message: 'feedback.addFolderFailure',
        error: res.error,
        retryAction: action,
        id: new Date().getTime(),
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_ADD_FOLDER_SUCCESS,
    data: { folder: { ...folder, ...res } },
    feedback: {
      message: 'feedback.addFolderSuccess',
      params: {
        successful: res.filesAssigned,
        bundleName: folder.name,
      },
      id: new Date().getTime(), // Add unique ID to feedback
    },
  });
  yield call(resolvePromiseAction, action, res);
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchAddFolder() {
  yield takeEvery(addFolder, withCurrentCaseId(doAddFolder));
}

const getNewFolders = (folders, folder) => {
  switch (folder.type) {
    case 'team-bundle':
      return {
        ...folders,
        teamBundles: addItemImmutableDeepNested(folders.teamBundles, folder),
      };
    case 'private-bundle':
      return {
        ...folders,
        privateBundles: addItemImmutableDeepNested(folders.privateBundles, folder),
      };
    case 'bundle':
      return {
        ...folders,
        bundles: addItemImmutableDeepNested(folders.bundles, folder),
      };
    case 'courtBundle':
      return {
        ...folders,
        courtBundles: addItemImmutableDeepNested(folders.courtBundles, folder),
      };
    case 'trialbook':
      return {
        ...folders,
        trialbooks: addItemImmutableDeepNested(folders.trialbooks, folder),
      };
    default:
      return folders;
  }
};

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_ADD_FOLDER_BEGIN:
      return {
        ...state,
        addFolderPending: true,
        addFolderFeedback: null,
      };

    case CASE_ADD_FOLDER_SUCCESS:
      return {
        ...state,
        addFolderPending: false,
        addFolderFeedback: action.feedback,
        folders: getNewFolders(state.folders, action.data.folder),
      };

    case CASE_ADD_FOLDER_FAILURE:
      return {
        ...state,
        addFolderPending: false,
        addFolderFeedback: action.feedback,
      };

    case CASE_ADD_FOLDER_DISMISS_FEEDBACK:
      return {
        ...state,
        addFolderFeedback: null,
      };

    default:
      return state;
  }
}
