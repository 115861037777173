import { takeEvery, put, call } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import {
  VIEWING_LEAVE_A_GROUP_BEGIN,
  VIEWING_LEAVE_A_GROUP_SUCCESS,
  VIEWING_LEAVE_A_GROUP_FAILURE,
} from './constants';
import api from 'common/api';
import { withCurrentCaseId, withHubConnectionId } from 'common/selectors';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

export const leaveAGroup = createPromiseAction(VIEWING_LEAVE_A_GROUP_BEGIN, (payload) => ({
  role: payload,
}));

// worker Saga: will be fired on VIEWING_LEAVE_A_GROUP_BEGIN actions
export function* doLeaveAGroup(action) {
  const {
    payload: { caseId, role, connectionId },
  } = action;
  const res = yield call(api.del, `/cases/${caseId}/${role}/${connectionId}`);

  if (res && res.error) {
    yield put({
      type: VIEWING_LEAVE_A_GROUP_FAILURE,
      feedback: {
        /*message: 'feedback.leaveAGroupFailure',*/ error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: VIEWING_LEAVE_A_GROUP_SUCCESS,
  });

  yield call(resolvePromiseAction, action, res);
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchLeaveAGroup() {
  yield takeEvery(leaveAGroup, withCurrentCaseId(withHubConnectionId(doLeaveAGroup)));
}

export function useLeaveAGroup() {
  const dispatch = useDispatch();

  const { leaveAGroupPending, leaveAGroupFeedback } = useSelector(
    (state) => ({
      leaveAGroupPending: state.viewing.leaveAGroupPending,
      leaveAGroupFeedback: state.viewing.leaveAGroupFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(leaveAGroup(...args));
    },
    [dispatch],
  );

  return {
    leaveAGroup: boundAction,
    leaveAGroupPending,
    leaveAGroupFeedback,
  };
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case VIEWING_LEAVE_A_GROUP_BEGIN + '.TRIGGER':
      return {
        ...state,
        leaveAGroupPending: true,
        leaveAGroupFeedback: null,
      };

    case VIEWING_LEAVE_A_GROUP_SUCCESS:
      return {
        ...state,
        leaveAGroupPending: false,
        leaveAGroupFeedback: action.feedback,
      };

    case VIEWING_LEAVE_A_GROUP_FAILURE:
      return {
        ...state,
        leaveAGroupPending: false,
        leaveAGroupFeedback: action.feedback,
      };

    default:
      return state;
  }
}
