import React from 'react';
import { Box, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { selectUserDataRoomSettings } from 'common/selectors';
import { Delete } from '@mui/icons-material';
import T from 'i18n';

const useStyles = makeStyles({
  tableContainer: {
    maxHeight: '400px',
    overflowY: 'auto',
    overflowX: 'auto',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    borderBottom: '1px solid #ccc',
    textAlign: 'left',
    padding: '8px',
    position: 'sticky',
    top: 0,
    backgroundColor: '#f9f9f9',
    zIndex: 1,
  },
  td: {
    borderBottom: '1px solid #eee',
    padding: '8px',
  },
});

type PreviewAssignFilesProps = {
  docs: any[];
  isEditing?: boolean;
  onDeleteRow?: (rowIndex: number) => void;
};

const PreviewAssignFiles: React.FC<PreviewAssignFilesProps> = ({
  docs,
  isEditing = false,
  onDeleteRow,
}) => {
  const classes = useStyles();
  const userDataRoomSettings = useSelector(selectUserDataRoomSettings);

  if (!docs?.length) return null;

  const allKeys = new Set<string>();
  docs.forEach((doc) => {
    Object.keys(doc).forEach((key) => allKeys.add(key));
  });
  const columns = Array.from(allKeys).filter((col) => userDataRoomSettings?.columns?.includes(col));

  const showDeleteColumn = isEditing && onDeleteRow;

  return (
    <Box className={classes.tableContainer}>
      <table className={classes.table}>
        <thead>
          <tr>
            {showDeleteColumn && (
              <th className={classes.th} style={{ width: 50, textAlign: 'center' }}></th>
            )}
            {columns.map((col) => (
              <th key={col} className={classes.th}>
                {col}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {docs.map((doc, rowIndex) => {
            return (
              <tr key={rowIndex}>
                {showDeleteColumn && (
                  <td className={classes.td} style={{ textAlign: 'center' }}>
                    <IconButton
                      size="small"
                      onClick={() => onDeleteRow?.(rowIndex)}
                      aria-label={T.translate('case.assign.dialog.table.deleteRow')}
                    >
                      <Delete fontSize="small" />
                    </IconButton>
                  </td>
                )}
                {columns.map((col) => {
                  let val = doc[col];
                  if (Array.isArray(val) || typeof val === 'object') {
                    val = JSON.stringify(val);
                  }
                  return (
                    <td key={col} className={classes.td}>
                      {val != null ? val : ''}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Box>
  );
};

export default PreviewAssignFiles;
