import { call, put, takeEvery, select } from 'redux-saga/effects';
import {
  VIEWING_DELETE_ANNOTATION_BEGIN,
  VIEWING_DELETE_ANNOTATION_SUCCESS,
  VIEWING_DELETE_ANNOTATION_FAILURE,
  VIEWING_DELETE_ANNOTATION_DISMISS_FEEDBACK,
} from './constants';
import { CASE_DELETE_ANNOTATIONS_SUCCESS } from 'features/case/redux/constants';
import api from 'common/api';
import {
  withCurrentCaseId,
  selectCurrentFile,
  selectCurrentAnnotationId,
  withCurrentUserId,
} from 'common/selectors';
import { deleteItemImmutable } from 'utils/arrays';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { filterAnnotations } from './filterAnnotations';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';

export const deleteAnnotation = createPromiseAction(VIEWING_DELETE_ANNOTATION_BEGIN);

export function dismissDeleteAnnotationFeedback() {
  return {
    type: VIEWING_DELETE_ANNOTATION_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on VIEWING_DELETE_ANNOTATION_BEGIN actions
export function* doDeleteAnnotation(action) {
  const {
    payload: { annotation, fileId, caseId, currentUserId: userId },
  } = action;
  const file = yield select(selectCurrentFile);
  const annotationId = yield select(selectCurrentAnnotationId);

  const res = yield call(
    api.del,
    `/cases/${caseId}/files/${fileId || file}/annotations/${annotation.id}`,
  );

  if (res && res.error) {
    yield put({
      type: VIEWING_DELETE_ANNOTATION_FAILURE,
      feedback: {
        message: 'feedback.deleteAnnotationFailure',
        error: res.error,
        retryAction: action,
      },
    });

    return yield call(rejectPromiseAction, action, { error: true });
  }

  yield put({
    type: VIEWING_DELETE_ANNOTATION_SUCCESS,
    data: { annotation },
    userId,
  });

  yield call(resolvePromiseAction, action, res);

  if (fileId || (annotationId && annotationId === annotation.id)) {
    // if deleting from annotations table remove annotations from grid
    yield put({
      type: CASE_DELETE_ANNOTATIONS_SUCCESS,
      data: { annotation },
    });
  }
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchDeleteAnnotation() {
  yield takeEvery(deleteAnnotation, withCurrentUserId(withCurrentCaseId(doDeleteAnnotation)));
}

export function useDeleteAnnotation() {
  const dispatch = useDispatch();

  const { deleteAnnotationPending, deleteAnnotationFeedback, annotations } = useSelector(
    (state) => ({
      deleteAnnotationPending: state.viewing.deleteAnnotationPending,
      deleteAnnotationFeedback: state.viewing.deleteAnnotationFeedback,
      annotations: state.viewing.annotations,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(deleteAnnotation(...args));
    },
    [dispatch],
  );

  return {
    annotations,
    deleteAnnotation: boundAction,
    deleteAnnotationPending,
    deleteAnnotationFeedback,
  };
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case VIEWING_DELETE_ANNOTATION_BEGIN + '.TRIGGER':
      return {
        ...state,
        deleteAnnotationPending: true,
        deleteAnnotationFeedback: null,
      };

    case VIEWING_DELETE_ANNOTATION_SUCCESS:
      const indexOfTheAnnotationToDelete = state.annotations.findIndex(
        (x) => x.id === action.data.annotation.id,
      );

      const newAnnotations =
        indexOfTheAnnotationToDelete >= 0 &&
        state.annotations &&
        state.annotations.length > 0 &&
        deleteItemImmutable(state.annotations, indexOfTheAnnotationToDelete);
      return {
        ...state,
        deleteAnnotationPending: false,
        deleteAnnotationFeedback: action.feedback,
        ...(state.annotations &&
          state.annotations.length > 0 &&
          newAnnotations && {
            annotations: newAnnotations,
            filteredAnnotations: filterAnnotations(
              newAnnotations,
              state.updatedFilters,
              action.userId,
            ),
          }),
      };

    case VIEWING_DELETE_ANNOTATION_FAILURE:
      return {
        ...state,
        deleteAnnotationPending: false,
        deleteAnnotationFeedback: action.feedback,
      };

    case VIEWING_DELETE_ANNOTATION_DISMISS_FEEDBACK:
      return {
        ...state,
        deleteAnnotationFeedback: null,
      };

    default:
      return state;
  }
}
