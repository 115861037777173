export default (documents, zeroBasedIndex, includeTab = false, initialStartTabIndex) => {
  let tabIndex = initialStartTabIndex - 1;
  return documents.map((document, index) => {
    tabIndex += 1;
    return {
      ...document,
      sortOrder: zeroBasedIndex ? index : index + 1,
      ...(includeTab && { tab: tabIndex }),
    };
  });
};
