import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import classnames from 'classnames';
import T from 'i18n';

const FilterCases = ({ cases, handleFilterCases }) => {
  const [searchVal, setSearchVal] = useState('');
  const [focused, setFocused] = useState(false);

  const handleSearchVal = (val) => {
    setSearchVal(val);
    const filteredCases = cases.filter(
      (item) =>
        item.name.toLowerCase().includes(val.toLowerCase()) ||
        (item.claimNumber && item.claimNumber.toLowerCase().includes(val.toLowerCase())),
    );
    handleFilterCases(filteredCases);
  };

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
      }}
      className="search-cases"
    >
      <div className={classnames('search-container search-box-size', { focused: focused })}>
        <FormControl
          type="search"
          placeholder={T.translate('home.searchCasesMsg')}
          className="mr-sm-2"
          value={searchVal}
          onChange={({ target: { value } }) => handleSearchVal(value)}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSearchVal(e.target.value);
              e.stopPropagation();
              e.preventDefault();
            }
          }}
          autoFocus
        />
      </div>
    </Form>
  );
};

export default FilterCases;
