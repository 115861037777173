import { call, put, takeEvery, select, all } from 'redux-saga/effects';
import {
  CASE_ASSIGN_DOCUMENTS_BEGIN,
  CASE_ASSIGN_DOCUMENTS_SUCCESS,
  CASE_ASSIGN_ALL_DOCUMENTS_SUCCESS,
  CASE_ADD_DOCUMENTS_SUCCESS,
  CASE_ASSIGN_DOCUMENTS_FAILURE,
  CASE_ASSIGN_DOCUMENTS_DISMISS_FEEDBACK,
} from './constants';
import api from 'common/api';
import { withCurrentCaseId, selectCurrentFolder, selectDocuments } from '../../../common/selectors';
import { replaceItemImmutable } from 'utils/arrays';
import addSortNumberToDocuments from './addSortNumberToDocuments';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';

export const assignDocuments = createPromiseAction(CASE_ASSIGN_DOCUMENTS_BEGIN);

export function dismissAssignDocumentsFeedback() {
  return {
    type: CASE_ASSIGN_DOCUMENTS_DISMISS_FEEDBACK,
  };
}

export function* doAssignDocuments(action) {
  const {
    payload: { documentIds, folderId, isAttachment = false, caseId },
  } = action;
  // if no folder no assign
  if (!folderId) {
    return yield put({
      type: CASE_ASSIGN_DOCUMENTS_FAILURE,
      feedback: {
        message: 'feedback.assignDocumentsFailure',
        params: { unsuccessful: documentIds.length },
        error: 'No folder id specified',
      },
    });
  }

  const currentFolder = yield select(selectCurrentFolder);
  const allDocuments = yield select(selectDocuments);
  let payload = [];
  if (!isAttachment)
    payload = allDocuments.reduce(
      (acc, doc) =>
        documentIds.includes(doc.folderFileId) ||
        documentIds.includes(doc.compositeKey) ||
        documentIds.includes(doc.id)
          ? [...acc, { id: doc.id, name: doc.name }]
          : acc,
      [],
    );
  else payload = documentIds;

  const shouldAssign = folderId && folderId === currentFolder.id;

  const res = yield call(
    api.post,
    `/cases/${caseId}/folders/${folderId}/files?includeFileList=${isAttachment}`,
    payload,
  );

  if (res && res.error) {
    yield put({
      type: CASE_ASSIGN_DOCUMENTS_FAILURE,
      feedback: {
        message: 'feedback.assignDocumentsFailure',
        params: { unsuccessful: documentIds.length },
        error: res.error,
      },
      /*retryAction: action,*/
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  if (isAttachment)
    yield all(
      res.results &&
        res.results.map((doc) =>
          put({
            type: CASE_ASSIGN_DOCUMENTS_SUCCESS,
            data: { document: doc, shouldAssign, zeroBasedIndex: currentFolder.zeroBasedIndex },
          }),
        ),
    );
  else {
    yield put({
      type: CASE_ADD_DOCUMENTS_SUCCESS,
      data: res,
      feedback: {
        message: 'feedback.assignDocumentsSuccess',
        params: { successful: res.filesAssigned },
      },
    });
    yield put({ type: CASE_ASSIGN_ALL_DOCUMENTS_SUCCESS });
  }
  yield call(resolvePromiseAction, action, res);
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchAssignDocuments() {
  yield takeEvery(assignDocuments, withCurrentCaseId(doAssignDocuments));
}

// Redux reducer
export function reducer(state, action) {
  let assignDocumentsTempFeed;
  let documentLeft;
  switch (action.type) {
    case CASE_ASSIGN_DOCUMENTS_BEGIN + '.TRIGGER':
      return {
        ...state,
        assignDocumentsPending: state.assignDocumentsPending + action.payload.documentIds.length,
        // clear only if batch finished
        ...(!state.assignDocumentsPending && { assignDocumentsFeedback: null }),
      };

    case CASE_ASSIGN_DOCUMENTS_SUCCESS:
      const indexOfTheDocumentToUpdate =
        action.data.shouldAssign &&
        state.documents.findIndex(({ id }) => id === action.data.document.id);
      const shouldReplace = indexOfTheDocumentToUpdate > -1;
      documentLeft = (state.assignDocumentsPending || state.uploadFilesPending) - 1;
      assignDocumentsTempFeed = {
        message:
          state.assignDocumentsTempFeed &&
          state.assignDocumentsTempFeed.params &&
          state.assignDocumentsTempFeed.params.unsuccesful
            ? 'feedback.assignDocumentsFailureAndSuccess'
            : 'feedback.assignDocumentsSuccess',
        params: {
          ...(state.assignDocumentsTempFeed && state.assignDocumentsTempFeed.params),
          successful:
            ((state.assignDocumentsTempFeed &&
              state.assignDocumentsTempFeed.params &&
              state.assignDocumentsTempFeed.params.successful) ||
              0) + 1,
        },
      };
      return {
        ...state,
        assignDocumentsPending: documentLeft,
        assignDocumentsTempFeed,
        ...(!documentLeft && {
          assignDocumentsFeedback: assignDocumentsTempFeed,
          assignDocumentsTempFeed: null,
        }),
        ...(action.data.shouldAssign
          ? {
              documents: addSortNumberToDocuments(
                shouldReplace
                  ? replaceItemImmutable(
                      state.documents,
                      action.data.document,
                      indexOfTheDocumentToUpdate,
                    )
                  : [...state.documents, action.data.document],
                action.data.zeroBasedIndex,
              ),
            }
          : {}),
      };

    case CASE_ADD_DOCUMENTS_SUCCESS:
      return {
        ...state,
        assignDocumentsPending: false,
        assignDocumentsFeedback: action.feedback,
      };

    case CASE_ASSIGN_DOCUMENTS_FAILURE:
      return {
        ...state,
        assignDocumentsPending: false,
        assignDocumentsFeedback: action.feedback,
      };

    case CASE_ASSIGN_DOCUMENTS_DISMISS_FEEDBACK:
      return {
        ...state,
        assignDocumentsFeedback: null,
      };

    default:
      return state;
  }
}
