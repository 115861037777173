import React from 'react';
import SelectForm from './SelectForm';
// import PropTypes from 'prop-types';

const MultiSelectForm = (props) => {
  return <SelectForm {...props} isMulti />;
};

MultiSelectForm.propTypes = {};
MultiSelectForm.defaultProps = {};

export default MultiSelectForm;
