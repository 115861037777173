import { takeLatest, put, call } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import {
  VIEWING_START_PRESENTING_BEGIN,
  VIEWING_START_PRESENTING_SUCCESS,
  VIEWING_START_PRESENTING_FAILURE,
} from './constants';
import api from 'common/api';
import { withCurrentCaseId, withHubConnectionId } from 'common/selectors';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

export const startPresenting = createPromiseAction(VIEWING_START_PRESENTING_BEGIN);

// worker Saga: will be fired on VIEWING_JOIN_A_GROUP_BEGIN actions
export function* doStartPresenting(action) {
  const {
    payload: { caseId, connectionId, hearingRoomMode, message },
  } = action;

  const res = yield call(api.post, `/cases/${caseId}/present`, {
    message,
    connectionId,
    hearingRoomMode,
  });

  if (res && res.error) {
    yield put({
      type: VIEWING_START_PRESENTING_FAILURE,
      feedback: {
        message: 'feedback.startPresentingFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: VIEWING_START_PRESENTING_SUCCESS,
  });

  yield call(resolvePromiseAction, action, res);
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchStartPresenting() {
  yield takeLatest(startPresenting, withCurrentCaseId(withHubConnectionId(doStartPresenting)));
}

export function useStartPresenting() {
  const dispatch = useDispatch();

  const { startPresentingPending, startPresentingFeedback } = useSelector(
    (state) => ({
      startPresentingPending: state.viewing.startPresentingPending,
      startPresentingFeedback: state.viewing.startPresentingFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(startPresenting(...args));
    },
    [dispatch],
  );

  return {
    startPresenting: boundAction,
    startPresentingPending,
    startPresentingFeedback,
  };
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case VIEWING_START_PRESENTING_BEGIN + '.TRIGGER':
      return {
        ...state,
        startPresentingPending: true,
        startPresentingFeedback: null,
      };

    case VIEWING_START_PRESENTING_SUCCESS:
      return {
        ...state,
        startPresentingPending: false,
        startPresentingFeedback: action.feedback,
      };

    case VIEWING_START_PRESENTING_FAILURE:
      return {
        ...state,
        startPresentingPending: false,
        startPresentingFeedback: action.feedback,
      };

    default:
      return state;
  }
}
