import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import Auth from '@aws-amplify/auth';
import { Controller, useForm } from 'react-hook-form';
import CircularProgress from '@mui/material/CircularProgress';
import T from 'i18n';
import Box from '@mui/material/Box';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';

const useStyles = makeStyles({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    backgroundColor: '#f50057 !important',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: '2rem',
  },
  magicText: {
    display: 'flex',
    padding: '1rem 2rem 1rem 1rem',
    backgroundColor: '#F3F3F3',
    borderRadius: '0.25rem',
  },
});

function AddPhone({ onSavePhoneCompleted, user }: { onSavePhoneCompleted: any; user: any }) {
  const classes = useStyles();

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({ defaultValues: { number: '' } });
  const [loading, setLoading] = useState(false);

  const addPhone = (data: any) => {
    setLoading(true);
    const { number } = data;
    Auth.updateUserAttributes(user, { phone_number: number.replace(/ /g, '') })
      .then((result) => {
        onSavePhoneCompleted(result);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper} style={{ marginTop: 0 }}>
        <Avatar className={classes.avatar} sx={{ m: 1 }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {T.translate('auth.phoneMissing')}
        </Typography>
        <form onSubmit={handleSubmit(addPhone)} className={classes.form} noValidate>
          <Controller
            name="number"
            rules={{
              required: true,
              validate: (val) => matchIsValidTel(val),
            }}
            control={control}
            render={({ field }) => (
              <MuiTelInput
                {...field}
                id="number"
                defaultCountry={'IE'}
                label={T.translate('auth.phone')}
                autoComplete="do-not-complete-this-field"
                error={!!errors.number}
                helperText={
                  errors.number &&
                  T.translate(
                    errors.number.type === 'validate' ? 'auth.phoneInvalid' : 'auth.phoneRequired',
                  )
                }
                style={{ minHeight: '6rem', marginTop: 0 }}
              />
            )}
          />
          <Box className={classes.magicText}>
            <span style={{ marginLeft: '1rem' }}>
              {T.translate('auth.addPhoneAddtionalMessage')}
            </span>
          </Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, fontSize: '1rem' }}
            disabled={loading}
          >
            {loading && <CircularProgress size={17} />}
            {!loading && T.translate('auth.savePhone')}
          </Button>
        </form>
      </div>
    </Container>
  );
}

AddPhone.propTypes = {};
AddPhone.defaultProps = {};

export default AddPhone;
