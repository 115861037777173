import React, { useEffect, useState } from 'react';
import T from 'i18n';
import { Button } from '@mui/material';
import { Modal } from 'features/common/MaterialBasedComponents';
import { Spinner, SystemTestsTable } from 'features/common';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'features/common/redux/actions';
import * as caseActions from 'features/case/redux/actions';
import { selectIsAuthenticated } from 'common/selectors';
import App from 'features/twilioChecks/App';

export const beforeLoginTVChecks = (dispatch, setFinalData) => {
  dispatch(actions.fetchSystemStatus())
    .then((res) => {
      setFinalData((prevData) => [
        ...prevData,
        { test: 'System status', status: 'Pass', result: 'No action needed' },
      ]);
    })
    .catch((err) => {
      setFinalData((prevData) => [
        ...prevData,
        {
          test: 'System status',
          status: 'Fail',
          result: {
            'Expected result': 200,
            'Actual result': err.status || err || 'No response',
            Action: 'Provide this document to your network team to ensure access to our API',
          },
        },
      ]);
    });
  fetch(
    ` https://cognito-idp.eu-west-1.amazonaws.com/${process.env.REACT_APP_COGNITO_USER_POOLS_ID}/.well-known/jwks.json`,
  )
    .then((res) => {
      if (res.status === 200)
        setFinalData((prevData) => [
          ...prevData,
          {
            test: 'Cognito check',
            status: 'Pass',
            result: 'No action needed',
          },
        ]);
      else throw new Error({ errMsg: 'Something went wrong', status: res.status });
    })
    .catch((err) => {
      setFinalData((prevData) => [
        ...prevData,
        {
          test: 'Cognito check',
          status: 'Fail',
          result: {
            'Expected result': 200,
            'Actual result': err.status || err || 'No response',
            Action: 'Provide this document to your network team to ensure access to our API',
          },
        },
      ]);
    });
};

const PrimaryButton = ({ onClick, label, disabled }) => (
  <Button type="button" onClick={onClick} variant="contained" color="primary" disabled={disabled}>
    {T.translate(label)}
  </Button>
);

const SystemDiagnostics = ({ show, handleClose }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [loading, setLoading] = useState(false);
  const [modalMode, setModalMode] = useState('');
  const [showDownloadBtn, setShowDownloadBtn] = useState(false);
  const [showSendBtn, setShowSendBtn] = useState(false);
  const [sendBtnClicked, setSendBtnClicked] = useState(false);
  const [finalData, setFinalData] = useState([]);

  const getTestStatus = (data) => {
    const failedTest = data.filter((item) => item.status === 'Fail');
    const status =
      data.length < 9 ? 'incomplete' : failedTest && failedTest.length > 0 ? 'fail' : 'pass';
    return status;
  };

  const downloadResults = (data) => {
    if (data.length > 0) {
      const filterData = data.map((item) => {
        delete item.selected;
        return item;
      });
      const dataStr =
        'data:text/json;charset=utf-8,' +
        encodeURIComponent(
          JSON.stringify({ status: getTestStatus(filterData), diagnostics: filterData }),
        );
      const dlAnchorElem = document.getElementById('downloadAnchorElem');
      dlAnchorElem.setAttribute('href', dataStr);
      dlAnchorElem.setAttribute('download', 'system_test_results.json');
      dlAnchorElem.click();
    }
  };

  const sendResults = (data, stopAutoSend, noFeedback) => {
    setSendBtnClicked(true);
    if (data.length > 0) {
      const filterData = data.map((item) => {
        delete item.selected;
        return item;
      });
      if (!isAuthenticated && !stopAutoSend) {
        const jsonFile = JSON.stringify({
          status: getTestStatus(filterData),
          diagnostics: filterData,
        });
        const dlAnchorElem = document.getElementById('downloadAnchorElem');
        dlAnchorElem.setAttribute(
          'href',
          'mailto:support@trialview.com?subject=System test report&body=' + jsonFile,
        );
        dlAnchorElem.click();
      } else {
        submitTestResults(filterData, noFeedback);
      }
    }
  };

  const submitTestResults = (data, noFeedback) => {
    dispatch(
      caseActions.addSystemTest({
        results: { status: getTestStatus(data), diagnostics: data },
        noFeedback,
      }),
    );
  };

  useEffect(() => {
    if (!isAuthenticated && finalData.length === 2) {
      setLoading(false);
      setShowDownloadBtn(true);
      setShowSendBtn(true);
    } else if (finalData.length === 9) {
      setShowDownloadBtn(true);
      setShowSendBtn(true);
    }
    //finalData.map(test => test.status === 'Fail' && setShowSendBtn(true));
  }, [finalData, isAuthenticated]);

  const onMount = () => {
    if (show) {
      setLoading(true);
      if (isAuthenticated) {
        setLoading(false);
        setModalMode('FullSystemCheck');
      } else setModalMode('InitialSystemCheck');

      beforeLoginTVChecks(dispatch, setFinalData);
    }
  };

  useEffect(onMount, [show]);

  return (
    <Modal
      show={show}
      title={T.translate('case.diagnostics')}
      handleClose={() => {
        if (!sendBtnClicked) sendResults(finalData, !isAuthenticated ? true : false, true);
        handleClose();
        setShowDownloadBtn(false);
        setShowSendBtn(false);
        setSendBtnClicked(false);
        setFinalData([]);
      }}
      handleSave={showSendBtn ? () => sendResults(finalData) : undefined}
      saveTitle={T.translate('common.sendTestReport')}
      buttons={
        <PrimaryButton
          onClick={() => downloadResults(finalData)}
          disabled={!showDownloadBtn}
          label="common.downloadResults"
        />
      }
    >
      <div style={{ height: '30rem', margin: '-1rem' }}>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {modalMode === 'FullSystemCheck' ? (
              <App setData={(val) => setFinalData(val)} isModalOpened={show} />
            ) : (
              finalData && (
                <div style={{ height: '30rem' }}>
                  <SystemTestsTable data={finalData} />
                </div>
              )
            )}
            <a id="downloadAnchorElem" style={{ display: 'none' }}></a>
          </>
        )}
      </div>
    </Modal>
  );
};

export default SystemDiagnostics;
