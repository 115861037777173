import { put, takeEvery, take, select, call } from 'redux-saga/effects';
import {
  CASE_REPLACE_FILE_BEGIN,
  CASE_REPLACE_FILE_SUCCESS,
  CASE_REPLACE_FILE_FAILURE,
  CASE_REPLACE_FILE_DISMISS_FEEDBACK,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId, selectCurrentFolder } from 'common/selectors';
import { prepareFile } from './uploadFiles';
import addSortNumberToDocuments from './addSortNumberToDocuments';
import { replaceItemImmutable } from 'utils/arrays';

export const replaceFile = createPromiseAction(
  CASE_REPLACE_FILE_BEGIN,
  (payloadFile, payloadId) => ({
    newFile: payloadFile.newFile[0],
    fileId: payloadId,
  }),
);

export function dismissReplaceFileFeedback() {
  return {
    type: CASE_REPLACE_FILE_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_UPLOAD_FILE_BEGIN actions
function* doReplaceFile(action) {
  const {
    payload: { newFile, fileId, caseId },
  } = action;
  const currentFolder = yield select(selectCurrentFolder);
  const fileToSend = prepareFile(newFile);

  const channel = yield call(
    api.createUploadFileChannel,
    'PUT',
    `/cases/${caseId}/files/${fileId}`,
    fileToSend,
  );

  while (true) {
    const { error, success, response: document } = yield take(channel);
    if (error) {
      yield put({
        type: CASE_REPLACE_FILE_FAILURE,
        feedback: {
          message: 'feedback.replaceFileFailure',
          error: error,
          retryAction: action,
        },
        fileId,
      });
      return yield call(rejectPromiseAction, action, error);
    }
    if (success) {
      yield put({
        type: CASE_REPLACE_FILE_SUCCESS,
        data: { meta: document, fileId, zeroBasedIndex: currentFolder.zeroBasedIndex },
        feedback: {
          message: 'feedback.replaceFileSuccess',
          params: { fileId },
          success: true,
        },
      });
      return yield call(resolvePromiseAction, action, success);
    }
  }
}

export function* watchReplaceFile() {
  yield takeEvery(replaceFile, withCurrentCaseId(doReplaceFile));
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_REPLACE_FILE_BEGIN:
      return {
        ...state,
        replaceFilePending: true,
        replaceFileFeedback: null,
      };

    case CASE_REPLACE_FILE_SUCCESS:
      const getIndex = (documentId) => state.documents.findIndex(({ id }) => id === documentId);
      return {
        ...state,
        replaceFilePending: false,
        replaceFileFeedback: action.feedback,
        documents: addSortNumberToDocuments(
          replaceItemImmutable(
            state.documents,
            { ...state.documents[getIndex(action.data.fileId)], ...action.data.meta },
            getIndex(action.data.fileId),
          ),
          action.data.zeroBasedIndex,
        ),
      };

    case CASE_REPLACE_FILE_FAILURE:
      const concurencyError = action.feedback.error.status === 412;
      return {
        ...state,
        replaceFilePending: false,
        replaceFileFeedback: action.feedback,
        ...(concurencyError && {
          documents: replaceItemImmutable(
            state.documents,
            {
              ...state.documents[getIndex(action.fileId)],
              ...action.feedback.error.body,
            },
            getIndex(action.fileId),
          ),
        }),
      };

    case CASE_REPLACE_FILE_DISMISS_FEEDBACK:
      return {
        ...state,
        replaceFilePending: false,
        replaceFileFeedback: null,
      };

    default:
      return state;
  }
}
