import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  CONFERENCE_GET_TOKEN_BEGIN,
  CONFERENCE_GET_TOKEN_SUCCESS,
  CONFERENCE_GET_TOKEN_FAILURE,
  CONFERENCE_GET_TOKEN_DISMISS_FEEDBACK,
  CONFERENCE_GET_TOKEN_DISMISS_TOKEN,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from 'common/selectors';

export const getToken = createPromiseAction(CONFERENCE_GET_TOKEN_BEGIN);

export function dismissToken() {
  return {
    type: CONFERENCE_GET_TOKEN_DISMISS_TOKEN,
  };
}

export function dismissGetTokenFeedback() {
  return {
    type: CONFERENCE_GET_TOKEN_DISMISS_FEEDBACK,
  };
}

export function* doGetToken(action) {
  const {
    payload: { caseId, service, room },
  } = action;

  let res = yield call(
    api.post,
    `/t2/cases/${caseId}/rooms/${room === 'public' ? 'public' : 'private'}/participants`,
    { service, room },
  );

  if (res && res.error) {
    yield put({
      type: CONFERENCE_GET_TOKEN_FAILURE,
      feedback: {
        message:
          res.error && res.error.status === 409
            ? 'feedback.getTokenFailureHearingRoomOpenned'
            : 'feedback.getTokenFailure',
        error: res.error,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CONFERENCE_GET_TOKEN_SUCCESS,
    data: { ...res, room },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchGetToken() {
  yield takeLatest(getToken, withCurrentCaseId(doGetToken));
}

export function useToken() {
  const dispatch = useDispatch();

  const { token, getTokenPending, getTokenFeedback } = useSelector(
    (state) => ({
      token: state.conference.token,
      getTokenPending: state.conference.getTokenPending,
      getTokenFeedback: state.conference.getTokenFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(getToken(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissGetTokenFeedback());
  }, [dispatch]);

  const boundDismissToken = useCallback(() => {
    return dispatch(dismissToken());
  }, [dispatch]);

  return {
    token,
    getToken: boundAction,
    getTokenPending,
    getTokenFeedback,
    dismissGetTokenFeedback: boundDismissFeedback,
    dismissToken: boundDismissToken,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CONFERENCE_GET_TOKEN_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        getTokenPending: true,
        getTokenFeedback: null,
      };

    case CONFERENCE_GET_TOKEN_SUCCESS:
      // The request is success
      return {
        ...state,
        getTokenPending: false,
        getTokenFeedback: action.feedback,
        token: action.data.token,
        room: action.data.room,
      };

    case CONFERENCE_GET_TOKEN_FAILURE:
      // The request is failed
      return {
        ...state,
        getTokenPending: false,
        getTokenFeedback: action.feedback,
      };

    case CONFERENCE_GET_TOKEN_DISMISS_FEEDBACK:
      // Dismiss the request failure error
      return {
        ...state,
        getTokenFeedback: null,
      };

    case CONFERENCE_GET_TOKEN_DISMISS_TOKEN:
      return {
        ...state,
        token: null,
        room: null,
      };

    default:
      return state;
  }
}
