import {
  faCheckCircle,
  faExclamationCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { CircularProgressWithLabel } from '../CustomProgress';
import { FileMetaData } from '../types';
import { FixedSizeList as List } from 'react-window';
import CloseIcon from '@mui/icons-material/Close';

interface RenderFilesAndFoldersProps {
  fileMetadata: FileMetaData[];
  progressStateRef: React.MutableRefObject<{ [key: string]: number }>;
  setFileMetadata: React.Dispatch<React.SetStateAction<FileMetaData[]>>;
  removeFile: (fileName: string) => void;
}

interface RowProps {
  index: number;
  style: React.CSSProperties;
}

const RenderFilesAndFolders = ({
  fileMetadata,
  progressStateRef,
  setFileMetadata,
  removeFile,
}: RenderFilesAndFoldersProps) => {
  const ITEM_HEIGHT = 40;
  const MAX_VISIBLE_ITEMS = 10;

  // Optionally, sort by status so that uploading or failed appear first
  const statusOrder: { [key: string]: number } = {
    unsupported: 0,
    failed: 1,
    cancelled: 2,
    uploading: 3,
    '': 4,
    uploaded: 5,
  };
  const sortedFiles = [...fileMetadata].sort((a, b) => {
    const aStatus = statusOrder[a.status] ?? 6;
    const bStatus = statusOrder[b.status] ?? 6;
    return aStatus - bStatus;
  });

  // Row component for react-window
  function Row({ index, style }: RowProps) {
    const file = sortedFiles[index];
    const progressValue = progressStateRef.current[file.uniqueId] || 0;

    return (
      <li
        key={file.uniqueId}
        style={{
          ...style,
          display: 'flex',
          alignItems: 'center',
          padding: '0 10px',
          borderBottom: '1px solid #e0e0e0',
        }}
      >
        {/* If line breaks were removed from the file name */}
        {file.lineBreakRemoved && (
          <Tooltip title="Line break removed">
            <FontAwesomeIcon
              icon={faExclamationCircle}
              style={{ color: 'grey', marginRight: '8px', height: 12, width: 12 }}
            />
          </Tooltip>
        )}

        {/* Show folderPath + fileName if needed */}
        <Tooltip title={`${file.folderPath}/${file.name}`}>
          <span>
            {file.folderPath ? `${file.folderPath}/` : ''}
            {file.name.length > 25
              ? `${file.name.substring(0, 10)}...${file.name.substring(file.name.length - 10)}`
              : file.name}
          </span>
        </Tooltip>

        <span style={{ marginLeft: 'auto', marginRight: '10px' }}>
          {(file.size / (1024 * 1024)).toFixed(2)} MB
        </span>

        {/* Status UI */}
        {file.status === 'uploading' ? (
          <CircularProgressWithLabel value={progressValue} size={28} />
        ) : file.status === 'unsupported' ? (
          <span style={{ color: 'red' }}>Unsupported</span>
        ) : file.status === 'uploaded' ? (
          <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', marginLeft: '8px' }} />
        ) : file.status === 'failed' || file.status === 'cancelled' ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title={file.errorMessage || 'Unknown error'}>
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{
                  color: file.status === 'cancelled' ? 'orange' : 'red',
                  marginLeft: '8px',
                }}
              />
            </Tooltip>
          </div>
        ) : null}

        {/* Cancel or Remove */}
        {file.status === 'uploading' && progressValue < 100 ? (
          <IconButton
            onClick={() => {
              if (file.abortController) {
                file.abortController.abort();
                setFileMetadata((prevFiles: FileMetaData[]) =>
                  prevFiles.map((f) =>
                    f.uniqueId === file.uniqueId ? { ...f, status: 'cancelled' } : f,
                  ),
                );
              }
            }}
            style={{ marginLeft: '10px' }}
          >
            <CloseIcon style={{ height: 12, width: 12 }} />
          </IconButton>
        ) : file.status !== 'uploaded' && file.status !== 'cancelled' ? (
          <IconButton onClick={() => removeFile(file.name)} style={{ marginLeft: '10px' }}>
            <CloseIcon style={{ height: 12, width: 12 }} />
          </IconButton>
        ) : null}
      </li>
    );
  }

  const listHeight = Math.min(sortedFiles.length, MAX_VISIBLE_ITEMS) * ITEM_HEIGHT;

  return (
    <List
      height={listHeight}
      itemCount={sortedFiles.length}
      itemSize={ITEM_HEIGHT}
      width="100%"
      style={{ overflowX: 'hidden', overflowY: 'auto', paddingBottom: '60px', paddingTop: 4 }}
    >
      {Row}
    </List>
  );
};

export default RenderFilesAndFolders;
