export default () => {
  self.addEventListener('message', (event) => {
    const data = event.data;

    const performIntensiveTask = () => {
      const { documents, filterOptions, trialBundlesMemo, tagsFetched, defaultFilterOptions } =
        data;
      const toString = (obj: any) => {
        if (obj === null) return 'null';
        if (typeof obj === 'boolean' || typeof obj === 'number') return obj.toString();
        if (typeof obj === 'string') return obj;
        if (typeof obj === 'symbol') throw new TypeError();
        return obj.toString();
      };

      const defaultCompare = (x: any, y: any) => {
        if (x === undefined && y === undefined) return 0;
        if (x === undefined) return 1;
        if (y === undefined) return -1;
        const xString = toString(x);
        const yString = toString(y);
        if (xString < yString) return -1;
        if (xString > yString) return 1;
        return 0;
      };

      const reduceTrialBundleFolders = (folders: any) =>
        folders
          .reduce(
            (acc: any, item: any) =>
              ([] as any).concat(
                acc,
                !(item.name === 'index' && item.readOnly)
                  ? {
                      name: item.name,
                      id: item.id,
                      level: item.level > -1 ? item.level + 1 : 0,
                      group: (Array.isArray(item.folders) && item.folders.length > 0) || !item.code,
                      type: item.type, // Make sure type is passed along
                    }
                  : [],
                Array.isArray(item.folders) && item.folders.length > 0
                  ? reduceTrialBundleFolders(item.folders)
                  : [],
              ),
            [],
          )
          .filter(Boolean);

      try {
        if (documents && documents.length > 0) {
          if (filterOptions?.length > 0) {
            return filterOptions;
          } else {
            const allValues = documents.reduce((acc: any, doc: any) => {
              return {
                authors: doc.author ? (acc.authors || []).concat(doc.author) : acc.authors || [],
                recipients: doc.recipient
                  ? (acc.recipients || []).concat(doc.recipient)
                  : acc.recipients || [],
                createdBy: doc.createdBy
                  ? (acc.createdBy || []).concat(doc.createdBy)
                  : acc.createdBy || [],
                entities: Object.assign(
                  {},
                  acc.entities || {},
                  Object.keys(doc.entities || {})
                    .filter((key) => key !== 'cost')
                    .reduce((ncc: any, key) => {
                      const obj = {} as any;
                      obj[key] = [].concat(
                        ncc[key] || [],
                        doc.entities[key],
                        (acc.entities || {})[key] || [],
                      );

                      return Object.assign({}, ncc, obj);
                    }, {}),
                ),
              };
            }, {});
            const entities = Object.keys(allValues.entities).reduce((acc: any, key: any) => {
              const obj = {} as any;
              obj[key] = Array.from(
                new Map(
                  allValues.entities[key].map((e: any) => [e && e.toLowerCase(), e]),
                ).values(),
              )
                .sort()
                .filter(Boolean);

              return Object.assign({}, acc, obj);
            }, {});
            const authors = Array.from(new Set(allValues.authors)).sort();
            const recipients = Array.from(new Set(allValues.recipients)).sort();
            const createdBySet = Array.from(
              new Set(allValues.createdBy.map((item: any) => item.id)),
            )
              .map((id: any) => allValues.createdBy.find((item: any) => item.id === id))
              .sort((a, b) => defaultCompare(a.name, b.name));

            // Get all bundles first
            const allBundles = reduceTrialBundleFolders(trialBundlesMemo);

            // Separate bundles by type
            const trialBundles = allBundles.filter(
              (bundle: any) => bundle.id === 'trialBundle' || bundle.type === 'trialbook',
            );

            const teamBundles = allBundles.filter(
              (bundle: any) => bundle.id === 'teamBundles' || bundle.type === 'team-bundle',
            );

            const privateBundles = allBundles.filter(
              (bundle: any) => bundle.id === 'privateBundles' || bundle.type === 'private-bundle',
            );

            return ([] as any).concat(
              defaultFilterOptions.map((option: any) => {
                let originalArray = [];

                if (option.filterKey === 'authors') {
                  originalArray = authors;
                } else if (option.filterKey === 'recipients') {
                  originalArray = recipients;
                } else if (option.filterKey === 'createdBy') {
                  originalArray = createdBySet;
                } else if (option.filterKey === 'tags') {
                  originalArray = tagsFetched;
                } else if (option.filterKey === 'trialBundles') {
                  originalArray = trialBundles;
                } else if (option.filterKey === 'teamBundles') {
                  originalArray = teamBundles;
                } else if (option.filterKey === 'privateBundles') {
                  originalArray = privateBundles;
                }

                return Object.assign({}, option, { originalArray });
              }),
              // Only include specific entity filters that you want
              Object.keys(entities)
                .filter((entityKey) =>
                  // Add any entity keys you want to keep
                  ['filters.names', 'filters.organizations', 'filters.locations'].includes(
                    entityKey,
                  ),
                )
                .map((entityKey: any) => ({
                  filterName: `filters.${entityKey}`,
                  filterKey: entityKey,
                  originalArray: entities[entityKey],
                })),
            );
          }
        }
      } catch (e) {
        console.log(e);
        return [];
      }
    };

    const result = performIntensiveTask();
    self.postMessage(result);
  });
};
