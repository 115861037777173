import React, { useState, useEffect } from 'react';
import T from 'i18n';
import classnames from 'classnames';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { caseAdminModalActions } from './enums';
import history from 'common/history';

const useStyles = makeStyles(({ palette }) => ({
  card: {
    borderRadius: '1rem',
    maxWidth: '20rem',
    minHeight: '18rem',
    textAlign: 'center',
    margin: '1rem',
  },
  avatar: {
    width: '5rem',
    height: '5rem',
    margin: 'auto',
  },
  userAvatar: {
    width: '2.5rem',
    height: '2.5rem',
    fontSize: '1rem',
  },
  heading: {
    fontSize: '1.5rem',
    letterSpacing: '0.5px',
    marginTop: '1rem',
    marginBottom: 0,
  },
  subheader: {
    fontSize: 14,
    color: palette.grey[500],
    marginBottom: '0.875em',
  },
  statLabel: {
    fontSize: '1rem',
    color: palette.grey[500],
    fontWeight: 500,
    margin: 0,
  },
  statValue: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
    marginBottom: 4,
    letterSpacing: '1px',
  },
}));

const TeamCard = ({ team, handleShow }) => {
  const classes = useStyles();

  const getInitials = (val) => {
    const names = val.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };
  return (
    <Card className={classnames(classes.card)}>
      <CardContent>
        <Avatar className={classes.avatar}>{getInitials(team.name)}</Avatar>
        <h3 className={classes.heading}>{team.name}</h3>
        <span className={classes.subheader}>
          {team.users.length > 0 ? `${team.users.length} Members` : 'No members assigned yet'}
        </span>
      </CardContent>
      <Box>
        <IconButton
          onClick={() => {
            history.push(history.location.pathname + `/teams/${team.id}`);
          }}
        >
          <EditIcon />
        </IconButton>
        {team.users.length === 0 && (
          <IconButton onClick={() => handleShow(caseAdminModalActions.removeTeam, team)}>
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
    </Card>
  );
};

export default TeamCard;
