import {
  COMMON_FETCH_ROLES_BEGIN,
  COMMON_FETCH_ROLES_SUCCESS,
  COMMON_FETCH_ROLES_FAILURE,
  COMMON_FETCH_ROLES_DISMISS_FEEDBACK,
} from './constants';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';

export const fetchRoles = createPromiseAction(COMMON_FETCH_ROLES_BEGIN, (payload) => ({
  inputData: payload,
}));

export function dismissFetchRolesFeedback() {
  return {
    type: COMMON_FETCH_ROLES_DISMISS_FEEDBACK,
  };
}

export function* doFetchRoles(action) {
  const { groupRoleByClass, includePermissions } = action.payload.inputData;
  let res = yield call(
    api.get,
    `/roles?groupByRoleClass=${groupRoleByClass}&includePermissions=${includePermissions}`,
  );

  if (res && res.error) {
    yield put({
      type: COMMON_FETCH_ROLES_FAILURE,
      feedback: { message: 'feedback.fetchRolesFailure', error: res.error, retryAction: action },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: COMMON_FETCH_ROLES_SUCCESS,
    data: { roles: res },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchFetchRoles() {
  yield takeLatest(fetchRoles, doFetchRoles);
}

export function useFetchRoles() {
  const dispatch = useDispatch();

  const { roles, fetchRolesPending, fetchRolesFeedback } = useSelector(
    (state) => ({
      roles: state.common.roles,
      fetchRolesPending: state.common.fetchRolesPending,
      fetchRolesFeedback: state.common.fetchRolesFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(fetchRoles(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissFetchRolesFeedback());
  }, [dispatch]);

  return {
    roles,
    fetchRoles: boundAction,
    fetchRolesPending,
    fetchRolesFeedback,
    dismissFetchRolesFeedback: boundDismissFeedback,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case COMMON_FETCH_ROLES_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        fetchRolesPending: true,
        fetchRolesFeedback: null,
      };

    case COMMON_FETCH_ROLES_SUCCESS:
      // The request is success
      return {
        ...state,
        fetchRolesPending: false,
        fetchRolesFeedback: action.feedback,
        roles: action.data.roles,
      };

    case COMMON_FETCH_ROLES_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchRolesPending: false,
        fetchRolesFeedback: action.feedback,
      };

    case COMMON_FETCH_ROLES_DISMISS_FEEDBACK:
      // Dismiss the request failure error
      return {
        ...state,
        fetchRolesFeedback: null,
      };

    default:
      return state;
  }
}
