import React, { useEffect } from 'react';
import { IconButton, Card, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './SideDrawer.scss';
import PerfectScrollbar from 'react-perfect-scrollbar';

type SideDrawerProps = {
  showSidebar: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  title: string;
  global?: boolean;
};

const isMobileDevice = () => {
  return /Mobi|Android/i.test(navigator.userAgent);
};

const SideDrawer: React.FC<SideDrawerProps> = ({
  showSidebar,
  onClose,
  children,
  title,
  global,
}) => {
  useEffect(() => {
    const scrollElement = document.getElementById('sidedrawer-scroll-area');

    const preventDefault = (e: TouchEvent) => {
      e.preventDefault();
    };

    if (scrollElement) {
      scrollElement.addEventListener('touchmove', preventDefault, { passive: false });
    }

    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener('touchmove', preventDefault);
      }
    };
  }, []);

  return (
    <div
      className={`sidedrawer-container ${showSidebar ? 'open' : ''}`}
      // if global, make the background darkened
      style={{
        backgroundColor: global ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
        overflowY: 'hidden',
      }}
    >
      <Card className="sidedrawer-card" style={{ padding: 10 }}>
        <Typography variant="h6">{title}</Typography>
        <IconButton onClick={() => onClose()} style={{ position: 'absolute', right: 0, top: 0 }}>
          <CloseIcon />
        </IconButton>
        {isMobileDevice() ? (
          <div className="mobile-scroll">
            <div
              className="sidedrawer-content"
              style={{ width: '100%', height: 'calc(100% - 110px)' }}
            >
              {children || <Typography variant="body1">No content</Typography>}
            </div>
          </div>
        ) : (
          <PerfectScrollbar
            options={{ suppressScrollX: true }}
            style={{ width: '100%', height: 'calc(100% - 110px)' }}
            id="sidedrawer-scroll-area"
          >
            <div className="sidedrawer-content" id="sidedrawer-scroll-area">
              {children || <Typography variant="body1">No content</Typography>}
            </div>
          </PerfectScrollbar>
        )}
      </Card>
    </div>
  );
};

export default SideDrawer;
