import React, { useCallback, useRef } from 'react';
import { ACTIONTYPE } from '../AppStateProvider';
import { PreflightTest, runPreflight } from 'twilio-video';
import * as actions from 'features/conference/redux/actions';

export default function usePreflightTest(
  dispatch: React.Dispatch<ACTIONTYPE>,
  dispatchAction: Function,
) {
  const preflightTestRef = useRef<PreflightTest>();
  const startPreflightTest = useCallback(() => {
    // Don't start a new preflight test if one is already running
    if (preflightTestRef.current) {
      return;
    }

    dispatch({ type: 'preflight-started' });

    return dispatchAction(actions.getToken({ service: 'twilio', room: 'public' }))
      .then((response: any) => {
        const preflightTest = runPreflight(response.token);

        preflightTestRef.current = preflightTest;

        preflightTest.on('progress', (progress) => {
          dispatch({ type: 'preflight-progress', progress });
        });

        preflightTest.on('completed', (report) => {
          dispatch({ type: 'preflight-completed', report });
          dispatch({ type: 'preflight-finished' });
        });

        preflightTest.on('failed', (error) => {
          dispatch({ type: 'preflight-failed', error });
          dispatch({ type: 'preflight-finished' });
        });
      })
      .catch((error: Error) => {
        dispatch({ type: 'preflight-token-failed', error });
        dispatch({ type: 'preflight-finished' });
      });
  }, [dispatch, dispatchAction]);

  return { startPreflightTest } as const;
}
