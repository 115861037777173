import React, { useState } from 'react';
import T from 'i18n';
import { createStyles, makeStyles } from '@mui/styles';
import {
  Button,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  Switch,
  Typography,
} from '@mui/material';
import { CancelPresentation, TrendingFlat } from '@mui/icons-material';
import { transcriptModes } from '../enums';
import { useUpdateRecording } from '../../redux/updateRecording';
import equal from 'react-fast-compare';

const useStyles = makeStyles(() =>
  createStyles({
    settingsContainer: {
      color: '#777',
      background: 'transparent',
      borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
      height: '100%',
      flexDirection: 'column',
      overflow: 'hidden',
      marginLeft: '1rem',
    },
    settingsHeading: {
      marginTop: '1rem',
      flexGrow: 1,
      fontWeight: '600',
    },
    contentContainer: {
      padding: '1rem',
    },
    controlContainer: {
      padding: '1rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
    },
    speakerContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '1rem',
    },
  }),
);

interface SpeakersProps {
  detectedSpeakers: Array<string>;
  recordedSpeakers: Array<object>;
  speakers: Array<any>;
  handleSpeakerChange: Function;
}

export function Speakers({
  detectedSpeakers,
  recordedSpeakers,
  speakers,
  handleSpeakerChange,
}: SpeakersProps) {
  const classes = useStyles();
  return (
    detectedSpeakers &&
    recordedSpeakers && (
      <div style={{ width: '100%' }}>
        {detectedSpeakers.map((spk, idx) => (
          <div key={idx} className={classes.speakerContainer}>
            <div>{`Speaker ${idx + 1}`}</div>
            <TrendingFlat fontSize="large" />
            <Select
              name={speakers[idx].id}
              value={speakers[idx].value}
              placeholder={T.translate('viewing.selectSpeakerPlaceholder')}
              onChange={(e) => handleSpeakerChange(e.target)}
              variant="standard"
              sx={{ width: '60%' }}
            >
              {recordedSpeakers.map((rSpk: any, index) => (
                <MenuItem key={index} value={rSpk.name}>
                  {rSpk.name}
                </MenuItem>
              ))}
            </Select>
          </div>
        ))}
      </div>
    )
  );
}

interface TranscriptSettingsProps {
  videoDetails: any;
  setTranscriptMode: Function;
  setShowRecordingTime: Function;
  showRecordingTime: boolean;
  showParagraphView: boolean;
  setShowParagrapghView: Function;
  setTranscriptList: Function;
  transcriptMode: string;
  dispatch: any;
}

export default function TranscriptSettings({
  videoDetails,
  setTranscriptMode,
  setShowRecordingTime,
  showRecordingTime,
  showParagraphView,
  setShowParagrapghView,
  setTranscriptList,
  transcriptMode,
  dispatch,
}: TranscriptSettingsProps) {
  const classes = useStyles();
  const { updateRecording } = useUpdateRecording();
  const [showAbsoluteTime, setShowAbsoluteTime] = useState(showRecordingTime);
  const [showCombinedView, setShowCombinedView] = useState(showParagraphView);
  const [speakers, setSpeakers] = useState(
    videoDetails.detectedSpeakers.map((itm: string) => {
      const alreadyMappedSpk = videoDetails.recordedSpeakers.find(
        (item: { id: string; mappedToDetectedSpeaker: string | null; name: 'string' }) =>
          item.mappedToDetectedSpeaker === itm,
      );
      if (alreadyMappedSpk) return { id: itm, value: alreadyMappedSpk.name };
      return { id: itm, value: itm };
    }),
  );

  const handleSpeakerChange = (val: { name: string; value: string }) => {
    setSpeakers([
      ...speakers.map((itm: { id: string }) => {
        if (itm.id === val.name) return { id: val.name, value: val.value };
        return itm;
      }),
    ]);
  };
  const handleDoneClicked = () => {
    if (showRecordingTime !== showAbsoluteTime) setShowRecordingTime(showAbsoluteTime);
    if (showParagraphView !== showCombinedView) {
      setTranscriptList(null);
      setShowParagrapghView(showCombinedView);
    }
    setTranscriptMode(transcriptModes.VIEW);
  };

  const handleUpdateSpeaker = () => {
    const updatedSpeakers = videoDetails.recordedSpeakers.map(
      (item: { id: string; mappedToDetectedSpeaker: string | null; name: 'string' }) => {
        const speaker = speakers.find(
          (spk: { name: string; value: string }) => spk.value === item.name,
        );
        if (speaker) return { ...item, mappedToDetectedSpeaker: speaker.id };
        return { ...item, mappedToDetectedSpeaker: '' };
      },
    );
    if (!equal(updatedSpeakers, videoDetails.recordedSpeakers)) {
      setTranscriptList(null);
      updateRecording({
        fileId: videoDetails.id,
        recording: {
          recordedSpeakers: updatedSpeakers,
        },
      }).then((res: any) => {
        dispatch({
          type: 'updateDetails',
          fileType: res.type,
          videoDetails: {
            id: res.id,
            videoLocation: res.location,
            subtitlesLocation: res.subtitlesLocation,
            transcript: res.transcript,
            recordingTime: res.docDate && res.docDate.length > 1 && res.docDate.split(' ')[1],
            docsPresented: res.docsPresented,
            isRecording: res.subtype === 'recording',
            detectedSpeakers: res.detectedSpeakers,
            recordedSpeakers: res.recordedSpeakers,
          },
        });
      });
    }
    setTranscriptMode(transcriptModes.EDIT);
  };
  return (
    <div className={classes.settingsContainer}>
      <div className="description-class" style={{ paddingLeft: '1rem' }}>
        <h3 className={classes.settingsHeading}>
          {transcriptMode === transcriptModes.ASSIGNSPEAKERS
            ? T.translate('viewing.mapSpeaker')
            : T.translate('viewing.settings')}
        </h3>
        <IconButton onClick={() => setTranscriptMode(transcriptModes.VIEW)}>
          <CancelPresentation fontSize="large" />
        </IconButton>
      </div>
      <div className={classes.contentContainer}>
        <Typography variant="body1">
          {transcriptMode === transcriptModes.ASSIGNSPEAKERS
            ? T.translate('viewing.mapSpeakerMsg')
            : T.translate('viewing.settingsHeaderMsg')}
        </Typography>
        <div className={classes.controlContainer}>
          {transcriptMode === transcriptModes.ASSIGNSPEAKERS ? (
            <Speakers
              detectedSpeakers={videoDetails.detectedSpeakers}
              recordedSpeakers={videoDetails.recordedSpeakers}
              speakers={speakers}
              handleSpeakerChange={handleSpeakerChange}
            />
          ) : (
            <>
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Switch
                    sx={{ ml: '2rem' }}
                    checked={showAbsoluteTime}
                    onChange={() => setShowAbsoluteTime(!showAbsoluteTime)}
                  />
                }
                label={`${T.translate('viewing.showAbsoluteTime')}`}
              />
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Switch
                    sx={{ ml: '2rem' }}
                    checked={showCombinedView}
                    onChange={() => setShowCombinedView(!showCombinedView)}
                  />
                }
                label={`${T.translate('viewing.showCombinedView')}`}
              />
            </>
          )}
        </div>
        <div style={{ float: 'right' }}>
          {transcriptMode === transcriptModes.ASSIGNSPEAKERS ? (
            <Button variant="contained" onClick={() => handleUpdateSpeaker()}>
              {T.translate('viewing.continueToEditMode')}
            </Button>
          ) : (
            <Button variant="contained" onClick={() => handleDoneClicked()}>
              {T.translate('viewing.done')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
