import React, { useState } from 'react';
import classnames from 'classnames';
import T from 'i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

type HeaderRow = {
  columns: Array<any>;
  onSort: (stuff: any) => void;
  sortedColumn: string;
  sortedDirection: 'asc' | 'desc' | null;
};

export default React.memo(({ columns, onSort, sortedColumn, sortedDirection }: HeaderRow) => {
  const [sortByColumn, setSortByColumn] = useState(sortedColumn);
  const [sortDirection, setSortDirection] = useState(sortedDirection);

  return (
    <thead>
      <tr>
        {columns.map(
          (
            { id, accessor, Header, minWidth, width, onClick, onCustomSort, sort = true },
            index,
          ) => {
            const currentColumn = id || accessor;
            const isSortByColumn = sortByColumn === currentColumn;
            const icon = isSortByColumn
              ? sortDirection === 'asc'
                ? faSortUp
                : faSortDown
              : faSort;

            return (
              <th
                className={classnames(id || accessor)}
                key={index}
                style={{ minWidth, width }}
                {...((sort || onClick) && {
                  onClick: (event) => {
                    if (onClick) {
                      onClick({ setSortByColumn, setSortDirection, onSort, event });
                      return;
                    }

                    let column = currentColumn,
                      direction;

                    if (isSortByColumn) {
                      if (sortDirection === 'asc') {
                        direction = 'desc';
                      } else {
                        direction = null;
                        column = null;
                      }
                    } else {
                      direction = 'asc';
                    }
                    setSortDirection(direction as 'asc' | 'desc' | null);
                    setSortByColumn(column);
                    onSort({
                      sortByColumn: column,
                      sortDirection: direction,
                      onCustomSort,
                      event,
                    });
                  },
                })}
              >
                <div
                  className={classnames('th-content', { 'column-header': sort || onClick })}
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  {typeof Header === 'function' ? <Header /> : T.translate(Header)}
                  {!onClick && sort && icon && (
                    <FontAwesomeIcon
                      style={{ color: '#35ABF6', opacity: isSortByColumn ? 1 : 0.3 }}
                      icon={icon}
                    />
                  )}
                </div>
              </th>
            );
          },
        )}
      </tr>
    </thead>
  );
});
