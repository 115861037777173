import React, { useState, useEffect } from 'react';
import { ActivePane, useAppStateContext } from '../../AppStateProvider/AppStateProvider';
import UAParser from 'ua-parser-js';
import { getQualityScore } from '../Quality/getQualityScore/getQualityScore';
import { QualityScore } from '../Quality/Quality';
import { SystemTestsTable } from 'features/common';
import * as actions from 'features/common/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectHubConnectionTransportType } from 'common/selectors';

export function Results({ setData }: { setData: (val: Array<any>) => void }) {
  const dispatchAction = useDispatch();
  const hubConnectionTransportType = useSelector(selectHubConnectionTransportType);
  const { state } = useAppStateContext();
  const [finalData, setFinalData] = useState(Array);
  const { totalQualityScore, latency, jitter, packetLoss, bitrate } = getQualityScore(
    state.preflightTest.report,
    state.bitrateTest.report,
  );

  const userAgentParser = new UAParser();
  const userAgentInfo = userAgentParser.getResult();

  const afterLogInTVTests = () => {
    dispatchAction(actions.fetchHealthcheck())
      .then(() =>
        setFinalData((prevData) => [
          ...prevData,
          { test: 'Secure api check', status: 'Pass', result: 'No action needed' },
        ]),
      )
      .catch((err: NetworkError) => {
        setFinalData((prevData) => [
          ...prevData,
          {
            test: 'Secure Api Check',
            status: 'Fail',
            result: {
              'Expected result': 200,
              'Actual result': err.status,
              Action: 'Provide this document to your network team to ensure access to our API',
            },
          },
        ]);
      });
    if (hubConnectionTransportType === 'WebSockets') {
      setFinalData((prevData) => {
        return [...prevData, { test: 'SignalR Check', status: 'Pass', result: 'No action needed' }];
      });
    } else {
      setFinalData((prevData) => [
        ...prevData,
        {
          test: 'SignalR Check',
          status: `${
            hubConnectionTransportType === 'ServerSentEvents' || 'LongPolling'
              ? 'Partial Pass'
              : 'Fail'
          }`,
          result: {
            'Expected result': 'WebSockets',
            'Actual result': hubConnectionTransportType,
          },
        },
      ]);
    }
  };

  useEffect(() => {
    if (state.activePane === ActivePane.Results) {
      const isVideoInputTestPassed =
        state.videoInputTestReport && state.videoInputTestReport.errors.length === 0;
      const isAudioInputTestPassed =
        state.audioInputTestReport && state.audioInputTestReport.errors.length === 0;
      const isAudioOutputTestPassed =
        state.audioOutputTestReport && state.audioOutputTestReport.errors.length === 0;
      const isPreFlightTestPassed =
        state.preflightTest.signalingGatewayReachable && state.preflightTest.turnServersReachable;
      const isTwilioStatusTestPassed = state.twilioStatus && !state.twilioStatusError;
      const networkTestsPassed =
        totalQualityScore === QualityScore.Excellent || totalQualityScore === QualityScore.Good;
      const allowedTwilioStatus = ['Group Rooms', 'Network Traversal Service'];
      const filteredTwilioStatus =
        state.twilioStatus &&
        Object.keys(state.twilioStatus)
          .filter((key) => allowedTwilioStatus.includes(key))
          .reduce((obj, key) => {
            return {
              ...obj,
              [key]:
                key === 'Group Rooms'
                  ? state.twilioStatus?.['Group Rooms']
                  : state.twilioStatus?.['Network Traversal Service'],
            };
          }, {});
      const finalTestResults = [
        isVideoInputTestPassed
          ? {
              test: 'Video Input Check',
              status: 'Pass',
              result: 'No action needed',
            }
          : {
              test: 'Video Input Check',
              status: 'Fail',
              result: 'Check your browser settings and grant permission for camera',
              errors: state.videoInputTestReport?.errors,
            },
        isAudioInputTestPassed
          ? {
              test: 'Audio Input Check',
              status: 'Pass',
              result: 'No action needed',
            }
          : {
              test: 'Audio Input Check',
              status: 'Fail',
              result: 'Check your browser settings and grant permission for audio',
              errors: state.audioInputTestReport?.errors,
            },
        isAudioOutputTestPassed
          ? {
              test: 'Audio Output Check',
              status: 'Pass',
              result: 'No action needed',
            }
          : {
              test: 'Audio Output Check',
              status: 'Fail',
              result: 'Check your browser settings and grant permission for audio',
              errors: state.audioOutputTestReport?.errors,
            },
        isPreFlightTestPassed
          ? {
              test: 'Media Server Check',
              status: 'Pass',
              result: 'No action needed',
            }
          : {
              test: 'Media Server Check',
              status: 'Fail',
              result: {
                'Expected result': 'Reachable media servers',
                'Actual result': 'Issue occured while accessing media servers',
                Action: true,
              },
            },
        isTwilioStatusTestPassed
          ? {
              test: 'Media Room Check',
              status: 'Pass',
              result: filteredTwilioStatus,
            }
          : {
              test: 'Media Room Check',
              status: 'Fail',
              result: {
                'Expected result': 'Operational media rooms',
                'Actual result': 'Issue occured while accessing media rooms',
                Action: true,
              },
            },
        {
          test: 'Network Check',
          status: `${networkTestsPassed ? 'Pass' : 'Partial Pass'}`,
          result: {
            'RTT (ms) avg/max': `${latency.average} / ${latency.max} (${
              QualityScore[latency.qualityScore]
            })`,
            'Jitter (s) avg/max': `${jitter.average} / ${jitter.max} (${
              QualityScore[jitter.qualityScore]
            })`,
            'Packet loss avg/max': `${packetLoss.average}% / ${packetLoss.max}% (${
              QualityScore[packetLoss.qualityScore]
            })`,
            'Bitrate (kbps) avg/max': `${bitrate.average} / ${bitrate.max} (${
              QualityScore[bitrate.qualityScore]
            })`,
          },
        },
        {
          test: 'Browser Check',
          status: 'Pass',
          result: userAgentInfo.browser,
        },
      ];
      setFinalData(finalTestResults);
      afterLogInTVTests();
    }
  }, [state]);

  useEffect(() => {
    if (finalData.length > 0) setData(finalData);
  }, [finalData]);

  return <SystemTestsTable data={finalData} />;
}
