import { selectCurrentFolder } from 'common/selectors';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { documentModalActions } from '../../enums';
import { unassignDocumentMultiple } from '../../redux/unassignDocumentMultiple';
import MoveDocument from './MoveDocument';
import T from 'i18n';
import { Modal } from 'features/common/MaterialBasedComponents';

const ModalCopyRemove = ({ show, handleClose, handleSave, action, selectedRows }) => {
  const dispatch = useDispatch();
  const currentFolder = useSelector(selectCurrentFolder);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <Modal
      show={show}
      title={T.translate(
        action === documentModalActions.copy
          ? 'case.copyDocumentToFolder'
          : 'case.removeDocumentFromFolder',
      )}
      handleClose={handleClose}
      handleSave={
        action === documentModalActions.copy
          ? handleSubmit(({ folder: folderId }) => {
              handleSave(folderId, action, selectedRows);
              handleClose();
            })
          : () => {
              currentFolder && currentFolder.id
                ? dispatch(unassignDocumentMultiple(selectedRows))
                : handleSave(currentFolder.id, action, selectedRows);
              handleClose();
            }
      }
      saveTitle={T.translate(
        action === documentModalActions.copy ? 'generic.copy' : 'generic.remove',
      )}
    >
      {action === documentModalActions.copy ? (
        <MoveDocument control={control} errors={errors} autofocus={show} />
      ) : (
        <div>
          {T.translate('case.removeDocumentsformFolder', {
            documents: selectedRows.map((row) => row.name).join(', '),
            folder: currentFolder.name || 'all documents',
          })}
        </div>
      )}
    </Modal>
  );
};

export default ModalCopyRemove;
