import { call, put /*fork, take, cancel, delay,*/, takeEvery } from 'redux-saga/effects';
import {
  VIEWING_SEND_MESSAGE_BEGIN,
  VIEWING_SEND_MESSAGE_SUCCESS,
  VIEWING_SEND_MESSAGE_FAILURE,
  VIEWING_SEND_MESSAGE_DISMISS_FEEDBACK,
} from './constants';
import api from 'common/api';
import { withCurrentCaseId, withHubConnectionId } from 'common/selectors';
import runningInDev from 'utils/runningInDev';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

export const sendMessage = createPromiseAction(VIEWING_SEND_MESSAGE_BEGIN);

export function dismissSendMessageFeedback() {
  return {
    type: VIEWING_SEND_MESSAGE_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_SEND_MESSAGE_BEGIN actions
export function* doSendMessage(action) {
  const {
    payload: { message, caseId, connectionId },
  } = action;
  const res = yield call(api.post, `/cases/${caseId}/messages`, {
    ...message,
    connectionId,
    timestamp: Date.now(),
  });

  if (res && res.error) {
    yield put({
      type: VIEWING_SEND_MESSAGE_FAILURE,
      feedback: {
        ...(runningInDev && { message: 'feedback.sendMessageFailure' }),
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  // Dispatch success action out of try/catch so that render errors are not catched.
  yield put({
    type: VIEWING_SEND_MESSAGE_SUCCESS,
    data: res,
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchSendMessage() {
  yield takeEvery(sendMessage, withCurrentCaseId(withHubConnectionId(doSendMessage)));
}

export function useSendMessage() {
  const dispatch = useDispatch();

  const { sendMessageFeedback } = useSelector(
    (state) => ({
      sendMessageFeedback: state.viewing.sendMessageFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(sendMessage(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissSendMessageFeedback());
  }, [dispatch]);

  return {
    sendMessage: boundAction,
    sendMessageFeedback,
    dismissSendMessageFeedback: boundDismissFeedback,
  };
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case VIEWING_SEND_MESSAGE_BEGIN + '.TRIGGER':
      return state;

    case VIEWING_SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        sendMessageFeedback: action.feedback,
      };

    case VIEWING_SEND_MESSAGE_FAILURE:
      return {
        ...state,
        sendMessageFeedback: action.feedback,
      };

    case VIEWING_SEND_MESSAGE_DISMISS_FEEDBACK:
      return {
        ...state,
        sendMessageFeedback: null,
      };

    default:
      return state;
  }
}
