export const CASE_FETCH_FOLDERS_BEGIN = 'CASE_FETCH_FOLDERS_BEGIN';
export const CASE_FETCH_FOLDERS_SUCCESS = 'CASE_FETCH_FOLDERS_SUCCESS';
export const CASE_FETCH_FOLDERS_FAILURE = 'CASE_FETCH_FOLDERS_FAILURE';
export const CASE_FETCH_FOLDERS_DISMISS_FEEDBACK = 'CASE_FETCH_FOLDERS_DISMISS_FEEDBACK';
export const CASE_FETCH_CONTAINERS_BEGIN = 'CASE_FETCH_CONTAINERS_BEGIN';
export const CASE_FETCH_CONTAINERS_SUCCESS = 'CASE_FETCH_CONTAINERS_SUCCESS';
export const CASE_FETCH_CONTAINERS_FAILURE = 'CASE_FETCH_CONTAINERS_FAILURE';
export const CASE_FETCH_CONTAINERS_DISMISS_FEEDBACK = 'CASE_FETCH_CONTAINERS_DISMISS_FEEDBACK';
export const CASE_FETCH_DOCUMENTS_BEGIN = 'CASE_FETCH_DOCUMENTS_BEGIN';
export const CASE_FETCH_DOCUMENTS_SUCCESS = 'CASE_FETCH_DOCUMENTS_SUCCESS';
export const CASE_FETCH_DOCUMENTS_SUCCESS_PARTIAL = 'CASE_FETCH_DOCUMENTS_SUCCESS_PARTIAL';
export const CASE_FETCH_DOCUMENTS_FAILURE = 'CASE_FETCH_DOCUMENTS_FAILURE';
export const CASE_FETCH_DOCUMENTS_DISMISS_FEEDBACK = 'CASE_FETCH_DOCUMENTS_DISMISS_FEEDBACK';
export const CASE_FETCH_DOCUMENTS_CLEAR = 'CASE_FETCH_DOCUMENTS_CLEAR';
export const CASE_FETCH_DOCUMENTS_DOC_FINDER_BEGIN = 'CASE_FETCH_DOCUMENTS_DOC_FINDER_BEGIN';
export const CASE_FETCH_DOCUMENTS_DOC_FINDER_SUCCESS = 'CASE_FETCH_DOCUMENTS_DOC_FINDER_SUCCESS';
export const CASE_FETCH_DOCUMENTS_DOC_FINDER_SUCCESS_PARTIAL =
  'CASE_FETCH_DOCUMENTS_DOC_FINDER_SUCCESS_PARTIAL';
export const CASE_FETCH_DOCUMENTS_DOC_FINDER_FAILURE = 'CASE_FETCH_DOCUMENTS_DOC_FINDER_FAILURE';
export const CASE_FETCH_DOCUMENTS_DOC_FINDER_DISMISS_FEEDBACK =
  'CASE_FETCH_DOCUMENTS_DOC_FINDER_DISMISS_FEEDBACK';
export const CASE_FETCH_DOCUMENTS_DOC_FINDER_CLEAR = 'CASE_FETCH_DOCUMENTS_DOC_FINDER_CLEAR';
export const CASE_UPLOAD_FILES_BEGIN = 'CASE_UPLOAD_FILES_BEGIN';
export const CASE_UPLOAD_FILES_SUCCESS = 'CASE_UPLOAD_FILES_SUCCESS';
export const CASE_UPLOAD_FILES_PROGRESS = 'CASE_UPLOAD_FILES_PROGRESS';
export const CASE_UPLOAD_FILES_FAILURE = 'CASE_UPLOAD_FILES_FAILURE';
export const CASE_UPLOAD_DUPLICATE_FILE = 'CASE_UPLOAD_DUPLICATE_FILE';
export const CASE_UPLOAD_FILES_DISMISS_FEEDBACK = 'CASE_UPLOAD_FILES_DISMISS_FEEDBACK';
export const CASE_UPLOAD_FILES_CLOSE_PROGRESS = 'CASE_UPLOAD_FILES_CLOSE_PROGRESS';
export const CASE_UPLOAD_FILES_REJECT = 'CASE_UPLOAD_FILES_REJECT';
export const CASE_UPLOAD_FILE_TOO_LARGE = 'CASE_UPLOAD_FILE_TOO_LARGE';
export const CASE_UPLOAD_FILES_DISMISS_TOO_LARGE_FEEDBACK =
  'CASE_UPLOAD_FILES_DISMISS_TOO_LARGE_FEEDBACK';
export const CASE_ASSIGN_DOCUMENTS_BEGIN = 'CASE_ASSIGN_DOCUMENTS_BEGIN';
export const CASE_ASSIGN_DOCUMENTS_SUCCESS = 'CASE_ASSIGN_DOCUMENTS_SUCCESS';
export const CASE_ASSIGN_ALL_DOCUMENTS_SUCCESS = 'CASE_ASSIGN_ALL_DOCUMENTS_SUCCESS';
export const CASE_ADD_DOCUMENTS_SUCCESS = 'CASE_ADD_DOCUMENTS_SUCCESS';
export const CASE_ASSIGN_DOCUMENTS_FAILURE = 'CASE_ASSIGN_DOCUMENTS_FAILURE';
export const CASE_ASSIGN_DOCUMENTS_DISMISS_FEEDBACK = 'CASE_ASSIGN_DOCUMENTS_DISMISS_FEEDBACK';
export const CASE_UNASSIGN_DOCUMENTS_BEGIN = 'CASE_UNASSIGN_DOCUMENTS_BEGIN';
export const CASE_UNASSIGN_DOCUMENTS_SUCCESS = 'CASE_UNASSIGN_DOCUMENTS_SUCCESS';
export const CASE_UNASSIGN_ALL_DOCUMENTS_SUCCESS = 'CASE_UNASSIGN_ALL_DOCUMENTS_SUCCESS';
export const CASE_UNASSIGN_DOCUMENTS_FAILURE = 'CASE_UNASSIGN_DOCUMENTS_FAILURE';
export const CASE_UNASSIGN_DOCUMENTS_DISMISS_FEEDBACK = 'CASE_UNASSIGN_DOCUMENTS_DISMISS_FEEDBACK';
export const CASE_ADD_FOLDER_BEGIN = 'CASE_ADD_FOLDER_BEGIN';
export const CASE_ADD_FOLDER_SUCCESS = 'CASE_ADD_FOLDER_SUCCESS';
export const CASE_ADD_FOLDER_FAILURE = 'CASE_ADD_FOLDER_FAILURE';
export const CASE_ADD_FOLDER_DISMISS_FEEDBACK = 'CASE_ADD_FOLDER_DISMISS_FEEDBACK';
export const CASE_DELETE_FOLDER_BEGIN = 'CASE_DELETE_FOLDER_BEGIN';
export const CASE_DELETE_FOLDER_SUCCESS = 'CASE_DELETE_FOLDER_SUCCESS';
export const CASE_DELETE_FOLDER_FAILURE = 'CASE_DELETE_FOLDER_FAILURE';
export const CASE_DELETE_FOLDER_DISMISS_FEEDBACK = 'CASE_DELETE_FOLDER_DISMISS_FEEDBACK';
export const CASE_UPDATE_FOLDER_BEGIN = 'CASE_UPDATE_FOLDER_BEGIN';
export const CASE_UPDATE_FOLDER_SUCCESS = 'CASE_UPDATE_FOLDER_SUCCESS';
export const CASE_UPDATE_FOLDER_AGREED_SUCCESS = 'CASE_UPDATE_FOLDER_AGREED_SUCCESS';
export const CASE_UPDATE_FOLDER_FAILURE = 'CASE_UPDATE_FOLDER_FAILURE';
export const CASE_UPDATE_FOLDER_DISMISS_FEEDBACK = 'CASE_UPDATE_FOLDER_DISMISS_FEEDBACK';
export const CASE_FETCH_NOTIFICATION_SUCCESS = 'CASE_FETCH_NOTIFICATION_SUCCESS';
export const CASE_UPDATE_DOCUMENT_BEGIN = 'CASE_UPDATE_DOCUMENT_BEGIN';
export const CASE_UPDATE_DOCUMENT_SUCCESS = 'CASE_UPDATE_DOCUMENT_SUCCESS';
export const CASE_UPDATE_DOCUMENT_TAB_SUCCESS = 'CASE_UPDATE_DOCUMENT_TAB_SUCCESS';
export const CASE_UPDATE_DOCUMENT_FAILURE = 'CASE_UPDATE_DOCUMENT_FAILURE';
export const CASE_UPDATE_DOCUMENT_DISMISS_FEEDBACK = 'CASE_UPDATE_DOCUMENT_DISMISS_FEEDBACK';
export const CASE_UPDATE_DOCUMENT_SORT_BEGIN = 'CASE_UPDATE_DOCUMENT_SORT_BEGIN';
export const CASE_UPDATE_DOCUMENT_SORT_SUCCESS = 'CASE_UPDATE_DOCUMENT_SORT_SUCCESS';
export const CASE_UPDATE_DOCUMENT_SORT_FAILURE = 'CASE_UPDATE_DOCUMENT_SORT_FAILURE';
export const CASE_UPDATE_DOCUMENT_SORT_DISMISS_FEEDBACK =
  'CASE_UPDATE_DOCUMENT_SORT_DISMISS_FEEDBACK';
export const CASE_MERGE_DOCUMENTS_BEGIN = 'CASE_MERGE_DOCUMENTS_BEGIN';
export const CASE_MERGE_DOCUMENTS_SUCCESS = 'CASE_MERGE_DOCUMENTS_SUCCESS';
export const CASE_MERGE_DOCUMENTS_FAILURE = 'CASE_MERGE_DOCUMENTS_FAILURE';
export const CASE_MERGE_DOCUMENTS_DISMISS_FEEDBACK = 'CASE_MERGE_DOCUMENTS_DISMISS_FEEDBACK';
export const CASE_FETCH_TEMPLATES_BEGIN = 'CASE_FETCH_TEMPLATES_BEGIN';
export const CASE_FETCH_TEMPLATES_SUCCESS = 'CASE_FETCH_TEMPLATES_SUCCESS';
export const CASE_FETCH_TEMPLATES_FAILURE = 'CASE_FETCH_TEMPLATES_FAILURE';
export const CASE_FETCH_TEMPLATES_DISMISS_FEEDBACK = 'CASE_FETCH_TEMPLATES_DISMISS_FEEDBACK';
export const CASE_FETCH_TEAMS_BEGIN = 'CASE_FETCH_TEAMS_BEGIN';
export const CASE_FETCH_TEAMS_SUCCESS = 'CASE_FETCH_TEAMS_SUCCESS';
export const CASE_FETCH_TEAMS_FAILURE = 'CASE_FETCH_TEAMS_FAILURE';
export const CASE_FETCH_TEAMS_DISMISS_FEEDBACK = 'CASE_FETCH_TEAMS_DISMISS_FEEDBACK';
export const CASE_CREATE_TEAM_BEGIN = 'CASE_CREATE_TEAM_BEGIN';
export const CASE_CREATE_TEAM_SUCCESS = 'CASE_CREATE_TEAM_SUCCESS';
export const CASE_CREATE_TEAM_FAILURE = 'CASE_CREATE_TEAM_FAILURE';
export const CASE_CREATE_TEAM_DISMISS_FEEDBACK = 'CASE_CREATE_TEAM_DISMISS_FEEDBACK';
export const CASE_UPDATE_TEAM_BEGIN = 'CASE_UPDATE_TEAM_BEGIN';
export const CASE_UPDATE_TEAM_SUCCESS = 'CASE_UPDATE_TEAM_SUCCESS';
export const CASE_UPDATE_TEAM_FAILURE = 'CASE_UPDATE_TEAM_FAILURE';
export const CASE_UPDATE_TEAM_DISMISS_FEEDBACK = 'CASE_UPDATE_TEAM_DISMISS_FEEDBACK';
export const CASE_REMOVE_TEAM_BEGIN = 'CASE_REMOVE_TEAM_BEGIN';
export const CASE_REMOVE_TEAM_SUCCESS = 'CASE_REMOVE_TEAM_SUCCESS';
export const CASE_REMOVE_TEAM_FAILURE = 'CASE_REMOVE_TEAM_FAILURE';
export const CASE_REMOVE_TEAM_DISMISS_FEEDBACK = 'CASE_REMOVE_TEAM_DISMISS_FEEDBACK';
export const CASE_GET_MORE_INFO_DOCUMENT_BEGIN = 'CASE_GET_MORE_INFO_DOCUMENT_BEGIN';
export const CASE_GET_MORE_INFO_DOCUMENT_SUCCESS = 'CASE_GET_MORE_INFO_DOCUMENT_SUCCESS';
export const CASE_GET_MORE_INFO_DOCUMENT_FAILURE = 'CASE_GET_MORE_INFO_DOCUMENT_FAILURE';
export const CASE_GET_MORE_INFO_DOCUMENT_DISMISS_FEEDBACK =
  'CASE_GET_MORE_INFO_DOCUMENT_DISMISS_FEEDBACK';
export const CASE_GET_MORE_INFO_FOLDER_BEGIN = 'CASE_GET_MORE_INFO_FOLDER_BEGIN';
export const CASE_GET_MORE_INFO_FOLDER_SUCCESS = 'CASE_GET_MORE_INFO_FOLDER_SUCCESS';
export const CASE_GET_MORE_INFO_FOLDER_FAILURE = 'CASE_GET_MORE_INFO_FOLDER_FAILURE';
export const CASE_GET_MORE_INFO_FOLDER_DISMISS_FEEDBACK =
  'CASE_GET_MORE_INFO_FOLDER_DISMISS_FEEDBACK';

export const CASE_GET_FOLDER_STATS_BEGIN = 'CASE_GET_FOLDER_STATS_BEGIN';
export const CASE_GET_FOLDER_STATS_SUCCESS = 'CASE_GET_FOLDER_STATS_SUCCESS';
export const CASE_GET_FOLDER_STATS_FAILURE = 'CASE_GET_FOLDER_STATS_FAILURE';
export const CASE_GET_FOLDER_STATS_DISMISS_FEEDBACK = 'CASE_GET_FOLDER_STATS_DISMISS_FEEDBACK';

export const CASE_FETCH_LAYOUTS_BEGIN = 'CASE_FETCH_LAYOUTS_BEGIN';
export const CASE_FETCH_LAYOUTS_SUCCESS = 'CASE_FETCH_LAYOUTS_SUCCESS';
export const CASE_FETCH_LAYOUTS_FAILURE = 'CASE_FETCH_LAYOUTS_FAILURE';
export const CASE_FETCH_LAYOUTS_DISMISS_FEEDBACK = 'CASE_FETCH_LAYOUTS_DISMISS_FEEDBACK';
export const CASE_UPDATE_LAYOUTS_BEGIN = 'CASE_UPDATE_LAYOUTS_BEGIN';
export const CASE_UPDATE_LAYOUTS_SUCCESS = 'CASE_UPDATE_LAYOUTS_SUCCESS';
export const CASE_UPDATE_LAYOUTS_FAILURE = 'CASE_UPDATE_LAYOUTS_FAILURE';
export const CASE_UPDATE_LAYOUTS_DISMISS_FEEDBACK = 'CASE_UPDATE_LAYOUTS_DISMISS_FEEDBACK';
export const CASE_FETCH_CASE_DETAILS_BEGIN = 'CASE_FETCH_CASE_DETAILS_BEGIN';
export const CASE_FETCH_CASE_DETAILS_SUCCESS = 'CASE_FETCH_CASE_DETAILS_SUCCESS';
export const CASE_FETCH_CASE_DETAILS_FAILURE = 'CASE_FETCH_CASE_DETAILS_FAILURE';
export const CASE_FETCH_CASE_DETAILS_DISMISS_FEEDBACK = 'CASE_FETCH_CASE_DETAILS_DISMISS_FEEDBACK';
export const CASE_UPDATE_CASE_BEGIN = 'CASE_UPDATE_CASE_BEGIN';
export const CASE_UPDATE_CASE_SUCCESS = 'CASE_UPDATE_CASE_SUCCESS';
export const CASE_UPDATE_CASE_FAILURE = 'CASE_UPDATE_CASE_FAILURE';
export const CASE_UPDATE_CASE_DISMISS_FEEDBACK = 'CASE_UPDATE_CASE_DISMISS_FEEDBACK';
export const CASE_UPDATE_DOCUMENT_META_BEGIN = 'CASE_UPDATE_DOCUMENT_META_BEGIN';
export const CASE_UPDATE_DOCUMENT_META_SUCCESS = 'CASE_UPDATE_DOCUMENT_META_SUCCESS';
export const CASE_UPDATE_DOCUMENT_META_FAILURE = 'CASE_UPDATE_DOCUMENT_META_FAILURE';
export const CASE_UPDATE_DOCUMENT_META_DISMISS_FEEDBACK =
  'CASE_UPDATE_DOCUMENT_META_DISMISS_FEEDBACK';
export const CASE_FETCH_TSANDCS_BEGIN = 'CASE_FETCH_TSANDCS_BEGIN';
export const CASE_FETCH_TSANDCS_SUCCESS = 'CASE_FETCH_TSANDCS_SUCCESS';
export const CASE_FETCH_TSANDCS_FAILURE = 'CASE_FETCH_TSANDCS_FAILURE';
export const CASE_FETCH_TSANDCS_FEEDBACK = 'CASE_FETCH_TSANDCS_FEEDBACK';
export const CASE_ACCEPT_TSANDCS_BEGIN = 'CASE_ACCEPT_TSANDCS_BEGIN';
export const CASE_ACCEPT_TSANDCS_SUCCESS = 'CASE_ACCEPT_TSANDCS_SUCCESS';
export const CASE_ACCEPT_TSANDCS_FAILURE = 'CASE_ACCEPT_TSANDCS_FAILURE';
export const CASE_ACCEPT_TSANDCS_FEEDBACK = 'CASE_ACCEPT_TSANDCS_FEEDBACK';
export const CASE_FETCH_FILE_DETAILS_BEGIN = 'CASE_FETCH_FILE_DETAILS_BEGIN';
export const CASE_FETCH_FILE_DETAILS_SUCCESS = 'CASE_FETCH_FILE_DETAILS_SUCCESS';
export const CASE_FETCH_FILE_DETAILS_FAILURE = 'CASE_FETCH_FILE_DETAILS_FAILURE';
export const CASE_FETCH_FILE_DETAILS_FEEDBACK = 'CASE_FETCH_FILE_DETAILS_FEEDBACK';
export const CASE_REPLACE_FILE_BEGIN = 'CASE_REPLACE_FILE_BEGIN';
export const CASE_REPLACE_FILE_SUCCESS = 'CASE_REPLACE_FILE_SUCCESS';
export const CASE_REPLACE_FILE_FAILURE = 'CASE_REPLACE_FILE_FAILURE';
export const CASE_REPLACE_FILE_DISMISS_FEEDBACK = 'CASE_REPLACE_FILE_DISMISS_FEEDBACK';
export const CASE_GENERATE_HYPERLINKS_BEGIN = 'CASE_GENERATE_HYPERLINKS_BEGIN';
export const CASE_GENERATE_HYPERLINKS_SUCCESS = 'CASE_GENERATE_HYPERLINKS_SUCCESS';
export const CASE_GENERATE_HYPERLINKS_FAILURE = 'CASE_GENERATE_HYPERLINKS_FAILURE';
export const CASE_GENERATE_HYPERLINKS_DISMISS_FEEDBACK =
  'CASE_GENERATE_HYPERLINKS_DISMISS_FEEDBACK';
export const CASE_FETCH_RECENT_FILES_BEGIN = 'CASE_FETCH_RECENTLYVIEWED_FILES_BEGIN';
export const CASE_FETCH_RECENT_FILES_SUCCESS = 'CASE_FETCH_RECENTLYVIEWED_FILES_SUCCESS';
export const CASE_FETCH_RECENT_FILES_FAILURE = 'CASE_FETCH_RECENTLYVIEWED_FILES_FAILURE';
export const CASE_FETCH_RECENT_FILES_DISMISS_FEEDBACK =
  'CASE_FETCH_RECENTLYVIEWED_FILES_DISMISS_FEEDBACK';
export const CASE_FETCH_SEARCHED_FILES_BEGIN = 'CASE_FETCH_SEARCHED_FILES_BEGIN';
export const CASE_FETCH_SEARCHED_FILES_SUCCESS = 'CASE_FETCH_SEARCHED_FILES_SUCCESS';
export const CASE_FETCH_SEARCHED_FILES_FAILURE = 'CASE_FETCH_SEARCHED_FILES_FAILURE';
export const CASE_FETCH_SEARCHED_FILES_DISMISS_FEEDBACK =
  'CASE_FETCH_SEARCHED_FILES_DISMISS_FEEDBACK';
export const CASE_FETCH_SEARCHED_FILES_CLEAR = 'CASE_FETCH_SEARCHED_FILES_CLEAR';
export const CASE_FETCH_USER_DETAILS_BEGIN = 'CASE_FETCH_USER_DETAILS_BEGIN';
export const CASE_FETCH_USER_DETAILS_SUCCESS = 'CASE_FETCH_USER_DETAILS_SUCCESS';
export const CASE_FETCH_USER_DETAILS_FAILURE = 'CASE_FETCH_USER_DETAILS_FAILURE';
export const CASE_FETCH_USER_DETAILS_DISMISS_FEEDBACK = 'CASE_FETCH_USER_DETAILS_DISMISS_FEEDBACK';
export const CASE_FETCH_TEAM_BEGIN = 'CASE_FETCH_TEAM_BEGIN';
export const CASE_FETCH_TEAM_SUCCESS = 'CASE_FETCH_TEAM_SUCCESS';
export const CASE_FETCH_TEAM_FAILURE = 'CASE_FETCH_TEAM_FAILURE';
export const CASE_FETCH_TEAM_DISMISS_FEEDBACK = 'CASE_FETCH_TEAM_DISMISS_FEEDBACK';
export const CASE_UPDATE_TEAM_MEMBER_BEGIN = 'CASE_UPDATE_TEAM_MEMBER_BEGIN';
export const CASE_UPDATE_TEAM_MEMBER_SUCCESS = 'CASE_UPDATE_TEAM_MEMBER_SUCCESS';
export const CASE_UPDATE_TEAM_MEMBER_FAILURE = 'CASE_UPDATE_TEAM_MEMBER_FAILURE';
export const CASE_UPDATE_TEAM_MEMBER_DISMISS_FEEDBACK = 'CASE_UPDATE_TEAM_MEMBER_DISMISS_FEEDBACK';
export const CASE_REMOVE_TEAM_MEMBER_BEGIN = 'CASE_REMOVE_TEAM_MEMBER_BEGIN';
export const CASE_REMOVE_TEAM_MEMBER_SUCCESS = 'CASE_REMOVE_TEAM_MEMBER_SUCCESS';
export const CASE_REMOVE_TEAM_MEMBER_FAILURE = 'CASE_REMOVE_TEAM_MEMBER_FAILURE';
export const CASE_REMOVE_TEAM_MEMBER_DISMISS_FEEDBACK = 'CASE_REMOVE_TEAM_MEMBER_DISMISS_FEEDBACK';
export const CASE_TEAM_MEMBER_SEND_INVITE_BEGIN = 'CASE_TEAM_MEMBER_SEND_INVITE_BEGIN';
export const CASE_TEAM_MEMBER_SEND_INVITE_SUCCESS = 'CASE_TEAM_MEMBER_SEND_INVITE_SUCCESS';
export const CASE_TEAM_MEMBER_SEND_INVITE_FAILURE = 'CASE_TEAM_MEMBER_SEND_INVITE_FAILURE';
export const CASE_TEAM_MEMBER_SEND_INVITE_DISMISS_FEEDBACK =
  'CASE_TEAM_MEMBER_SEND_INVITE_DISMISS_FEEDBACK';
export const CASE_FETCH_INVITE_LINK_BEGIN = 'CASE_FETCH_INVITE_LINK_BEGIN';
export const CASE_FETCH_INVITE_LINK_SUCCESS = 'CASE_FETCH_INVITE_LINK_SUCCESS';
export const CASE_FETCH_INVITE_LINK_FAILURE = 'CASE_FETCH_INVITE_LINK_FAILURE';
export const CASE_FETCH_INVITE_LINK_DISMISS_FEEDBACK = 'CASE_FETCH_INVITE_LINK_DISMISS_FEEDBACK';
export const CASE_TEAM_MEMBER_ACCEPT_INVITE_BEGIN = 'CASE_TEAM_MEMBER_ACCEPT_INVITE_BEGIN';
export const CASE_TEAM_MEMBER_ACCEPT_INVITE_SUCCESS = 'CASE_TEAM_MEMBER_ACCEPT_INVITE_SUCCESS';
export const CASE_TEAM_MEMBER_ACCEPT_INVITE_FAILURE = 'CASE_TEAM_MEMBER_ACCEPT_INVITE_FAILURE';
export const CASE_TEAM_MEMBER_ACCEPT_INVITE_DISMISS_FEEDBACK =
  'CASE_TEAM_MEMBER_ACCEPT_INVITE_DISMISS_FEEDBACK';
export const CASE_GET_TEAM_ACTIVITY_LOGS_BEGIN = 'CASE_GET_TEAM_ACTIVITY_LOGS_BEGIN';
export const CASE_GET_TEAM_ACTIVITY_LOGS_SUCCESS = 'CASE_GET_TEAM_ACTIVITY_LOGS_SUCCESS';
export const CASE_GET_TEAM_ACTIVITY_LOGS_FAILURE = 'CASE_GET_TEAM_ACTIVITY_LOGS_FAILURE';
export const CASE_GET_TEAM_ACTIVITY_LOGS_DISMISS_FEEDBACK =
  'CASE_GET_TEAM_ACTIVITY_LOGS_DISMISS_FEEDBACK';
export const CASE_FETCH_ADMINS_BEGIN = 'CASE_FETCH_ADMINS_BEGIN';
export const CASE_FETCH_ADMINS_SUCCESS = 'CASE_FETCH_ADMINS_SUCCESS';
export const CASE_FETCH_ADMINS_FAILURE = 'CASE_FETCH_ADMINS_FAILURE';
export const CASE_FETCH_ADMINS_DISMISS_FEEDBACK = 'CASE_FETCH_ADMINS_DISMISS_FEEDBACK';
export const CASE_FETCH_MODERATORS_BEGIN = 'CASE_FETCH_MODERATORS_BEGIN';
export const CASE_FETCH_MODERATORS_SUCCESS = 'CASE_FETCH_MODERATORS_SUCCESS';
export const CASE_FETCH_MODERATORS_FAILURE = 'CASE_FETCH_MODERATORS_FAILURE';
export const CASE_FETCH_MODERATORS_DISMISS_FEEDBACK = 'CASE_FETCH_MODERATORS_DISMISS_FEEDBACK';
export const CASE_ADD_ROLES_USER_BEGIN = 'CASE_ADD_ROLES_USER_BEGIN';
export const CASE_ADD_ROLES_USER_SUCCESS = 'CASE_ADD_ROLES_USER_SUCCESS';
export const CASE_ADD_ROLES_USER_FAILURE = 'CASE_ADD_ROLES_USER_FAILURE';
export const CASE_ADD_ROLES_USER_DISMISS_FEEDBACK = 'CASE_ADD_ROLES_USER_DISMISS_FEEDBACK';
export const CASE_REMOVE_CASE_ROLES_USER_BEGIN = 'CASE_REMOVE_CASE_ROLES_USER_BEGIN';
export const CASE_REMOVE_CASE_ROLES_USER_SUCCESS = 'CASE_REMOVE_CASE_ROLES_USER_SUCCESS';
export const CASE_REMOVE_CASE_ROLES_USER_FAILURE = 'CASE_REMOVE_CASE_ROLES_USER_FAILURE';
export const CASE_REMOVE_CASE_ROLES_USER_DISMISS_FEEDBACK =
  'CASE_REMOVE_CASE_ROLES_USER_DISMISS_FEEDBACK';
export const CASE_FETCH_ROLES_BEGIN = 'CASE_FETCH_ROLES_BEGIN';
export const CASE_FETCH_ROLES_SUCCESS = 'CASE_FETCH_ROLES_SUCCESS';
export const CASE_FETCH_ROLES_FAILURE = 'CASE_FETCH_ROLES_FAILURE';
export const CASE_FETCH_ROLES_DISMISS_FEEDBACK = 'CASE_FETCH_ROLES_DISMISS_FEEDBACK';
export const CASE_ADD_SYSTEM_TEST_BEGIN = 'CASE_ADD_SYSTEM_TEST_BEGIN';
export const CASE_ADD_SYSTEM_TEST_SUCCESS = 'CASE_ADD_SYSTEM_TEST_SUCCESS';
export const CASE_ADD_SYSTEM_TEST_FAILURE = 'CASE_ADD_SYSTEM_TEST_FAILURE';
export const CASE_ADD_SYSTEM_TEST_DISMISS_FEEDBACK = 'CASE_ADD_SYSTEM_TEST_DISMISS_FEEDBACK';
export const CASE_FETCH_SYSTEM_TEST_BEGIN = 'CASE_FETCH_SYSTEM_TEST_BEGIN';
export const CASE_FETCH_SYSTEM_TEST_SUCCESS = 'CASE_FETCH_SYSTEM_TEST_SUCCESS';
export const CASE_FETCH_SYSTEM_TEST_FAILURE = 'CASE_FETCH_SYSTEM_TEST_FAILURE';
export const CASE_FETCH_SYSTEM_TEST_DISMISS_FEEDBACK = 'CASE_FETCH_SYSTEM_TEST_DISMISS_FEEDBACK';
export const CASE_TEAM_MEMBER_RESEND_INVITE_BEGIN = 'CASE_TEAM_MEMBER_RESEND_INVITE_BEGIN';
export const CASE_TEAM_MEMBER_RESEND_INVITE_SUCCESS = 'CASE_TEAM_MEMBER_RESEND_INVITE_SUCCESS';
export const CASE_TEAM_MEMBER_RESEND_INVITE_FAILURE = 'CASE_TEAM_MEMBER_RESEND_INVITE_FAILURE';
export const CASE_TEAM_MEMBER_RESEND_INVITE_DISMISS_FEEDBACK =
  'CASE_TEAM_MEMBER_RESEND_INVITE_DISMISS_FEEDBACK';
export const CASE_TEAM_MEMBER_CHANGE_TEAM_SUCCESS = 'CASE_TEAM_MEMBER_CHANGE_TEAM_SUCCESS';
export const CASE_FETCH_LOGIN_HISTORY_BEGIN = 'CASE_FETCH_LOGIN_HISTORY_BEGIN';
export const CASE_FETCH_LOGIN_HISTORY_SUCCESS = 'CASE_FETCH_LOGIN_HISTORY_SUCCESS';
export const CASE_FETCH_LOGIN_HISTORY_FAILURE = 'CASE_FETCH_LOGIN_HISTORY_FAILURE';
export const CASE_FETCH_LOGIN_HISTORY_DISMISS_FEEDBACK =
  'CASE_FETCH_LOGIN_HISTORY_DISMISS_FEEDBACK';
export const CASE_FETCH_FOLDER_BEGIN = 'CASE_FETCH_FOLDER_BEGIN';
export const CASE_FETCH_FOLDER_SUCCESS = 'CASE_FETCH_FOLDER_SUCCESS';
export const CASE_FETCH_FOLDER_FAILURE = 'CASE_FETCH_FOLDER_FAILURE';
export const CASE_FETCH_FOLDER_DISMISS_FEEDBACK = 'CASE_FETCH_FOLDER_DISMISS_FEEDBACK';
export const CASE_FETCH_FOLDER_SPECIFIC_FILE_DETAILS_BEGIN =
  'CASE_FETCH_FOLDER_SPECIFIC_FILE_DETAILS_BEGIN';
export const CASE_FETCH_FOLDER_SPECIFIC_FILE_DETAILS_SUCCESS =
  'CASE_FETCH_FOLDER_SPECIFIC_FILE_DETAILS_SUCCESS';
export const CASE_FETCH_FOLDER_SPECIFIC_FILE_DETAILS_FAILURE =
  'CASE_FETCH_FOLDER_SPECIFIC_FILE_DETAILS_FAILURE';
export const CASE_FETCH_FOLDER_SPECIFIC_FILE_DETAILS_FEEDBACK =
  'CASE_FETCH_FOLDER_SPECIFIC_FILE_DETAILS_FEEDBACK';
export const CASE_UPDATE_FOLDER_SEQ_BEGIN = 'CASE_UPDATE_FOLDER_SEQ_BEGIN';
export const CASE_UPDATE_FOLDER_SEQ_SUCCESS = 'CASE_UPDATE_FOLDER_SEQ_SUCCESS';
export const CASE_UPDATE_FOLDER_SEQ_FAILURE = 'CASE_UPDATE_FOLDER_SEQ_FAILURE';
export const CASE_UPDATE_FOLDER_SEQ_DISMISS_FEEDBACK = 'CASE_UPDATE_FOLDER_SEQ_DISMISS_FEEDBACK';
export const CASE_FETCH_CONTAINER_BEGIN = 'CASE_FETCH_CONTAINER_BEGIN';
export const CASE_FETCH_CONTAINER_SUCCESS = 'CASE_FETCH_CONTAINER_SUCCESS';
export const CASE_FETCH_CONTAINER_FAILURE = 'CASE_FETCH_CONTAINER_FAILURE';
export const CASE_FETCH_CONTAINER_DISMISS_FEEDBACK = 'CASE_FETCH_CONTAINER_DISMISS_FEEDBACK';
export const CASE_FETCH_TAGS_BEGIN = 'CASE_FETCH_TAGS_BEGIN';
export const CASE_FETCH_TAGS_SUCCESS = 'CASE_FETCH_TAGS_SUCCESS';
export const CASE_FETCH_TAGS_FAILURE = 'CASE_FETCH_TAGS_FAILURE';
export const CASE_FETCH_TAGS_DISMISS_FEEDBACK = 'CASE_FETCH_TAGS_DISMISS_FEEDBACK';
export const CASE_CREATE_TAG_BEGIN = 'CASE_CREATE_TAG_BEGIN';
export const CASE_CREATE_TAG_SUCCESS = 'CASE_CREATE_TAG_SUCCESS';
export const CASE_CREATE_TAG_FAILURE = 'CASE_CREATE_TAG_FAILURE';
export const CASE_CREATE_TAG_DISMISS_FEEDBACK = 'CASE_CREATE_TAG_DISMISS_FEEDBACK';
export const CASE_DELETE_TAG_BEGIN = 'CASE_DELETE_TAG_BEGIN';
export const CASE_DELETE_TAG_SUCCESS = 'CASE_DELETE_TAG_SUCCESS';
export const CASE_DELETE_TAG_FAILURE = 'CASE_DELETE_TAG_FAILURE';
export const CASE_DELETE_TAG_DISMISS_FEEDBACK = 'CASE_DELETE_TAG_DISMISS_FEEDBACK';
export const CASE_UPDATE_TAG_BEGIN = 'CASE_UPDATE_TAG_BEGIN';
export const CASE_UPDATE_TAG_SUCCESS = 'CASE_UPDATE_TAG_SUCCESS';
export const CASE_UPDATE_TAG_FAILURE = 'CASE_UPDATE_TAG_FAILURE';
export const CASE_UPDATE_TAG_DISMISS_FEEDBACK = 'CASE_UPDATE_TAG_DISMISS_FEEDBACK';
export const CASE_SET_CURRENT_FILE = 'CASE_SET_CURRENT_FILE';
export const CASE_SET_CURRENT_FILE_FAILURE = 'CASE_SET_CURRENT_FILE_FAILURE';
export const CASE_FETCH_ANNOTATIONS_BEGIN = 'CASE_FETCH_ANNOTATIONS_BEGIN';
export const CASE_FETCH_ANNOTATIONS_SUCCESS = 'CASE_FETCH_ANNOTATIONS_SUCCESS';
export const CASE_FETCH_ANNOTATIONS_FAILURE = 'CASE_FETCH_ANNOTATIONS_FAILURE';
export const CASE_FETCH_ANNOTATIONS_DISMISS_FEEDBACK = 'CASE_FETCH_ANNOTATIONS_DISMISS_FEEDBACK';
export const CASE_UPDATE_ANNOTATIONS_BEGIN = 'CASE_UPDATE_ANNOTATIONS_BEGIN';
export const CASE_UPDATE_ANNOTATIONS_SUCCESS = 'CASE_UPDATE_ANNOTATIONS_SUCCESS';
export const CASE_UPDATE_ANNOTATIONS_FAILURE = 'CASE_UPDATE_ANNOTATIONS_FAILURE';
export const CASE_UPDATE_ANNOTATIONS_DISMISS_FEEDBACK = 'CASE_UPDATE_ANNOTATIONS_DISMISS_FEEDBACK';
export const CASE_DELETE_ANNOTATIONS_BEGIN = 'CASE_DELETE_ANNOTATIONS_BEGIN';
export const CASE_DELETE_ANNOTATIONS_SUCCESS = 'CASE_DELETE_ANNOTATIONS_SUCCESS';
export const CASE_DELETE_ANNOTATIONS_FAILURE = 'CASE_DELETE_ANNOTATIONS_FAILURE';
export const CASE_DELETE_ANNOTATIONS_DISMISS_FEEDBACK = 'CASE_DELETE_ANNOTATIONS_DISMISS_FEEDBACK';
export const CASE_CLEAR_ANNOTATIONS = 'CASE_CLEAR_ANNOTATIONS';
export const CASE_ADD_ANNOTATIONS = 'CASE_ADD_ANNOTATIONS';
export const CASE_REMOVE_ANNOTATIONS = 'CASE_REMOVE_ANNOTATIONS';
export const CASE_UPDATE_ANNOTATION_SORT_BEGIN = 'CASE_UPDATE_ANNOTATION_SORT_BEGIN';
export const CASE_UPDATE_ANNOTATION_SORT_SUCCESS = 'CASE_UPDATE_ANNOTATION_SORT_SUCCESS';
export const CASE_UPDATE_ANNOTATION_SORT_FAILURE = 'CASE_UPDATE_ANNOTATION_SORT_FAILURE';
export const CASE_UPDATE_ANNOTATION_SORT_DISMISS_FEEDBACK =
  'CASE_UPDATE_ANNOTATION_SORT_DISMISS_FEEDBACK';
export const CASE_SET_FILTER_OPTIONS = 'CASE_SET_FILTER_OPTIONS';
export const CASE_FETCH_FILE_VERSIONS_BEGIN = 'CASE_FETCH_FILE_VERSIONS_BEGIN';
export const CASE_FETCH_FILE_VERSIONS_SUCCESS = 'CASE_FETCH_FILE_VERSIONS_SUCCESS';
export const CASE_FETCH_FILE_VERSIONS_FAILURE = 'CASE_FETCH_FILE_VERSIONS_FAILURE';
export const CASE_FETCH_FILE_VERSIONS_DISMISS_FEEDBACK =
  'CASE_FETCH_FILE_VERSIONS_DISMISS_FEEDBACK';
export const CASE_UPDATE_FILE_VERSIONS_BEGIN = 'CASE_UPDATE_FILE_VERSIONS_BEGIN';
export const CASE_UPDATE_FILE_VERSIONS_SUCCESS = 'CASE_UPDATE_FILE_VERSIONS_SUCCESS';
export const CASE_UPDATE_FILE_VERSIONS_FAILURE = 'CASE_UPDATE_FILE_VERSIONS_FAILURE';
export const CASE_UPDATE_FILE_VERSIONS_DISMISS_FEEDBACK =
  'CASE_UPDATE_FILE_VERSIONS_DISMISS_FEEDBACK';
export const CASE_CREATE_FILE_VERSION_BEGIN = 'CASE_CREATE_FILE_VERSION_BEGIN';
export const CASE_CREATE_FILE_VERSION_SUCCESS = 'CASE_CREATE_FILE_VERSION_SUCCESS';
export const CASE_CREATE_FILE_VERSION_FAILURE = 'CASE_CREATE_FILE_VERSION_FAILURE';
export const CASE_CREATE_FILE_VERSION_DISMISS_FEEDBACK =
  'CASE_CREATE_FILE_VERSION_DISMISS_FEEDBACK';
export const CASE_FETCH_DIALIN_NUMBERS_BEGIN = 'CASE_FETCH_DIALIN_NUMBERS_BEGIN';
export const CASE_FETCH_DIALIN_NUMBERS_SUCCESS = 'CASE_FETCH_DIALIN_NUMBERS_SUCCESS';
export const CASE_FETCH_DIALIN_NUMBERS_FAILURE = 'CASE_FETCH_DIALIN_NUMBERS_FAILURE';
export const CASE_FETCH_DIALIN_NUMBERS_DISMISS_FEEDBACK =
  'CASE_FETCH_DIALIN_NUMBERS_DISMISS_FEEDBACK';
export const CASE_UPDATE_DIALIN_NUMBERS_BEGIN = 'CASE_UPDATE_DIALIN_NUMBERS_BEGIN';
export const CASE_UPDATE_DIALIN_NUMBERS_SUCCESS = 'CASE_UPDATE_DIALIN_NUMBERS_SUCCESS';
export const CASE_UPDATE_DIALIN_NUMBERS_FAILURE = 'CASE_UPDATE_DIALIN_NUMBERS_FAILURE';
export const CASE_UPDATE_DIALIN_NUMBERS_DISMISS_FEEDBACK =
  'CASE_UPDATE_DIALIN_NUMBERS_DISMISS_FEEDBACK';
export const CASE_DELETE_DIALIN_NUMBER_BEGIN = 'CASE_DELETE_DIALIN_NUMBER_BEGIN';
export const CASE_DELETE_DIALIN_NUMBER_SUCCESS = 'CASE_DELETE_DIALIN_NUMBER_SUCCESS';
export const CASE_DELETE_DIALIN_NUMBER_FAILURE = 'CASE_DELETE_DIALIN_NUMBER_FAILURE';
export const CASE_DELETE_DIALIN_NUMBER_DISMISS_FEEDBACK =
  'CASE_DELETE_DIALIN_NUMBER_DISMISS_FEEDBACK';
export const CASE_FETCH_HEARINGROOM_LOGS_BEGIN = 'CASE_FETCH_HEARINGROOM_LOGS_BEGIN';
export const CASE_FETCH_HEARINGROOM_LOGS_SUCCESS = 'CASE_FETCH_HEARINGROOM_LOGS_SUCCESS';
export const CASE_FETCH_HEARINGROOM_LOGS_FAILURE = 'CASE_FETCH_HEARINGROOM_LOGS_FAILURE';
export const CASE_FETCH_HEARINGROOM_LOGS_DISMISS_FEEDBACK =
  'CASE_FETCH_HEARINGROOM_LOGS_DISMISS_FEEDBACK';
export const CASE_FETCH_SIGNALR_TRANSIENT_NOTIFICATION_SUCCESS =
  'CASE_FETCH_SIGNALR_TRANSIENT_NOTIFICATION_SUCCESS';
export const CASE_FETCH_NOTIFICATIONS_BEGIN = 'CASE_FETCH_NOTIFICATIONS_BEGIN';
export const CASE_FETCH_NOTIFICATIONS_SUCCESS = 'CASE_FETCH_NOTIFICATIONS_SUCCESS';
export const CASE_FETCH_NOTIFICATIONS_FAILURE = 'CASE_FETCH_NOTIFICATIONS_FAILURE';
export const CASE_FETCH_NOTIFICATIONS_DISMISS_FEEDBACK =
  'CASE_FETCH_NOTIFICATIONS_DISMISS_FEEDBACK';
export const CASE_CLEAR_TRANSIENT_NOTIFICATIONS = 'CASE_CLEAR_TRANSIENT_NOTIFICATIONS';
export const CASE_FETCH_SIGNALR_NOTIFICATION_SUCCESS = 'CASE_FETCH_SIGNALR_NOTIFICATION_SUCCESS';
export const CASE_FETCH_SIGNALR_NOTIFICATION_FEEDBACK = 'CASE_FETCH_SIGNALR_NOTIFICATION_FEEDBACK';
export const CASE_UPDATE_NOTIFICATIONS_BEGIN = 'CASE_UPDATE_NOTIFICATIONS_BEGIN';
export const CASE_UPDATE_NOTIFICATIONS_SUCCESS = 'CASE_UPDATE_NOTIFICATIONS_SUCCESS';
export const CASE_UPDATE_NOTIFICATIONS_FAILURE = 'CASE_UPDATE_NOTIFICATIONS_FAILURE';
export const CASE_UPDATE_NOTIFICATIONS_DISMISS_FEEDBACK =
  'CASE_UPDATE_NOTIFICATIONS_DISMISS_FEEDBACK';
export const CASE_UPDATE_NOTIFICATION_BEGIN = 'CASE_UPDATE_NOTIFICATION_BEGIN';
export const CASE_UPDATE_NOTIFICATION_SUCCESS = 'CASE_UPDATE_NOTIFICATION_SUCCESS';
export const CASE_UPDATE_NOTIFICATION_FAILURE = 'CASE_UPDATE_NOTIFICATION_FAILURE';
export const CASE_UPDATE_NOTIFICATION_DISMISS_FEEDBACK =
  'CASE_UPDATE_NOTIFICATION_DISMISS_FEEDBACK';
export const CASE_FETCH_TAG_GROUPS_BEGIN = 'CASE_FETCH_TAG_GROUPS_BEGIN';
export const CASE_FETCH_TAG_GROUPS_SUCCESS = 'CASE_FETCH_TAG_GROUPS_SUCCESS';
export const CASE_FETCH_TAG_GROUPS_FAILURE = 'CASE_FETCH_TAG_GROUPS_FAILURE';
export const CASE_FETCH_TAG_GROUPS_DISMISS_FEEDBACK = 'CASE_FETCH_TAG_GROUPS_DISMISS_FEEDBACK';
export const CASE_ASSIGN_ANNOTATIONS_BEGIN = 'CASE_ASSIGN_ANNOTATIONS_BEGIN';
export const CASE_ASSIGN_ANNOTATIONS_SUCCESS = 'CASE_ASSIGN_ANNOTATIONS_SUCCESS';
export const CASE_ASSIGN_ANNOTATIONS_FAILURE = 'CASE_ASSIGN_ANNOTATIONS_FAILURE';
export const CASE_ASSIGN_ANNOTATIONS_DISMISS_FEEDBACK = 'CASE_ASSIGN_ANNOTATIONS_DISMISS_FEEDBACK';
export const CASE_BURN_CROSS_REF_BEGIN = 'CASE_BURN_CROSS_REF_BEGIN';
export const CASE_BURN_CROSS_REF_SUCCESS = 'CASE_BURN_CROSS_REF_SUCCESS';
export const CASE_BURN_CROSS_REF_FAILURE = 'CASE_BURN_CROSS_REF_FAILURE';
export const CASE_BURN_CROSS_REF_DISMISS_FEEDBACK = 'CASE_BURN_CROSS_REF_DISMISS_FEEDBACK';
export const CASE_FETCH_FILE_RINGS_BEGIN = 'CASE_FETCH_FILE_RINGS_BEGIN';
export const CASE_FETCH_FILE_RINGS_SUCCESS = 'CASE_FETCH_FILE_RINGS_SUCCESS';
export const CASE_FETCH_FILE_RINGS_FAILURE = 'CASE_FETCH_FILE_RINGS_FAILURE';
export const CASE_FETCH_FILE_RINGS_DISMISS_FEEDBACK = 'CASE_FETCH_FILE_RINGS_DISMISS_FEEDBACK';
export const CASE_UPLOAD_CONFIDENTIALITY_RING_FILES_BEGIN =
  'CASE_UPLOAD_CONFIDENTIALITY_RING_FILES_BEGIN';
export const CASE_UPLOAD_CONFIDENTIALITY_RING_FILES_SUCCESS =
  'CASE_UPLOAD_CONFIDENTIALITY_RING_FILES_SUCCESS';
export const CASE_UPLOAD_CONFIDENTIALITY_RING_FILES_FAILURE =
  'CASE_UPLOAD_CONFIDENTIALITY_RING_FILES_FAILURE';
export const CASE_UPLOAD_CONFIDENTIALITY_RING_FILES_DISMISS_FEEDBACK =
  'CASE_UPLOAD_CONFIDENTIALITY_RING_FILES_DISMISS_FEEDBACK';
export const CASE_UPDATE_CONFIDENTIALITY_RING_BEGIN = 'CASE_UPDATE_CONFIDENTIALITY_RING_BEGIN';
export const CASE_UPDATE_CONFIDENTIALITY_RING_SUCCESS = 'CASE_UPDATE_CONFIDENTIALITY_RING_SUCCESS';
export const CASE_UPDATE_CONFIDENTIALITY_RING_FAILURE = 'CASE_UPDATE_CONFIDENTIALITY_RING_FAILURE';
export const CASE_UPDATE_CONFIDENTIALITY_RING_DISMISS_FEEDBACK =
  'CASE_UPDATE_CONFIDENTIALITY_RING_DISMISS_FEEDBACK';
export const CASE_DELETE_FILE_RING_BEGIN = 'CASE_DELETE_FILE_RING_BEGIN';
export const CASE_DELETE_FILE_RING_SUCCESS = 'CASE_DELETE_FILE_RING_SUCCESS';
export const CASE_DELETE_FILE_RING_FAILURE = 'CASE_DELETE_FILE_RING_FAILURE';
export const CASE_DELETE_FILE_RING_DISMISS_FEEDBACK = 'CASE_DELETE_FILE_RING_DISMISS_FEEDBACK';
export const CASE_FETCH_MATCHES_BEGIN = 'CASE_FETCH_MATCHES_BEGIN';
export const CASE_FETCH_MATCHES_SUCCESS = 'CASE_FETCH_MATCHES_SUCCESS';
export const CASE_FETCH_MATCHES_FAILURE = 'CASE_FETCH_MATCHES_FAILURE';
export const CASE_FETCH_MATCHES_DISMISS_FEEDBACK = 'CASE_FETCH_MATCHES_DISMISS_FEEDBACK';
export const CASE_GENERATE_ANSWERS_BEGIN = 'CASE_GENERATE_ANSWERS_BEGIN';
export const CASE_GENERATE_ANSWERS_SUCCESS = 'CASE_GENERATE_ANSWERS_SUCCESS';
export const CASE_GENERATE_ANSWERS_FAILURE = 'CASE_GENERATE_ANSWERS_FAILURE';
export const CASE_GENERATE_ANSWERS_DISMISS_FEEDBACK = 'CASE_GENERATE_ANSWERS_DISMISS_FEEDBACK';
export const CASE_GENERATE_ANSWERS_CLEAR = 'CASE_GENERATE_ANSWERS_CLEAR';
export const CASE_FETCH_SIGNALR_ANSWER_SUCCESS = 'CASE_FETCH_SIGNALR_ANSWER_SUCCESS';
export const CASE_SET_TYPED_AI_RESPONSE_COUNT = 'CASE_SET_TYPED_AI_RESPONSE_COUNT';
export const CASE_REINDEX_BUNDLE_BEGIN = 'CASE_REINDEX_BUNDLE_BEGIN';
export const CASE_REINDEX_BUNDLE_SUCCESS = 'CASE_REINDEX_BUNDLE_SUCCESS';
export const CASE_REINDEX_BUNDLE_FAILURE = 'CASE_REINDEX_BUNDLE_FAILURE';
export const CASE_REINDEX_BUNDLE_DISMISS_FEEDBACK = 'CASE_REINDEX_BUNDLE_DISMISS_FEEDBACK';

export const CASE_UPLOAD_TRANSCRIPT_NOTES_BEGIN = 'CASE_UPLOAD_TRANSCRIPT_NOTES_BEGIN';
export const CASE_UPLOAD_TRANSCRIPT_NOTES_SUCCESS = 'CASE_UPLOAD_TRANSCRIPT_NOTES_SUCCESS';
export const CASE_UPLOAD_TRANSCRIPT_NOTES_FAILURE = 'CASE_UPLOAD_TRANSCRIPT_NOTES_FAILURE';
export const CASE_UPLOAD_TRANSCRIPT_NOTES_DISMISS_FEEDBACK =
  'CASE_UPLOAD_TRANSCRIPT_NOTES_DISMISS_FEEDBACK';

export const CASE_UPLOAD_PREVIEW_TRANSCRIPT_NOTES_BEGIN =
  'CASE_UPLOAD_PREVIEW_TRANSCRIPT_NOTES_BEGIN';
export const CASE_UPLOAD_PREVIEW_TRANSCRIPT_NOTES_SUCCESS =
  'CASE_UPLOAD_PREVIEW_TRANSCRIPT_NOTES_SUCCESS';
export const CASE_UPLOAD_PREVIEW_TRANSCRIPT_NOTES_FAILURE =
  'CASE_UPLOAD_PREVIEW_TRANSCRIPT_NOTES_FAILURE';
export const CASE_UPLOAD_PREVIEW_TRANSCRIPT_NOTES_DISMISS_FEEDBACK =
  'CASE_UPLOAD_TRANSCRIPT_NOTES_DISMISS_FEEDBACK';

export const CASE_UPDATE_DOCUMENT_SORT_SELECTION = 'CASE_UPDATE_DOCUMENT_SORT_SELECTION';
export const CASE_REMOVE_DOCUMENT_SORT_SELECTION = 'CASE_REMOVE_DOCUMENT_SORT_SELECTION';
export const CASE_CREATE_PAGE_REF_BEGIN = 'CASE_CREATE_PAGE_REF_BEGIN';
export const CASE_CREATE_PAGE_REF_SUCCESS = 'CASE_CREATE_PAGE_REF_SUCCESS';
export const CASE_CREATE_PAGE_REF_FAILURE = 'CASE_CREATE_PAGE_REF_FAILURE';
export const CASE_CREATE_PAGE_REF_DISMISS_FEEDBACK = 'CASE_CREATE_PAGE_REF_DISMISS_FEEDBACK';
export const CASE_FETCH_PAGE_REFS_BEGIN = 'CASE_FETCH_PAGE_REFS_BEGIN';
export const CASE_FETCH_PAGE_REFS_SUCCESS = 'CASE_FETCH_PAGE_REFS_SUCCESS';
export const CASE_FETCH_PAGE_REFS_FAILURE = 'CASE_FETCH_PAGE_REFS_FAILURE';
export const CASE_FETCH_PAGE_REFS_DISMISS_FEEDBACK = 'CASE_FETCH_PAGE_REFS_DISMISS_FEEDBACK';
export const CASE_FETCH_PAGE_REFS_CLEAR_ANNOTATIONS = 'CASE_FETCH_PAGE_REFS_CLEAR_ANNOTATIONS';
export const CASE_FETCH_FOLDER_HEALTH_BEGIN = 'CASE_FETCH_FOLDER_HEALTH_BEGIN';
export const CASE_FETCH_FOLDER_HEALTH_SUCCESS = 'CASE_FETCH_FOLDER_HEALTH_SUCCESS';
export const CASE_FETCH_FOLDER_HEALTH_FAILURE = 'CASE_FETCH_FOLDER_HEALTH_FAILURE';
export const CASE_FETCH_FOLDER_HEALTH_DISMISS_FEEDBACK =
  'CASE_FETCH_FOLDER_HEALTH_DISMISS_FEEDBACK';
export const CASE_CLEAR_MATCHES = 'CASE_CLEAR_MATCHES';
export const CASE_FETCH_SEARCH_HISTORY_BEGIN = 'CASE_FETCH_SEARCH_HISTORY_BEGIN';
export const CASE_FETCH_SEARCH_HISTORY_SUCCESS = 'CASE_FETCH_SEARCH_HISTORY_SUCCESS';
export const CASE_FETCH_SEARCH_HISTORY_FAILURE = 'CASE_FETCH_SEARCH_HISTORY_FAILURE';
export const CASE_FETCH_SEARCH_HISTORY_DISMISS_FEEDBACK =
  'CASE_FETCH_SEARCH_HISTORY_DISMISS_FEEDBACK';
export const CASE_FETCH_SEARCH_HISTORY_ITEM_BEGIN = 'CASE_FETCH_SEARCH_HISTORY_ITEM_BEGIN';
export const CASE_FETCH_SEARCH_HISTORY_ITEM_SUCCESS = 'CASE_FETCH_SEARCH_HISTORY_ITEM_SUCCESS';
export const CASE_FETCH_SEARCH_HISTORY_ITEM_FAILURE = 'CASE_FETCH_SEARCH_HISTORY_ITEM_FAILURE';
export const CASE_FETCH_SEARCH_HISTORY_ITEM_DISMISS_FEEDBACK =
  'CASE_FETCH_SEARCH_HISTORY_ITEM_DISMISS_FEEDBACK';
export const CASE_UPDATE_SEARCH_HISTORY_ITEM_BEGIN = 'CASE_UPDATE_SEARCH_HISTORY_ITEM_BEGIN';
export const CASE_UPDATE_SEARCH_HISTORY_ITEM_SUCCESS = 'CASE_UPDATE_SEARCH_HISTORY_ITEM_SUCCESS';
export const CASE_UPDATE_SEARCH_HISTORY_ITEM_FAILURE = 'CASE_UPDATE_SEARCH_HISTORY_ITEM_FAILURE';
export const CASE_UPDATE_SEARCH_HISTORY_ITEM_DISMISS_FEEDBACK =
  'CASE_UPDATE_SEARCH_HISTORY_ITEM_DISMISS_FEEDBACK';
export const CASE_DELETE_SEARCH_HISTORY_ITEM_BEGIN = 'CASE_DELETE_SEARCH_HISTORY_ITEM_BEGIN';
export const CASE_DELETE_SEARCH_HISTORY_ITEM_SUCCESS = 'CASE_DELETE_SEARCH_HISTORY_ITEM_SUCCESS';
export const CASE_DELETE_SEARCH_HISTORY_ITEM_FAILURE = 'CASE_DELETE_SEARCH_HISTORY_ITEM_FAILURE';
export const CASE_DELETE_SEARCH_HISTORY_ITEM_DISMISS_FEEDBACK =
  'CASE_DELETE_SEARCH_HISTORY_ITEM_DISMISS_FEEDBACK';
export const CASE_CREATE_TIMELINES_BEGIN = 'CASE_CREATE_TIMELINES_BEGIN';
export const CASE_CREATE_TIMELINES_SUCCESS = 'CASE_CREATE_TIMELINES_SUCCESS';
export const CASE_CREATE_TIMELINES_FAILURE = 'CASE_CREATE_TIMELINES_FAILURE';
export const CASE_CREATE_TIMELINES_DISMISS_FEEDBACK = 'CASE_CREATE_TIMELINES_DISMISS_FEEDBACK';
export const CASE_UPDATE_TIMELINES_BEGIN = 'CASE_UPDATE_TIMELINES_BEGIN';
export const CASE_UPDATE_TIMELINES_SUCCESS = 'CASE_UPDATE_TIMELINES_SUCCESS';
export const CASE_UPDATE_TIMELINES_FAILURE = 'CASE_UPDATE_TIMELINES_FAILURE';
export const CASE_UPDATE_TIMELINES_DISMISS_FEEDBACK = 'CASE_UPDATE_TIMELINES_DISMISS_FEEDBACK';
export const CASE_DELETE_TIMELINES_BEGIN = 'CASE_DELETE_TIMELINES_BEGIN';
export const CASE_DELETE_TIMELINES_SUCCESS = 'CASE_DELETE_TIMELINES_SUCCESS';
export const CASE_DELETE_TIMELINES_FAILURE = 'CASE_DELETE_TIMELINES_FAILURE';
export const CASE_DELETE_TIMELINES_DISMISS_FEEDBACK = 'CASE_DELETE_TIMELINES_DISMISS_FEEDBACK';
export const CASE_FETCH_TIMELINES_BEGIN = 'CASE_FETCH_TIMELINES_BEGIN';
export const CASE_FETCH_TIMELINES_SUCCESS = 'CASE_FETCH_TIMELINES_SUCCESS';
export const CASE_FETCH_TIMELINES_FAILURE = 'CASE_FETCH_TIMELINES_FAILURE';
export const CASE_FETCH_TIMELINES_DISMISS_FEEDBACK = 'CASE_FETCH_TIMELINES_DISMISS_FEEDBACK';
export const CASE_UNASSIGN_DOCUMENTS_MULTIPLE_BEGIN = 'CASE_UNASSIGN_DOCUMENTS_MULTIPLE_BEGIN';
export const CASE_UNASSIGN_DOCUMENTS_MULTIPLE_SUCCESS = 'CASE_UNASSIGN_DOCUMENTS_MULTIPLE_SUCCESS';
export const CASE_UNASSIGN_DOCUMENTS_MULTIPLE_FAILURE = 'CASE_UNASSIGN_DOCUMENTS_MULTIPLE_FAILURE';
export const CASE_UNASSIGN_DOCUMENTS_MULTIPLE_DISMISS_FEEDBACK =
  'CASE_UNASSIGN_DOCUMENTS_MULTIPLE_DISMISS_FEEDBACK';
export const CASE_ASSIGN_EVENTS_TO_TIMELINE_BEGIN = 'CASE_ASSIGN_EVENTS_TO_TIMELINE_BEGIN';
export const CASE_ASSIGN_EVENTS_TO_TIMELINE_SUCCESS = 'CASE_ASSIGN_EVENTS_TO_TIMELINE_SUCCESS';
export const CASE_ASSIGN_EVENTS_TO_TIMELINE_FAILURE = 'CASE_ASSIGN_EVENTS_TO_TIMELINE_FAILURE';
export const CASE_ASSIGN_EVENTS_TO_TIMELINE_DISMISS_FEEDBACK =
  'CASE_ASSIGN_EVENTS_TO_TIMELINE_DISMISS_FEEDBACK';
export const CASE_FETCH_TIMELINE_BEGIN = 'CASE_FETCH_TIMELINE_BEGIN';
export const CASE_FETCH_TIMELINE_SUCCESS = 'CASE_FETCH_TIMELINE_SUCCESS';
export const CASE_FETCH_TIMELINE_FAILURE = 'CASE_FETCH_TIMELINE_FAILURE';
export const CASE_FETCH_TIMELINE_DISMISS_FEEDBACK = 'CASE_FETCH_TIMELINE_DISMISS_FEEDBACK';
export const CASE_EXPORT_TIMELINE_BEGIN = 'CASE_EXPORT_TIMELINE_BEGIN';
export const CASE_EXPORT_TIMELINE_SUCCESS = 'CASE_EXPORT_TIMELINE_SUCCESS';
export const CASE_EXPORT_TIMELINE_FAILURE = 'CASE_EXPORT_TIMELINE_FAILURE';
export const CASE_EXPORT_TIMELINE_DISMISS_FEEDBACK = 'CASE_EXPORT_TIMELINE_DISMISS_FEEDBACK';
export const CASE_EXPORT_FOLDER_BEGIN = 'CASE_EXPORT_FOLDER_BEGIN';
export const CASE_EXPORT_FOLDER_SUCCESS = 'CASE_EXPORT_FOLDER_SUCCESS';
export const CASE_EXPORT_FOLDER_FAILURE = 'CASE_EXPORT_FOLDER_FAILURE';
export const CASE_EXPORT_FOLDER_DISMISS_FEEDBACK = 'CASE_EXPORT_FOLDER_DISMISS_FEEDBACK';
export const CASE_DOWNLOAD_FOLDER_BEGIN = 'CASE_DOWNLOAD_FOLDER_BEGIN';
export const CASE_DOWNLOAD_FOLDER_SUCCESS = 'CASE_DOWNLOAD_FOLDER_SUCCESS';
export const CASE_DOWNLOAD_FOLDER_FAILURE = 'CASE_DOWNLOAD_FOLDER_FAILURE';
export const CASE_DOWNLOAD_FOLDER_DISMISS_FEEDBACK = 'CASE_DOWNLOAD_FOLDER_DISMISS_FEEDBACK';
export const CASE_DELETE_FILE_VERSIONS_BEGIN = 'CASE_DELETE_FILE_VERSIONS_BEGIN';
export const CASE_DELETE_FILE_VERSIONS_SUCCESS = 'CASE_DELETE_FILE_VERSIONS_SUCCESS';
export const CASE_DELETE_FILE_VERSIONS_FAILURE = 'CASE_DELETE_FILE_VERSIONS_FAILURE';
export const CASE_DELETE_FILE_VERSIONS_DISMISS_FEEDBACK =
  'CASE_DELETE_FILE_VERSIONS_DISMISS_FEEDBACK';
export const CASE_FETCH_DOCUMENT_MERGE_DETAILS_BEGIN = 'CASE_FETCH_DOCUMENT_MERGE_DETAILS_BEGIN';
export const CASE_FETCH_DOCUMENT_MERGE_DETAILS_SUCCESS =
  'CASE_FETCH_DOCUMENT_MERGE_DETAILS_SUCCESS';
export const CASE_FETCH_DOCUMENT_MERGE_DETAILS_FAILURE =
  'CASE_FETCH_DOCUMENT_MERGE_DETAILS_FAILURE';
export const CASE_FETCH_DOCUMENT_MERGE_DETAILS_DISMISS_FEEDBACK =
  'CASE_FETCH_DOCUMENT_MERGE_DETAILS_DISMISS_FEEDBACK';
export const CASE_SET_AITOOLS_FILTERS = 'CASE_SET_AITOOLS_FILTERS';
export const CASE_SET_AITOOLS_FILTERS_FAILURE = 'CASE_SET_AITOOLS_FILTERS_FAILURE';

export const GET_SAS_TOKEN_BEGIN = 'GET_SAS_TOKEN_BEGIN';
export const GET_SAS_TOKEN_SUCCESS = 'GET_SAS_TOKEN_SUCCESS';
export const GET_SAS_TOKEN_FAILURE = 'GET_SAS_TOKEN_FAILURE';

export const UPLOAD_FILES_TO_AZURE_BEGIN = 'UPLOAD_FILES_TO_AZURE_BEGIN';
export const UPLOAD_FILES_TO_AZURE_SUCCESS = 'UPLOAD_FILES_TO_AZURE_SUCCESS';
export const UPLOAD_FILES_TO_AZURE_FAILURE = 'UPLOAD_FILES_TO_AZURE_FAILURE';

export const CREATE_BATCH_UPLOAD_BEGIN = 'CREATE_BATCH_UPLOAD_BEGIN';
export const CREATE_BATCH_UPLOAD_SUCCESS = 'CREATE_BATCH_UPLOAD_SUCCESS';
export const CREATE_BATCH_UPLOAD_FAILURE = 'CREATE_BATCH_UPLOAD_FAILURE';
export const CASE_SHOW_DOCFINDER_BOOKMARKS = 'CASE_SHOW_DOCFINDER_BOOKMARKS';
export const CASE_SHOW_DOCFINDER_FILTER = 'CASE_SHOW_DOCFINDER_FILTER';
export const CASE_CREATE_GROUP_BEGIN = 'CASE_CREATE_GROUP_BEGIN';
export const CASE_CREATE_GROUP_SUCCESS = 'CASE_CREATE_GROUP_SUCCESS';
export const CASE_CREATE_GROUP_FAILURE = 'CASE_CREATE_GROUP_FAILURE';
export const CASE_CREATE_GROUP_DISMISS_FEEDBACK = 'CASE_CREATE_GROUP_DISMISS_FEEDBACK';
export const CASE_FETCH_GROUPS_BEGIN = 'CASE_FETCH_GROUPS_BEGIN';
export const CASE_FETCH_GROUPS_SUCCESS = 'CASE_FETCH_GROUPS_SUCCESS';
export const CASE_FETCH_GROUPS_FAILURE = 'CASE_FETCH_GROUPS_FAILURE';
export const CASE_FETCH_GROUPS_DISMISS_FEEDBACK = 'CASE_FETCH_GROUPS_DISMISS_FEEDBACK';
export const CASE_REMOVE_GROUP_BEGIN = 'CASE_REMOVE_GROUP_BEGIN';
export const CASE_REMOVE_GROUP_SUCCESS = 'CASE_REMOVE_GROUP_SUCCESS';
export const CASE_REMOVE_GROUP_FAILURE = 'CASE_REMOVE_GROUP_FAILURE';
export const CASE_REMOVE_GROUP_DISMISS_FEEDBACK = 'CASE_REMOVE_GROUP_DISMISS_FEEDBACK';
export const CASE_SET_AI_SEARCH_MATCHES = 'CASE_SET_AI_SEARCH_MATCHES';
export const CASE_UPDATE_AI_SEARCH_MATCHES = 'CASE_UPDATE_AI_SEARCH_MATCHES';
export const CASE_CLEAR_AI_SEARCH_MATCHES = 'CASE_CLEAR_AI_SEARCH_MATCHES';
export const CASE_SET_AI_SEARCH_ANSWER_CHAT = 'CASE_SET_AI_SEARCH_ANSWER_CHAT';
export const CASE_SET_AI_SEARCH_SUGGESTED_QUESTIONS = 'CASE_SET_AI_SEARCH_SUGGESTED_QUESTIONS';
export const CASE_CLEAR_AI_SEARCH_STATE = 'CASE_CLEAR_AI_SEARCH_STATE';
export const CASE_ASK_AI_BEGIN = 'CASE_ASK_AI_BEGIN';
export const CASE_ASK_AI_SUCCESS = 'CASE_ASK_AI_SUCCESS';
export const CASE_ASK_AI_FAILURE = 'CASE_ASK_AI_FAILURE';
export const CASE_ASK_AI_DISMISS_FEEDBACK = 'CASE_ASK_AI_DISMISS_FEEDBACK';
export const CASE_PARSE_MATCH_BEGIN = 'CASE_PARSE_MATCH_BEGIN';
export const CASE_PARSE_MATCH_SUCCESS = 'CASE_PARSE_MATCH_SUCCESS';
export const CASE_PARSE_MATCH_FAILURE = 'CASE_PARSE_MATCH_FAILURE';
export const CASE_PARSE_MATCH_DISMISS_FEEDBACK = 'CASE_PARSE_MATCH_DISMISS_FEEDBACK';

// specifically for the bundle sidebar
export const CASE_TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

export const CASE_TOGGLE_LOCAL_UPLOAD_DRAWER = 'TOGGLE_LOCAL_UPLOAD_DRAWER';
export const SET_TOGGLE_UPLOAD_DRAWER = 'SET_TOGGLE_UPLOAD_DRAWER';
export const SET_GLOBAL_FILE_METADATA = 'SET_GLOBAL_FILE_METADATA';
export const SET_UPLOAD_IN_PROGRESS_PARAMS = 'SET_UPLOAD_IN_PROGRESS_PARAMS';

// Data map
export const SHOW_FILTERED_ROWS = 'SHOW_FILTERED_ROWS';
