import React, { useState } from 'react';
import { Slider } from '@mui/material';

export default ({ duration, handlePlayerSliderChange, playerRef }: any) => {
  const [seconds, setSeconds] = useState(0);

  setInterval(() => {
    const newSeconds =
      Math.round(((playerRef.current && playerRef.current.getCurrentTime()) || 0) * 10) / 10;
    if (seconds !== newSeconds) setSeconds(newSeconds);
  }, 250);

  return (
    <Slider
      size="small"
      sx={{ color: 'whitesmoke' }}
      min={0}
      max={duration}
      value={seconds}
      onClick={(e) => e.stopPropagation()}
      onChange={(e, value) => {
        handlePlayerSliderChange(value);
        e.stopPropagation();
      }}
    />
  );
};
