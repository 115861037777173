import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import * as actions from 'features/case/redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import T from 'i18n';
import Logger from 'utils/logger';
import { selectCurrentCaseId, selectUserNeedsToAcceptTsAndCs } from 'common/selectors';
import history from 'common/history';
import Avatar from '@mui/material/Avatar';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { Trans } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    width: 'auto',
    minWidth: '25rem',
    minHeight: '20rem',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid transparent',
    borderRadius: '1%',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  body: {
    margin: theme.spacing(2, 0, 0),
    padding: theme.spacing(0, 2, 0),
    fontSize: '1.1rem',
    fontWeight: '300',
  },
  root: {
    color: '#fff',
    backgroundColor: '#0288d1',
  },
  buttonMargin: {
    marginLeft: '1rem',
  },
  disabled: {
    color: '#fff',
    backgroundColor: '#0288d1',
  },
  errorIcon: {
    margin: theme.spacing(2, 0, 0),
  },
  CircularProgress: {
    margin: 'auto',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
  supportText: {
    margin: theme.spacing(1, 0, 0),
    color: 'gray',
  },
  link: {
    paddingBottom: '0.25rem',
  },
}));

const TsAndCsModal = ({ children }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const caseId = useSelector(selectCurrentCaseId);
  const [showModal, setShowModal] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [showModalContent, setShowModalContent] = useState(true);
  const userNeedsToAcceptTsAndCs = useSelector(selectUserNeedsToAcceptTsAndCs);

  const handleAcceptTsAndCs = () => {
    setLoading(true);
    dispatch(actions.acceptTsAndCs(caseId))
      .then((res) => {
        setShowModal(false);
        setLoading(false);
      })
      .catch((error) => {
        setShowErrorMsg(true);
        setLoading(false);
        Logger.ERROR('Accepting terms & conditions', error);
      });
  };

  const getTsAndCs = () => {
    setShowModalContent(false);
    dispatch(actions.fetchTsAndCs(caseId))
      .then((res) => {
        if (!res) {
          setShowModalContent(true);
          setShowErrorMsg(true);
        } else {
          const a = document.createElement('a');
          if (typeof res === 'string') {
            a.href = res;
            a.target = '_blank';
            a.rel = 'noopener noreferrer';
          } else {
            const fileBlob = new Blob([res], { type: 'application/pdf' });
            const blobUrl = URL.createObjectURL(fileBlob);
            a.href = blobUrl;
            a.download = `Terms&Conditions-${caseId}.pdf`;
          }
          a.click();
          setShowModalContent(true);
        }
      })
      .catch((error) => {
        setShowModalContent(true);
        setShowErrorMsg(true);
        Logger.ERROR('fetching terms & conditions', error);
      });
  };

  const email = T.translate('case.tsAndCsSupportEmail');
  return (
    <>
      {children}
      {userNeedsToAcceptTsAndCs && (
        <Modal open={showModal} className={classes.modal}>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <AssignmentTurnedInOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {T.translate('case.tsAndCsHeading')}
            </Typography>
            {!showModalContent ? (
              !showErrorMsg && <CircularProgress size={25} className={classes.CircularProgress} />
            ) : (
              <>
                {showErrorMsg ? (
                  <>
                    <ErrorOutlineIcon className={classes.errorIcon} color="error" />
                    <Typography color="error" component="h2" variant="subtitle1">
                      {' '}
                      {T.translate('case.tsAndCsErrorMsg')}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography component="h2" variant="body1" className={classes.body}>
                      {T.translate('case.tsAndCsMsg')}{' '}
                      <Link
                        component="button"
                        variank="body1"
                        onClick={getTsAndCs}
                        className={classes.link}
                      >
                        {T.translate('case.tsAndCsLink')}
                      </Link>
                    </Typography>
                    <Typography
                      component="h3"
                      variant="subtitle2"
                      color="textSecondary"
                      className={classes.supportText}
                    >
                      {/* prettier-ignore */}
                      <Trans i18nKey={'case.tsAndCsSupportMsg'}>
                        To find out more about Twilio setting click <a href={`mailto:${email}`}>{{email}}</a>.
                      </Trans>
                    </Typography>
                    <Button
                      fullWidth
                      variant={'contained'}
                      color={'primary'}
                      onClick={handleAcceptTsAndCs}
                      disabled={loading}
                      className={classnames(classes.submit)}
                    >
                      {loading && <CircularProgress size={17} />}
                      {!loading && T.translate('case.acceptTsAndCs')}
                    </Button>
                  </>
                )}
                <Grid item xs>
                  <Link
                    className={classnames(showErrorMsg && classes.errorIcon)}
                    component="button"
                    variant="body2"
                    onClick={() => {
                      setShowModal(false);
                      history.push(history.location.pathname.replace(/\/case.*/, ``));
                      window.location.reload();
                    }}
                  >
                    {T.translate('case.cancelTsAndCs')}
                  </Link>
                </Grid>
              </>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

export default TsAndCsModal;
