import React, { useEffect, useState } from 'react';
import T from 'i18n';
import { useDispatch } from 'react-redux';
import * as actions from '../../redux/actions';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { Modal } from 'features/common/MaterialBasedComponents';

const UpdateIndexModal = ({ show, handleClose, selectedRows, currentGridList }) => {
  const dispatch = useDispatch();
  const [newIndex, setNewIndex] = useState(parseInt(selectedRows[0]?.sortOrder));
  const [error, setError] = useState(false);
  const totalDocuments = currentGridList.length;
  const [file] = useState(selectedRows[0]);
  const [positionMessage, setPositionMessage] = useState(selectedRows[0]?.name);
  const maxIndex = currentGridList[currentGridList.length - 1]?.sortOrder;
  // Updated indexHandler function around line 20
  const indexHandler = (val) => {
    setError(false);

    // Convert val to a number
    const numVal = parseInt(val);

    // Get the minimum and maximum allowed sort orders
    const minSortOrder = currentGridList[0]?.sortOrder;
    const maxSortOrder = maxIndex;

    // Check for invalid conditions
    if (
      isNaN(numVal) || // Not a number
      numVal < minSortOrder || // Less than first element's order
      numVal > maxSortOrder // Greater than last element's order
    ) {
      setError(true);
      setNewIndex(val); // Keep the entered value to show the error state
    } else {
      setNewIndex(numVal);
    }
  };
  const handleSave = () => {
    if (newIndex < 1 || isNaN(newIndex) || newIndex > totalDocuments) {
      setError(true);
    } else {
      dispatch(
        actions.updateDocumentSort({
          index: newIndex - 1,
          ids: [file?.folderFileId || file?.compositeKey || file?.id],
          zeroBasedIndex: selectedRows[0].zeroBasedIndex,
          agreed: selectedRows[0].agreed,
          singleDroppedDocId: file?.id,
          hasFolderFieldId: file?.folderFileId? true : false,
        }),
      );
      handleClose();
    }
  };

  const handleMoveThroughFullList = (position) => {
    let indexToReposition = 0;
    if (position === 'last') {
      indexToReposition = currentGridList.length - 1;
    }
    const idsOfMultipleSelected = selectedRows.map((f) => f.folderFileId || f.compositeKey || f.id);
    dispatch(
      actions.updateDocumentSort({
        index: indexToReposition,
        ids:
          selectedRows?.length > 1
            ? idsOfMultipleSelected
            : [file?.folderFileId || file?.compositeKey || file?.id],
        zeroBasedIndex: file?.zeroBasedIndex,
        agreed: file?.agreed,
        singleDroppedDocId: file?.id,
        hasFolderFieldId: file?.folderFileId ? true : false,
      }),
    );

    handleClose();
  };

  const handleSaveMultiple = () => {
    if (newIndex < 1 || isNaN(newIndex) || newIndex > totalDocuments) {
      setError(true);
      return;
    } else {
      const ids = selectedRows.map((f) => f.folderFileId || f.compositeKey || f.id);
      let addedIndexValue = Number(newIndex - 1) + Number(selectedRows?.length - 1);
      if (addedIndexValue >= maxIndex - 1) {
        handleMoveThroughFullList('last');
      } else {
        dispatch(
          actions.updateDocumentSort({
            index: newIndex > selectedRows[0].sortOrder ? addedIndexValue : newIndex - 1,
            ids,
            zeroBasedIndex: selectedRows[0].zeroBasedIndex,
            agreed: selectedRows[0].agreed,
            singleDroppedDocId: false,
            hasFolderFieldId: selectedRows[0]?.folderFileId ? true : false,
          }),
        );
      }

      handleClose();
    }
  };

  const renderMultipleFilesContent = () => {
    return (
      <div>
        <p>{T.translate('generic.updateMultipleIndexes.warning')}</p>
        <PerfectScrollbar>
          <ul style={{ height: '150px' }}>
            {selectedRows.map((f) => {
              return (
                <li
                  key={file?.folderFileId || file?.compositeKey || f.id}
                  style={{ listStyleType: 'none', left: 0, paddingBottom: 3 }}
                >
                  {`${f?.sortOrder}: ${f?.name}`}
                </li>
              );
            })}
          </ul>
        </PerfectScrollbar>

        <p>
          {T.translate('generic.updateMultipleIndexes.indexOfFirst', {
            index: selectedRows[0]?.sortOrder,
          })}
        </p>
        <TextField
          fullWidth={true}
          variant="outlined"
          autoComplete="off"
          placeholder={`${T.translate('generic.changePositionModal.placeholder')}`}
          onChange={(e) => indexHandler(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && newIndex > 0 && !error && !isNaN(newIndex)) {
              e.preventDefault();
              e.stopPropagation();
              handleSaveMultiple();
            }
          }}
          value={newIndex}
          autoFocus={true}
          error={error}
        />
        <p className="max-length">
          {T.translate('generic.updateMultipleIndexes.goToIndex', {
            name: positionMessage,
          })}
          <br />
          {`${T.translate('generic.updateIndex.maxValue', {
            index: maxIndex,
          })}`}
        </p>
      </div>
    );
  };

  const renderSingleFileContent = () => (
    <>
      <p>{`${T.translate('generic.changePositionModal.currentIndex')}: ${file?.sortOrder}`}</p>
      <TextField
        fullWidth={true}
        variant="outlined"
        autoComplete="off"
        placeholder={`${T.translate('generic.changePositionModal.placeholder')}`}
        onChange={(e) => indexHandler(e.target.value)}
        onKeyDown={(e) => {
          if (
            e.key === 'Enter' &&
            newIndex > 0 &&
            !error &&
            !isNaN(newIndex) &&
            newIndex < selectedRows?.length
          ) {
            e.preventDefault();
            e.stopPropagation();
            handleSave();
          }
        }}
        value={newIndex}
        autoFocus={true}
        error={error}
      />
      <p className="max-length">{`${T.translate('generic.updateIndex.maxValue', {
        index: maxIndex,
      })}`}</p>

      {error && <p style={{ color: 'red' }}>{T.translate('generic.notAValidEntry')}</p>}
    </>
  );

  useEffect(() => {
    if (newIndex <= selectedRows[0]?.sortOrder) {
      setPositionMessage(selectedRows[0]?.name);
    } else {
      setPositionMessage(selectedRows[selectedRows?.length - 1]?.name);
    }
  }, [newIndex, selectedRows]);

  return (
    <Modal
      show={show}
      title={`${T.translate('generic.changePosition')} of ${file?.name}`}
      handleClose={handleClose}
      handleSave={selectedRows?.length > 1 ? handleSaveMultiple : handleSave}
      saveTitle={T.translate('generic.changePosition')}
      disableSave={error || newIndex === parseInt(selectedRows[0]?.sortOrder)}
      fullWidthLabel={true}
      buttons={
        <>
          <Button
            type="button"
            id="firstIndexMove"
            onClick={() => handleMoveThroughFullList('first')}
            variant="contained"
            color="primary"
            // Disable if the first selected item is already at the first position
            disabled={
              currentGridList.length > 0 &&
              selectedRows[0]?.sortOrder === currentGridList[0]?.sortOrder
            }
          >
            {T.translate('generic.updateIndex.moveFirst')}
          </Button>
          <Button
            type="button"
            id="lastIndexMove"
            onClick={() => handleMoveThroughFullList('last')}
            variant="contained"
            color="primary"
            // Disable if the first selected item is already at the last position
            disabled={
              currentGridList.length > 0 &&
              selectedRows[0]?.sortOrder === currentGridList[currentGridList.length - 1]?.sortOrder
            }
          >
            {T.translate('generic.updateIndex.moveLast')}
          </Button>
        </>
      }
    >
      {selectedRows?.length > 1 ? renderMultipleFilesContent() : renderSingleFileContent()}
    </Modal>
  );
};

export default UpdateIndexModal;
