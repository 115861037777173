/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import T from 'i18n';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  IconButton,
  Checkbox,
  FormControlLabel,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import { Edit, EditOff, Undo, WarningOutlined } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import {
  selectPermissions,
  selectCurrentCaseTeam,
  selectCurrentCaseId,
  selectUserId,
} from 'common/selectors';
import api from 'common/api';

import { useUndoableList } from '../../hooks/useUndoableList';
import { uploadPreviewTranscriptNotes } from 'features/case/redux/uploadPreviewTranscriptNotes';
import { useFetchAnnotations } from 'features/viewing/redux/fetchAnnotations';
import { Spinner } from 'features/common';

const useStyles = makeStyles({
  tableContainer: {
    maxHeight: '400px',
    overflowY: 'auto',
    overflowX: 'auto',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    borderBottom: '1px solid #ccc',
    textAlign: 'left',
    padding: '8px',
    position: 'sticky',
    top: 0,
    backgroundColor: '#f9f9f9',
    zIndex: 1,
  },
  td: {
    borderBottom: '1px solid #eee',
    padding: '8px',
  },
  formSection: {
    marginBottom: '1rem',
  },
});

type PreviewTranscriptNotesProps = {
  show: boolean;
  handleClose: () => void;
  handleSave: (
    _evtOrNull: null,
    action: string,
    selectedRows: any[],
    formDataOrValues: any,
    closeModal: () => void,
    setLoading: (val: boolean) => void,
  ) => void;
  action: string;
  selectedRows: any[];
};

type CandidateNote = any;

type CandidateNoteRowProps = {
  note: CandidateNote;
  classes: { td: string };
  isEditing: boolean;
  onDelete: () => void;
};

const CandidateNoteRow: React.FC<CandidateNoteRowProps> = ({
  note,
  classes,
  isEditing,
  onDelete,
}) => {
  const timestamp = note.annotation?.selectedText || '(no timestamp)';
  const noteText = note.annotation?.data?.comment || '(no note)';
  const pageNumber = note.annotation?.pageNumber || '(no page)';
  return (
    <tr>
      {isEditing && (
        <td className={classes.td} style={{ textAlign: 'center' }}>
          <IconButton size="small" onClick={onDelete}>
            <EditOff color="error" fontSize="small" />
          </IconButton>
        </td>
      )}
      <td className={classes.td}>{timestamp}</td>
      <td className={classes.td}>{noteText}</td>
      <td className={classes.td}>{pageNumber}</td>
    </tr>
  );
};

const NoteNotMatched = ({ item, classes }: any) => {
  return (
    <tr style={{ backgroundColor: '#ECD474' }}>
      <td className={classes.td}>{item.timestamp}</td>
      <td className={classes.td}>{item.note}</td>
      <td className={classes.td}>{item.pageNumber}</td>
    </tr>
  );
};

const PreviewTranscriptNotes: React.FC<PreviewTranscriptNotesProps> = ({
  show,
  handleClose,
  selectedRows,
}) => {
  const [notesFile, setNotesFile] = useState<File | null>(null);
  const [onBehalfOfUserId, setOnBehalfOfUserId] = useState<string | null>(null);
  const [onBehalfOfUserEmail, setOnBehalfOfUserEmail] = useState<string | null>(null);
  const [overwriteExisting, setOverwriteExisting] = useState(false);
  const [existingAnnotationsForCurrentUserCount, setExistingAnnotationsForCurrentUserCount] =
    useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [fetchError, setFetchError] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [loadingExistingNotes, setLoadingExistingNotes] = useState(false);
  const [existingCurrentUserIdNotes, setExistingCurrentUserIdNotes] = useState<any>([]);
  const [notesNotMatched, setNotesNotMatched] = useState<any>([]);

  const classes = useStyles();
  const dispatch = useDispatch();
  const currentUserId = useSelector(selectUserId);
  const caseId = useSelector(selectCurrentCaseId);
  const { documents: { createNotesOnBehalfOfOtherUser } = {} } = useSelector(selectPermissions);
  const currentCaseTeam = useSelector(selectCurrentCaseTeam);

  const userOptions = createNotesOnBehalfOfOtherUser
    ? (currentCaseTeam?.users || []).map((u: any) => ({
        value: u.id,
        label: u.mail,
      }))
    : [];

  const { fetchAnnotations } = useFetchAnnotations();

  const {
    items: candidateNotes,
    setItems: setCandidateNotes,
    handleDeleteRow: handleDeleteCandidateNote,
    handleUndoDelete: handleUndoDeleteCandidateNote,
    undoStack,
  } = useUndoableList<CandidateNote>([]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmitPreview = async (formValues: any) => {
    const chosenFile = formValues.newFile instanceof FileList ? formValues.newFile[0] : null;
    setNotesFile(chosenFile);

    setOnBehalfOfUserId(formValues.onBehalfOfUserId || null);

    if (!caseId || !selectedRows?.[0]?.id) {
      setFetchError('Missing case ID or file selection');
      return;
    }
    if (!chosenFile) {
      setFetchError('No file selected');
      return;
    }

    setLoading(true);
    setFetchError(null);

    try {
      await uploadFileAndFetchCandidateNotes(chosenFile);
    } catch (err) {
      setFetchError(err instanceof Error ? err.message : 'Unknown error');
    } finally {
      setLoading(false);
    }
  };

  const uploadFileAndFetchCandidateNotes = async (file: File) => {
    const fileId = selectedRows?.[0]?.id;
    if (!caseId || !fileId) return;

    const response = await dispatch(uploadPreviewTranscriptNotes(file, onBehalfOfUserId, fileId))
      .then((res: any) => {
        setCandidateNotes(Array.isArray(res?.notesToCreate) ? res?.notesToCreate : []);
        setNotesNotMatched(Array.isArray(res?.notesNotMatched) ? res?.notesNotMatched : []);
        setExistingAnnotationsForCurrentUserCount(res.annotationsCount);
        return res;
      })
      .catch((err: any) => {
        console.error('Error uploading preview notes: ', err);
        throw err;
      });

    return response;
  };

  const handleCreateNotes = async () => {
    if (!caseId || !selectedRows?.[0]?.id) {
      setFetchError('Missing case ID or file selection.');
      return;
    }
    if (candidateNotes.length === 0) {
      setFetchError('No candidate notes to create.');
      return;
    }

    setLoading(true);
    setFetchError(null);

    const fileId = selectedRows[0].id;

    let query = `?overwriteExistingAnnotations=${overwriteExisting}`;
    if (onBehalfOfUserId) {
      query += `&onBehalfOfUserId=${onBehalfOfUserId}`;
    }

    try {
      await api.put(`/cases/${caseId}/files/${fileId}/transcript-notes${query}`, candidateNotes);

      handleClose();
    } catch (err) {
      setFetchError((err as Error)?.message || 'Unknown error');
      setLoading(false);
    }
  };

  const onDialogClose = () => {
    handleClose();
  };

  const handleToggleEditing = () => {
    setIsEditing((prev) => !prev);
  };

  const getExisting = async () => {
    try {
      setLoadingExistingNotes(true);
      let existing = await fetchAnnotations({
        file: selectedRows[0].id,
      });
      existing.filter((ann: any) => ann.annotation.type === 'HighlightAnnotation');
      if (existing.length > 0) {
        setExistingCurrentUserIdNotes(existing);
      }
      setLoadingExistingNotes(false);
    } catch (error) {
      console.error('Error fetching existing annotations', error);
    }
  };

  const clearAll = () => {
    setFetchError(null);
    setCandidateNotes([]);
    setOverwriteExisting(false);
    setIsEditing(false);
    setNotesFile(null);
    setOnBehalfOfUserId(null);
    setOnBehalfOfUserEmail(null);
    setExistingAnnotationsForCurrentUserCount(0);
    setExistingCurrentUserIdNotes([]);
    setNotesNotMatched([]);
    setNotesFile(null);
    setLoading(false);
    setLoadingExistingNotes(false);

    reset({
      newFile: null,
      onBehalfOfUserId: null,
    });
  };

  useEffect(() => {
    if (!show) {
      clearAll();
    }
  }, [show, reset, setCandidateNotes]);

  useEffect(() => {
    if (show && selectedRows?.length > 0 && onBehalfOfUserId === currentUserId) {
      getExisting();
    }
  }, [onBehalfOfUserId, currentUserId, selectedRows]);

  return (
    <Dialog open={show} onClose={onDialogClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        {`Preview Transcript Notes for ${selectedRows[0]?.id} `}
        {candidateNotes?.length > 0 && `(Total: ${candidateNotes?.length})`}
        <IconButton onClick={handleToggleEditing}>
          {isEditing ? <EditOff sx={{ color: '#ECD474' }} /> : <Edit />}
        </IconButton>
        {undoStack.length > 0 && (
          <IconButton onClick={handleUndoDeleteCandidateNote} title="Undo last deletion">
            <Undo />
          </IconButton>
        )}
        <Typography
          variant="body1"
          color="textSecondary"
          onClick={clearAll}
          style={{ cursor: 'pointer', float: 'right' }}
        >
          {T.translate('generic.reset', { defaultValue: 'Reset' })}
        </Typography>
      </DialogTitle>
      {notesNotMatched && notesNotMatched.length > 0 && (
        <span
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <WarningOutlined sx={{ color: '#ECD474', marginLeft: '20px' }} />
          <Typography variant="body2" color="textSecondary" sx={{ marginTop: '3px' }}>
            {T.translate('case.transcriptNotes.preview.warning', {
              defaultValue:
                'Some Notes were not matched to any timestamp in the document - check highlighted rows.',
            })}
          </Typography>
        </span>
      )}
      <DialogContent dividers>
        {candidateNotes.length === 0 && (
          <>
            {fetchError && (
              <Typography variant="body1" color="error" sx={{ mb: 2 }}>
                {fetchError}
              </Typography>
            )}

            <Typography variant="body1" paragraph>
              {selectedRows?.[0]
                ? T.translate('case.uploadTranscriptMsg', {
                    documentName: selectedRows[0].name || selectedRows[0].originalName,
                  })
                : T.translate('case.uploadTranscriptMsg', {
                    documentName: 'Unknown Document',
                  })}
            </Typography>

            {createNotesOnBehalfOfOtherUser && (
              <Box className={classes.formSection}>
                <FormControl fullWidth>
                  <InputLabel id="onBehalf-label">
                    {T.translate('case.onBehalfOfUser', {
                      defaultValue: 'On behalf of user',
                    })}
                  </InputLabel>
                  <Controller
                    name="onBehalfOfUserId"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <Select
                        labelId="onBehalf-label"
                        label={T.translate('case.onBehalfOfUser', {
                          defaultValue: 'On behalf of user',
                        })}
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                          const found = userOptions.find((u: any) => u.value === e.target.value);
                          setOnBehalfOfUserId(e.target.value || null);
                          setOnBehalfOfUserEmail(found?.label || null);
                        }}
                        fullWidth
                      >
                        <MenuItem value="">
                          <em>
                            {T.translate('case.onBehalfOfUserPlaceholder', {
                              defaultValue: 'None',
                            })}
                          </em>
                        </MenuItem>
                        {userOptions.map((u: any) => (
                          <MenuItem key={u.value} value={u.value}>
                            {u.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Box>
            )}

            <Box className={classes.formSection}>
              <Typography variant="body2" sx={{ mb: 1, fontWeight: 500 }}>
                {T.translate('generic.selectFile', {
                  defaultValue: 'Select a file to upload:',
                })}
              </Typography>
              <TextField
                type="file"
                fullWidth
                {...register('newFile', { required: true })}
                error={!!errors.newFile}
                helperText={errors.newFile ? 'A transcript notes file is required' : ''}
                inputProps={{ accept: '.txt,.csv,.json,.docx,.xlsx' }}
              />
            </Box>

            {/* {loading && (
              <Box display="flex" justifyContent="center" m={2}>
                <Spinner />
              </Box>
            )} */}
          </>
        )}

        {candidateNotes.length > 0 && (
          <>
            {fetchError && (
              <Typography variant="body1" color="error" sx={{ mb: 2 }}>
                {fetchError}
              </Typography>
            )}
            <div className={classes.tableContainer}>
              {candidateNotes.length === 0 ? (
                <Typography variant="body1" color="textSecondary">
                  {T.translate('case.transcriptNotes.preview.noCandidates', {
                    defaultValue: 'No candidate notes found or no file uploaded.',
                  })}
                </Typography>
              ) : (
                <table className={classes.table}>
                  <thead>
                    <tr>
                      {isEditing && (
                        <th className={classes.th} style={{ width: '40px', textAlign: 'center' }}>
                          {T.translate('case.transcriptNotes.preview.deleteColumnHeader', {
                            defaultValue: '',
                          })}
                        </th>
                      )}
                      <th className={classes.th}>
                        {T.translate('case.transcriptNotes.preview.timestampHeader', {
                          defaultValue: 'Timestamp',
                        })}
                      </th>
                      <th className={classes.th}>
                        {T.translate('case.transcriptNotes.preview.noteHeader', {
                          defaultValue: 'Note',
                        })}
                      </th>
                      <th className={classes.th}>
                        {T.translate('case.transcriptNotes.preview.pageNumber', {
                          defaultValue: 'Page number',
                        })}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {candidateNotes.map((note, index) => (
                      <CandidateNoteRow
                        key={index}
                        note={note}
                        classes={{ td: classes.td }}
                        isEditing={isEditing}
                        onDelete={() => handleDeleteCandidateNote(index)}
                      />
                    ))}
                    {notesNotMatched.map((item: any, index: any) => (
                      <NoteNotMatched item={item} classes={{ td: classes.td }} />
                    ))}
                  </tbody>
                </table>
              )}
            </div>

            <FormControlLabel
              control={
                <Checkbox
                  checked={overwriteExisting}
                  onChange={(e) => setOverwriteExisting(e.target.checked)}
                />
              }
              label={T.translate('case.transcriptNotes.preview.overwriteExisting', {
                defaultValue: `Overwrite ${existingAnnotationsForCurrentUserCount} existing notes for user: ${onBehalfOfUserEmail}`,
              })}
            />

            {onBehalfOfUserId === currentUserId && existingCurrentUserIdNotes.length > 0 && (
              <Box mt={2}>
                {loadingExistingNotes ? (
                  <Typography variant="body2">
                    {T.translate('case.transcriptNotes.preview.loadingExistingNotes')}
                  </Typography>
                ) : (
                  <>
                    {overwriteExisting && (
                      <>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 1 }}>
                          {T.translate('transcriptNotes.existing.title', {
                            defaultValue: 'Existing Notes',
                          })}
                        </Typography>
                        <div className={classes.tableContainer}>
                          <table className={classes.table}>
                            <thead>
                              <tr>
                                <th className={classes.th}>
                                  {T.translate('case.transcriptNotes.preview.timestampHeader', {
                                    defaultValue: 'Timestamp',
                                  })}
                                </th>
                                <th className={classes.th}>
                                  {T.translate('case.transcriptNotes.preview.noteHeader', {
                                    defaultValue: 'Note',
                                  })}
                                </th>
                                <th className={classes.th}>
                                  {T.translate('case.transcriptNotes.preview.pageNumber', {
                                    defaultValue: 'Page number',
                                  })}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {existingCurrentUserIdNotes.map((note: any, index: any) => (
                                <tr key={index}>
                                  <td className={classes.td}>
                                    {note.annotation?.selectedText || '(no timestamp)'}
                                  </td>
                                  <td className={classes.td}>
                                    {note.annotation?.data?.comment || '(no note)'}
                                  </td>
                                  <td className={classes.td}>
                                    {note.annotation?.pageNumber || '(no page)'}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </>
                    )}
                  </>
                )}
              </Box>
            )}
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onDialogClose} disabled={loading} sx={{ color: '#748CEC' }}>
          {T.translate('generic.cancel', { defaultValue: 'Cancel' })}
        </Button>
        {candidateNotes.length === 0 ? (
          <Button
            variant="contained"
            onClick={handleSubmit(onSubmitPreview)}
            disabled={!onBehalfOfUserId}
            color="primary"
          >
            {!loading ? (
              T.translate('generic.preview', { defaultValue: 'Preview' })
            ) : (
              <Spinner style={{ height: '1.5rem' }} />
            )}
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={handleCreateNotes}
            disabled={loading || candidateNotes.length === 0}
            color="primary"
          >
            {!loading ? (
              T.translate('case.transcriptNotes.preview.createButton', {
                defaultValue: 'Create',
              })
            ) : (
              <Spinner style={{ height: '1.5rem' }} />
            )}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default PreviewTranscriptNotes;
