import React, { useState, useEffect } from 'react';
import T from 'i18n';
import * as actions from 'features/case/redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { PageNotFound, Spinner } from 'features/common';
import { makeStyles } from '@mui/styles';
import { selectCurrentCaseTeams, selectIsAdmin, selectIsCaseAdmin } from 'common/selectors';
import { useStyles } from './TeamAdmin';
import TeamCard from './TeamCard';
import CaseAdminModal from './CaseAdminModal';
import { caseAdminModalActions } from './enums';

const defaultSmallScrrenSize = 991;

const useCaseAdminStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    padding: '1.5rem 2.5rem',
  },
}));

const CaseAdmin = () => {
  const classes = useStyles();
  const caseAdminClasses = useCaseAdminStyles();
  const dispatch = useDispatch();
  const [screenWidth, setScreenWidth] = useState('');
  const teams = useSelector(selectCurrentCaseTeams);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalAction, setModalAction] = useState('');
  const isSystemAdmin = useSelector(selectIsAdmin);
  const isCaseAdmin = useSelector(selectIsCaseAdmin);

  const handleShow = (action, team) => {
    setShowModal(true);
    setModalAction(action);
    setSelectedTeam(team);
  };

  const handleSave = (action, data) => {
    switch (action) {
      case caseAdminModalActions.addNewTeam:
        dispatch(actions.createTeam({ team: data }));
        break;
      case caseAdminModalActions.removeTeam:
        dispatch(actions.removeTeam({ teamId: data }));
        break;
      default:
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setModalAction('');
  };

  const onMount = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(onMount, []);

  if (!isSystemAdmin && !isCaseAdmin) return <PageNotFound />;

  return teams ? (
    <div className={caseAdminClasses.root}>
      <Grid container spacing={2} className={classes.fullsize}>
        <Grid item xs={12}>
          {screenWidth >= defaultSmallScrrenSize ? (
            <Button
              type="button"
              variant="contained"
              color="primary"
              style={{
                float: 'right',
              }}
              startIcon={<AddIcon />}
              onClick={() => handleShow(caseAdminModalActions.addNewTeam)}
            >
              {T.translate('case.addNewTeam')}
            </Button>
          ) : (
            <AddCircleIcon
              className={classes.inviteIconButton}
              fontSize="large"
              color="primary"
              onClick={() => handleShow(caseAdminModalActions.addNewTeam)}
            />
          )}
        </Grid>
        <Grid container item xs={12} className={classes.teamGrid} justifyContent={'center'}>
          {teams &&
            teams.map((team, idx) => (
              <Grid key={idx} item xs={screenWidth >= defaultSmallScrrenSize ? 2 : 3}>
                <TeamCard team={team} handleShow={handleShow} />
              </Grid>
            ))}
        </Grid>
      </Grid>
      <CaseAdminModal
        show={showModal}
        action={modalAction}
        handleSave={handleSave}
        handleClose={handleClose}
        team={selectedTeam}
      />
    </div>
  ) : (
    <Spinner />
  );
};

export default CaseAdmin;
