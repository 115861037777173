import {
  CASE_FETCH_TEAM_BEGIN,
  CASE_FETCH_TEAM_SUCCESS,
  CASE_FETCH_TEAM_FAILURE,
  CASE_FETCH_TEAM_DISMISS_FEEDBACK,
} from './constants';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';

export const fetchTeam = createPromiseAction(CASE_FETCH_TEAM_BEGIN, (payload) => ({
  teamId: payload,
}));

export function dismissFetchTeamFeedback() {
  return {
    type: CASE_FETCH_TEAM_DISMISS_FEEDBACK,
  };
}

export function* doFetchTeam(action) {
  const {
    payload: { caseId, teamId },
  } = action;

  let res = yield call(api.get, `/cases/${caseId}/teams/${teamId}`);

  if (res && res.error) {
    yield put({
      type: CASE_FETCH_TEAM_FAILURE,
      feedback: { message: 'feedback.fetchTeamFailure', error: res.error, retryAction: action },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_FETCH_TEAM_SUCCESS,
    data: { team: res },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchFetchTeam() {
  yield takeLatest(fetchTeam, withCurrentCaseId(doFetchTeam));
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_FETCH_TEAM_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        fetchTeamPending: true,
        fetchTeamFeedback: null,
      };

    case CASE_FETCH_TEAM_SUCCESS:
      // The request is success
      return {
        ...state,
        fetchTeamPending: false,
        fetchTeamFeedback: action.feedback,
        team: action.data.team,
      };

    case CASE_FETCH_TEAM_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchTeamPending: false,
        fetchTeamFeedback: action.feedback,
      };

    case CASE_FETCH_TEAM_DISMISS_FEEDBACK:
      // Dismiss the request failure Feedback
      return {
        ...state,
        fetchTeamFeedback: null,
      };

    default:
      return state;
  }
}
