import React from 'react';
import Form from 'react-bootstrap/Form';
import T from 'i18n';
import { objectValueFromPath } from 'utils/object';
// import PropTypes from 'prop-types';

export const getErrorFeedback = (name, errors) => {
  const error = objectValueFromPath(name, errors);

  if (error === undefined) return null;

  return error.type === 'required'
    ? error.message || T.translate('generic.fieldIsRequired')
    : error.type === 'invalid link'
      ? T.translate('generic.provideAValidLink')
      : error.type === 'isInvalidEmail'
        ? T.translate('generic.invalidEmail')
        : error.type === 'isExistingUser'
          ? T.translate('generic.userExists')
          : error.type === 'isValidDate'
            ? T.translate('generic.notAValidDate')
            : error.type === 'isValidTime'
              ? T.translate('generic.notAValidTime')
              : error.type === 'pattern' && name === 'name'
                ? T.translate('case.notValidBundleName')
                : T.translate('generic.notAValidEntry');
};

export default function ErrorFeedback({ name, errors, style = undefined }) {
  const value = getErrorFeedback(name, errors);
  return value ? (
    <Form.Control.Feedback type="invalid" style={style}>
      {value}
    </Form.Control.Feedback>
  ) : null;
}

ErrorFeedback.propTypes = {};
ErrorFeedback.defaultProps = {};
