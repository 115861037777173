import { Mark } from '@prizmdoc/viewer-core';

export const PRESENT_COLOR = '#a1f4a5';
export const REGULAR_COLOR = '#ffdfba';
export const LINK_COLOR = '#d57200';
export const SHARED_COLOR = '#FE6B64';
export const SELECTION_COLOR = '#B2C3EB';

// eslint-disable-next-line no-undef
export const HIGHLIGHTSDISAPPEAR = process.env.REACT_APP_VIEWER_DISAPPEAR_HIGHLIGHTS === 'true';

export const SCROLL_MARK_TYPE = Mark.Type.TextSignature;
export const PAGENUMBER_MARK_TYPE = Mark.Type.TextRedaction;
