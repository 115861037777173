import { call, put, takeLatest } from 'redux-saga/effects';
import {
  VIEWING_FETCH_FILE_LOCATION_BEGIN,
  VIEWING_FETCH_FILE_LOCATION_SUCCESS,
  VIEWING_FETCH_FILE_LOCATION_FAILURE,
  VIEWING_FETCH_FILE_LOCATION_DISMISS_FEEDBACK,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from 'common/selectors';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

export const fetchFileLocation = createPromiseAction(VIEWING_FETCH_FILE_LOCATION_BEGIN);

export function dismissFetchFileLocationFeedback() {
  return {
    type: VIEWING_FETCH_FILE_LOCATION_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_FETCH_FILE_LOCATION_BEGIN actions
export function* doFetchFileLocation(action) {
  const {
    payload: { caseId, fileId, original },
  } = action;
  const res = yield call(
    api.get,
    `/cases/${caseId}/files/${fileId}/location${original ? `?original=true` : ''}`,
  );

  if (res && res.error) {
    yield put({
      type: VIEWING_FETCH_FILE_LOCATION_FAILURE,
      feedback: {
        message: 'feedback.fetchFileLocationFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }
  // Dispatch success action out of try/catch so that render errors are not catched.
  yield put({
    type: VIEWING_FETCH_FILE_LOCATION_SUCCESS,
    data: res,
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchFetchFileLocation() {
  yield takeLatest(fetchFileLocation, withCurrentCaseId(doFetchFileLocation));
}

export function useFetchFileLocation() {
  const dispatch = useDispatch();

  const { fetchFileLocationPending, fetchFileLocationFeedback } = useSelector(
    (state) => ({
      fileLocation: state.viewing.fileLocation,
      fetchFileLocationPending: state.viewing.fetchFileLocationPending,
      fetchFileLocationFeedback: state.viewing.fetchFileLocationFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(fetchFileLocation(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissFetchFileLocationFeedback());
  }, [dispatch]);

  return {
    fetchFileLocation: boundAction,
    fetchFileLocationPending,
    fetchFileLocationFeedback,
    dismissFetchFileLocationFeedback: boundDismissFeedback,
  };
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case VIEWING_FETCH_FILE_LOCATION_BEGIN + '.TRIGGER':
      return {
        ...state,
        fetchFileLocationPending: true,
        fetchFileLocationFeedback: null,
      };

    case VIEWING_FETCH_FILE_LOCATION_SUCCESS:
      return {
        ...state,
        fetchFileLocationPending: false,
        fileLocation: action.data,
        fetchFileLocationFeedback: action.feedback,
      };

    case VIEWING_FETCH_FILE_LOCATION_FAILURE:
      return {
        ...state,
        fetchFileLocationPending: false,
        fetchFileLocationFeedback: action.feedback,
      };

    default:
      return state;
  }
}
