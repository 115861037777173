import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import NavBarLinks from 'features/common/NavBarLinks';
import NavBar from 'features/common/NavBar';
import withAuth from 'features/auth/withAuth';
import SidebarCaseAdmin from './SidebarCaseAdmin';
import {
  selectIsAuthenticated,
  selectIsAdmin,
  selectIsTeamAdmin,
  selectCurrentCase,
  selectIsCaseAdmin,
} from 'common/selectors';
import { Footer, PageNotFound, Spinner } from 'features/common';
import CaseSettingsButton from './CaseSettingsButton';
import SearchProvider from 'features/common/Search/SearchProvider';
import equal from 'react-fast-compare';

let searchRef = React.createRef();

const CaseAdminLayout = ({ children }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const currentCase = useSelector(selectCurrentCase);
  const isTeamAdmin = useSelector(selectIsTeamAdmin);
  const isCaseAdmin = useSelector(selectIsCaseAdmin);
  const isSystemAdmin = useSelector(selectIsAdmin);
  const [searchDetails, setSearchDetails] = useState(null);
  const [searchFunctions, setSearchFunctions] = useState(null);
  const fetchCaseDetailsPending = useSelector((state) => state.case.fetchCaseDetailsPending);
  const fetchCasesPending = useSelector((state) => state.home.fetchCasesPending);

  const searchHandler = (val) => {
    if (!equal(val, searchDetails)) setSearchDetails({ ...val });
  };

  const searchFunctionsHandler = (val) => {
    setSearchFunctions({ ...val });
  };

  if (fetchCasesPending || fetchCaseDetailsPending) return null;

  if (!isSystemAdmin && !isCaseAdmin) return <PageNotFound />;

  return (
    <>
      {isAuthenticated && currentCase.id ? (
        <div className="case-layout">
          <SidebarCaseAdmin
            isSidebarOpen={true}
            NavBarLinks={
              <NavBarLinks
                additionalButton={
                  <CaseSettingsButton
                    isTeamAdmin={isTeamAdmin}
                    isSystemAdmin={isSystemAdmin}
                    isCaseAdmin={isCaseAdmin}
                  />
                }
              />
            }
          />
          <div className="main-panel">
            <NavBar
              ref={(el) => (searchRef = el && el.searchRef)}
              hasSearch={!!searchDetails}
              search={searchDetails}
              searchFunctions={searchFunctions}
              brandText={
                currentCase.name + `${currentCase.claimNumber ? ` ${currentCase.claimNumber}` : ''}`
              }
              brandLink={{ currentCaseId: currentCase.id }}
              showBackIcon={true}
              additionalButton={
                <CaseSettingsButton
                  isTeamAdmin={isTeamAdmin}
                  isSystemAdmin={isSystemAdmin}
                  isCaseAdmin={isCaseAdmin}
                />
              }
            />
            <SearchProvider
              searchHandler={searchHandler}
              searchFunctionsHandler={searchFunctionsHandler}
              clearSearch={searchRef}
            >
              <div className="content">{children}</div>
            </SearchProvider>
            <Footer />
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default withAuth(CaseAdminLayout, { forceLogin: true });
