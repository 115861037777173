import {
  COMMON_FETCH_BOOKMARKS_BEGIN,
  COMMON_FETCH_BOOKMARKS_SUCCESS,
  COMMON_FETCH_BOOKMARKS_FAILURE,
  COMMON_FETCH_BOOKMARKS_DISMISS_FEEDBACK,
  COMMON_ADD_BOOKMARK_BEGIN,
  COMMON_ADD_BOOKMARK_SUCCESS,
  COMMON_ADD_BOOKMARK_FAILURE,
  COMMON_ADD_BOOKMARK_DISMISS_FEEDBACK,
  COMMON_DELETE_BOOKMARK_BEGIN,
  COMMON_DELETE_BOOKMARK_SUCCESS,
  COMMON_DELETE_BOOKMARK_FAILURE,
  COMMON_DELETE_BOOKMARK_DISMISS_FEEDBACK,
} from './constants';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from 'common/selectors';

export const fetchBookmarks = createPromiseAction(COMMON_FETCH_BOOKMARKS_BEGIN);

export const addBookmark = createPromiseAction(COMMON_ADD_BOOKMARK_BEGIN);

export const deleteBookmark = createPromiseAction(COMMON_DELETE_BOOKMARK_BEGIN);

export function dismissFetchBookmarksFeedback() {
  return {
    type: COMMON_FETCH_BOOKMARKS_DISMISS_FEEDBACK,
  };
}

export function dismissAddBookmarkFeedback() {
  return {
    type: COMMON_ADD_BOOKMARK_DISMISS_FEEDBACK,
  };
}

export function dismissDeleteBookmarkFeedback() {
  return {
    type: COMMON_DELETE_BOOKMARK_DISMISS_FEEDBACK,
  };
}

export function* doFetchBookmarks(action) {
  const { type, caseId } = action.payload;
  let res = yield call(api.get, `/cases/${caseId}/bookmarks/${type}`);

  if (res && res.error) {
    yield put({
      type: COMMON_FETCH_BOOKMARKS_FAILURE,
      feedback: {
        message: 'feedback.fetchBookmarksFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: COMMON_FETCH_BOOKMARKS_SUCCESS,
    data: {
      bookmarks: res.map((bookmark) => {
        return {
          ...bookmark,
          filterCategory: {
            ...bookmark.filterCategory,
            dateFilter: !!(bookmark.filterCategory?.dateTo || bookmark.filterCategory?.dateFrom),
          },
        };
      }),
    },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* doAddBookmark(action) {
  const { type, filterCategory, name, caseId } = action.payload;
  let res = yield call(api.post, `/cases/${caseId}/bookmarks`, {
    type,
    filterCategory,
    name,
  });

  if (res && res.error) {
    yield put({
      type: COMMON_ADD_BOOKMARK_FAILURE,
      feedback: {
        message: 'feedback.addBookmarkFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: COMMON_ADD_BOOKMARK_SUCCESS,
    data: {
      bookmark: {
        ...res,
        filterCategory: {
          ...res.filterCategory,
          dateFilter: !!(res.filterCategory?.dateTo || res.filterCategory?.dateFrom),
        },
      },
    },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* doDeleteBookmark(action) {
  const { id, caseId } = action.payload;
  let res = yield call(api.del, `/cases/${caseId}/bookmarks/${id}`);

  if (res && res.error) {
    yield put({
      type: COMMON_DELETE_BOOKMARK_FAILURE,
      feedback: {
        message: 'feedback.deleteBookmarkFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: COMMON_DELETE_BOOKMARK_SUCCESS,
    data: { deletedBookmarkId: id },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchFetchBookmarks() {
  yield takeLatest(fetchBookmarks, withCurrentCaseId(doFetchBookmarks));
}

export function* watchAddBookmark() {
  yield takeLatest(addBookmark, withCurrentCaseId(doAddBookmark));
}

export function* watchDeleteBookmark() {
  yield takeLatest(deleteBookmark, withCurrentCaseId(doDeleteBookmark));
}

export function useBookmarks(type) {
  const dispatch = useDispatch();

  const {
    bookmarks,
    fetchBookmarksPending,
    addBookmarkPending,
    deleteBookmarkPending,
    addBookmarkFeedback,
    deleteBookmarkFeedback,
    fetchBookmarksFeedback,
  } = useSelector(
    (state) => ({
      bookmarks: state.common.bookmarks,
      addBookmarkPending: state.common.addBookmarkPending,
      deleteBookmarkPending: state.common.deleteBookmarkPending,
      fetchBookmarksPending: state.common.fetchBookmarksPending,
      deleteBookmarkFeedback: state.common.deleteBookmarkFeedback,
      addBookmarkFeedback: state.common.addBookmarkFeedback,
      fetchBookmarksFeedback: state.common.fetchBookmarksFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (args) => {
      return dispatch(fetchBookmarks({ type, ...args }));
    },
    [type, dispatch],
  );

  const boundActionAdd = useCallback(
    (args) => {
      return dispatch(addBookmark({ type, ...args }));
    },
    [type, dispatch],
  );

  const boundActionDelete = useCallback(
    (args) => {
      return dispatch(deleteBookmark({ type, ...args }));
    },
    [type, dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissFetchBookmarksFeedback());
  }, [dispatch]);

  const boundDismissAddFeedback = useCallback(() => {
    return dispatch(dismissAddBookmarkFeedback());
  }, [dispatch]);

  const boundDismissDeleteFeedback = useCallback(() => {
    return dispatch(dismissDeleteBookmarkFeedback());
  }, [dispatch]);

  return {
    bookmarks,
    fetchBookmarks: boundAction,
    addBookmark: boundActionAdd,
    deleteBookmark: boundActionDelete,
    fetchBookmarksPending,
    fetchBookmarksFeedback,
    addBookmarkPending,
    addBookmarkFeedback,
    deleteBookmarkPending,
    deleteBookmarkFeedback,
    dismissFetchBookmarksFeedback: boundDismissFeedback,
    dismissAddBookmarkFeedback: boundDismissAddFeedback,
    dismissDeleteBookmarkFeedback: boundDismissDeleteFeedback,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case COMMON_FETCH_BOOKMARKS_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        fetchBookmarksPending: true,
        fetchBookmarksFeedback: null,
      };

    case COMMON_FETCH_BOOKMARKS_SUCCESS:
      // The request is success
      return {
        ...state,
        fetchBookmarksPending: false,
        fetchBookmarksFeedback: action.feedback,
        bookmarks: action.data.bookmarks,
      };

    case COMMON_FETCH_BOOKMARKS_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchBookmarksPending: false,
        fetchBookmarksFeedback: action.feedback,
      };

    case COMMON_FETCH_BOOKMARKS_DISMISS_FEEDBACK:
      // Dismiss the request failure error
      return {
        ...state,
        fetchBookmarksFeedback: null,
      };

    case COMMON_ADD_BOOKMARK_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        addBookmarkPending: true,
        addBookmarkFeedback: null,
      };

    case COMMON_ADD_BOOKMARK_SUCCESS:
      // The request is success
      return {
        ...state,
        addBookmarkPending: false,
        addBookmarkFeedback: action.feedback,
        bookmarks: [...state.bookmarks, action.data.bookmark].sort((a, b) =>
          a.name.localeCompare(b.name),
        ),
      };

    case COMMON_ADD_BOOKMARK_FAILURE:
      // The request is failed
      return {
        ...state,
        addBookmarkPending: false,
        addBookmarkFeedback: action.feedback,
      };

    case COMMON_ADD_BOOKMARK_DISMISS_FEEDBACK:
      // Dismiss the request failure error
      return {
        ...state,
        addBookmarkFeedback: null,
      };

    case COMMON_DELETE_BOOKMARK_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        deleteBookmarkPending: true,
        deleteBookmarkFeedback: null,
      };

    case COMMON_DELETE_BOOKMARK_SUCCESS:
      // The request is success
      return {
        ...state,
        deleteBookmarkPending: false,
        deleteBookmarkFeedback: action.feedback,
        bookmarks: state.bookmarks.filter(({ id }) => id !== action.data.deletedBookmarkId),
      };

    case COMMON_DELETE_BOOKMARK_FAILURE:
      // The request is failed
      return {
        ...state,
        deleteBookmarkPending: false,
        deleteBookmarkFeedback: action.feedback,
      };

    case COMMON_DELETE_BOOKMARK_DISMISS_FEEDBACK:
      // Dismiss the request failure error
      return {
        ...state,
        deleteBookmarkFeedback: null,
      };

    default:
      return state;
  }
}
