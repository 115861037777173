import {
  CASE_FETCH_FILE_RINGS_BEGIN,
  CASE_FETCH_FILE_RINGS_SUCCESS,
  CASE_FETCH_FILE_RINGS_FAILURE,
  CASE_FETCH_FILE_RINGS_DISMISS_FEEDBACK,
} from './constants';
import { useCallback } from 'react';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

export const fetchFileRings = createPromiseAction(CASE_FETCH_FILE_RINGS_BEGIN);

export function dismissFetchFileRingsFeedback() {
  return {
    type: CASE_FETCH_FILE_RINGS_DISMISS_FEEDBACK,
  };
}

export function* doFetchFileRings(action) {
  const { caseId, fileId } = action.payload;

  let res = yield call(api.get, `/cases/${caseId}/files/${fileId}/rings`);

  if (res && res.error) {
    yield put({
      type: CASE_FETCH_FILE_RINGS_FAILURE,
      feedback: {
        message: 'feedback.fetchFileRingsFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_FETCH_FILE_RINGS_SUCCESS,
    data: { fileRings: res },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchFetchFileRings() {
  yield takeLatest(fetchFileRings, withCurrentCaseId(doFetchFileRings));
}

export function useFetchFileRings() {
  const dispatch = useDispatch();

  const { fetchFileRingsPending, fetchFileRingsFeedback } = useSelector(
    (state) => ({
      fetchFileRingsPending: state.case.fetchFileRingsPending,
      fetchFileRingsFeedback: state.case.fetchFileRingsFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(fetchFileRings(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissFetchFileRingsFeedback());
  }, [dispatch]);

  return {
    fetchFileRings: boundAction,
    fetchFileRingsPending,
    fetchFileRingsFeedback,
    dismissFetchFileRingsFeedback: boundDismissFeedback,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_FETCH_FILE_RINGS_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        fetchFileRingsPending: true,
        fetchFileRingsFeedback: null,
      };

    case CASE_FETCH_FILE_RINGS_SUCCESS:
      // The request is success
      return {
        ...state,
        fetchFileRingsPending: false,
        fetchFileRingsFeedback: action.feedback,
      };

    case CASE_FETCH_FILE_RINGS_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchFileRingsPending: false,
        fetchFileRingsFeedback: action.feedback,
      };

    case CASE_FETCH_FILE_RINGS_DISMISS_FEEDBACK:
      // Dismiss the request failure Feedback
      return {
        ...state,
        fetchFileRingsFeedback: null,
      };

    default:
      return state;
  }
}
