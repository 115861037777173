import React, { useEffect } from 'react';
import T from 'i18n';
import { Button, IconButton, Skeleton } from '@mui/material';
import { getExportLink } from './utils';
import { BatchUploadReport } from './types';
import CloseIcon from '@mui/icons-material/Close';
import { useFetchNotifications } from '../redux/fetchNotifications';
import { Spinner } from 'features/common';
import { BatchUploadNotification } from 'features/common/types';
import { usePrevious } from 'utils/hooks';

type UploadStatusWindowProps = {
  uploadComplete: boolean;
  uploadInProgress: boolean;
  currentBatchId: number | null;
  batchSize: number;
  caseId: string;
  batchUploadReport: BatchUploadReport | null;
  clearAll: () => any;
};
type UploadCompleteContentProps = {
  caseId: string;
  currentBatchId: number | null;
  batchUploadReport: BatchUploadReport | null;
};

export const UploadCompleteContent = ({
  caseId,
  currentBatchId,
  batchUploadReport,
}: UploadCompleteContentProps) => {
  const prevBatchId = usePrevious(currentBatchId);
  const { clearTransient } = useFetchNotifications();

  useEffect(() => {
    if (prevBatchId !== currentBatchId) {
      clearTransient();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBatchId]);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <h5
        style={{
          textAlign: 'center',
        }}
      >
        {T.translate('case.batchUpload.report.header', {
          success: batchUploadReport?.success,
          failed: batchUploadReport?.failed,
          total: batchUploadReport?.total,
        })}
      </h5>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        onClick={async () => await getExportLink(caseId, currentBatchId)}
      >
        {T.translate('case.batchUpload.report.downloadFullReport')}
      </Button>
    </div>
  );
};

const UploadStatusWindow = ({
  uploadComplete,
  caseId,
  currentBatchId,
  uploadInProgress,
  batchUploadReport,
  batchSize,
  clearAll,
}: UploadStatusWindowProps) => {
  const { transientNotifications } = useFetchNotifications();
  const batchNotifications = transientNotifications?.filter(
    (notification: BatchUploadNotification) => {
      return notification?.type === 'batch-processing-file' && notification?.type !== null;
    },
  );

  return (
    <section className="case-file-drop-zone">
      <div
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        {!uploadInProgress && uploadComplete && (
          <IconButton onClick={() => clearAll()} style={{ position: 'absolute', right: 0, top: 0 }}>
            <CloseIcon style={{ height: 30, width: 30 }} />
          </IconButton>
        )}

        {uploadInProgress && !uploadComplete && (
          <>
            <>
              <h3 style={{ color: '#908f90', textAlign: 'center' }}>
                {T.translate('case.batchUpload.processing', {
                  batchSize,
                  notifications: batchNotifications?.length,
                })}
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              </h3>
            </>
            <span
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <p
                style={{
                  marginRight: '8px',
                  textAlign: 'center',
                }}
              >
                {(batchNotifications &&
                  batchNotifications[batchNotifications?.length - 1]?.title) ||
                  ''}
              </p>
              <Spinner
                style={{
                  fontSize: '0.4rem',
                  alignSelf: 'center',
                  marginBottom: 10,
                }}
              />
            </span>
          </>
        )}
        {uploadComplete && currentBatchId && batchUploadReport && (
          <UploadCompleteContent
            caseId={caseId}
            currentBatchId={currentBatchId}
            batchUploadReport={batchUploadReport}
          />
        )}
      </div>
    </section>
  );
};

export default UploadStatusWindow;
