import { put, select, takeLatest, call } from 'redux-saga/effects';
import {
  CASE_FETCH_DOCUMENTS_DOC_FINDER_BEGIN,
  CASE_FETCH_DOCUMENTS_DOC_FINDER_SUCCESS,
  CASE_FETCH_DOCUMENTS_DOC_FINDER_SUCCESS_PARTIAL,
  CASE_FETCH_DOCUMENTS_DOC_FINDER_FAILURE,
  CASE_FETCH_DOCUMENTS_DOC_FINDER_DISMISS_FEEDBACK,
  CASE_FETCH_DOCUMENTS_DOC_FINDER_CLEAR,
} from './constants';
import api from 'common/api';
import { withCurrentCaseId, selectCurrentFolder } from 'common/selectors';
import addSortNumberToDocuments from './addSortNumberToDocuments';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import moment from 'moment';

export const fetchDocumentsDocFinder = createPromiseAction(CASE_FETCH_DOCUMENTS_DOC_FINDER_BEGIN);

export function dismissFetchDocumentsDocFinderFeedback() {
  return {
    type: CASE_FETCH_DOCUMENTS_DOC_FINDER_DISMISS_FEEDBACK,
  };
}

export function clearDocumentsDocFinder() {
  return {
    type: CASE_FETCH_DOCUMENTS_DOC_FINDER_CLEAR,
  };
}

// worker Saga: will be fired on CASE_FETCH_DOCUMENTS_DOC_FINDER_BEGIN actions
export function* doFetchDocumentsDocFinder(action) {
  const {
    payload: { caseId, pageSize, pageIndex, presentMode },
  } = action;
  const currentFolder = yield select(selectCurrentFolder);

  const res = yield call(api.post, `/cases/${caseId}/search`, {
    searchType: 'Files',
    searchQuery: action.payload.search,
    filters: {
      fileName: action.payload.fileName,
      authors: action.payload.author || [],
      recipients: action.payload.recipient || [],
      createdBy: action.payload.createdBy || [],
      dateTo: action.payload.dateTo && moment(action.payload.dateTo).format('DD/MM/YYYY'),
      dateFrom: action.payload.dateFrom && moment(action.payload.dateFrom).format('DD/MM/YYYY'),
      folders: (action.payload.trialBook || []).map((folder) => folder.id),
      tags: action.payload.tags || [],
      hasAnnotations:
        action.payload.hasAnnotations === 'false'
          ? false
          : action.payload.hasAnnotations === 'true'
            ? true
            : null,
      hasHyperlinks:
        action.payload.hasHyperlinks === 'false'
          ? false
          : action.payload.hasHyperlinks === 'true'
            ? true
            : null,
      privateOnly:
        action.payload.privateOnly === 'false'
          ? false
          : action.payload.privateOnly === 'true'
            ? true
            : null,
    },
  });

  if (res && res.error) {
    yield put({
      type: CASE_FETCH_DOCUMENTS_DOC_FINDER_FAILURE,
      feedback: {
        message: 'feedback.fetchDocumentsFailure',
        params: { folder: currentFolder.name },
        error: res.error,
        id: currentFolder.id,
        retryAction: { feedback: { id: currentFolder.id }, ...action },
      },
    });

    return yield call(rejectPromiseAction, action, res.error);
  }
  // Dispatch success action out of try/catch so that render errors are not catched.
  if ((pageIndex || pageSize) && !currentFolder.id) {
    yield put({
      type: CASE_FETCH_DOCUMENTS_DOC_FINDER_SUCCESS_PARTIAL,
      data: {
        documents: res.results,
        totalDocuments: res.count,
        zeroBasedIndex: currentFolder.zeroBasedIndex,
      },
    });
    return yield call(resolvePromiseAction, action, res);
  }

  yield put({
    type: CASE_FETCH_DOCUMENTS_DOC_FINDER_SUCCESS,
    data: {
      documents: res.nonce || presentMode ? res.results : res,
      zeroBasedIndex: currentFolder.zeroBasedIndex,
    },
  });

  yield call(resolvePromiseAction, action, res);
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchFetchDocumentsDocFinder() {
  yield takeLatest(fetchDocumentsDocFinder, withCurrentCaseId(doFetchDocumentsDocFinder));
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_FETCH_DOCUMENTS_DOC_FINDER_BEGIN + '.TRIGGER':
      return {
        ...state,
        fetchDocumentsPending: true,
        fetchDocumentsFeedback: action.feedback,
        sortDirection: null,
        sortByColumn: null,
      };

    case CASE_FETCH_DOCUMENTS_DOC_FINDER_CLEAR:
      return {
        ...state,
        documents: [],
        totalDocuments: 0,
        authorsList: null,
        recipientsList: null,
        isDeepSearchPerformed: false,
      };

    case CASE_FETCH_DOCUMENTS_DOC_FINDER_SUCCESS_PARTIAL:
      if (state.documents.length >= action.data.totalDocuments) {
        return {
          ...state,
          fetchDocumentsPending: false,
        };
      }
      return {
        ...state,
        fetchDocumentPartial: true,
        fetchDocumentsPending: false,
        fetchDocumentsFeedback: action.feedback,
        documents: addSortNumberToDocuments(
          [...state.documents, ...action.data.documents],
          action.data.zeroBasedIndex,
        ),
        totalDocuments: action.data.totalDocuments,
        isDeepSearchPerformed: false,
      };

    case CASE_FETCH_DOCUMENTS_DOC_FINDER_SUCCESS:
      return {
        ...state,
        fetchDocumentPartial: false,
        fetchDocumentsPending: false,
        fetchDocumentsFeedback: action.feedback,
        documents: addSortNumberToDocuments(action.data.documents, action.data.zeroBasedIndex),
        totalDocuments: action.data.totalDocuments,
        isDeepSearchPerformed: true,
      };

    case CASE_FETCH_DOCUMENTS_DOC_FINDER_FAILURE:
      return {
        ...state,
        fetchDocumentsPending: false,
        fetchDocumentsFeedback: action.feedback,
        isDeepSearchPerformed: false,
      };

    case CASE_FETCH_DOCUMENTS_DOC_FINDER_DISMISS_FEEDBACK:
      return {
        ...state,
        fetchDocumentsFeedback: null,
      };

    default:
      return state;
  }
}
