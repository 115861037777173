/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SideDrawer from './SideDrawer';
import {
  setGlobalFileMetadata,
  setGlobalUploadInProgress,
  setUploadDrawerOpen,
} from 'features/globalUpload/redux/uploadDrawer';
import {
  selectGlobalUploadFileMetadata,
  selectIsUploadDrawerOpen,
  selectUploadDrawerFolder,
} from 'common/selectors';
import UploadFileContent from './TableWrapper/UploadFileContent';
import { BatchUploadReport, FileMetaData } from './TableWrapper/types';
import { usePrevious } from 'utils/hooks';
import { Alert } from '@mui/material';
import T from 'i18n';

const UploadDrawerWrapper = () => {
  const dispatch = useDispatch();
  const [uploadComplete, setUploadComplete] = useState(false);
  const [batchUploading, setBatchUploading] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [batchUploadReport, setBatchUploadReport] = useState<BatchUploadReport>(null);
  const [currentBatchId, setCurrentBatchId] = useState<number | null>(null);
  const [showSidebar, setShowSideBar] = useState(false);
  const [batchSize, setBatchSize] = useState(0);

  const _fileMetadata = useSelector(selectGlobalUploadFileMetadata);
  const [fileMetadata, setFileMetadata] = useState<FileMetaData[]>(_fileMetadata);

  const uploadFolder = useSelector(selectUploadDrawerFolder);
  const previousUploadFolder = usePrevious(uploadFolder);
  const showUploadDrawer = useSelector(selectIsUploadDrawerOpen);

  const _setFileMetadata = (files: FileMetaData[]) => dispatch(setGlobalFileMetadata(files));

  const handleCloseSideDrawer = () => {
    if (uploadInProgress || batchUploading) {
      <Alert severity="error">
        {T.translate('global.uploadDrawer.alert', {
          defaultValue: 'Upload in progress. Please wait',
        })}
      </Alert>;
      return;
    }
    dispatch(setUploadDrawerOpen(false, null));
  };

  const clearAll = () => {
    setUploadComplete(false);
    setBatchUploading(false);
    setUploadInProgress(false);
    setBatchUploadReport(null);
    setCurrentBatchId(null);
    setShowSideBar(false);
    setBatchSize(0);
  };

  useEffect(() => {
    clearAll();
  }, []);

  useEffect(() => {
    if (fileMetadata) {
      _setFileMetadata(fileMetadata);
    }
  }, [fileMetadata]);

  useEffect(() => {
    if (previousUploadFolder !== uploadFolder) {
      setFileMetadata([]);
    }
  }, [uploadFolder]);

  useEffect(() => {
    dispatch(setGlobalUploadInProgress(uploadInProgress, uploadComplete));
  }, [uploadInProgress, uploadComplete, batchUploading]);

  return (
    <>
      <SideDrawer
        showSidebar={showUploadDrawer}
        onClose={handleCloseSideDrawer}
        title={T.translate('global.uploadDrawer.title', {
          defaultValue: `Upload Documents to ${uploadFolder?.name}`,
        })}
        global={true}
      >
        <UploadFileContent
          fileMetadata={fileMetadata}
          setFileMetadata={setFileMetadata}
          currentFolderId={uploadFolder?.id}
          showSidebar={showSidebar}
          uploadComplete={uploadComplete}
          setUploadComplete={setUploadComplete}
          setBatchUploading={setBatchUploading}
          setCurrentBatchId={setCurrentBatchId}
          setUploadInProgress={setUploadInProgress}
          uploadInProgress={uploadInProgress}
          setBatchUploadReport={setBatchUploadReport}
          currentBatchId={currentBatchId}
          setShowSideBar={setShowSideBar}
          setBatchSize={setBatchSize}
          folder={uploadFolder}
          batchSize={batchSize}
          batchUploadReport={batchUploadReport}
          containStatusWindow={true}
        />
      </SideDrawer>
    </>
  );
};

export default UploadDrawerWrapper;
