import { selectDocuments } from 'common/selectors';
import React, { useCallback, useRef, useEffect, useContext } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';

// Create a context to pass the data length around
export const ScrollDataContext = React.createContext<{ dataLength: number }>({ dataLength: 0 });

// Create the CustomScrollbars component that will be used by CustomScrollbarsVirtualList
export const CustomScrollbars = ({
  onScroll,
  style,
  children,
  ...rest
}: {
  onScroll?: () => void;
  style?: React.CSSProperties;
  children: React.ReactNode;
  [key: string]: any;
}) => {
  const scrollbarRef = useRef<any>(null);

  return (
    <PerfectScrollbar
      containerRef={(ref) => {
        scrollbarRef.current = ref;
      }}
      style={style}
      options={{ minScrollbarLength: 20 }}
      onScroll={onScroll}
      {...rest}
    >
      {children}
    </PerfectScrollbar>
  );
};

// The main component that will be used as outerElementType for List
const CustomScrollbarsVirtualList = React.forwardRef<any, any>((props, ref) => {
  const { children, style, onScroll, ...rest } = props;
  const documents = useSelector(selectDocuments);
  const scrollbarRef = useRef<any>(null);

  // Get dataLength from context or provide a fallback
  const { dataLength = 0 } = useContext(ScrollDataContext);

  // Track previous dataLength to detect changes
  const prevDataLengthRef = useRef<number>(dataLength);

  // Reset scroll when filtered data is small
  useEffect(() => {
    if (
      dataLength !== prevDataLengthRef.current &&
      dataLength > 0 &&
      dataLength < 10 &&
      scrollbarRef.current
    ) {
      scrollbarRef.current.scrollTop = 0;
    }
    prevDataLengthRef.current = dataLength;
  }, [dataLength]);

  const refSetter = useCallback(
    (scrollbarsRef) => {
      if (scrollbarsRef) {
        // Store the scrollbar reference
        scrollbarRef.current = scrollbarsRef;

        // Handle URL hash-based scrolling
        const currentUrlHashVal = window.location.hash;
        const hashFileId = currentUrlHashVal.split('#')[1];
        const fileIndex = documents.findIndex((item: any) => item.id === hashFileId);

        if (fileIndex > 10) {
          scrollbarsRef.scrollTop = 37 * (fileIndex - 10);
        }

        // Forward the ref
        if (ref) {
          if (typeof ref === 'function') {
            ref(scrollbarsRef);
          } else {
            ref.current = scrollbarsRef;
          }
        }
      }
    },
    [ref, documents],
  );

  return (
    <PerfectScrollbar
      containerRef={refSetter}
      style={style}
      options={{ minScrollbarLength: 20 }}
      onScroll={onScroll}
      {...rest}
    >
      {children}
    </PerfectScrollbar>
  );
});

export default CustomScrollbarsVirtualList;
