import { useState, useEffect, useRef, useCallback } from 'react';

export const useRefState = (initialValue: any) => {
  const [state, setState] = useState(initialValue);
  const stateRef = useRef(state);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);
  return [state, stateRef, setState];
};

export const usePrevious = (value: any, defaultValue = {}) => {
  const ref = useRef(defaultValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export function useFunctionAsState(fn: any) {
  const [val, setVal] = useState(() => fn);

  const setFunc = useCallback((fn) => {
    setVal(() => fn);
  }, []);

  return [val, setFunc];
}

interface UseHideOnMouseStopProps {
  delay?: number;
  hideCursor?: boolean;
  initialHide?: boolean;
  showOnlyOnContainerHover?: boolean;
}

type UseHideOnMouseStopReturn = [boolean, () => void, () => void];

export const useHideOnMouseStop = ({
  delay = 2000,
  hideCursor = false,
  initialHide = false,
  showOnlyOnContainerHover = false,
}: UseHideOnMouseStopProps): UseHideOnMouseStopReturn => {
  const timer = useRef<NodeJS.Timeout>();
  const [hide, setHide] = useState(initialHide);
  const [hover, setHover] = useState(false);
  const toggleVisibility = useCallback(
    (hide: boolean, cursor: string) => {
      setHide(hide);
      if (hideCursor) {
        document.body.style.cursor = cursor;
      }
    },
    [hideCursor],
  );
  const onMouseEnter = useCallback(() => setHover(true), [setHover]);
  const onMouseLeave = useCallback(() => setHover(false), [setHover]);
  const onMouseMove = useCallback(() => {
    timer.current && clearTimeout(timer.current);

    if (hide) {
      if (showOnlyOnContainerHover && hover) {
        toggleVisibility(!hide, 'default');
      } else if (!showOnlyOnContainerHover) {
        toggleVisibility(!hide, 'default');
      }
    }

    timer.current = setTimeout(() => {
      if (!hover) {
        toggleVisibility(true, 'none');
      }
    }, delay);
  }, [hide, hover, delay, showOnlyOnContainerHover, toggleVisibility]);

  useEffect(() => {
    window.addEventListener('mousemove', onMouseMove);

    return () => {
      window.removeEventListener('mousemove', onMouseMove);
    };
  }, [onMouseMove]);

  return [hide, onMouseEnter, onMouseLeave];
};
