import React from 'react';
import { Route } from 'react-router';
import { useStore } from 'react-redux';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import T from 'i18n';

const CustomRoute = React.memo(
  ({ component: Component, render, computedMatch, routeChanged, title, ...rest }) => {
    const store = useStore();
    store.dispatch(routeChanged({ ...computedMatch, location: rest.location }));

    // setting page title
    document.title =
      typeof title === 'function'
        ? title(computedMatch.params)
        : T.translate(title) || document.title;

    return (
      <Route
        {...rest}
        render={(props) => {
          return render ? render(props) : <Component {...props} />;
        }}
      />
    );
  },
);

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    routeChanged: bindActionCreators({ ...actions }, dispatch).routeChanged,
  };
}

export default connect(null, mapDispatchToProps)(CustomRoute);
