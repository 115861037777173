import React from 'react';
import Cognito from './Cognito';

const cognitoSettings = {
  aws_cognito_identity_pool_id: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  aws_user_pools_id: process.env.REACT_APP_COGNITO_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_USER_POOLS_WEB_CLIENT_ID,
};

export const Authenticated = React.memo(({ forceLogin, children }) => {
  return (
    <Cognito forceLogin={forceLogin} cognitoSettings={cognitoSettings}>
      {children}
    </Cognito>
  );
});

export default function withAuth(WrappedComponent, props) {
  return (innerProps) => (
    <Authenticated {...props}>
      <WrappedComponent {...innerProps} />
    </Authenticated>
  );
}
