import React, { useEffect, useMemo, useState } from 'react';
import T from 'i18n';
import {
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Menu,
  useMediaQuery,
  Button,
  CircularProgress,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import { selectEnableNotifications, selectFolderContainers } from 'common/selectors';
import { useFetchNotifications } from 'features/case/redux/fetchNotifications';
import { NotificationsOutlined } from '@mui/icons-material';
import { useUpdateNotifications } from 'features/case/redux/updateNotifications';
import NotificationItem from './NotificationItem';
import { Link } from 'react-router-dom';

const maxBadgeVal = 99;

const useStyles = makeStyles(() => ({
  notificationIcon: {
    padding: '0.5rem',
    '&:hover': {
      color: 'rgba(0,0,0,.7)',
    },
  },
  notificationMenu: {
    '& .MuiMenu-paper': {
      border: '1px solid rgba(0,0,0,.15)',
      borderRadius: '.25rem',
      padding: 0,
      boxShadow: 'none',
      overflow: 'visible',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        right: 10,
        width: 10,
        height: 10,
        backgroundColor: 'white',
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 0,
        borderLeft: '1px solid rgba(0,0,0,.15)',
        borderTop: '1px solid rgba(0,0,0,.15)',
      },
    },
  },
  notificationSubheader: {
    lineHeight: '2.5rem',
    fontSize: '1rem',
    fontWeight: '600',
    padding: 0,
  },
  notificationSubheaderHeading: {
    paddingLeft: '1.3rem',
    paddingRight: '0.3rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  clearAllBtn: {
    color: '#7ba0f4',
    '&:hover': {
      color: '#0619f5',
    },
  },
  notificationItemBgd: {
    backgroundColor: 'rgba(108, 103, 129, 0.1)',
  },
}));

// eslint-disable-next-line react/prop-types
const Notifications = ({ mainTheme }) => {
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [spinnerAnchorEl, setSpinnerAnchorEl] = useState(null);

  const classes = useStyles();
  const { fetchNotifications, notifications, transientNotifications } = useFetchNotifications();
  const { updateNotifications } = useUpdateNotifications();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isNotificationsEnabled = useSelector(selectEnableNotifications);
  const [anchorEl, setAnchorEl] = useState(null);
  const containers = useSelector(selectFolderContainers);

  const spinnerMenuOpen = Boolean(spinnerAnchorEl);
  const open = Boolean(anchorEl);
  const newNotificationsCount =
    (notifications && notifications.filter((itm) => !itm.read).length) || 0;

  const groupedByFolderId = useMemo(() => {
    if (!transientNotifications || transientNotifications.length === 0) return {};
    const map = {};
    transientNotifications.forEach((notif) => {
      if (notif.type === 'batch-processing-file') {
        const fId = notif.folderId ?? 'no-folder-id';
        if (!map[fId]) {
          map[fId] = [];
        }
        map[fId].push(notif);
      }
    });
    return map;
  }, [transientNotifications]);
  const handleSpinnerClick = (event) => {
    setSpinnerAnchorEl(event.currentTarget);
  };

  const handleSpinnerClose = (event) => {
    event.stopPropagation();
    setSpinnerAnchorEl(null);
  };

  const clearAllHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    updateNotifications({ data: { hide: true } });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    fetchNotifications();
  };

  const handleClose = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(null);
  };

  const getFolderPathById = (folderId) => {
    if (!containers || !folderId) {
      return { path: [], codePath: [] };
    }

    function findFolderNamePath(folderList, targetId, currentPath = []) {
      for (const folder of folderList) {
        if (folder.id === targetId || folder.code === targetId) {
          return [...currentPath, folder.name];
        }
        // Recurse on subfolders
        if (folder.folders && folder.folders.length > 0) {
          const subPath = findFolderNamePath(folder.folders, targetId, [
            ...currentPath,
            folder.name,
          ]);
          if (subPath) {
            return subPath;
          }
        }
      }
      return null; // Not found
    }

    for (const container of containers) {
      if (container.folders && container.folders.length > 0) {
        const namePath = findFolderNamePath(container.folders, folderId, [container.name]);

        if (namePath) {
          return {
            path: namePath,
          };
        }
      }
    }

    return { path: [], codePath: [] };
  };

  useEffect(() => {
    return () => {
      updateNotifications({ data: { hide: true } });
    };
  }, [updateNotifications]);

  useEffect(() => {
    if (isNotificationsEnabled) {
      fetchNotifications();
    }
  }, [isNotificationsEnabled, fetchNotifications]);

  useEffect(() => {
    if (isNotificationsEnabled && transientNotifications && transientNotifications.length > 0) {
      setUploadInProgress(transientNotifications.some((notification) => notification.entityId > 0));
    } else {
      setUploadInProgress(false);
    }
  }, [transientNotifications, isNotificationsEnabled]);

  return (
    isNotificationsEnabled && (
      <>
        {uploadInProgress && transientNotifications?.length > 0 && (
          <>
            <IconButton
              id="spinner-icon-btn"
              disableRipple
              onClick={handleSpinnerClick}
              // className={classes.notificationIcon}
              sx={{
                ...((mainTheme === 'dark' || mainTheme === 'blue') && { color: 'white' }),
              }}
            >
              <CircularProgress style={{ width: '22px', height: '22px', color: 'darkgrey' }} />
            </IconButton>
            <Menu
              anchorEl={spinnerAnchorEl}
              id="spinner-menu"
              open={spinnerMenuOpen}
              onClose={handleSpinnerClose}
              className={classes.notificationMenu}
              PaperProps={{
                sx: {
                  width: isMobile ? '70vw' : '25vw',
                },
              }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
              <Box>
                <List
                  suppressScrollX
                  dense
                  subheader={
                    <ListSubheader className={classes.notificationSubheader}>
                      <span className={classes.notificationSubheaderHeading}>
                        {T.translate('case.batchUpload.inProgressTitle', {
                          defaultValue: 'Upload in Progress',
                        })}
                      </span>
                      <Divider />
                    </ListSubheader>
                  }
                >
                  {Object.keys(groupedByFolderId).length > 0 ? (
                    Object.entries(groupedByFolderId).map(([folderId, notifs]) => {
                      const { path } = getFolderPathById(folderId) || {
                        path: [],
                        codePath: [],
                      };
                      const lastNotif = notifs[notifs.length - 1];
                      const route = lastNotif.link;
                      return (
                        <React.Fragment key={folderId}>
                          <ListSubheader disableSticky className={classes.notificationSubheader}>
                            <Link to={route} underline="hover" color="inherit" target="_blank">
                              <span
                                style={{ fontSize: '0.95rem', padding: '1rem', color: '#7ba0f4' }}
                              >
                                {T.translate('case.batchUpload.inProgressForFolder', {
                                  folderId,
                                  defaultValue: `Folder: ${path.join(' > ')}`,
                                })}
                              </span>
                            </Link>
                          </ListSubheader>

                          <ListItem>
                            <ListItemText>
                              {notifs[notifs?.length - 1].message} {notifs[notifs?.entityId]} /{' '}
                              {notifs[notifs?.length - 1].batchSize}
                            </ListItemText>
                          </ListItem>

                          <Divider />
                        </React.Fragment>
                      );
                    })
                  ) : (
                    <ListItem>
                      <ListItemText>
                        {T.translate('case.batchUpload.noNotification', {
                          defaultValue: 'No active notification',
                        })}
                      </ListItemText>
                    </ListItem>
                  )}
                </List>
              </Box>
            </Menu>
          </>
        )}
        <div style={{ alignSelf: 'center' }}>
          <IconButton
            id="notifications-icon-btn"
            size="large"
            disableRipple
            onClick={handleClick}
            className={classes.notificationIcon}
            sx={{
              ...((mainTheme === 'dark' || mainTheme === 'blue') && { color: 'white' }),
            }}
          >
            <Badge badgeContent={newNotificationsCount} color="primary" max={maxBadgeVal}>
              <NotificationsOutlined />
            </Badge>
          </IconButton>
        </div>
        <Menu
          anchorEl={anchorEl}
          id="notifications-menu"
          open={open}
          onClose={handleClose}
          className={classes.notificationMenu}
          PaperProps={{
            sx: {
              width: isMobile ? '70vw' : '25vw',
            },
          }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          <Box>
            <PerfectScrollbar options={{ suppressScrollX: true }} style={{ maxHeight: '50vh' }}>
              <List
                dense
                subheader={
                  <ListSubheader className={classes.notificationSubheader}>
                    <span className={classes.notificationSubheaderHeading}>
                      {T.translate('case.notifications')}
                      {notifications && notifications.length > 0 && (
                        <Button
                          variant="text"
                          className={classes.clearAllBtn}
                          onClick={(e) => clearAllHandler(e)}
                        >
                          {T.translate('generic.clearAll')}
                        </Button>
                      )}
                    </span>
                    <Divider />
                  </ListSubheader>
                }
              >
                {notifications && notifications.length > 0 ? (
                  notifications.map((item, index) => (
                    <React.Fragment key={item.id || index}>
                      <NotificationItem notificationItem={item} handleClose={handleClose} />
                      {index !== notifications.length - 1 && <Divider />}
                    </React.Fragment>
                  ))
                ) : (
                  <ListItem>
                    <ListItemText>{T.translate('common.noNotificationsMsg')}</ListItemText>
                  </ListItem>
                )}
              </List>
            </PerfectScrollbar>
          </Box>
        </Menu>
      </>
    )
  );
};

export default Notifications;
