import React, { useCallback } from 'react';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import history from 'common/history';
import DropZone from 'features/common/DropZone';
import ItemTitle from './ItemTitle';
import { selectPermissions } from 'common/selectors';
import { assignDocuments } from '../redux/assignDocuments';
import { assignAnnotations } from '../redux/assignAnnotations';
import { fetchFolder } from '../redux/fetchFolder';
import { FileDropZone } from 'features/common';
import { assignEventsToTimeline } from '../redux/assignEventsToTimeline';
import { fetchTimeline, useFetchTimeline } from '../redux/fetchTimeline';

export default ({ folder, to, icon, className, buttonActions }: any) => {
  const dispatch = useDispatch();

  const {
    folders: { canUploadDocuments, canAddDocuments },
  } = useSelector(selectPermissions);

  const { timeline } = useFetchTimeline();

  const handleClick = useCallback(
    async (e) => {
      if (e.shiftKey || e.ctrlKey || to === history.location.pathname) {
        e.preventDefault();
      }

      setTimeout(async () => {
        if (
          folder &&
          folder.id &&
          folder.type !== 'quicklinks' &&
          folder.type !== 'tags' &&
          folder.type !== 'recordings' &&
          folder.type !== 'timelines'
        ) {
          await dispatch(fetchFolder({ folderId: folder.id, noPromise: false }));
        } else if (folder && folder.id && folder.type === 'timelines' && folder.level === 1) {
          await dispatch(fetchTimeline({ timelineId: folder.id }));
        }
      }, 0);
    },
    [to, folder, dispatch],
  );

  return (
    <DropZone
      className="document-drop-zone"
      canDrop={(item: any) => {
        return (
          (!!canAddDocuments(folder) || folder.type === 'tags' || folder.type === 'timelines') &&
          item.folderCode !== folder.code &&
          folder.code !== 'all-documents' &&
          folder.code !== 'all-annotations' &&
          !folder.readOnly
        );
      }}
      onDrop={(item: any) => {
        if (item.type === 'ANNOTATION')
          dispatch(
            assignAnnotations({
              annotationIds: item.documentIds,
              tagId: folder.id,
            }),
          );
        else if (item.type === 'EVENT') {
          const currentEventsList = timeline?.events || [];
          const eventsToCopy = item.documentIds.map((id: any) =>
            currentEventsList.find((event: any) => event.id === id),
          );
          dispatch(assignEventsToTimeline({ eventsToCopy, timelineId: folder.id }));
        } else dispatch(assignDocuments({ documentIds: item.documentIds, folderId: folder.id }));
      }}
      allowedItems={
        folder.type === 'tags' || folder.code === 'all-annotations'
          ? ['ANNOTATION']
          : folder.type === 'timelines'
            ? ['EVENT']
            : ['DOCUMENT']
      }
    >
      <NavLink to={to} onClick={handleClick} className={classnames(className, 'nav-link')}>
        {canUploadDocuments(folder) ? (
          <FileDropZone folderId={folder.id} noInput>
            <ItemTitle folder={folder} icon={icon} buttonActions={buttonActions} />
          </FileDropZone>
        ) : (
          <ItemTitle folder={folder} icon={icon} buttonActions={buttonActions} />
        )}
      </NavLink>
    </DropZone>
  );
};
