import {
  CASE_DELETE_SEARCH_HISTORY_ITEM_BEGIN,
  CASE_DELETE_SEARCH_HISTORY_ITEM_SUCCESS,
  CASE_DELETE_SEARCH_HISTORY_ITEM_FAILURE,
  CASE_DELETE_SEARCH_HISTORY_ITEM_DISMISS_FEEDBACK,
} from './constants';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from 'common/selectors';
import { deleteItemImmutable } from 'utils/arrays';

export const deleteSearchHistoryItem = createPromiseAction(CASE_DELETE_SEARCH_HISTORY_ITEM_BEGIN);

export function dismissDeleteSearchHistoryItemFeedback() {
  return {
    type: CASE_DELETE_SEARCH_HISTORY_ITEM_DISMISS_FEEDBACK,
  };
}

export function* doDeleteSearchHistoryItem(action) {
  const {
    payload: { caseId, searchHistoryItm },
  } = action;

  let res = yield call(api.del, `/cases/${caseId}/search-history/${searchHistoryItm.id}`);

  if (res && res.error) {
    yield put({
      type: CASE_DELETE_SEARCH_HISTORY_ITEM_FAILURE,
      feedback: {
        message: 'feedback.deleteSearchHistoryItemFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_DELETE_SEARCH_HISTORY_ITEM_SUCCESS,
    payload: { searchHistoryItm },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchDeleteSearchHistoryItem() {
  yield takeLatest(deleteSearchHistoryItem, withCurrentCaseId(doDeleteSearchHistoryItem));
}

export function useDeleteSearchHistoryItem() {
  const dispatch = useDispatch();

  const { deleteSearchHistoryItemPending, deleteSearchHistoryItemFeedback } = useSelector(
    (state) => ({
      deleteSearchHistoryItemPending: state.case.deleteSearchHistoryItemPending,
      deleteSearchHistoryItemFeedback: state.case.deleteSearchHistoryItemFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(deleteSearchHistoryItem(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissDeleteSearchHistoryItemFeedback());
  }, [dispatch]);

  return {
    deleteSearchHistoryItem: boundAction,
    deleteSearchHistoryItemPending,
    deleteSearchHistoryItemFeedback,
    dismissDeleteSearchHistoryItemFeedback: boundDismissFeedback,
  };
}

export function reducer(state, action) {
  const getIndex = (itemId) =>
    state.searchHistory && state.searchHistory.findIndex(({ id }) => id === itemId);

  switch (action.type) {
    case CASE_DELETE_SEARCH_HISTORY_ITEM_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        deleteSearchHistoryItemPending: true,
        deleteSearchHistoryItemFeedback: null,
      };

    case CASE_DELETE_SEARCH_HISTORY_ITEM_SUCCESS:
      // The request is success
      return {
        ...state,
        deleteSearchHistoryItemPending: false,
        deleteSearchHistoryItemFeedback: action.feedback,
        searchHistory: deleteItemImmutable(
          state.searchHistory,
          getIndex(action.payload.searchHistoryItm.id),
        ),
      };

    case CASE_DELETE_SEARCH_HISTORY_ITEM_FAILURE:
      // The request is failed
      return {
        ...state,
        deleteSearchHistoryItemPending: false,
        deleteSearchHistoryItemFeedback: action.feedback,
      };

    case CASE_DELETE_SEARCH_HISTORY_ITEM_DISMISS_FEEDBACK:
      // Dismiss the request failure error
      return {
        ...state,
        deleteSearchHistoryItemFeedback: null,
      };

    default:
      return state;
  }
}
