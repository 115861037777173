import { MenuItem, Select } from '@mui/material';
import React, { useState } from 'react';
import T from 'i18n';

const DateFormLabel = ({ isAutoSetDocDate, dates, trigger, setValue }) => {
  const [dateType, setDateType] = useState('');
  const handleChange = (e) => {
    setDateType(e.target.value);
    setValue('docDate', e.target.value.split(' ')[0] || '');
    setValue('docTime', e.target.value.split(' ')[1] || '');
    trigger(['docDate', 'docTime']);
  };

  return (
    isAutoSetDocDate &&
    dates.length > 0 && (
      <div>
        <span>{T.translate('case.autoSetDocDate')} </span>
        <Select
          value={dateType}
          label="Age"
          onChange={handleChange}
          variant="standard"
          autoWidth
          size="small"
          sx={{
            '&::before': { borderBottom: 'none' },
            '&::after': { borderBottom: 'none' },
          }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {dates.length > 0 &&
            dates.map((dt, index) => (
              <MenuItem key={index} value={dt.date}>
                {dt.name + ' - ' + dt.date}
              </MenuItem>
            ))}
        </Select>
      </div>
    )
  );
};

export default DateFormLabel;
