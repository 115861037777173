import React from 'react';
import moment from 'moment';
import Table from 'features/common/Table';

const LoginHistoryTable = ({ data }) => {
  const isItemLoaded = ({ index }) => !!data[index];
  const loadMoreItems = () => {
    return;
  };
  const columns = [
    {
      id: 'date',
      Header: 'common.signInTime',
      Cell: (result) => (
        <div className="customGrid">
          <span>{moment(result.creationDate).format('MMMM DD, YYYY, HH:mm')}</span>
        </div>
      ),
      minWidth: '150px',
      width: '15rem',
      sort: false,
    },
    {
      id: 'city',
      Header: 'common.city',
      Cell: (result) => (
        <div className="customGrid">
          <span>{result.city}</span>
        </div>
      ),
      minWidth: '90px',
      width: '10rem',
      sort: false,
    },
    {
      id: 'country',
      Header: 'common.country',
      Cell: (result) => (
        <div className="customGrid">
          <span>{result.country}</span>
        </div>
      ),
      minWidth: '90px',
      width: '10rem',
      sort: false,
    },
    {
      id: 'deviceName',
      Header: 'common.deviceName',
      Cell: (result) => (
        <div className="customGrid">
          <span style={result.flag ? { color: 'red' } : null}>{result.deviceName}</span>
        </div>
      ),
      minWidth: '200px',
      width: 'calc(100% - 35rem)',
      sort: false,
    },
  ];

  return (
    <div style={{ height: '30rem' }}>
      <Table
        key={Math.random()}
        data={data}
        columns={columns}
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        totalDocumentsCount={0}
        rowHeight={40}
        readOnly
      />
    </div>
  );
};

export default LoginHistoryTable;
