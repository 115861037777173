import React from 'react';
import { suggestedRecipientsMenuOptions } from '../../enums';
import { MenuItem, Select } from '@mui/material';
import T from 'i18n';

const AuthorRecipientLabel = ({
  isAuthor,
  data,
  setValue,
  setSelectedVal,
  value,
  setRecipientsList,
}) => {
  const customSuggestedRecipientsOptions = [
    {
      value: suggestedRecipientsMenuOptions.addAll,
      label: T.translate('case.addAllSuggestedRecipients'),
    },
  ];
  const handleChange = (e) => {
    if (isAuthor) {
      setValue('author', e.target.value);
      setSelectedVal(e.target.value);
    } else setRecipientsList(e.target.value);
  };

  return (
    <div>
      <span>{T.translate(isAuthor ? 'case.suggestedAuthors' : 'case.suggestedRecipients')} </span>
      <Select
        value={value}
        onChange={handleChange}
        variant="standard"
        autoWidth
        size="small"
        sx={{
          '&::before': { borderBottom: 'none' },
          '&::after': { borderBottom: 'none' },
        }}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {data.length > 0 &&
          data.map((dt, index) => (
            <MenuItem key={index} value={dt} disabled={!isAuthor}>
              {dt}
            </MenuItem>
          ))}
        {data.length > 0 &&
          !isAuthor &&
          customSuggestedRecipientsOptions.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </Select>
    </div>
  );
};

export default AuthorRecipientLabel;
