import { put, select, takeEvery, call } from 'redux-saga/effects';
import {
  CASE_FETCH_RECENT_FILES_BEGIN,
  CASE_FETCH_RECENT_FILES_SUCCESS,
  CASE_FETCH_RECENT_FILES_FAILURE,
  CASE_FETCH_RECENT_FILES_DISMISS_FEEDBACK,
} from './constants';
import api from 'common/api';
import { withCurrentCaseId, selectCurrentFolder } from 'common/selectors';
import addSortNumberToDocuments from './addSortNumberToDocuments';

export function fetchRecentFiles() {
  // If need to pass args to saga, pass it with the begin action.
  return {
    type: CASE_FETCH_RECENT_FILES_BEGIN,
  };
}

export function dismissFetchRecentFilesFeedback() {
  return {
    type: CASE_FETCH_RECENT_FILES_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_FETCH_DOCUMENTS_BEGIN actions
export function* doFetchRecentFiles(action) {
  const {
    payload: { caseId },
  } = action;
  const currentFolder = yield select(selectCurrentFolder);

  const res = yield call(
    api.get,
    currentFolder.type === 'quicklinks'
      ? currentFolder.code === 'recently-viewed'
        ? `/cases/${caseId}/files?recentlyViewed=true&pageSize=20`
        : `/cases/${caseId}/files?recentlyPresented=true&pageSize=20`
      : currentFolder.type === 'recordings'
        ? `/cases/${caseId}/files?recordings=true`
        : `/cases/${caseId}/tags/${currentFolder.id}/files`,
  );

  if (res && res.error) {
    return yield put({
      type: CASE_FETCH_RECENT_FILES_FAILURE,
      feedback: {
        message: 'feedback.fetchRecentFilesFailure',
        error: res.error,
      },
    });
  }
  // Dispatch success action out of try/catch so that render errors are not catched.

  yield put({
    type: CASE_FETCH_RECENT_FILES_SUCCESS,
    data: {
      documents: res,
      zeroBasedIndex: currentFolder.zeroBasedIndex,
    },
  });
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchFetchRecentFiles() {
  yield takeEvery(CASE_FETCH_RECENT_FILES_BEGIN, withCurrentCaseId(doFetchRecentFiles));
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_FETCH_RECENT_FILES_BEGIN:
      return {
        ...state,
        fetchDocumentsPending: true,
        fetchDocumentsFeedback: action.feedback,
      };

    case CASE_FETCH_RECENT_FILES_SUCCESS:
      return {
        ...state,
        fetchDocumentsPending: false,
        fetchDocumentsFeedback: action.feedback,
        documents: addSortNumberToDocuments(action.data.documents, action.data.zeroBasedIndex),
        totalDocuments: action.data.totalDocuments,
      };

    case CASE_FETCH_RECENT_FILES_FAILURE:
      return {
        ...state,
        fetchDocumentsPending: false,
        fetchDocumentsFeedback: action.feedback,
      };

    case CASE_FETCH_RECENT_FILES_DISMISS_FEEDBACK:
      return {
        ...state,
        fetchDocumentsFeedback: null,
      };

    default:
      return state;
  }
}
