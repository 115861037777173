import {
  COMMON_FETCH_ALLPERMISSIONS_BEGIN,
  COMMON_FETCH_ALLPERMISSIONS_SUCCESS,
  COMMON_FETCH_ALLPERMISSIONS_FAILURE,
  COMMON_FETCH_ALLPERMISSIONS_DISMISS_FEEDBACK,
} from './constants';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';

export const fetchAllPermissions = createPromiseAction(
  COMMON_FETCH_ALLPERMISSIONS_BEGIN,
  (payload) => ({
    inputData: payload,
  }),
);

export function dismissFetchAllPermissionsFeedback() {
  return {
    type: COMMON_FETCH_ALLPERMISSIONS_DISMISS_FEEDBACK,
  };
}

export function* doFetchAllPermissions(action) {
  let res = yield call(api.get, `/roles/masterRole`);

  if (res && res.error) {
    yield put({
      type: COMMON_FETCH_ALLPERMISSIONS_FAILURE,
      feedback: {
        message: 'feedback.fetchAllPermissionsFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: COMMON_FETCH_ALLPERMISSIONS_SUCCESS,
    data: { permissions: res },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchFetchAllPermissions() {
  yield takeLatest(fetchAllPermissions, doFetchAllPermissions);
}

export function reducer(state, action) {
  switch (action.type) {
    case COMMON_FETCH_ALLPERMISSIONS_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        fetchAllPermissionsPending: true,
        fetchAllPermissionsFeedback: null,
      };

    case COMMON_FETCH_ALLPERMISSIONS_SUCCESS:
      // The request is success
      return {
        ...state,
        fetchAllPermissionsPending: false,
        fetchAllPermissionsFeedback: action.feedback,
        permissions: action.data.permissions,
      };

    case COMMON_FETCH_ALLPERMISSIONS_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchAllPermissionsPending: false,
        fetchAllPermissionsFeedback: action.feedback,
      };

    case COMMON_FETCH_ALLPERMISSIONS_DISMISS_FEEDBACK:
      // Dismiss the request failure error
      return {
        ...state,
        fetchAllPermissionsFeedback: null,
      };

    default:
      return state;
  }
}
