import React, { useRef, useState, useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Tooltip, Badge, Popper, ClickAwayListener } from '@mui/material';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import Filters from 'features/common/Filters';
import debounce from 'utils/debounce';
import T from 'i18n';
import { useDispatch } from 'react-redux';
import { clearSearchedFiles, fetchSearchedFiles } from 'features/case/redux/fetchSearchedFiles';
import { selectClickEnterToPresent } from 'common/selectors';
import { useSelector } from 'react-redux';

interface SearchConfig {
  documents?: any[];
  isAllDocsWithLongList?: boolean;
  isPresentModeWithBackEnd?: boolean;
  isPresentModePage?: boolean;
  isDocFinderPage?: boolean;
  isSearchModePage?: boolean;
  isReady?: boolean;
  placeholder?: string;
  selectedColumns?: string[];
  isClickEnterToPresent?: boolean;
  isAnnotation?: boolean;
  isLoading?: boolean;
  hideFilterOptions?: boolean;
  showGoToPageFilter?: boolean;
  useOnlyIdColForPresentMode?: boolean;
  showDeepSearch?: boolean;
}

interface FiltersState {
  goToGlobalPageIdx: string;
  authors: any[];
  recipients: any[];
  createdBy: any[];
  tags: any[];
  dateFrom: Date | null;
  dateTo: Date | null;
  dateFilter: boolean;
  withAnnotations: boolean;
  withoutAnnotations: boolean;
  public: boolean;
  private: boolean;
  withPublicHyperlinks: boolean;
  withoutPublicHyperlinks: boolean;
  trialBundles: any[];
  shared: boolean;
  notShared: boolean;
}

interface SearchAllFilesProps {
  search?: SearchConfig;
  style?: React.CSSProperties;
  presentView?: boolean;
  hasSearch?: boolean;
  clickToSearch?: boolean;
  hasDocFinderFilter?: boolean;
  setShowDocFinderFilter?: (val: boolean) => void;
  showDocFinderFilter?: boolean;
  isBundleTabPage?: boolean;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  setSearchError: React.Dispatch<React.SetStateAction<string>>;
  setDocsToDisplay: React.Dispatch<React.SetStateAction<any[]>>;
  handleOpenDoc: any;
  docsToDisplay: any[];
}

function SearchAllFiles({
  search,
  style,
  presentView,
  hasSearch = true,
  clickToSearch,
  hasDocFinderFilter,
  setShowDocFinderFilter,
  showDocFinderFilter,
  isBundleTabPage,
  value,
  setValue,
  setSearchError,
  setDocsToDisplay,
  handleOpenDoc,
  docsToDisplay,
}: SearchAllFilesProps) {
  /**
   * State
   */
  const [filters, setFilters] = useState<FiltersState>({
    goToGlobalPageIdx: '',
    authors: [],
    recipients: [],
    createdBy: [],
    tags: [],
    dateFrom: null,
    dateTo: null,
    dateFilter: false,
    withAnnotations: false,
    withoutAnnotations: false,
    public: false,
    private: false,
    withPublicHyperlinks: false,
    withoutPublicHyperlinks: false,
    trialBundles: [],
    shared: false,
    notShared: false,
  });
  const [filtersCount, setFiltersCount] = useState(0);
  const [freshSearch, setFreshSearch] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [filterPosition, setFilterPosition] = useState<HTMLElement | null>(null);

  const isReady = search?.isReady;

  const dispatch = useDispatch();
  const isClickEnterToPresent = useSelector(selectClickEnterToPresent);
  const sfHandler = {
    searchChanged: (term: any) => {},
    deepSearchChanged: (term: any) => {
      dispatch(
        fetchSearchedFiles({
          searchTerm: term,
          isDocFinderPage: true,
          isSearchModePage: false,
          isPresentModeWithBackEnd: false,
        }),
      )
        .then((res: any) => {
          setDocsToDisplay(res.results || []);
        })
        .catch((err: any) => {
          console.error('Error fetching files:', err);
        });
    },

    filterChanged: (filters: any) => {},

    search: (val: any) => {},

    searchNext: () => {},

    clearSearchedFiles: (allDocs: any) => {
      dispatch(clearSearchedFiles());
    },

    presentToHearingRoom: (allDocs: any, val: any) => {},
    presentFirstDoc: (allDocs: any, val: any) => {},
  };
  /**
   * Debounce references
   *
   * We use useRef + function assignment so we don't recreate
   * the debounced functions on each render, preventing re-subscription.
   */
  const debounceFnRef = useRef<((val: string) => void) | null>(null);
  const debounceDeepSearchFnRef = useRef<((val: string) => void) | null>(null);

  // Create the deep search debounced function just once
  if (!debounceDeepSearchFnRef.current) {
    debounceDeepSearchFnRef.current = debounce((val: string) => {
      if (!sfHandler) return;
      if (!val) {
        sfHandler.deepSearchChanged?.('');
        return;
      }
      if (!search) return;

      const { isPresentModeWithBackEnd, isAllDocsWithLongList } = search;
      const length = val.length;

      if (
        (length > 1 && isPresentModeWithBackEnd) ||
        (length > 0 && !isPresentModeWithBackEnd && !isAllDocsWithLongList) ||
        (length > 2 && isAllDocsWithLongList)
      ) {
        sfHandler.deepSearchChanged?.(val);
      }
    }, 500);
  }

  const clearDocumentSelection = useCallback(() => {
    if (search?.documents) {
      search.documents.forEach((doc) => {
        if (doc.selected) {
          doc.selected = false;
        }
      });
    }
  }, [search]);

  const searchHandler = useCallback(() => {
    try {
      if (freshSearch) {
        const doSearch = debounce(() => {
          sfHandler.search?.(value);
          setFreshSearch(false);
        }, 250);
        doSearch();
      } else {
        sfHandler.searchNext?.();
      }
    } catch (error) {
      console.error(error);
      setSearchError(T.translate('case.presentView.errors.searchError'));
    }
  }, [freshSearch, sfHandler, value]);
  const onChangeHandler = useCallback(
    (newVal: string) => {
      setValue(newVal);
      setFreshSearch(true);

      const trimmedValue = newVal.replace(/\s*\/\s*/g, '/');

      if (!debounceFnRef.current) {
        debounceFnRef.current = debounce((val: string) => {
          clearDocumentSelection();
          sfHandler?.searchChanged?.(val);
        }, 350);
      }
      debounceFnRef.current(trimmedValue);
    },
    [clearDocumentSelection, sfHandler, setValue],
  );

  const onChangeHandlerForLongList = useCallback(
    (newVal: string) => {
      setValue((prevVal) => {
        if (newVal.length < 3 && prevVal.length > newVal.length) {
          // If user is deleting chars, might need to trigger empty
          if (!debounceFnRef.current) {
            debounceFnRef.current = debounce((val: string) => {
              clearDocumentSelection();
              sfHandler?.searchChanged?.(val);
            }, 500);
          }
          debounceFnRef.current('');
        }
        return newVal;
      });
      setFreshSearch(true);

      if (!search) return;
      const { isPresentModePage, isAllDocsWithLongList } = search;
      const length = newVal.length;

      const meetsDeepSearch =
        // e.g. partial IDs or references
        (length === 2 && /[a-zA-Z]/.test(newVal[0]) && /[0-9]/.test(newVal[1])) ||
        (length === 3 &&
          /[a-zA-Z]/.test(newVal[0]) &&
          /[0-9]/.test(newVal[1]) &&
          /[0-9]/.test(newVal[2])) ||
        (length > 3 && isPresentModePage) ||
        (length > 2 && isAllDocsWithLongList);

      if (meetsDeepSearch) {
        const trimmedValue = newVal.replace(/\s*\/\s*/g, '/');
        if (!debounceFnRef.current) {
          debounceFnRef.current = debounce((val: string) => {
            clearDocumentSelection();
            sfHandler?.searchChanged?.(val);
          }, 500);
        }
        debounceFnRef.current(trimmedValue);
      }
    },
    [clearDocumentSelection, search, sfHandler, setValue],
  );

  const filterHandler = useCallback(
    (val: FiltersState) => {
      // Adjust filtersCount
      for (const key of Object.keys(val)) {
        setFiltersCount((prevCount) => {
          const oldVal = (filters as any)[key];
          const newVal = (val as any)[key];

          // If array => difference in length
          if (Array.isArray(oldVal) || Array.isArray(newVal)) {
            const oldLen = Array.isArray(oldVal) ? oldVal.length : 0;
            const newLen = Array.isArray(newVal) ? newVal.length : 0;
            return prevCount + (newLen - oldLen);
          } else if (oldVal !== newVal && newVal) {
            // skip certain fields
            if (['goToGlobalPageIdx', 'dateFrom', 'dateTo'].includes(key)) {
              return prevCount;
            }
            return prevCount + 1;
          } else if (oldVal !== newVal && !newVal && prevCount > 0) {
            if (['goToGlobalPageIdx', 'dateFrom', 'dateTo'].includes(key)) {
              return prevCount;
            }
            return prevCount - 1;
          }
          return prevCount;
        });
      }

      setFilters(val);
      clearDocumentSelection();
      sfHandler?.filterChanged?.(val);
    },
    [clearDocumentSelection, filters, sfHandler],
  );

  const handleFilterClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      // If external doc finder filter usage
      if (hasDocFinderFilter && setShowDocFinderFilter) {
        setShowDocFinderFilter(!showDocFinderFilter);
      } else {
        setShowFilters(true);
        setFilterPosition(event.currentTarget);
      }
    },
    [hasDocFinderFilter, setShowDocFinderFilter, showDocFinderFilter],
  );

  const handleClickAway = useCallback(() => {
    setShowFilters(false);
  }, []);

  const formStyle: React.CSSProperties = {
    ...(hasSearch ? {} : { display: 'none' }),
    ...(presentView ? { display: 'flex', flex: 1 } : {}),
    ...style,
  };

  return (
    <>
      <div style={{ width: '100%' }}>
        <Form onSubmit={(e: any) => e.preventDefault()} inline={!presentView} style={formStyle}>
          {search?.showGoToPageFilter && !isBundleTabPage && !(sfHandler && sfHandler.search) && (
            <div style={{ display: 'flex', marginRight: '1rem' }}>
              <FormControl
                type="search"
                placeholder={T.translate('case.goToGlobalPage')}
                style={{ marginRight: '0.5rem' }}
                value={filters.goToGlobalPageIdx}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const numericValue = e.target.value.replace(/[^\d.]+/g, '');
                  const newFilters = { ...filters, goToGlobalPageIdx: numericValue };
                  filterHandler(newFilters);
                  setFilters(newFilters);
                }}
                onFocus={() => {
                  if (value === '') setDocsToDisplay([]);
                  // if (search?.documents && sfHandler?.clearSearchedFiles) {
                  //   sfHandler.clearSearchedFiles(search.documents);
                  // }
                }}
                onBlur={() => {}}
                disabled={!isReady}
              />
            </div>
          )}

          {search && Object.keys(search).length > 0 && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flex: 1,
                cursor: clickToSearch ? 'pointer' : 'auto',
              }}
            >
              <FormControl
                type="search"
                placeholder={search.placeholder || T.translate('case.search')}
                style={{ flex: 1, marginRight: '0.5rem' }}
                value={value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const trimmed = e.target.value.trimStart();

                  // if isPresentModeWithBackEnd, clearing files first
                  if (search.isPresentModeWithBackEnd) {
                    sfHandler?.clearSearchedFiles?.(search.documents || []);
                  }

                  if (
                    (search.isPresentModePage && search.useOnlyIdColForPresentMode) ||
                    search.isAllDocsWithLongList
                  ) {
                    onChangeHandlerForLongList(trimmed);
                  } else {
                    onChangeHandler(trimmed);
                  }

                  if (
                    search.showDeepSearch ||
                    search.isSearchModePage ||
                    search.isDocFinderPage ||
                    search.isPresentModeWithBackEnd
                  ) {
                    debounceDeepSearchFnRef.current?.(trimmed);
                  }
                }}
                onFocus={() => {
                  if (value === '') setDocsToDisplay([]);
                  // if (search?.documents && sfHandler?.clearSearchedFiles) {
                  //   sfHandler.clearSearchedFiles(search.documents);
                  // }
                }}
                onBlur={() => {}}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter' && isClickEnterToPresent) {
                    if (docsToDisplay.length === 1 && docsToDisplay[0].private !== true)
                      handleOpenDoc(docsToDisplay[0]);
                    searchHandler();
                    e.stopPropagation();
                    e.preventDefault();
                  }
                }}
                disabled={!isReady || clickToSearch}
                readOnly={!!clickToSearch}
              />
            </div>
          )}

          {/**
           * Filter button if there's no explicit "search" button or "hideFilterOptions" is false
           */}
          {!(sfHandler && sfHandler.search) && !(search && search.hideFilterOptions) && (
            <ClickAwayListener onClickAway={handleClickAway}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Tooltip title={T.translate('case.filter')}>
                  <div
                    onClick={handleFilterClick}
                    style={{
                      cursor: !isReady ? 'not-allowed' : 'pointer',
                      marginRight: 8,
                    }}
                  >
                    <Badge badgeContent={filtersCount} color="primary">
                      <FilterListRoundedIcon />
                    </Badge>
                  </div>
                </Tooltip>
                <Popper
                  open={Boolean(showFilters)}
                  anchorEl={filterPosition}
                  placement="bottom-end"
                  disablePortal
                >
                  <Filters
                    defaultFilters={filters}
                    filterHandler={filterHandler}
                    documents={search?.documents}
                    columns={search?.selectedColumns}
                    isAnnotation={!!search?.isAnnotation}
                  />
                </Popper>
              </div>
            </ClickAwayListener>
          )}

          {!presentView && (
            <Button
              variant="light"
              onClick={searchHandler}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <FontAwesomeIcon
                icon={faSearch}
                spin={!!search?.isLoading}
                style={{ marginRight: 4 }}
              />
            </Button>
          )}
        </Form>
      </div>
    </>
  );
}

export default SearchAllFiles;
